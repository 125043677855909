import { message } from 'antd';

const getSetBase64 = (
  file: File,
  setBase64: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>,
): void => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => setBase64(reader.result);
  reader.onerror = (error) => {
    message.error(`Lỗi ${error} vui lòng thử lại sau`);
  };
};
export default getSetBase64;
