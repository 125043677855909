import { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectOrder } from 'store/order.slice';
import { Table } from 'antd';
import { formatDate } from 'utils/formatDate';
import { ColumnsType } from 'antd/lib/table';
import { orderHistoryEventValues } from 'libraries/utils/globalVariable';

import { useLocation } from 'react-router';
import styled from 'styled-components';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import FormRefundOrderModal from '../modals/FormRefundOrderModal';
import Button from 'libraries/components/commons/Button';

interface History {
  created_at: string;
  event: string;
  verifier?: string;
  events?: string;
  sale_name: string;
  note: string;
  status?: string;
  reply?: string;
  verify_at?: any;
}
const TableContainer = styled.div`
  overflow: hidden;
`;
function addRefundOder(history: any): any {
  const arr: any = [];
  history?.forEach((element: any) => {
    if (element.status === 'approve' || element.status === 'reject') {
      arr.push(element);
      arr.push({ ...element, events: 'reply', id: element.id + 1000 });
    } else {
      arr.push(element);
    }
  });

  return arr;
}
const OrderHistory = (): ReactElement => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get('event_id');
  const [isFormRefundModalVisible, setIsFormRefundModalVisible] =
    useState(false);
  const [isCheckReply, setIsCheckReply] = useState(false);
  const [dataModal, setDataModal] = useState();
  const orderData = useSelector(selectOrder);

  const handleCanelFormRefund = (): void => {
    setIsFormRefundModalVisible(false);
  };
  const showFormRefundModal = (data: any): void => {
    setDataModal(data);
    setIsFormRefundModalVisible(true);
  };
  useEffect(() => {
    if (eventId && orderData.history && isCheckReply === false) {
      const data2: any = orderData.history.filter(
        (item: any) => item.id === eventId,
      );
      if (data2.length !== 0) {
        setDataModal(data2[0]);
        setIsFormRefundModalVisible(true);
      }
    }
  }, [eventId, orderData.history, isCheckReply]);

  const columns: ColumnsType<History> = [
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '10%',
      render: (item, data) =>
        item !== '' && data.status !== 'reject' && data.status !== 'approve' ? (
          <>{formatDate(item)}</>
        ) : (
          <>{formatDate(data?.verify_at)}</>
        ),
    },
    {
      title: 'Sự kiện',
      dataIndex: 'event',
      width: '15%',
      key: 'event',
      render: (item, data) => {
        for (let i = 0; i < orderHistoryEventValues.length; i++) {
          if (
            item === orderHistoryEventValues[i].value &&
            item !== 'withdraw'
          ) {
            return <div>{orderHistoryEventValues[i].label}</div>;
          }
        }
        if (item === 'withdraw' && data.events !== 'reply') {
          return (
            <Button.Primary onClick={() => showFormRefundModal(data)}>
              Yêu cầu hoàn tiền
            </Button.Primary>
          );
        } else if (item === 'bonus' && data.events !== 'reply') {
          return (
            <Button.Primary onClick={() => showFormRefundModal(data)}>
              Yêu cầu thưởng
            </Button.Primary>
          );
        } else if (
          item === 'bonus' &&
          data.events === 'reply' &&
          data.status === 'approve'
        ) {
          return <div style={{ color: '#17a2b8' }}>Duyệt thưởng</div>;
        } else if (
          item === 'bonus' &&
          data.events === 'reply' &&
          data.status === 'reject'
        ) {
          return <div style={{ color: 'red' }}>Từ chối thưởng</div>;
        } else if (
          item === 'withdraw' &&
          data.events === 'reply' &&
          data.status === 'approve'
        ) {
          return <div style={{ color: '#17a2b8' }}>Duyệt hoàn tiền</div>;
        } else {
          return <div style={{ color: 'red' }}>Từ chối hoàn tiền</div>;
        }
      },
    },

    {
      title: 'Người xử lý',
      dataIndex: 'sale_name',
      key: 'sale_name',
      render: (item, data) => {
        const isRefundRequest =
          (data.event === 'withdraw' || data.event === 'bonus') &&
          data.events !== 'reply';
        const isProcessed =
          data.events === 'reply' &&
          (data.status === 'approve' || data.status === 'reject');

        if (isRefundRequest) {
          return data.sale_name;
        } else if (isProcessed) {
          return data.verifier;
        } else {
          return item;
        }
      },
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      render: (item) => item !== '' && <>{formatMoneyVnd(item)}</>,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      render: (item, data) =>
        data.events !== 'reply' ? <div>{item}</div> : <div>{data.reply}</div>,
    },
  ];

  return (
    <TableContainer>
      {orderData.history && (
        <Table
          dataSource={addRefundOder(orderData.history)}
          pagination={false}
          columns={columns}
          bordered
          rowKey="id"
        />
      )}
      {dataModal && (
        <FormRefundOrderModal
          isBackMoneyModalVisible={isFormRefundModalVisible}
          handleCancel={handleCanelFormRefund}
          dataModal={dataModal}
          setIsCheckReply={setIsCheckReply}
        />
      )}
    </TableContainer>
  );
};

export default OrderHistory;
