import { ReactElement } from 'react';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Link from 'libraries/components/commons/Link';
import { LeftOutlined } from '@ant-design/icons';
import { generatePath, useParams } from 'react-router';
import { route } from 'routers/routeNames';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import { message, Tabs } from 'antd';
import GeneralInfo from './tabs/GeneralInfo';
import {
  useExportPasscodeMutation,
  useGetUserInfoQuery,
  useResetPasswordMutation,
} from 'services/user.service';
import NotFound from 'components/commons/NotFound';
import Breadcrumb from 'components/commons/Breadcrumb';
import {
  selectUserInfo,
  selectUserLearnProcess,
  selectUserTransaction,
} from 'store/user.slice';
import { useSelector } from 'react-redux';
import UserTransaction from './tabs/UserTransaction';
import useQuery from 'libraries/hooks/query';
import { useHistory } from 'react-router-dom';
import LearnProcess from './tabs/learnProcess/LearnProcess';
import { copy } from 'clipboard';

const { TabPane } = Tabs;

const GetUser = (): ReactElement => {
  const { userId } = useParams<{ userId: string }>();
  const { isError, isLoading } = useGetUserInfoQuery({
    user_id: userId,
    code: '',
  });

  const user = useSelector(selectUserInfo);
  const userTransaction = useSelector(selectUserTransaction);

  const [resetPassword] = useResetPasswordMutation();
  const [exportPasscode] = useExportPasscodeMutation();
  const searchParams = useQuery();
  const tabKey = searchParams.get('tab') ?? '1';
  const history = useHistory();

  const resetPasswordBtn = () => {
    resetPassword(userId)
      .unwrap()
      .then(() => message.success('Reset mật khẩu thành công'))
      .catch((error) => {
        message.error(error.data);
      });
  };
  const handleTabsChange = (key: string) => {
    history.replace({ search: `?tab=${key}` });
  };

  const sendInfoUserToParents = () => {
    exportPasscode(userId)
      .unwrap()
      .then((data) => {
        // Adding text value to clipboard using copy function
        const isCopy = copy(data.link);

        //Dispalying notification
        if (isCopy) {
          message.success('Copied to Clipboard');
        }
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    return <NotFound subTitle="Không tìm thấy user này" />;
  }
  return (
    <>
      {user && (
        <Breadcrumb
          secondaryLink={generatePath(route.user.manage)}
          secondaryText="User"
          text={user.full_name}
        />
      )}

      <LinksContainer>
        <Link.Default to="#" onClick={() => history.goBack()}>
          <LeftOutlined /> Quay lại
        </Link.Default>
        <Link.Warning to={'#'} onClick={resetPasswordBtn}>
          Reset mật khẩu
        </Link.Warning>
        <Link.Primary to={'#'} onClick={sendInfoUserToParents}>
          Gửi thông tin phụ huynh
        </Link.Primary>
      </LinksContainer>

      <BorderTopBoxContent>
        <Tabs defaultActiveKey={tabKey} onChange={handleTabsChange}>
          <TabPane tab="Thông tin chung" key="1">
            <GeneralInfo />
          </TabPane>
          <TabPane tab="Quá trình học tập" key="2">
            <LearnProcess />
          </TabPane>
          <TabPane tab="Giao dịch học phí" key="3">
            <UserTransaction transactionsList={userTransaction} />
          </TabPane>
        </Tabs>
      </BorderTopBoxContent>
    </>
  );
};
export default GetUser;
