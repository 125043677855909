import React, { ReactElement } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/auth.slice';
import { useGetLoggedInUserQuery } from 'libraries/services/auth.service';
import Main from 'layout/Main';
import LoadingSpin from 'libraries/components/LoadingSpin';

const App = (): ReactElement => {
  const dispatch = useDispatch();
  const { isLoading, isError, data } = useGetLoggedInUserQuery();

  // Waiting check authentication
  if (isLoading) {
    return <LoadingSpin />;
  }

  // Render Login Page if not authenticated
  if (isError) {
    dispatch(setUser({}));
  }

  // Authenticated, update state in store
  if (data) {
    dispatch(setUser(data));
  }

  return (
    <Router>
      <Main />
    </Router>
  );
};

export default App;
