import { LeftOutlined } from '@ant-design/icons';
import { message } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { ReactElement, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useUpdateBannerPositionMutation } from 'services/configure.service';
import { selectBanners, updateBannersPosition } from 'store/promotion.slice';
import styled from 'styled-components';

const Wrapper = styled.div``;

const grid = 8;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  color: '#000',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

const SortBannerDetail = (): ReactElement => {
  const dispatch = useDispatch();
  const banners = useSelector(selectBanners);

  const [saveBannersPosition] = useUpdateBannerPositionMutation();

  const [sort, setSort] = useState(false);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.source.index !== result.destination.index) {
      dispatch(
        updateBannersPosition({
          fromIndex: result.source.index,
          toIndex: result.destination?.index,
        }),
      );
      setSort(true);
    }
  };

  const handleSavePosition = () => {
    const data = banners.reduce((arr: number[], item) => {
      arr.push(item.id);
      return arr;
    }, []);
    saveBannersPosition({ banner_ids: data })
      .unwrap()
      .then(() => {
        message.success('Lưu vị trí banner thành công!');
        setSort(false);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.configure.banner.list)}
        secondaryText="Banner trang chủ"
        text="Sắp xếp vị trí banner"
      />
      <LinksContainer style={{ display: 'flex' }}>
        <Link.Default to={generatePath(route.configure.banner.list)}>
          <LeftOutlined /> Quay lại
        </Link.Default>
        <Button.Primary
          onClick={() => handleSavePosition()}
          disabled={!sort}
          style={{ height: '36px' }}
        >
          Lưu
        </Button.Primary>
      </LinksContainer>
      <Wrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {banners.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Wrapper>
    </>
  );
};

export default SortBannerDetail;
