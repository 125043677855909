import { format, formatDistanceToNow, isBefore } from 'date-fns';
import vi from 'date-fns/locale/vi';

export const formatDate = (date: string, dateType = 'dd-MM-yyyy'): string => {
  return format(new Date(date), dateType);
};

export const timeDistanceToNow = (dateTime: string): string => {
  const [date, month, year, hour, minute, second] = format(
    new Date(dateTime),
    'dd-MM-yyyy H:mm:ss',
  ).split(/\D/);

  return formatDistanceToNow(
    new Date(+year, +month - 1, +date, +hour, +minute, +second),
    {
      locale: vi,
      addSuffix: true,
    },
  );
};

export const checkBeforeDate = (dateTime: string): boolean => {
  const [dateCurrent, monthCurrent, yearCurrent] = format(
    new Date(),
    'dd-MM-yyyy H:mm:ss',
  ).split(/\D/);
  const [date, month, year] = format(
    new Date(dateTime),
    'dd-MM-yyyy H:mm:ss',
  ).split(/\D/);

  return isBefore(
    new Date(+year, +month, +date),
    new Date(+yearCurrent, +monthCurrent, +dateCurrent),
  );
};
