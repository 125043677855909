import { ReactElement, useEffect, useState } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import {
  Col,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  DatePicker,
} from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';

import {
  classBranchValues,
  classStatusValues,
  classStudiedDayValues,
  classTypeValues,
  ROLE_SALE,
  ROLE_TRAINER,
} from 'libraries/utils/globalVariable';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useCreateClassMutation,
  useGetListCoursesQuery,
} from 'services/classes.service';
import { useGetUsersByRoleQuery } from 'services/user.service';
import disabledSubmissionDate from 'libraries/utils/disabledSubmissionDate';
import { useGetTracksListQuery } from 'services/track.service';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';

const { Option } = Select;
const { TextArea } = Input;

// const config = {
//   rules: [
//     {
//       type: 'object' as const,
//       required: true,
//       message: 'Ngày khai giảng dự kiến',
//     },
//   ],
// };

const CreateClassContent = (): ReactElement => {
  const [valueTrack, setValueTrack] = useState<string>('');
  const history = useHistory();
  const { data: teachers } = useGetUsersByRoleQuery(ROLE_TRAINER);
  const { data: salers } = useGetUsersByRoleQuery(ROLE_SALE);
  const { data: courses } = useGetListCoursesQuery();
  const { data: tracks } = useGetTracksListQuery(
    `keyword=&master_id=&status=active`,
  );
  const [numberMoney, setNumberMoney] = useState('');
  const [numberMoneyOnLab, setNumberMoneyOnLab] = useState('');
  const [tmpType, setTmpType] = useState('lab');

  const [createNewClass] = useCreateClassMutation();

  const [form] = Form.useForm();

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    createNewClass({
      ...values,
      start_time:
        values.start_time !== undefined
          ? values.start_time.utc(true).startOf('D').toISOString()
          : null,
      day_of_week:
        values.day_of_week && values.day_of_week.length > 0
          ? values.day_of_week.map((item: string) => Number(item))
          : ([] as number[]),
    })
      .unwrap()
      .then(() => {
        message.success('Tạo lớp học mới thành công!');
        setTimeout(() => {
          history.push(generatePath(route.class.list));
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      status: tmpType === 'video' ? 'active' : 'scheduled',
    });
  }, [tmpType]);

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.class.list)}
        secondaryText="Lớp học"
        text="Tạo lớp"
      />

      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={{
          type: 'lab',
          status: 'scheduled',
        }}
      >
        <LinksContainer>
          <Link.Default to={generatePath(route.class.list)}>
            <LeftOutlined /> Quay lại
          </Link.Default>
          <Button.Primary
            type="primary"
            htmlType="submit"
            style={{ height: '35px' }}
          >
            Tạo lớp
          </Button.Primary>
        </LinksContainer>
        <BorderTopBoxContent>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={14}>
              <Form.Item
                label={<FormLabel label="Tên lớp" />}
                name="name"
                rules={[
                  { required: true, message: 'Xin vui lòng nhập tên lớp!' },
                ]}
              >
                <Input />
              </Form.Item>

              {courses && (
                <Form.Item
                  label={<FormLabel label="Khoá học" />}
                  name="course_id"
                  rules={[
                    { required: true, message: 'Xin vui lòng chọn khoá học!' },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Danh sách khoá học"
                  >
                    {courses.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              {tracks && (
                <Form.Item
                  label={<FormLabel label="Lộ trình" require={false} />}
                  name="track_id"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Danh sách lộ trình"
                    onChange={(value) => setValueTrack(value)}
                  >
                    {tracks.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                name="note"
                label={<FormLabel label="Ghi chú" require={false} />}
              >
                <TextArea rows={2} />
              </Form.Item>
              {teachers && (
                <Form.Item
                  label={<FormLabel label="Giảng viên" require={false} />}
                  name="teacher_list"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Danh sách giảng viên"
                  >
                    {teachers.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.info}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              {salers && (
                <Form.Item
                  label={<FormLabel label="Nhân viên marketing" />}
                  name="sale_id"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng chọn nhân viên marketing!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    placeholder="Danh sách nhân viên"
                  >
                    {salers.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.info}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label={<FormLabel label="Hình thức lớp" />}
                name="type"
              >
                <Select onChange={(value: string) => setTmpType(value)}>
                  {classTypeValues.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {(tmpType === 'lab' ||
                tmpType === 'mixed' ||
                tmpType === 'video') &&
                valueTrack === '' && (
                  <div style={{ position: 'relative' }}>
                    <Form.Item
                      label={
                        <FormLabel
                          label={
                            tmpType === 'video' ? 'Giá tiền' : 'Giá học Onlab'
                          }
                          require={tmpType === 'video' ? true : false}
                        />
                      }
                      name="price_lab"
                      rules={[
                        {
                          required: tmpType === 'video' ? true : false,
                          message: 'Xin vui lòng nhập giá tiền!',
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        placeholder="0"
                        onChange={(value: any) => setNumberMoneyOnLab(value)}
                      />
                    </Form.Item>
                    <p
                      style={{
                        marginTop: '-24px',
                        fontWeight: '600',
                        position: 'absolute',
                        top: '135%',
                        left: '0;',
                        fontSize: '14px',
                      }}
                    >
                      {convertCurrencyToWords(+numberMoneyOnLab)}
                    </p>
                  </div>
                )}
              {(tmpType === 'live' || tmpType === 'mixed') && (
                <div style={{ position: 'relative' }}>
                  <Form.Item
                    label={
                      <FormLabel
                        label="Giá học trực tuyến có tương tác"
                        require={false}
                      />
                    }
                    name="price_live"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      placeholder="0"
                      onChange={(value: any) => setNumberMoney(value)}
                    />
                  </Form.Item>
                  <p
                    style={{
                      marginTop: '-24px',
                      fontWeight: '600',
                      position: 'absolute',
                      top: '135%',
                      left: '0;',
                      fontSize: '14px',
                    }}
                  >
                    {convertCurrencyToWords(+numberMoney)}
                  </p>
                </div>
              )}
            </Col>
            <Col xs={24} md={10}>
              <Form.Item label={<FormLabel label="Trạng thái" />} name="status">
                <Select disabled>
                  {classStatusValues.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {tmpType !== 'video' && (
                <>
                  <Form.Item
                    name="start_time"
                    label={
                      <FormLabel
                        label="Ngày khai giảng dự kiến"
                        require={false}
                      />
                    }
                  >
                    <DatePicker
                      disabledDate={disabledSubmissionDate}
                      format="DD-MM-YYYY"
                    />
                  </Form.Item>
                  <Form.Item
                    name="day_of_week"
                    label={
                      <FormLabel label="Ngày học trong tuần" require={false} />
                    }
                  >
                    <Select
                      optionFilterProp="children"
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Chọn ngày học"
                    >
                      {classStudiedDayValues.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={
                      <FormLabel label="Giờ bắt đầu buổi học" require={false} />
                    }
                    name="start_at"
                  >
                    <Input placeholder="18h30" />
                  </Form.Item>
                  <Form.Item
                    label={
                      <FormLabel
                        label="Giờ kết thúc buổi học"
                        require={false}
                      />
                    }
                    name="end_at"
                  >
                    <Input placeholder="21h30" />
                  </Form.Item>
                  <Form.Item
                    label={<FormLabel label="Cơ sở" require={false} />}
                    name="branch_name"
                  >
                    <Select placeholder="Chọn cơ sở">
                      {classBranchValues.map((item, index) => (
                        <Option value={item.value} key={index}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={<FormLabel label="Số buổi" require={false} />}
                    name="lessons"
                    rules={[{ type: 'number' }]}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder="0" />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>
        </BorderTopBoxContent>
      </Form>
    </>
  );
};

export default CreateClassContent;
