import { ReactElement } from 'react';
import styled from 'styled-components';

interface PropsType {
  handleExportExcel: () => void;
}

const Anchor = styled('a')`
  display: inline-block;
  font-weight: 600;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 3px;
  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
  color: #fff;
  background-color: #3c8dbc;
  border-color: #367fa9;
  &:hover,
  &:active {
    color: #fff;
    background-color: #367fa9;
    border-color: #367fa9;
  }
`;

const ExportExcel = ({ handleExportExcel }: PropsType): ReactElement => {
  return <Anchor onClick={handleExportExcel}>Xuất Excel</Anchor>;
};

export default ExportExcel;
