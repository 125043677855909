import Modal from 'components/commons/Modal';
import { Form, Select } from 'antd';
import Button from 'libraries/components/commons/Button';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectItemsHasFilter, setItemToOrder } from 'store/order.slice';
import { useState } from 'react';
interface ItemModalProps {
  itemModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  setIsChangeItems: (value: boolean) => void;
}
interface Value {
  item: string;
  choose_type: string;
}

const { Option } = Select;

const ItemModal = ({
  itemModalVisible,
  handleOk,
  handleCancel,
  setIsChangeItems,
}: ItemModalProps): ReactElement => {
  const items = useSelector(selectItemsHasFilter);
  const [isMixed, setIsMixed] = useState(false);
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);

  const handleSelectItem = (value: string) => {
    setIsSelected(true);
    const temp = items.filter((item) => item.name === value)[0];

    if (temp.class_type === 'mixed') {
      setIsMixed(true);
    } else {
      setIsMixed(false);
    }
  };

  const handleFinish = (value: Value) => {
    let { choose_type } = value;
    choose_type = choose_type || '';

    const temp = items.filter((item) => item.name === value.item)[0];
    const cloneObj = { ...temp, choose_type };
    dispatch(setItemToOrder(cloneObj));
    setIsChangeItems(true);
    handleOk();
  };

  return (
    <Modal
      title="Thêm item vào đơn hàng"
      visible={itemModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form layout="vertical" onFinish={handleFinish}>
        <Form.Item label="Chọn item" name="item">
          <Select onChange={handleSelectItem} showSearch>
            {items.map((item) => {
              const { price_id, name } = item;
              return (
                <Option key={price_id} value={name}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {isMixed && (
          <Form.Item label="Chọn kiểu học" name="choose_type">
            <Select>
              <Option key="lab" value="lab">
                Học phòng lab
              </Option>
              <Option key="live" value="live">
                Học online
              </Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button.Default htmlType="submit" disabled={!isSelected}>
              Thêm vào đơn
            </Button.Default>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ItemModal;
