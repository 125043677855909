import { UserWithRole } from 'models/user.model';
import { ReactElement } from 'react';
import { Table } from 'antd';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';

interface PropsType {
  salers: UserWithRole[];
}

const SaleTable = ({ salers }: PropsType): ReactElement => {
  const columns: ColumnsType<UserWithRole> = [
    {
      key: 'full_name',
      width: '20%',
      title: 'Họ tên',
      dataIndex: 'full_name',
      render: (_text, item) => (
        <RouterLink
          to={generatePath(route.report.sale.detail, { saleId: item.id })}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          {item.full_name}
        </RouterLink>
      ),
    },
    {
      key: 'email',
      width: '20%',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: 'phone',
      width: '15%',
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
    {
      key: 'bank_name',
      width: '30%',
      title: 'Ngân hàng',
      dataIndex: 'bank_name',
    },
    {
      key: 'bank_account',
      width: '15%',
      title: 'Số tài khoản',
      dataIndex: 'bank_account',
    },
  ];

  return (
    <TableContainer>
      <Table<UserWithRole>
        columns={columns}
        dataSource={salers}
        pagination={{ showSizeChanger: false }}
        rowKey="id"
        bordered
      />
    </TableContainer>
  );
};

export default SaleTable;
