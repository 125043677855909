import NotFoundPage from 'libraries/pages/NotFoundPage';
import { IRoute } from 'models/route.model';
import CreatePost from 'pages/blogs/create/CreatePost';
import PostDetail from 'pages/blogs/detail/PostDetail';
import ListPosts from 'pages/blogs/list/ListPosts';
import OtherPosts from 'pages/blogs/other/OtherPosts';
import OwnPosts from 'pages/blogs/own/OwnPosts';
import ConvertTag from 'pages/category/convertTag/ConvertTag';
import SubjectsList from 'pages/category/subjects/SubjectsList';
import TagsList from 'pages/category/tags/TagsList';
import AttendancePage from 'pages/classes/attendance/AttendancePage';
import CreateClass from 'pages/classes/create/CreateClass';
import ClassDetail from 'pages/classes/detail/ClassDetail';
import ListClasses from 'pages/classes/list/ListClasses';
import PreClass from 'pages/classes/pre/PreClass';
import Reserve from 'pages/classes/reseve/Reseve';
import ClassStudent from 'pages/classes/student/ClassStudent';
import CreateCourse from 'pages/course/create/CreateCourse';
import CoursePage from 'pages/course/detail/CoursePage';
import CourseList from 'pages/course/list/CourseList';
import CrawlData from 'pages/crawl/CrawData';
import Dashboard from 'pages/dashboard/Dashboard';
import Advisory from 'pages/orders/advisory/Advisory';
import AdvisoryDetail from 'pages/orders/advisory/detail/AdvisoryDetail';
import CreateOrder from 'pages/orders/create/CreateOrder';
import Debt from 'pages/orders/debt/Debt';
import OrderDetailPage from 'pages/orders/detail/OrderDetailPage';
import OrderEvent from 'pages/orders/event/OrderEventPage';
import ListOrders from 'pages/orders/list/ListOrders';
import CreateProject from 'pages/project/create/CreateProject';
import ProjectDetailPage from 'pages/project/detail/ProjectDetailPage';
import ProjectsPage from 'pages/project/list/ProjectsPage';
import CreateBanner from 'pages/banner/create/CreateBanner';
import ListBanners from 'pages/banner/list/ListBanners';
import SortBanner from 'pages/banner/sort/SortBanner';
import CreateVoucher from 'pages/promotions/create-voucher/CreateVoucher';
import VoucherPage from 'pages/promotions/detail/VoucherPage';
import ListPromotions from 'pages/promotions/list/ListPromotion';
import FinanceReport from 'pages/report/finance/FinanceReport';
import SaleDetail from 'pages/report/sale/detail/SaleDetail';
import SaleList from 'pages/report/sale/list/SaleList';
import CreateSchedule from 'pages/schedule/create/CreateSchedule';
import ScheduleDetail from 'pages/schedule/detail/ScheduleDetail';
import SchedulesList from 'pages/schedule/list/SchedulesList';
import CreateTrack from 'pages/track/create/CreateTrack';
import CreateTrackMaster from 'pages/track/create/CreateTrackMaster';
import TrackDetail from 'pages/track/detail/TrackDetail';
import TrackMaster from 'pages/track/detail/TrackMaster';
import TracksList from 'pages/track/list/TracksList';
import CreateUser from 'pages/users/create/CreateUser';
import ManageUsers from 'pages/users/manage/ManageUsers';
import UserInfo from 'pages/users/user/UserInfo';
import { route } from './routeNames';
import BannerDetail from 'pages/banner/detail/BannerDetail';
import TeachersReport from 'pages/report/teacher/list/TeachersReport';
import TeacherDetail from 'pages/report/teacher/detail/TeacherDetail';
import CreatePayment from 'pages/report/pay/create/CreatePayment';
import PaymentDetail from 'pages/report/pay/detail/PaymentDetail';
import CreateReceipt from 'pages/report/receipt/create/CreateReceipt';
import ReceiptDetail from 'pages/report/receipt/detail/ReceiptDetail';
import SummaryListReport from '../pages/report/summary/list/SummaryListReport';
import SummaryDetail from '../pages/report/summary/detail/SummaryDetail';
import ConfigSalaries from '../pages/config/ConfigSalaries';
import HistoryFixedSalary from '../pages/config/tabs/HistoryFixedSalary';
import ManageTeacher from 'pages/users/manage/ManageTeacher';
import ListClassesCurrent from 'pages/classes/list/ListClassesCurrent';
import KpiSaleList from 'pages/report/sale/list/KpiSaleList';
import GetList from 'pages/blogs/list20days/GetListPost';

const routes: IRoute[] = [
  // dashboard
  {
    path: route.dashboard,
    title: 'Dashboard',
    component: Dashboard,
  },

  // report
  {
    path: route.report.finance,
    title: 'Báo cáo thu chi',
    component: FinanceReport,
  },
  {
    path: route.report.summary.list,
    title: 'Tổng kết lương',
    component: SummaryListReport,
  },
  {
    path: route.report.summary.detail,
    title: 'Thu nhập',
    component: SummaryDetail,
  },

  {
    path: route.report.pay.create,
    title: 'Tạo phiếu chi',
    component: CreatePayment,
  },

  {
    path: route.report.pay.detail,
    title: 'Phiếu chi',
    component: PaymentDetail,
  },
  {
    path: route.report.receipt.create,
    title: 'Tạo phiếu thu',
    component: CreateReceipt,
  },

  {
    path: route.report.receipt.detail,
    title: 'Phiếu thu',
    component: ReceiptDetail,
  },

  {
    path: route.report.teacher.list,
    title: 'Giảng viên',
    component: TeachersReport,
  },
  {
    path: route.report.teacher.detail,
    title: 'Thu nhập của giảng viên',
    component: TeacherDetail,
  },
  {
    path: route.report.sale.list,
    title: 'Nhân viên tuyển sinh',
    component: SaleList,
  },
  {
    path: route.report.kpi_sale.list,
    title: 'Nhân viên tuyển sinh',
    component: KpiSaleList,
  },
  {
    path: route.report.sale.detail,
    title: 'Thông tin nhân viên tuyển sinh',
    component: SaleDetail,
  },
  // {
  //   path: route.report.classDebt,
  //   title: 'Nộp học phí từng lớp',
  //   component: ClassDebtReport,
  // },
  // {
  //   path: route.report.attendance,
  //   title: 'Tình hình đi học từng lớp',
  //   component: ClassAttendance,
  // },

  // blogs
  {
    path: route.blog.list,
    title: 'Tất cả bài viết',
    component: ListPosts,
  },
  {
    path: route.blog.list_twenty_days,
    title: 'Bài viết 20 ngày',
    component: GetList,
  },
  {
    path: route.blog.own,
    title: 'Bài viết của tôi',
    component: OwnPosts,
  },
  {
    path: route.blog.other,
    title: 'Trang tĩnh',
    component: OtherPosts,
  },
  {
    path: route.blog.create,
    title: 'Tạo bài viết',
    component: CreatePost,
  },
  {
    path: route.blog.detail,
    title: 'Chi tiết bài viết',
    component: PostDetail,
  },

  // crawl
  {
    path: route.crawl.create,
    title: 'Crawl Data',
    component: CrawlData,
  },

  // orders
  {
    path: route.order.list,
    title: 'Danh sách đơn hàng',
    component: ListOrders,
  },
  {
    path: route.order.create,
    title: 'Tạo đơn hàng',
    component: CreateOrder,
  },
  {
    path: route.order.advisory,
    title: 'Tư vấn',
    component: Advisory,
  },
  {
    path: route.order.advisoryDetail,
    title: 'Chi tiết tư vấn',
    component: AdvisoryDetail,
  },
  {
    path: route.order.debt,
    title: 'Nợ',
    component: Debt,
  },
  {
    path: route.order.detail,
    title: 'Chi tiết đơn hàng',
    component: OrderDetailPage,
  },
  {
    path: route.order.event,
    title: 'Sự kiện đơn hàng',
    component: OrderEvent,
  },

  // promotions
  {
    path: route.promotion.list,
    title: 'Danh sách khuyến mãi',
    component: ListPromotions,
  },
  {
    path: route.promotion.createVoucher,
    title: 'Tạo voucher',
    component: CreateVoucher,
  },
  {
    path: route.promotion.detail,
    title: 'Chi tiết voucher',
    component: VoucherPage,
  },

  // class
  {
    path: route.class.list,
    title: 'Danh sách lớp học',
    component: ListClasses,
  },
  {
    path: route.class.create,
    title: 'Tạo lớp học',
    component: CreateClass,
  },
  {
    path: route.class.listCurrent,
    title: 'Danh sách lớp học hôm nay',
    component: ListClassesCurrent,
  },
  {
    path: route.class.pre,
    title: 'Pre Class',
    component: PreClass,
  },
  {
    path: route.class.reserve,
    title: 'Reseve',
    component: Reserve,
  },
  {
    path: route.class.detail,
    title: 'Thông tin lớp học',
    component: ClassDetail,
  },
  {
    path: route.class.student,
    title: 'Thông tin học viên',
    component: ClassStudent,
  },
  {
    path: route.class.attendance,
    title: 'Điểm danh',
    component: AttendancePage,
  },

  // Schedule
  {
    path: route.schedule.list,
    title: 'Danh sách',
    component: SchedulesList,
  },
  {
    path: route.schedule.create,
    title: 'Tạo lịch mới',
    component: CreateSchedule,
  },
  {
    path: route.schedule.detail,
    title: 'Chi tiết lịch khai giảng',
    component: ScheduleDetail,
  },

  // Course
  {
    path: route.course.list,
    title: 'Danh sách khoá học',
    component: CourseList,
  },
  {
    path: route.course.create,
    title: 'Tạo khoá học',
    component: CreateCourse,
  },
  {
    path: route.course.detail,
    title: 'Thông tin khoá học',
    component: CoursePage,
  },

  // Users
  {
    path: route.user.manage,
    title: 'Quản lý users',
    component: ManageUsers,
  },
  {
    path: route.user.manage_teacher,
    title: 'Quản lý giảng viên',
    component: ManageTeacher,
  },
  {
    path: route.user.create,
    title: 'Tạo user',
    component: CreateUser,
  },
  {
    path: route.user.info,
    title: 'Thông tin user',
    component: UserInfo,
  },
  {
    path: route.notFound,
    title: 'Not Found',
    component: NotFoundPage,
  },

  // Category tag
  {
    path: route.category.tags,
    title: 'Danh sách tag',
    component: TagsList,
  },
  {
    path: route.category.subjectsList,
    title: 'Danh mục tag',
    component: SubjectsList,
  },
  {
    path: route.category.convertTag,
    title: 'Đổi tag bài viết',
    component: ConvertTag,
  },

  // Track
  {
    path: route.track.list,
    title: 'Danh sách lộ trình',
    component: TracksList,
  },
  {
    path: route.track.create,
    title: 'Tạo track',
    component: CreateTrack,
  },
  {
    path: route.track.createTrackMaster,
    title: 'Tạo track master',
    component: CreateTrackMaster,
  },
  {
    path: route.track.master,
    title: 'Track master',
    component: TrackMaster,
  },
  {
    path: route.track.detail,
    title: 'Chi tiết lộ trình',
    component: TrackDetail,
  },

  // Project
  {
    path: route.project.list,
    title: 'Sản phẩm học viên',
    component: ProjectsPage,
  },
  {
    path: route.project.create,
    title: 'Đăng sản phẩm',
    component: CreateProject,
  },
  {
    path: route.project.detail,
    title: 'Chi tiết sản phẩm',
    component: ProjectDetailPage,
  },

  // Configure
  {
    path: route.configure.banner.list,
    title: 'Banner trang chủ',
    component: ListBanners,
  },
  {
    path: route.configure.banner.create,
    title: 'Tạo banner',
    component: CreateBanner,
  },
  {
    path: route.configure.banner.sort,
    title: 'Sắp xếp vị trí banner',
    component: SortBanner,
  },
  {
    path: route.configure.banner.detail,
    title: 'Chỉnh sửa banner',
    component: BannerDetail,
  },
  {
    path: route.configure.salary.index,
    title: 'Cấu hình lương',
    component: ConfigSalaries,
  },
  {
    path: route.configure.salary.fix_salary_history,
    title: 'Lịch sử lương cứng',
    component: HistoryFixedSalary,
  },
];

export default routes;
