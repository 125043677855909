import { ReactElement, useEffect, useState } from 'react';
import Modal from 'components/commons/Modal';
import { selectOrder } from 'store/order.slice';
import { useSelector } from 'react-redux';
import { Col, Input, message, Row } from 'antd';
import Form from 'libraries/components/form/Form';
import Button from 'libraries/components/commons/Button';
import FormLabel from 'libraries/components/form/FormLabel';
import { useParams } from 'react-router';
import {
  useRefundOrderMutation,
  useVerifyRefundMutation,
} from 'services/order.service';
import disabledSubmissionDate from 'libraries/utils/disabledSubmissionDate';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import styled from 'styled-components';
import { useGetLoggedInUserQuery } from 'libraries/services/auth.service';
interface Bank {
  id: number;
  name: string;
  code: string;
  bin: string;
  shortName: string;
  logo: string;
  transferSupported: number;
  lookupSupported: number;
  short_name: string;
  support: number;
  isTransfer: number;
  swift_code: string;
}
interface FormRefundOrderModalProps {
  isBackMoneyModalVisible: boolean;
  handleCancel: () => void;
  dataModal: any;
  setIsCheckReply: any;

  refetch: any;
}
const Image = styled.div`
  margin-bottom: 12px;
  max-width: 250px;
  @media (max-width: 1250px) {
    max-width: 200px;
  }
`;

const { TextArea } = Input;
function getShortNameByBin(banks: Bank[], bin: string): string {
  const bank = banks.find((b) => b.bin === bin);
  return bank ? bank.shortName : '';
}
function getTitle(events: string, status: string): string {
  if (events == 'bonus') return 'Yêu cầu thưởng';
  if (events == 'withdraw' && status == 'pending') {
    return 'Yêu cầu hoàn tiền';
  } else if (events == 'withdraw' && status == 'approve') {
    return 'Duyệt hoàn tiền';
  } else {
    return 'Từ chối hoàn tiền';
  }
}

const FormRefundOrderModalPay = ({
  handleCancel,
  isBackMoneyModalVisible,
  dataModal,
  setIsCheckReply,

  refetch,
}: FormRefundOrderModalProps): ReactElement => {
  const {
    amount,
    bank_account,
    bank_name,
    event,
    event_id,
    note,
    reply,
    receiver_full_name,
    receiver_email,
    receiver_phone,
    status,
  } = dataModal;

  const [contentQR, setContentQR] = useState('');
  const [numberMoney, setNumberMoney] = useState('');
  const [isCheck, setIsCheck] = useState(false);
  const [requiedRefund, setRequiedRefund] = useState('');
  const dataOrder = useSelector(selectOrder);
  const [banks, setBanks] = useState<Bank[]>([]);
  const { full_name, email, phone, items } = dataOrder;
  const { paid } = dataOrder;

  const { data } = useGetLoggedInUserQuery();

  useEffect(() => {
    refetch();
  }, [isCheck]);
  const [refundOrder] = useRefundOrderMutation();
  const [verifyRefund] = useVerifyRefundMutation();
  const listItemString = items ? items.map((item) => item.name).toString() : '';
  useEffect(() => {
    fetch('https://api.vietqr.io/v2/banks')
      .then((res) => res.json())
      .then((data) => setBanks(data.data));
    if (note == '' && event == 'bonus') {
      setContentQR('Thưởng cho học viên');
    } else if (note == '' && event == 'withdraw') {
      setContentQR('Hoàn lại học phí');
    } else if (note?.trim() != '') {
      setContentQR(note);
    }
  }, []);

  useEffect(() => {
    if (note == '' && event == 'bonus') {
      setContentQR('Thưởng cho học viên');
    } else if (note == '' && event == 'withdraw') {
      setContentQR('Hoàn lại học phí');
    } else if (note?.trim() != '') {
      setContentQR(note);
    }
  }, [note]);

  const options = banks?.map((bank: any) => ({
    value: bank.bin,
    label: ` ${bank.shortName} (${bank.name})`,
    shortName: bank.shortName,
  }));

  const handleOk = () => {
    console.log('OK');
  };
  const handleClose = () => {
    handleCancel();
  };

  const handleApproveRefund = () => {
    setIsCheckReply(true);
    setIsCheck(!isCheck);
    verifyRefund({
      event_id: event_id,
      status: 'approve',
      reply: requiedRefund,
    })
      .unwrap()
      .then(() => {
        message.success('Xử lý thành công');
        handleCancel();
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };
  const handleRejectRefund = () => {
    if (requiedRefund.trim() == '') {
      message.error('Vui lòng phản hồi lý do từ chối !');
      return;
    }
    setIsCheck(!isCheck);
    setIsCheckReply(true);
    verifyRefund({
      event_id: event_id,
      status: 'reject',
      reply: requiedRefund,
    })
      .unwrap()
      .then(() => {
        message.success('Xử lý thành công');
        handleCancel();
      })
      .catch((error: any) => {
        message.error(error.data);
      });
  };

  return (
    <Modal
      title={getTitle(event, status)}
      width={720}
      visible={isBackMoneyModalVisible}
      onOk={handleOk}
      onCancel={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[]}
    >
      <Row>
        <Col xs={24} sm={24} md={12}>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs={8}>Họ tên:</Col>

            <Col xs={16}>{receiver_full_name}</Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs={8}>Email:</Col>
            <Col xs={16}>{receiver_email}</Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs={8}>SDT:</Col>
            <Col xs={16}>{receiver_phone}</Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs={8}>Ngân hàng:</Col>
            <Col xs={16}>{bank_name}</Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs={8}>STK:</Col>
            <Col xs={16}>{bank_account}</Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs={8}>Đã thanh toán:</Col>
            <Col xs={16}>
              <p style={{ color: '#3c8dbc', fontWeight: '600' }}>
                {formatMoneyVnd(amount)}
              </p>
            </Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs={8}>
              {event == 'bonus' ? 'Số tiền thưởng:' : 'Số tiền hoàn:'}
            </Col>
            <Col xs={16}>
              <p style={{ color: 'red', fontWeight: '500' }}>
                {formatMoneyVnd(amount)}
              </p>
            </Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs={8}>Lý do:</Col>
            <Col xs={16}>{note}</Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Image>
            <img
              src={`https://img.vietqr.io/image/${bank_name}-${bank_account}-print.jpg?accountName=${receiver_full_name}&amount=${amount}&addInfo=${contentQR}`}
              alt="QrCode"
            />
          </Image>
        </Col>
      </Row>
      <Row style={{ marginBottom: '12px' }}>
        <Col xs={6} sm={4}>
          Phản hồi:
        </Col>
        <Col xs={18} sm={20}>
          {status == 'pending' && (
            <textarea
              style={{ width: '100%', padding: '15px' }}
              value={requiedRefund}
              onChange={(e) => setRequiedRefund(e.target.value)}
            ></textarea>
          )}
          {status != 'pending' && <p>{reply}</p>}
        </Col>
      </Row>
      {status == 'pending' && data?.roles.includes(1) && (
        <div style={{ marginTop: '32px' }}>
          <Button.Primary
            style={{ marginRight: '12px' }}
            onClick={handleApproveRefund}
          >
            Chấp nhận
          </Button.Primary>
          <Button.Danger onClick={handleRejectRefund}>Từ chối</Button.Danger>
        </div>
      )}
    </Modal>
  );
};

export default FormRefundOrderModalPay;
