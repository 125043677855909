import { Input, Row, Col, Button, message } from 'antd';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import { values } from 'lodash';
import { OrderType } from 'models/order.model';
// import Button from 'libraries/components/commons/Button';
import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateOrderInfoMutation } from 'services/order.service';

// const { TextArea } = Input;

const RedReceipt = (props: OrderType): ReactElement => {
  const [editing, setEditing] = useState(true);

  const { orderId } = useParams<{ orderId: string }>();

  const [updateOrderInfo, { isLoading }] = useUpdateOrderInfoMutation();

  const handleClick = (values: any) => {
    if (!editing) {
      setEditing(true);
    } else {
      const body = {
        orderId,
        data: {
          user_id: props.user_id,
          payment_type: props.payment_type,
          note: props.note,
          is_company: props.is_company,
          company_info: {
            company: values.company,
            tax_code: values.tax_code,
            address: values.address,
            mobile: values.mobile,
          },
        },
      };

      updateOrderInfo(body)
        .then(() => {
          message.success('Cập nhật thông tin công ty thành công');
          setEditing(false);
        })
        .catch((error) => {
          message.error(error);
        });
    }
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={props.sponsor}
        layout="vertical"
        onFinish={handleClick}
      >
        <Row gutter={[30, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={<FormLabel label="Tên công ty" require={true} />}
              name="company"
            >
              <Input disabled={!editing} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={<FormLabel label="Mã số thuế" require={true} />}
              name="tax_code"
            >
              <Input disabled={!editing} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={<FormLabel label="Địa chỉ" require={true} />}
              name="address"
            >
              <Input disabled={!editing} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={<FormLabel label="Số điện thoại" require={false} />}
              name="mobile"
            >
              <Input disabled={!editing} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {editing ? 'Lưu' : 'Thay đổi'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RedReceipt;
