import { ReactElement } from 'react';
import { Grid } from 'antd';

interface BreakpointType {
  [key: string]: boolean;
}
interface MediaType {
  on: string[];
  children: ReactElement;
}

export const matchScreen = (
  screen: string,
  breakpoint: BreakpointType,
): boolean => {
  switch (screen) {
    case 'xxl':
      return breakpoint.xxl;
    case 'xl':
      return !breakpoint.xxl && breakpoint.xl;
    case 'lg':
      return !breakpoint.xxl && !breakpoint.xl && breakpoint.lg;
    case 'md':
      return (
        !breakpoint.xxl && !breakpoint.xl && !breakpoint.lg && breakpoint.md
      );
    case 'sm':
      return (
        !breakpoint.xxl &&
        !breakpoint.xl &&
        !breakpoint.lg &&
        !breakpoint.md &&
        breakpoint.sm
      );
    default:
      return breakpoint.xs;
  }
};

const MediaQuery = ({ on, children }: MediaType): ReactElement => {
  const breakpoint = Grid.useBreakpoint();
  let match = false;

  for (const screen of on) {
    if (matchScreen(screen, breakpoint)) match = true;
  }

  return <>{match && children}</>;
};

export default MediaQuery;
