import { useEffect, useState, CSSProperties } from 'react';
import styled from 'styled-components';
import {
  Form,
  Input,
  InputNumber,
  message,
  Timeline as TimelineAntd,
  Typography,
} from 'antd';
import { ConfigHistory } from 'models/config.model';
import { useUpdateBaseSalaryMutation } from 'services/configure.service';
import { formatNumber } from 'libraries/utils/changeFormatData';
import Button from 'libraries/components/commons/Button';
import { formatDate } from 'utils/formatDate';
import produce from 'immer';

const mb36: CSSProperties = {
  marginBottom: 36,
};
interface iProps {
  baseSalary: ConfigHistory[] | undefined;
}

const FormContaner = styled.div`
  display: flex;
  justify-content: center;
`;
const Timeline = styled(TimelineAntd)`
  width: 100%;
  height: 100%;
`;
const TimelineContainer = styled.div`
  /* height: 300px; */
  /* overflow-y: scroll;
  overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;
const { Text, Title } = Typography;

const ConfigCommision = ({ baseSalary }: iProps) => {
  const [form] = Form.useForm();
  const [histories, setHistories] = useState([] as ConfigHistory[]);
  const [updateBs] = useUpdateBaseSalaryMutation();

  useEffect(() => {
    if (!baseSalary) return;
    setHistories(baseSalary);

    return () => setHistories([] as ConfigHistory[]);
  }, [baseSalary]);

  const onFinish = async () => {
    try {
      const { baseSalary } = await form.validateFields();
      if (baseSalary > 50 || !baseSalary) {
        message.error('Mức tối đa là 50%');
        return;
      }
      updateBs({ percent_finance: baseSalary })
        .unwrap()
        .then(() => {
          setHistories(
            produce((draft) => {
              const date = new Date();
              draft.unshift({
                start_date: date.toISOString(),
                percent_finance: baseSalary,
              });
            }),
          );
          message.success('Cập nhật thành công');
        })
        .catch((err) => {
          message.error(JSON.stringify(err));
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <FormContaner>
        <Form
          onFinish={onFinish}
          initialValues={{
            baseSalary: baseSalary ? baseSalary[0].percent_finance : 0,
          }}
          form={form}
        >
          <Form.Item label={<Text strong>Mức hoa hồng</Text>}>
            <Input.Group className="group">
              <Form.Item
                name="baseSalary"
                rules={[
                  {
                    validator: () => {
                      const bsField = form.getFieldValue('baseSalary');
                      if (bsField > 50) {
                        return Promise.reject(
                          new Error('Mức trả tối đa là 50'),
                        );
                      } else if (bsField < 0) {
                        return Promise.reject(
                          new Error('Mức trả không được âm'),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                noStyle
              >
                <InputNumber formatter={formatNumber} addonAfter="%" />
              </Form.Item>
              <Form.Item noStyle style={{ marginLeft: '12px' }}>
                <Button.Default htmlType="submit">Cập nhật</Button.Default>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Form>
      </FormContaner>
      <TimelineContainer>
        <Title level={3} style={mb36}>
          Lịch sử trả lương
        </Title>
        <Timeline mode="left">
          {histories.map((history, index, arr) => (
            <Timeline.Item
              key={history.start_date}
              color={index === arr.length ? 'green' : 'blue'}
              label={formatDate(history.start_date)}
            >
              <Text strong> {history.percent_finance} % </Text>
            </Timeline.Item>
          ))}
        </Timeline>
      </TimelineContainer>
    </>
  );
};

export default ConfigCommision;
