import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(AntBreadcrumb)`
  font-size: 20px;
  margin-bottom: 15px;
  a {
    color: #444;
  }
  &.span:last-child {
    color: #333;
  }
`;

const { Item } = AntBreadcrumb;

interface PropsType {
  secondaryLink?: string;
  secondaryText?: string;
  thirdLink?: string;
  thirdText?: string;
  text?: string;
}

const Breadcrumb = ({
  secondaryLink,
  secondaryText,
  thirdLink,
  thirdText,
  text,
}: PropsType): ReactElement => {
  return (
    <Wrapper separator={<RightOutlined />}>
      <Item>
        <a href="/">Dashboard</a>
      </Item>
      {secondaryLink && (
        <Item>
          <Link to={secondaryLink}>{secondaryText}</Link>
        </Item>
      )}
      {thirdLink && (
        <Item>
          <Link to={thirdLink}>{thirdText}</Link>
        </Item>
      )}
      {text !== '' && <Item>{text}</Item>}
    </Wrapper>
  );
};

Breadcrumb.defaultProps = {
  text: '',
};

export default Breadcrumb;
