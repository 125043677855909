import { ReactElement } from 'react';
import styled from 'styled-components';
import Link from './libraries/components/commons/Link';
import { LeftOutlined } from '@ant-design/icons';
import LinksContainer from './libraries/components/commons/LinksContainer';
import { useParams } from 'react-router';
import { useGetUserInfoQuery } from './services/user.service';
import { UserInforType } from './models/user.model';
import { Tabs } from 'antd';
import LearnProcess from './pages/users/user/tabs/learnProcess/LearnProcess';
import { BorderTopBoxContent } from './components/commons/ComponentContent';
import NotFound from './components/commons/NotFound';
import { useHistory } from 'react-router-dom';
import useQuery from './libraries/hooks/query';
import UserTransaction from './pages/users/user/tabs/UserTransaction';

const { TabPane } = Tabs;

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StudentInfo = (): ReactElement => {
  const { userId } = useParams<{ userId: string }>();
  const searchParams = useQuery();
  const code = searchParams.get('code') ?? '';
  const { data, isError, isLoading } = useGetUserInfoQuery({
    code: code,
    user_id: userId,
  });

  const user = data ? data : ({} as UserInforType);

  if (isLoading) {
    return <></>;
  }

  if (isError) {
    return <NotFound subTitle="Không tìm thấy user này" />;
  }
  return (
    <>
      <GroupButton>
        <div>
          <div>Họ và tên: {user.full_name}</div>
          <div>Số điện thoai: {user.phone}</div>
          <div>Email: {user.email}</div>
        </div>
      </GroupButton>
      <BorderTopBoxContent>
        <Tabs defaultActiveKey={'1'}>
          <TabPane tab="Quá trình học tập" key="1">
            <LearnProcess />
          </TabPane>
          <TabPane tab="Giao dịch học phí" key="2">
            <UserTransaction transactionsList={user.transaction} />
          </TabPane>
        </Tabs>
      </BorderTopBoxContent>
    </>
  );
};

export default StudentInfo;
