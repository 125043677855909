import ShowServerError from 'components/commons/ShowServerError';
import { ReactElement } from 'react';
import { useGetActiveBannersListQuery } from 'services/configure.service';
import SortBannerDetail from './SortBannerDetail';

const GetActiveBanner = (): ReactElement => {
  const { isError, error } = useGetActiveBannersListQuery();

  if (isError) return <ShowServerError error={error} />;

  return <SortBannerDetail />;
};

export default GetActiveBanner;
