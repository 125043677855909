import Table, { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import TableContainer from 'components/table/TableContainer';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { Schedule } from 'models/schedule.model';
import { ReactElement, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetListCoursesQuery } from 'services/classes.service';
import { useGetTracksListQuery } from 'services/schedule.service';
import { v4 } from 'uuid';

interface PropsType {
  schedules: Schedule[];
}

type TableDataProps = Schedule & { key: React.Key };

const ScheduleTable = ({ schedules }: PropsType): ReactElement => {
  const { data: courses } = useGetListCoursesQuery();
  const { data: tracks } = useGetTracksListQuery();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TableDataProps>>({
    order: 'descend',
    columnKey: 'start_date',
  });

  const tableData = schedules.map((item) => ({
    ...item,
    key: v4(),
  })) as TableDataProps[];
  const onChange: TableProps<TableDataProps>['onChange'] = (
    _pagination,
    _filter,
    sorter,
  ) => {
    setSortedInfo(sorter as SorterResult<TableDataProps>);
  };

  const columns: ColumnsType<TableDataProps> = [
    {
      key: 'start_date',
      dataIndex: 'start_date',
      width: '15%',
      title: 'Ngày khai giảng',
      render: (_text, item) => (
        <Link
          to={generatePath(route.schedule.detail, {
            itemType: item.item_type,
            itemId: item.item_id,
            startDate: item.start_date,
          })}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          {formatDate(item.start_date)}
        </Link>
      ),
      sorter: (a, b) => {
        const firstDate = new Date(a.start_date).getTime();
        const secondDate = new Date(b.start_date).getTime();
        return firstDate - secondDate;
      },
      sortOrder:
        sortedInfo.columnKey === 'start_date' ? sortedInfo.order : null,
    },
    {
      key: 'item_id',
      dataIndex: 'item_id',
      width: '40%',
      title: 'Khoá học/ Lộ trình',
      render: (_text, item) => {
        if (item.item_type === 'course' && courses) {
          for (let i = 0; i < courses?.length; i++) {
            if (courses[i].id === item.item_id) {
              return (
                <Link
                  to={generatePath(route.course.detail, {
                    courseId: item.item_id,
                  })}
                  style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                  target="_blank"
                >
                  {courses[i].name}
                </Link>
              );
            }
          }
        }
        if (item.item_type === 'track' && tracks) {
          for (let i = 0; i < tracks?.length; i++) {
            if (tracks[i].id === item.item_id) {
              return (
                <Link
                  to={generatePath(route.track.detail, {
                    trackId: item.item_id,
                  })}
                  target="_blank"
                  style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                >
                  {tracks[i].name}
                </Link>
              );
            }
          }
        }
      },
    },
    {
      key: 'day_of_week',
      dataIndex: 'day_of_week',
      width: '10%',
      title: 'Thứ',
      render: (day_of_week) => {
        const dayOfWeek = [...day_of_week];
        dayOfWeek.sort((a: number, b: number) => a - b);

        if (dayOfWeek.includes(8)) {
          dayOfWeek[dayOfWeek.length - 1] = 'CN';
        }

        return dayOfWeek.join(', ');
      },
    },
    {
      key: 'start_at',
      dataIndex: 'start_at',
      width: '15%',
      title: 'Giờ',
      render: (_text, item) => item.start_at + ' - ' + item.end_at,
    },
    {
      key: 'branch_name',
      dataIndex: 'branch_name',
      width: '20%',
      title: 'Cơ sở',
    },
  ];
  return (
    <TableContainer>
      <Table<TableDataProps>
        columns={columns}
        dataSource={tableData}
        pagination={{ showSizeChanger: false }}
        bordered
        onChange={onChange}
      />
    </TableContainer>
  );
};

export default ScheduleTable;
