import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { formatMoney } from 'libraries/utils/changeFormatData';
import { studentStatusValues } from 'libraries/utils/globalVariable';
import { ReportStudentDebt } from 'models/report.model';
import { ReactElement } from 'react';

import { Link, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

interface PropsType {
  students: ReportStudentDebt[];
}

const ClassStudentTable = ({ students }: PropsType): ReactElement => {
  const columns: ColumnsType<ReportStudentDebt> = [
    {
      width: '3%',
      title: '#',
      key: 'index',
      dataIndex: 'index',
    },
    {
      width: '10%',
      title: 'Tên',
      key: 'full_name',
      dataIndex: 'full_name',
    },
    {
      width: '10%',
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      width: '10%',
      title: 'Số điện thoại',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      width: '10%',
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        if (status !== '') {
          for (let i = 0; i < studentStatusValues.length; i++) {
            const item = studentStatusValues[i];
            if (item.value === status) {
              return item.label;
            }
          }
        }
        return '';
      },
    },

    {
      key: 'paid',
      width: '8%',
      title: 'Đã nộp',
      dataIndex: 'paid',
      render: (paid) => (paid !== 0 ? formatMoney(paid) : 0),
    },
    {
      key: 'debt',
      width: '8%',
      title: 'Còn nợ',
      dataIndex: 'debt',
      render: (debt) => (debt !== 0 ? formatMoney(debt) : ''),
    },
    {
      key: 'should_pay',
      width: '8%',
      title: 'Tổng tiền',
      dataIndex: 'should_pay',
      render: (should_pay) => (should_pay !== 0 ? formatMoney(should_pay) : 0),
    },
    {
      key: 'order_id',
      width: '7%',
      title: 'Mã đơn hàng',
      dataIndex: 'order_id',
      render: (order_id) => {
        if (order_id === 0) return '';
        return (
          <Link
            to={generatePath(route.order.detail, {
              orderId: order_id,
            })}
            target="_blank"
          >
            {order_id}
          </Link>
        );
      },
    },
  ];

  return (
    <TableContainer>
      <Table<ReportStudentDebt>
        columns={columns}
        dataSource={students}
        pagination={false}
        rowKey="id"
        bordered
      />
    </TableContainer>
  );
};

export default ClassStudentTable;
