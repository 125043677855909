import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectIsSidebarCollapse } from 'store/general.slice';
import SimpleBar from 'simplebar-react';
import Sidebar from 'layout/Sidebar';
import Menu from 'layout/Menu';

const CollapsableSidebar = (): ReactElement => {
  const isSidebarCollapse = useSelector(selectIsSidebarCollapse);

  return (
    <Sidebar style={{ width: isSidebarCollapse ? 80 : 256 }}>
      <SimpleBar style={{ height: '100%' }}>
        <Menu inlineCollapsed={isSidebarCollapse} />
      </SimpleBar>
    </Sidebar>
  );
};

export default CollapsableSidebar;
