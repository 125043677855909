import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ReactElement, useEffect, useState } from 'react';
import NotFound from './NotFound';
import ServerError from './ServerError';

interface PropsType {
  error: Pick<PropsType, never> &
    Partial<Pick<PropsType, 'error'>> &
    Partial<Pick<{ error: string }, never>>;
}

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}
const ShowServerError = ({ error }: PropsType): ReactElement => {
  const [errorCurrent, setErrorCurrent] = useState<string | any>('');
  const [status, setStatus] = useState<string | any>('404');
  useEffect(() => {
    if (isFetchBaseQueryError(error)) {
      setStatus(error.status);
      setErrorCurrent(error.data);
    }
  }, [error]);
  return <ServerError subTitle={errorCurrent} title={status} />;
};

ShowServerError.defaultProps = {
  error: 'Lỗi tải dữ liệu từ sever',
};

export default ShowServerError;
