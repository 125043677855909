import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  UserInforType,
  UserLearnProcess,
  UsersType,
  UserTransaction,
} from 'models/user.model';
import { userApi } from 'services/user.service';
import { RootState } from 'store';

const initialState = {
  users: [] as UsersType[],
  total_page: 0,
  currentPage: 1,
  userInfo: {} as UserInforType,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update state user when login
    builder.addMatcher(
      userApi.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        state.users = payload;
      },
    );

    builder.addMatcher(
      userApi.endpoints.createUser.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
      },
    );

    builder.addMatcher(
      userApi.endpoints.resetPassword.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
      },
    );

    builder.addMatcher(
      userApi.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        state.userInfo = payload;
      },
    );
  },
});

export const selectUsers = (state: RootState): UsersType[] => {
  return state.users.users;
};
export const selectUserInfo = (state: RootState): UserInforType => {
  return state.users.userInfo;
};
export const selectUserTransaction = (state: RootState): UserTransaction[] => {
  return state.users.userInfo.transaction;
};
export const selectUserLearnProcess = (state: RootState): UserLearnProcess[] =>
  state.users.userInfo.learn_process;

export const selectUserLearnProcessById = createSelector(
  selectUserLearnProcess,
  (_: void, id: string) => id,
  (learnProcess, id) =>
    learnProcess.find((item) => item.class_id === id) ?? learnProcess[0],
);

export const selectUserAllClass = createSelector(
  selectUserLearnProcess,
  (learnProcess) =>
    learnProcess.map((item) => ({
      id: item.class_id,
      title: item.class_name,
    })),
);

// export const { searchUser } = userSlice.actions;

export default userSlice.reducer;
