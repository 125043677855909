import { createApi } from '@reduxjs/toolkit/query/react';
import { ClassFind, TeacherFind } from 'models/class.model';
import {
  HistorySalary,
  ListPayReceiptType,
  ListApiSaleType,
  PayOrderReport,
  PayOtherReport,
  PayReceipt,
  PayReceiptDetail,
  ReceiptOrderReport,
  ReceiptOtherReport,
  ReportClassAttendance,
  ReportStudentAttendance,
  ReportStudentDebt,
  SalaryInfos,
  SaleBlogReport,
  SaleClassReport,
  SaleOrderReport,
  SummarySalaryList,
  TeacherAttendanceSalary,
  TeacherCurriculimReport,
  TeacherListReport,
  TeacherSalaryReport,
} from 'models/report.model';
import { UserInforType } from 'models/user.model';
import { BonusDetails } from 'models/report.model';
import { baseQuery } from './base.service';
import { ConfigHistory } from '../models/config.model';

interface ResPaid {
  data: number;
}

export const reportApi = createApi({
  reducerPath: 'reportApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getClassesList: builder.query<ClassFind[], string>({
      query: (keyword) => {
        return {
          url: `list-classes${keyword}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ClassFind[]) => {
        response = response === null ? ([] as ClassFind[]) : response;
        if (response.length > 0) {
          response.map((item) => {
            item.total_students = item.total_students ? item.total_students : 0;
            item.schedule = item.schedule.includes('0001-01-01T00')
              ? ''
              : item.schedule;
            item.teachers = item.teachers
              ? item.teachers
              : ([] as TeacherFind[]);
            item.location = item.location ? item.location : '';
          });
        }
        return response;
      },
    }),

    // Finance
    getFinanceReport: builder.query<
      ListPayReceiptType,
      { from: string; to: string }
    >({
      query: ({ from, to }) => {
        return {
          url: `list-pay-receipt?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ListPayReceiptType) => {
        response.receipt_from_other =
          response.receipt_from_other ?? ([] as ReceiptOtherReport[]);
        response.receipt_from_other.length > 0 &&
          response.receipt_from_other.map((item) => {
            item.datetime = item.datetime.includes('0001-01-01T00')
              ? ''
              : item.datetime;
          });
        response.receipt_from_order =
          response.receipt_from_order ?? ([] as ReceiptOrderReport[]);
        response.receipt_from_order.length > 0 &&
          response.receipt_from_order.map((item) => {
            item.datetime = item.datetime.includes('0001-01-01T00')
              ? ''
              : item.datetime;
            item.items = item.items ?? ([] as string[]);
          });
        response.pay_for_other =
          response.pay_for_other ?? ([] as PayOtherReport[]);
        response.pay_for_other.length > 0 &&
          response.pay_for_other.map((item) => {
            item.datetime = item.datetime.includes('0001-01-01T00')
              ? ''
              : item.datetime;
          });
        response.pay_for_order =
          response.pay_for_order ?? ([] as PayOrderReport[]);
        response.pay_for_order.length > 0 &&
          response.pay_for_order.map((item) => {
            item.datetime = item.datetime.includes('0001-01-01T00')
              ? ''
              : item.datetime;
          });
        return response;
      },
    }),

    createPaymentReceipt: builder.mutation<string, PayReceipt>({
      query: (data) => {
        return {
          url: `pay-receipt`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getApiSaleList: builder.query<
      ListApiSaleType,
      { from: string; to: string }
    >({
      query: ({ from, to }) => {
        return {
          url: `sale/kpi?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ListApiSaleType) => {
        return response;
      },
    }),
    updatePaymentReceipt: builder.mutation<
      string,
      { id: number; data: PayReceipt }
    >({
      query: ({ id, data }) => {
        return {
          url: `pay-receipt/${id}`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    createUser: builder.mutation<
      { id: string; full_name: string; phone: string; email: string },
      { full_name: string; phone: string; email: string; roles: number[] }
    >({
      query: (data) => {
        return {
          url: `create-users`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getReceiptPaymentDetail: builder.query<PayReceiptDetail, string>({
      query: (id) => {
        return {
          url: `pay-receipt/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: PayReceiptDetail) => {
        response.datetime = response.datetime.includes('0001-01-01T00')
          ? ''
          : response.datetime;
        response.receiver_id = response.receiver_id ?? '';
        response.receiver_full_name = response.receiver_full_name ?? '';

        return response;
      },
    }),

    // Teacher
    getTeachersReport: builder.query<
      TeacherListReport[],
      { from: string; to: string }
    >({
      query: ({ from, to }) => {
        return {
          url: `teachers-salary?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: TeacherListReport[]) => {
        response = response ?? ([] as TeacherListReport[]);
        return response;
      },
    }),

    getTeachersCurriculumReport: builder.query<
      TeacherCurriculimReport[],
      { from: string; to: string }
    >({
      query: ({ from, to }) => {
        return {
          url: `teachers-curriculum?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: TeacherCurriculimReport[]) => {
        response = response ?? ([] as TeacherCurriculimReport[]);
        return response;
      },
    }),

    getTeacherInfo: builder.query<UserInforType, string>({
      query: (userId) => {
        return {
          url: `user/${userId}`,
          method: 'GET',
        };
      },
    }),

    getTeacherSalaryReport: builder.query<
      TeacherSalaryReport,
      { id: string; from: string; to: string }
    >({
      query: ({ id, from, to }) => {
        return {
          url: `teacher/${id}/salary-info?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: TeacherSalaryReport) => {
        response.salary_attendance =
          response.salary_attendance ?? ([] as TeacherAttendanceSalary[]);
        response.salary_attendance.length > 0 &&
          response.salary_attendance.map((item) => {
            item.date = item.date.includes('0001-01-01T00') ? '' : item.date;
          });

        return response;
      },
    }),

    getUserBonusSalaryReport: builder.query<
      BonusDetails[],
      { from: string; to: string; id: string }
    >({
      query: ({ from, to, id }) => {
        return {
          url: `user/${id}/bonus-salary/?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: BonusDetails[]) => {
        response = response ?? ([] as BonusDetails[]);
        response.length > 0 &&
          response.map((item) => {
            item.date = item.date.includes('0001-01-01T00') ? '' : item.date;
          });
        return response;
      },
    }),

    sendUserBonusSalaryReport: builder.mutation<{ id: number }, BonusDetails>({
      query: (body) => ({
        url: `${process.env.REACT_APP_TEACHER_URL}api/salary-bonus`,
        method: 'POST',
        body,
      }),
    }),

    deleteUserBonusSalaryReport: builder.mutation<string, number>({
      query: (id) => ({
        url: `${process.env.REACT_APP_TEACHER_URL}api/salary-bonus/${id}`,
        method: 'DELETE',
      }),
    }),

    // Sale

    getBaseSalary: builder.query<ConfigHistory[], void>({
      query: () => {
        return {
          url: 'base-salary',
          medthod: 'GET',
        };
      },
    }),

    getSaleInfo: builder.query<UserInforType, string>({
      query: (saleId) => {
        return {
          url: `user/${saleId}`,
          method: 'GET',
        };
      },
    }),

    getSaleClassReport: builder.query<
      SaleClassReport[],
      { sale_id: string; from: string; to: string }
    >({
      query: ({ sale_id, from, to }) => {
        return {
          url: `sale/${sale_id}/classes-report?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: SaleClassReport[]) => {
        response = response !== null ? response : ([] as SaleClassReport[]);
        response.length > 0 &&
          response.map((item) => {
            item.list_students =
              item.list_students !== null
                ? item.list_students
                : ([] as ReportStudentDebt[]);
            item.list_students.length > 0 &&
              item.list_students.map((student, index) => {
                student.index = index + 1;
              });
          });
        return response;
      },
    }),

    getSaleOrderReport: builder.query<
      SaleOrderReport[],
      { sale_id: string; from: string; to: string }
    >({
      query: ({ sale_id, from, to }) => {
        return {
          url: `sale/${sale_id}/order-report?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: SaleOrderReport[]) => {
        response = response !== null ? response : ([] as SaleOrderReport[]);
        response.length > 0 &&
          response.map((item, index) => {
            item.order_at = item.order_at.includes('0001-01-01T00')
              ? ''
              : item.order_at;
            item.index = index + 1;
            item.items = item.items !== null ? item.items : ([] as string[]);
          });
        return response;
      },
    }),

    getSaleBlogReport: builder.query<
      SaleBlogReport[],
      { sale_id: string; from: string; to: string }
    >({
      query: ({ sale_id, from, to }) => {
        return {
          url: `sale/${sale_id}/blog-report?from=${from}&to=${to}`,
          method: 'GET',
        };
      },
      transformResponse: (response: SaleBlogReport[]) => {
        response = response !== null ? response : ([] as SaleBlogReport[]);
        response.length > 0 &&
          response.map((item, index) => {
            item.published_at = item.published_at.includes('0001-01-01T00')
              ? ''
              : item.published_at;
            item.index = index + 1;
          });
        return response;
      },
    }),

    // Admin & Salary
    sendAdminApproval: builder.mutation<
      void,
      Pick<BonusDetails, 'id' | 'status' | 'reason' | 'bonus'>
    >({
      query: ({ status, id, reason, bonus }) => ({
        url: `/bonus-salary/${id}/verify/`,
        method: 'POST',
        body: { status, reason, bonus },
      }),
    }),
    sendSalary: builder.mutation<
      { id: number },
      {
        salary: number;
        user_id: string;
        start_date: string;
        end_date: string;
        note: string;
      }
    >({
      query: ({ salary, user_id, start_date, end_date, note }) => ({
        url: `/salary`,
        method: 'POST',
        body: {
          salary,
          user_id,
          start_date,
          end_date,
          note,
        },
      }),
    }),

    sendAdminCreatedBonus: builder.mutation<{ id: number }, BonusDetails>({
      query: (body) => ({
        url: `bonus-salary`,
        method: 'POST',
        body: body,
      }),
    }),

    sendPaidSalary: builder.mutation<
      { paid: number },
      { id: string; note: string; paid: number }
    >({
      query: ({ note, paid, id }) => ({
        url: `/salary/${id}`,
        method: 'POST',
        body: { note, paid },
      }),
      transformResponse: (response: { paid: number }) => response,
    }),

    getSalaryList: builder.query<
      SummarySalaryList[],
      { from: string; to: string }
    >({
      query: ({ from, to }) => ({
        url: `/user-salary?from=${from}&to=${to}`,
        method: 'GET',
      }),
    }),
    getSaleSalaryInfo: builder.query<
      SalaryInfos,
      { id: string; from: string; to: string }
    >({
      query: ({ id, from, to }) => ({
        url: `/user/${id}/salary-info?from=${from}&to=${to}`,
        method: 'GET',
      }),
      transformResponse(res: SalaryInfos, meta, arg) {
        res.fix_salary = res.fix_salary ?? 0;
        res.history_salary = res.history_salary ?? ([] as HistorySalary[]);
        res.note = res.note ?? '';
        res.paid = res.paid ?? 0;
        res.salary_attendance =
          res.salary_attendance ?? ([] as TeacherAttendanceSalary[]);
        res.salary_bonus = res.salary_bonus ?? ([] as BonusDetails[]);
        res.salary_sale = res.salary_sale ?? ([] as ReceiptOrderReport[]);
        res.total_sale = res.total_sale ?? 0;

        return res;
      },
    }),
    getEverySalaryInfo: builder.query<SalaryInfos, string>({
      query: (salary_id) => ({
        url: `/salary-info/${salary_id}`,
        method: 'GET',
      }),
    }),
    // Attendance
    getClassAttendances: builder.query<ReportClassAttendance, string>({
      query: (class_id) => {
        return {
          url: `classes/${class_id}/attendance-report`,
          method: 'GET',
        };
      },
      transformResponse: (response: ReportClassAttendance) => {
        response.students =
          response.students === null
            ? ([] as ReportStudentAttendance[])
            : response.students;
        if (response.students.length > 0) {
          response.students.map((item, index) => (item.index = index + 1));
        }
        response.teachers =
          response.teachers === null ? ([] as string[]) : response.teachers;
        response.start_date = response.start_date.includes('0001-01-01T00')
          ? ''
          : response.start_date;
        return response;
      },
    }),

    getClassDebt: builder.query<ReportStudentDebt[], string>({
      query: (class_id) => {
        return {
          url: `classes/${class_id}/payment-report`,
          method: 'GET',
        };
      },
      transformResponse: (response: ReportStudentDebt[]) => {
        response = response === null ? ([] as ReportStudentDebt[]) : response;
        if (response.length > 0) {
          response.map((item, index) => (item.index = index + 1));
        }
        return response;
      },
    }),
  }),
});

export const {
  useGetClassesListQuery,
  useGetFinanceReportQuery,
  useGetApiSaleListQuery,
  useCreatePaymentReceiptMutation,
  useUpdatePaymentReceiptMutation,
  useCreateUserMutation,
  useGetReceiptPaymentDetailQuery,
  useGetTeachersReportQuery,
  useGetTeachersCurriculumReportQuery,
  useGetTeacherInfoQuery,
  useGetTeacherSalaryReportQuery,
  useGetSaleInfoQuery,
  useGetSaleClassReportQuery,
  useGetSaleOrderReportQuery,
  useGetSaleBlogReportQuery,
  useGetClassAttendancesQuery,
  useGetClassDebtQuery,
  useSendUserBonusSalaryReportMutation,
  useDeleteUserBonusSalaryReportMutation,
  useGetUserBonusSalaryReportQuery,
  useSendAdminApprovalMutation,
  useSendSalaryMutation,
  useGetBaseSalaryQuery,
  useGetSalaryListQuery,
  useGetEverySalaryInfoQuery,
  useSendPaidSalaryMutation,
  useGetSaleSalaryInfoQuery,
  useSendAdminCreatedBonusMutation,
} = reportApi;
