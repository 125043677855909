import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Image } from '../models/media.model';
import { mediaApi } from '../services/media.service';
import { LIMIT_IMAGE_PER_PAGE } from '../utils/globalVariable';

const initialState = {
  images: [] as Image[],
  total_page: 0,
};

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    updateImageInfoInList: (state, { payload }) => {
      const { id, title, description } = payload;
      const arr = [...state.images];
      arr
        .filter((item) => item.id === id)
        .map((item) => {
          item.title = title;
          item.description = description;
        });
      state.images = arr;
    },
    updateImagesList: (state, { payload }) => {
      const arr = [...state.images];
      if (arr.length === LIMIT_IMAGE_PER_PAGE) arr.pop();
      arr.unshift(payload);
      state.images = arr;
    },
    removeImageFromList: (state, { payload }) => {
      const newArr = [...state.images];
      const findIndex = newArr.findIndex((item) => item.id === payload);
      newArr.splice(findIndex, 1);
      state.images = newArr;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      mediaApi.endpoints.findImagesInSystem.matchFulfilled,
      (state, { payload }) => {
        state.images = payload.images;
        state.total_page = payload.total_page;
      },
    );
    builder.addMatcher(
      mediaApi.endpoints.getUserImages.matchFulfilled,
      (state, { payload }) => {
        state.images = payload.images;
        state.total_page = payload.total_page;
      },
    );
  },
});

export const { updateImageInfoInList, updateImagesList, removeImageFromList } =
  imageSlice.actions;

export const selectImages = (state: RootState): Image[] => state.image.images;
export const selectTotal = (state: RootState): number => state.image.total_page;

export default imageSlice.reducer;
