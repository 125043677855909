import { ComponentContent } from 'components/commons/ComponentContent';
import { ReactElement } from 'react';
import Tabs from 'components/commons/Tabs';

import RedReceipt from './RedReceipt';
import Students from './Students';
import OrderHistory from './OrderHistory';
import ItemDetail from './ItemDetail';
import { useSelector } from 'react-redux';
import { selectOrder } from 'store/order.slice';

interface Props {
  refetchClasses: () => void;
}

const { TabPane } = Tabs;

const OrderTabs = ({ refetchClasses }: Props): ReactElement => {
  const orderData = useSelector(selectOrder);
  const { is_company } = orderData;

  return (
    <ComponentContent>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Lịch sử đơn hàng" key="1">
          <OrderHistory />
        </TabPane>

        {is_company && (
          <TabPane tab="Học viên doanh nghiệp" key="2">
            <Students />
          </TabPane>
        )}

        <TabPane tab="Chi tiết item" key="3">
          <ItemDetail refetchClasses={refetchClasses} />
        </TabPane>

        <TabPane tab="Thông tin xuất hoá đơn đỏ" key="4">
          <RedReceipt {...orderData} />
        </TabPane>
      </Tabs>
    </ComponentContent>
  );
};

export default OrderTabs;
