import { ROLE_SALE } from 'libraries/utils/globalVariable';
import { ReactElement } from 'react';
import { useGetUsersByRoleQuery } from 'services/user.service';

import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import { UserWithRole } from 'models/user.model';
import SaleTable from './SaleTable';

const GetList = (): ReactElement => {
  const { data, isError, error } = useGetUsersByRoleQuery(ROLE_SALE);

  const salers = data ? data : ([] as UserWithRole[]);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Nhân viên tuyển sinh" />
      <BorderTopBoxContent>
        <SaleTable salers={salers} />
      </BorderTopBoxContent>
    </>
  );
};

export default GetList;
