import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSaleInfoQuery } from 'services/report.service';

import NotFound from 'components/commons/NotFound';
import SaleDetailContent from './SaleDetailContent';

const GetSaleInfo = (): ReactElement => {
  const { saleId } = useParams<{ saleId: string }>();
  const { isError } = useGetSaleInfoQuery(saleId);

  if (isError) {
    <NotFound subTitle="Lỗi khi tìm thông tin nhân viên tuyển sinh" />;
  }

  return <SaleDetailContent />;
};

export default GetSaleInfo;
