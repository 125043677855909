import NotAuthorized from 'components/commons/NotAuthorized';
import NotFound from 'components/commons/NotFound';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { highestRoles } from 'libraries/utils/globalVariable';
import { PostInfo } from 'models/blog.model';
import { Tag } from 'models/tag.model';
import { ReactElement, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPostDetailQuery, useGetTagsQuery } from 'services/blog.service';
import checkUserRole from 'utils/checkUserRole';
import PostContentPage from './PostContentPage';

const GetPostDetail = (): ReactElement => {
  const { postId } = useParams<{ postId: string }>();
  const checkRole = checkUserRole(highestRoles);
  const blogLocalStore = JSON.parse(
    window.localStorage.getItem('blog') || '[]',
  );
  const { data, isFetching, isError } = useGetPostDetailQuery(postId);
  const { data: tagsList } = useGetTagsQuery();

  console.log(data);

  const post = data ? data : ({} as PostInfo);
  const [initValueBlog, setInitValueBlog] = useState<any>();

  useEffect(() => {
    setInitValueBlog(post);
    const newBlog = blogLocalStore.filter((item: any) => item.id == post.id);
    if (newBlog.length == 0) return;
    const newInitValueBlog = { ...post, ...newBlog[0] };
    setInitValueBlog(newInitValueBlog);
  }, [data]);
  const tags = tagsList ? tagsList : ([] as Tag[]);

  if (isError) {
    console.log(isError);

    return <NotFound subTitle="Không tìm thấy bài viết!" />;
  }

  if (!checkRole && data && data.post_type === 'special') {
    return <NotAuthorized />;
  }

  if (isFetching) {
    return <LoadingSpin />;
  }

  return initValueBlog ? (
    <PostContentPage
      post={data!}
      tags={tags}
      contents={post.content}
      setInitValueBlog={setInitValueBlog}
      postRevert={data}
    />
  ) : (
    <div></div>
  );
};
export default GetPostDetail;
