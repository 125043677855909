import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { TeacherCurriculimReport } from 'models/report.model';

import TableContainer from 'components/table/TableContainer';

interface PropsType {
  curriculums: TeacherCurriculimReport[];
}

const CurriculumReport = ({ curriculums }: PropsType): ReactElement => {
  const columns: ColumnsType<TeacherCurriculimReport> = [
    {
      key: 'full_name',
      width: '16%',
      title: 'Giảng viên',
      dataIndex: 'full_name',
    },
    {
      key: 'count_video',
      width: '14%',
      title: 'Số video',
      dataIndex: 'count_video',
    },
    {
      key: 'count_theory',
      width: '14%',
      title: 'Số lý thuyết',
      dataIndex: 'count_theory',
    },
    {
      key: 'count_quiz',
      width: '14%',
      title: 'Số quiz',
      dataIndex: 'count_quiz',
    },
    {
      key: 'count_file',
      width: '14%',
      title: 'Số file upload',
      dataIndex: 'count_file',
    },
    {
      key: 'count_assignment',
      width: '14%',
      title: 'Số bài tập',
      dataIndex: 'count_assignment',
    },
    {
      key: 'count_lecture',
      width: '14%',
      title: 'Số bài giảng',
      dataIndex: 'count_lecture',
    },
  ];

  return (
    <TableContainer>
      <Table<TeacherCurriculimReport>
        columns={columns}
        dataSource={curriculums}
        pagination={false}
        rowKey="id"
        bordered
      />
    </TableContainer>
  );
};

export default CurriculumReport;
