import { Grid } from 'antd';

interface Breakpoint {
  [key: string]: boolean;
}

const useMedia = (): Breakpoint => {
  const { xs, sm, md, lg, xl, xxl } = Grid.useBreakpoint();

  return {
    xs: xs || false,
    sm: (!xxl && !xl && !lg && !md && sm) || false,
    md: (!xxl && !xl && !lg && md) || false,
    lg: (!xxl && !xl && lg) || false,
    xl: (!xxl && xl) || false,
    xxl: xxl || false,
  };
};

export default useMedia;
