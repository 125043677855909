import Breadcrumb from 'components/commons/Breadcrumb';
import { ReactElement, useState } from 'react';
import { useGetUsersQuery } from 'services/user.service';
import { ReloadOutlined } from '@ant-design/icons';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import TeacherTable from './TeacherTable';

interface KeySearchType {
  keyword: string;
  status: string;
  role: string;
}

const GetListTeacher = (): ReactElement => {
  const [keySearch, setKeySearch] = useState('?keyword=&status=&role=');

  const [state, setState] = useState<KeySearchType>({
    keyword: '',
    status: '',
    role: '',
  });

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const { isError, error, data } = useGetUsersQuery(keySearch);

  if (isError) return <ShowServerError error={error} />;

  return (
    <>
      <Breadcrumb text="Danh sách giảng viên" />
      <LinksContainer>
        <Link.Info
          to="#"
          onClick={() => setState({ keyword: '', status: '', role: '' })}
        >
          <ReloadOutlined /> Refresh
        </Link.Info>
      </LinksContainer>
      <BorderBoxContent>
        <TeacherTable
          state={state}
          handleSetState={handleSetState}
          setKeySearch={setKeySearch}
          listTeacher={data}
        />
      </BorderBoxContent>
    </>
  );
};
export default GetListTeacher;
