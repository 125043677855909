import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { Input, Table, Select, message } from 'antd';
import { removeTagFromList, selectTags } from 'store/tag.slice';
import { Tag } from 'models/tag.model';
import TableContainer from 'components/table/TableContainer';
import { tagTypeValues } from 'libraries/utils/globalVariable';
import Button from 'libraries/components/commons/Button';
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { useDeleteTagMutation } from 'services/tag.service';
import EditTagModal from './EditTagModal';

interface KeySearchType {
  keyword: string;
  keyType: string;
  keyOrder: string;
  keyDirection: 'asc' | 'desc';
}

interface PropsType {
  state: KeySearchType;
  handleSetState: (field: string, value: string) => void;
  setKeySearch: (value: string) => void;
  resetKeySearch: () => void;
}

const { Option } = Select;

const TagsTable = ({
  state,
  handleSetState,
  setKeySearch,
  resetKeySearch,
}: PropsType): ReactElement => {
  const dispatch = useDispatch();
  const tags = useSelector(selectTags);
  const { keyType } = state;
  const [tmpKeyword, setTmpKeyword] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState({} as Tag);

  const [deleteTag] = useDeleteTagMutation();

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: 'Bạn có chắc chắn muốn xoá tag này?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deleteTag(id)
          .unwrap()
          .then(() => {
            message.success('Xoá tag thành công!');
            dispatch(removeTagFromList(id));
            resetKeySearch();
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const columns: ColumnsType<Tag> = [
    {
      width: '35%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      title: 'Tên tag',
      children: [
        {
          key: 'name',
          dataIndex: 'name',
          title: (
            <Input
              value={tmpKeyword}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('keyword', tmpKeyword);
                }
              }}
              onChange={(e) => setTmpKeyword(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          ),
        },
      ],
    },
    {
      width: '30%',
      title: 'Loại',
      children: [
        {
          key: 'types',
          dataIndex: 'types',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={keyType}
              onChange={(value) => handleSetState('keyType', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              {tagTypeValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (types) => {
            if (types.length > 0) {
              let result = '';
              for (let i = 0; i < types.length; i++) {
                const type = types[i];
                for (let j = 0; j < tagTypeValues.length; j++) {
                  const item = tagTypeValues[j];
                  if (item.value === type) {
                    if (i < types.length - 1) result += item.label + ', ';
                    else result += item.label;
                  }
                }
              }
              return result;
            }
            return '';
          },
        },
      ],
    },
    {
      width: '20%',
      title: 'Số lượng sử dụng',
      children: [
        {
          key: 'count',
          dataIndex: 'count',
        },
      ],
      sorter: (a, b) => a.count - b.count,
      showSorterTooltip: false,
    },
    {
      width: '15%',
      children: [
        {
          key: 'action',
          dataIndex: 'action',
          render: (_text, item) => (
            <div>
              <Button.Info
                onClick={() => {
                  setSelected(item);
                  setIsModalVisible(true);
                }}
                style={{ height: '34px', marginRight: '10px' }}
              >
                <EditFilled />
              </Button.Info>
              <Button.Danger
                style={{ height: '34px' }}
                onClick={() => showDeleteConfirm(item.id)}
                disabled={item.count > 0}
              >
                <DeleteFilled />
              </Button.Danger>
            </div>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    const { keyword, keyType, keyOrder, keyDirection } = state;
    setKeySearch(
      `keyword=${keyword}&type=${keyType}&order=${keyOrder}&direction=${keyDirection}`,
    );
    if (keyword === '') setTmpKeyword('');
  }, [state]);

  return (
    <>
      <TableContainer>
        <Table<Tag>
          columns={columns}
          dataSource={tags}
          pagination={{ showSizeChanger: false }}
          rowKey="id"
          bordered
        />
      </TableContainer>
      {isModalVisible && (
        <EditTagModal
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          tag={selected}
        />
      )}
    </>
  );
};
export default TagsTable;
