import MarkdownIt from 'markdown-it';
import footnote from 'markdown-it-footnote';
import deflist from 'markdown-it-deflist';

import markdownItMermaid from './markdown-it-mermaid'; //Hacked by CuongTM
import highlight from './markdown-it-highlightjs'; //Hacked by CuongTM
import MarkdownItKatex from './markdown-it-katex'; //Hacked by CuongTM

import abbreviation from 'markdown-it-abbr';
import underline from 'markdown-it-ins';
import mark from 'markdown-it-mark';
import copy from 'markdown-it-code-copy';

import codeTabs from 'markdown-it-codetabs';
import namedCodeBlock from 'markdown-it-named-code-blocks';

import copyOptions from '../components/markdown/assets/js/copyOptions';
import '@mdi/font/css/materialdesignicons.css';


import MarkdownItTreelist from 'markdown-it-treelist';
import MarkdownItLabel from 'markdown-it-label';
import MarkdownItCollapsible from 'markdown-it-collapsible';
import { figure } from '@mdit/plugin-figure';
import { imgSize } from '@mdit/plugin-img-size';
import { container } from '@mdit/plugin-container';
import { ins } from '@mdit/plugin-ins';
import { plantuml } from '@mdit/plugin-plantuml';
import { align } from './markdown-it-align';
import { alert } from '@mdit/plugin-alert';
import '@mdit/plugin-alert/style';
import { tab } from '@mdit/plugin-tab';

declare const hljs: any;

const parser = new MarkdownIt({
  html: true,
  xhtmlOut: true,
  breaks: true,
  linkify: true,
  langPrefix: 'language-',
  typographer: true,
});

const tabOptions = {
  name: 'tabs',
};

parser
  .use(footnote)
  .use(deflist)
  .use(namedCodeBlock)
  .use(MarkdownItKatex)
  .use(abbreviation)
  .use(underline)
  .use(plantuml as any)
  .use(mark)
  .use(highlight, { hljs })
  .use(copy, copyOptions)
  .use(MarkdownItTreelist)
  .use(MarkdownItLabel)
  .use(MarkdownItCollapsible)
  .use(figure as any)
  .use(imgSize as any)
  .use(container as any)
  .use(ins as any)
  .use(plantuml as any)
  .use(align as any)
  .use(alert as any)
  .use(tab as any, tabOptions)
  .use(markdownItMermaid.MermaidPlugIn)
  .use(codeTabs);

export default parser;
