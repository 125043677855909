import NotFound from 'components/commons/NotFound';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import { useGetTrackInfoQuery } from 'services/track.service';
import TrackDetailContent from './TrackDetailContent';

const GetTrackDetail = (): ReactElement => {
  const { trackId } = useParams<{ trackId: string }>();
  const { isError, isLoading } = useGetTrackInfoQuery(trackId);

  if (isError) {
    return <NotFound subTitle="Không tìm thấy lộ trình!" />;
  }

  if (isLoading) {
    return <LoadingSpin text="Đang tải dữ liệu" />;
  }

  return <TrackDetailContent />;
};
export default GetTrackDetail;
