import { createApi } from '@reduxjs/toolkit/query/react';
import { PriceType } from 'models/order.model';
import { Promotion, Voucher, VoucherItems } from 'models/promotion.model';
import { ListItemType } from 'store/order.slice';
import { baseQuery } from './base.service';

interface UpdatePromotion {
  name: string;
  content: string;
  start_date: string;
  end_date: string;
  discount: string;
  discount_percent: number;
  item_ids: string[];
}

interface NewPromotion {
  name: string;
  content: string;
  start_date: string;
  end_date: string;
  discount: number;
  discount_percent: number;
  list_items: {
    id: string;
    type: string;
    price_lab: number;
    price_live: number;
  }[];
}

export const promotionApi = createApi({
  reducerPath: 'promotions',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getPromotions: builder.query<Promotion[], void>({
      query: () => {
        return {
          url: `list-promotion`,
          method: 'GET',
        };
      },
      transformResponse: (response: Promotion[]) => {
        response = response === null ? ([] as Promotion[]) : response;
        response.length > 0 &&
          response.map((item, index) => {
            item.start_date = item.start_date.includes('0001-01-01T00')
              ? ''
              : item.start_date;
            item.end_date = item.end_date.includes('0001-01-01T00')
              ? ''
              : item.end_date;
            item.index = index + 1;
          });

        return response;
      },
    }),
    getVoucherDetail: builder.query<Voucher, string>({
      query: (promo_name) => {
        return {
          url: `promotion/${promo_name}`,
          method: 'GET',
        };
      },
      transformResponse: (response: Voucher) => {
        response.list_items = response.list_items ?? ([] as VoucherItems[]);
        return response;
      },
    }),
    updateVoucherInfo: builder.mutation<string, UpdatePromotion>({
      query: (data) => {
        return {
          url: `promotion/${data.name}`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    createPromotion: builder.mutation<string, NewPromotion>({
      query: (data) => {
        return {
          url: `promotion`,
          method: 'POST',
          body: data,
        };
      },
    }),

    deletePromotion: builder.mutation<string, string>({
      query: (name) => {
        return {
          url: `promotion/${name}`,
          method: 'DELETE',
        };
      },
    }),

    getItemsList: builder.query<ListItemType[], void>({
      query: () => {
        return {
          url: `list-items?type=class`,
          method: 'GET',
        };
      },
      transformResponse: (response: ListItemType[]) => {
        response = response ?? ([] as ListItemType[]);
        response.length > 0 &&
          response.map(
            (item) => (item.price = item.price ?? ([] as PriceType[])),
          );
        return response;
      },
    }),
  }),
});

export const {
  useGetPromotionsQuery,
  useGetVoucherDetailQuery,
  useUpdateVoucherInfoMutation,
  useCreatePromotionMutation,
  useDeletePromotionMutation,
  useGetItemsListQuery,
} = promotionApi;
