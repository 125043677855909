import { Banner } from 'models/banner.model';
import { ReactElement, useEffect, useState } from 'react';
import { Col, Input, message, Row, Select } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import styled from 'styled-components';

import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  DeleteFilled,
  ExclamationCircleFilled,
  LeftOutlined,
} from '@ant-design/icons';
import {
  useDeleteBannerMutation,
  useUpdateBannerMutation,
} from 'services/configure.service';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { Option } = Select;

const BannerDetailContent = (banner: Banner): ReactElement => {
  const { bannerId } = useParams<{ bannerId: string }>();
  const history = useHistory();
  const [chosenThumbnail, setChosenThumbnail] = useState(banner.thumbnail);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [deleteBanner] = useDeleteBannerMutation();
  const [updateBanner] = useUpdateBannerMutation();

  const [form] = Form.useForm();

  const showDeleteConfirm = () => {
    confirm({
      title: 'Xác nhận xoá',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá banner này không ?',
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deleteBanner(bannerId)
          .unwrap()
          .then(() => {
            message.success('Xoá banner thành công!');
            setTimeout(() => {
              history.push(generatePath(route.configure.banner.list));
            }, 700);
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: any) => {
    updateBanner({
      id: bannerId,
      data: {
        ...values,
        status: values.status === 'true' ? true : false,
      },
    })
      .unwrap()
      .then(() => {
        message.success('Cập nhật banner thành công');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      name: banner.name,
      link: banner.link,
      status: banner.status ? 'true' : 'false',
      thumbnail: banner.thumbnail,
    });
    setChosenThumbnail(banner.thumbnail);
  }, [banner]);

  useEffect(() => {
    form.setFieldsValue({
      thumbnail: chosenThumbnail,
    });
  }, [chosenThumbnail]);

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={banner}
      >
        <GroupButton>
          <LinksContainer style={{ display: 'flex' }}>
            <Link.Default to={generatePath(route.configure.banner.list)}>
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
            >
              Cập nhật
            </Button.Primary>
            <Link.Danger to="#" onClick={showDeleteConfirm}>
              <DeleteFilled /> Xoá
            </Link.Danger>
          </LinksContainer>
        </GroupButton>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={10}>
            <BorderTopBoxContent>
              <Form.Item
                label={<FormLabel label="Tên banner" />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên banner!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Redirect To Url" />}
                name="link"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập link redirect!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Trạng thái" require={false} />}
                name="status"
              >
                <Select>
                  <Option value="true">Kích hoạt</Option>
                  <Option value="false">Vô hiệu hoá</Option>
                </Select>
              </Form.Item>
            </BorderTopBoxContent>
          </Col>
          <Col xs={24} md={14}>
            <BorderTopBoxContent>
              <Form.Item
                label={<FormLabel label="Ảnh banner" />}
                name="thumbnail"
                extra="Tỉ lệ banner (chiều ngang / chiều cao) = 10/6.Kích thước tối thiểu 1000px x 600px. Định dạnh jpg hoặc png."
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn ảnh banner!',
                  },
                ]}
              >
                {chosenThumbnail !== '' && <img src={chosenThumbnail} />}
              </Form.Item>
              <div>
                <Button.Info onClick={() => setIsModalVisible(true)}>
                  Chọn ảnh
                </Button.Info>
              </div>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setChosenThumbnail}
        />
      )}
    </>
  );
};

export default BannerDetailContent;
