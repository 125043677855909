import { ReactElement } from 'react';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { Table } from 'antd';

import { TeacherListReport } from 'models/report.model';

import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { formatMoney } from 'libraries/utils/changeFormatData';
import { Moment } from 'moment';

interface PropsType {
  teachers: TeacherListReport[];
  from?: Moment;
  to?: Moment;
}

const GeneralSalary = ({ teachers, from, to }: PropsType): ReactElement => {
  const columns: ColumnsType<TeacherListReport> = [
    {
      key: 'full_name',
      width: '20%',
      title: 'Họ tên',
      dataIndex: 'full_name',
      render: (_text, item) => (
        <RouterLink
          to={{
            pathname: generatePath(route.report.teacher.detail, {
              userId: item.id,
            }),
            state: {
              from: from?.toISOString(),
              to: to?.toISOString(),
            },
          }}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          {item.full_name}
        </RouterLink>
      ),
    },
    {
      key: 'email',
      width: '20%',
      title: 'Email',
      dataIndex: 'email',
      render: (email) => <a href={`mailto:${email}`}>{email}</a>,
    },
    {
      key: 'phone',
      width: '15%',
      title: 'Số điện thoại',
      dataIndex: 'phone',
      render: (phone) => <a href={`tel:${phone}`}>{phone}</a>,
    },
    {
      key: 'bank_name',
      width: '20%',
      title: 'Ngân hàng',
      dataIndex: 'bank_name',
    },
    {
      key: 'bank_account',
      width: '15%',
      title: 'Số tài khoản',
      dataIndex: 'bank_account',
    },
    {
      key: 'salary',
      width: '10%',
      title: 'Lương',
      dataIndex: 'salary',
      render: (salary) => (salary !== 0 ? formatMoney(salary) : 0),
    },
  ];

  return (
    <TableContainer>
      <Table<TeacherListReport>
        columns={columns}
        dataSource={teachers}
        pagination={false}
        rowKey="id"
        bordered
      />
    </TableContainer>
  );
};

export default GeneralSalary;
