import Link from 'libraries/components/commons/Link';
import { classTypeValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { TeacherAttendanceSalary } from 'models/report.model';
import { ReactElement } from 'react';
import { BonusDetails } from 'models/report.model';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';
import { currDate } from 'libraries/utils/datetime';
import { downloadExcelFile, exportMultipleSheet } from 'libraries/utils/excel';

interface PropsType {
  name: string;
  attendances: TeacherAttendanceSalary[];
  firstDay: string;
  lastDay: string;
  bonuses: BonusDetails[];
}

const ExportExcel = ({
  name,
  attendances,
  firstDay,
  lastDay,
  bonuses,
}: PropsType): ReactElement => {
  const { full_name, email } = useSelector(selectUser);

  const approvedBonuses = bonuses.filter((bonus) => bonus.status === 'approve');
  const totalSalary = attendances.reduce(
    (prev, cur) => (prev += cur.salary),
    0,
  );
  const totalBonus = approvedBonuses.reduce(
    (prev, cur) => (prev += cur.bonus),
    0,
  );

  const handleExportExcel = () => {
    const info = {
      creator: full_name,
      email: email,
      date: currDate(),
    };

    const sheets = [
      {
        sheetName: 'Dạy học',
        data: [
          [
            'Ngày điểm danh',
            'Lớp học',
            'Số học viên đi học/ Tổng số',
            'Lợi nhuận (đ)',
            'Lương (đ)',
            'Ghi chú',
          ],
          ...attendances.map((item) => [
            formatDate(item.date),
            item.class_name,
            item.class_size,
            item.profit,
            item.salary,
            classTypeValues.find((type) => type.value === item.class_type)
              ?.label || '',
          ]),
        ],
      },
      {
        sheetName: 'Lương thưởng',
        data: [
          ['Ngày thực hiện', 'Đầu việc', 'Lương thưởng (đ)'],
          ...approvedBonuses.map((item) => [
            formatDate(item.date),
            item.content,
            item.bonus,
          ]),
        ],
      },
      {
        sheetName: 'Tổng',
        data: [
          ['Tổng lương', 'Tổng thưởng', 'Thưởng + Lương'],
          [totalSalary, totalBonus, totalSalary + totalBonus],
        ],
      },
    ];

    const workbook = exportMultipleSheet(sheets, info);

    const fileName = `Báo cáo chi tiết thu nhập của giảng viên ${name} từ ${firstDay} đến ${lastDay}`;
    downloadExcelFile(workbook, fileName);
  };

  return (
    <Link.Primary
      to="#"
      onClick={handleExportExcel}
      style={{ height: 32, lineHeight: 'normal' }}
    >
      Xuất Excel
    </Link.Primary>
  );
};

export default ExportExcel;
