import { createSlice } from '@reduxjs/toolkit';
import { ClassFind } from 'models/class.model';
import { ReportClassAttendance } from 'models/report.model';
import { UserInforType } from 'models/user.model';
// import moment, { Moment } from 'moment';
import { reportApi } from 'services/report.service';
import { RootState } from 'store';

// export interface ReportDateRange {
//   fromDate: Moment;
//   toDate: Moment;
// }

export interface ReportState {
  classes: ClassFind[];
  attendances: ReportClassAttendance;
  sale: UserInforType;
  teacher: UserInforType;
  // reportDateRange: ReportDateRange;
}

const initialState: ReportState = {
  classes: [] as ClassFind[],
  attendances: {} as ReportClassAttendance,
  sale: {} as UserInforType,
  teacher: {} as UserInforType,
  // reportDateRange: {
  //   fromDate: moment.utc().date(1).hour(0).minute(0).second(0).millisecond(0),
  //   toDate: moment
  //     .utc()
  //     .month(moment.utc().month() + 1)
  //     .date(0)
  //     .hour(23)
  //     .minute(59)
  //     .second(59)
  //     .millisecond(999),
  // },
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      reportApi.endpoints.getClassesList.matchFulfilled,
      (state, { payload }) => {
        state.classes = payload;
      },
    );
    builder.addMatcher(
      reportApi.endpoints.getSaleInfo.matchFulfilled,
      (state, { payload }) => {
        state.sale = payload;
      },
    );
    builder.addMatcher(
      reportApi.endpoints.getTeacherInfo.matchFulfilled,
      (state, { payload }) => {
        state.teacher = payload;
      },
    );
  },
});

export const {} = reportSlice.actions;
export const selectClasses = (state: RootState): ClassFind[] =>
  state.report.classes;

export const selectSale = (state: RootState): UserInforType =>
  state.report.sale;

export const selectTeacher = (state: RootState): UserInforType =>
  state.report.teacher;

// export const selectReportDateRange = (state: RootState): ReportDateRange =>
//   state.report.reportDateRange;

export default reportSlice.reducer;
