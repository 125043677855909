import { LeftOutlined } from '@ant-design/icons';
import { Col, Input, message, Row, Select } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import { ReactElement, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useCreateBannerMutation } from 'services/configure.service';

const { Option } = Select;

const CreateBannerDetail = (): ReactElement => {
  const history = useHistory();
  const [createBanner] = useCreateBannerMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [thumbnail, setThumbnail] = useState('');

  const [form] = Form.useForm();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: any) => {
    createBanner({ ...values, status: values.status === 'true' ? true : false })
      .unwrap()
      .then((response) => {
        message.success('Tạo banner thành công');
        setTimeout(() => {
          history.push(
            generatePath(route.configure.banner.detail, {
              bannerId: response.banner_id,
            }),
          );
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      thumbnail: thumbnail,
    });
  }, [thumbnail]);

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.configure.banner.list)}
        secondaryText="Banner trang chủ"
        text="Tạo banner"
      />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ status: 'false' }}
      >
        <LinksContainer>
          <Link.Default to={generatePath(route.configure.banner.list)}>
            <LeftOutlined /> Quay lại
          </Link.Default>
          <Button.Primary
            type="primary"
            htmlType="submit"
            style={{ height: '36px' }}
          >
            Lưu
          </Button.Primary>
        </LinksContainer>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={10}>
            <BorderTopBoxContent>
              <Form.Item
                label={<FormLabel label="Tên banner" />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên banner!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Redirect To Url" />}
                name="link"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập link redirect!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Trạng thái" require={false} />}
                name="status"
              >
                <Select>
                  <Option value="true">Kích hoạt</Option>
                  <Option value="false">Vô hiệu hoá</Option>
                </Select>
              </Form.Item>
            </BorderTopBoxContent>
          </Col>
          <Col xs={24} md={14}>
            <BorderTopBoxContent>
              <Form.Item
                label={<FormLabel label="Ảnh banner" />}
                name="thumbnail"
                extra="Tỉ lệ banner (chiều ngang / chiều cao) = 10/6.Kích thước tối thiểu 1000px x 600px. Định dạnh jpg hoặc png."
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn ảnh banner!',
                  },
                ]}
              >
                {thumbnail !== '' && <img src={thumbnail} />}
              </Form.Item>
              <div>
                <Button.Info onClick={() => setIsModalVisible(true)}>
                  Chọn ảnh
                </Button.Info>
              </div>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setThumbnail}
        />
      )}
    </>
  );
};

export default CreateBannerDetail;
