import { LeftOutlined } from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import { useDateRange } from 'libraries/hooks/date';
import { NewItem } from 'models/promotion.model';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useCreatePromotionMutation,
  useGetItemsListQuery,
} from 'services/promotion.service';
import { ListItemType } from 'store/order.slice';
import styled from 'styled-components';
import ListItems from './ListItems';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { Option } = Select;
const { RangePicker } = DatePicker;

const today = new Date();

const CreateVoucherContent = (): ReactElement => {
  const history = useHistory();
  const { data, isError, error } = useGetItemsListQuery();
  const list = data ?? ([] as ListItemType[]);

  const [createVoucher] = useCreatePromotionMutation();

  const [chosenItems, setChosenItems] = useState<string[]>([]);
  const [listItems, setListItems] = useState<NewItem[]>([]);

  const typeDiscounts = ['discount_percent', 'discount'];
  const [typeDiscount, setTypeDiscount] = useState(typeDiscounts[0]);

  const handleTypeDisCounts = (value: string): void => {
    setTypeDiscount(value);
  };

  const [discount, setDiscount] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);

  const [form] = Form.useForm();

  const { startOfDay, endOfDay } = useDateRange();

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    createVoucher({
      name: values.name,
      content: values.content,
      start_date: startOfDay(values.time[0].utc(true)).toISOString(),
      end_date: endOfDay(values.time[1].utc(true)).toISOString(),
      discount: values.discount,
      list_items: listItems,
      discount_percent: values.discount_percent,
    })
      .unwrap()
      .then(() => {
        message.success('Tạo voucher mới thành công!');
        setTimeout(() => {
          history.push(generatePath(route.promotion.list));
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    const newList = list.reduce((arr, item) => {
      if (chosenItems.includes(item.item_id)) {
        const price_lab = item.price.reduce((price: number, i) => {
          if (i.type === 'lab') price = i.price;
          return price;
        }, 0);
        const price_live = item.price.reduce((price: number, i) => {
          if (i.type === 'live') price = i.price;
          return price;
        }, 0);
        arr.push({
          id: item.item_id,
          type: item.type,
          price_lab,
          price_live,
        });
      }
      return arr;
    }, [] as NewItem[]);
    setListItems(newList);
  }, [chosenItems]);

  if (isError) return <ShowServerError error={error} />;

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.promotion.list)}
        secondaryText="Khuyến mại"
        text="Tạo voucher"
      />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          time: [
            moment(moment(today).format('DD/MM/YYYY'), 'DD/MM/YYYY'),
            moment(moment(today).format('DD/MM/YYYY'), 'DD/MM/YYYY'),
          ],
          discount: 0,
          discount_percent: 0,
        }}
      >
        <GroupButton>
          <LinksContainer style={{ display: 'flex' }}>
            <Link.Default to={generatePath(route.promotion.list)}>
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px', marginTop: '5px' }}
            >
              Lưu
            </Button.Primary>
          </LinksContainer>
        </GroupButton>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={12} lg={8}>
            <BorderTopBoxContent>
              <div className="box-header">Thông tin khuyến mại</div>
              <div className="box-body">
                <Form.Item
                  label={<FormLabel label="Tiêu đề" />}
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập tên voucher!',
                    },
                  ]}
                  name="name"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Mã kích hoạt voucher" />}
                  extra="Mã kích hoạt chỉ bao gồm ký tự viết hoa từ A-Z và số từ 0-9 và dấu gạch ngang (độ dài từ 4 - 16 ký tự)"
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập mã kích hoạt!',
                    },
                    // {
                    //   pattern: /^[A-Z0-9-]{4,16}$/,
                    //   message: 'Sai định dạng',
                    // },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label={<FormLabel label="Khuyến mại theo" />}>
                  <Select
                    defaultValue="discount_percent"
                    onChange={handleTypeDisCounts}
                  >
                    <Option value="discount_percent">Phần trăm %</Option>
                    <Option value="discount">Khoản tiền trực tiếp</Option>
                  </Select>
                </Form.Item>
                {typeDiscount === 'discount_percent' ? (
                  <Form.Item
                    label={<FormLabel label="Mức giảm" />}
                    name="discount_percent"
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng nhập mức giảm giá!',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      onChange={(value: number | string | null) => {
                        if (typeof value === 'number')
                          setDiscountPercent(value);
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={<FormLabel label="Mức giảm" />}
                    name="discount"
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng nhập mức giảm giá!',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      onChange={(value: number | string | null) => {
                        if (typeof value === 'number') setDiscount(value);
                      }}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="time"
                  label={<FormLabel label="Khoảng thời gian" />}
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập khoảng thời gian!',
                    },
                  ]}
                >
                  <RangePicker format="DD-MM-YYYY" />
                </Form.Item>
              </div>
            </BorderTopBoxContent>
          </Col>
          <Col xs={24} md={12} lg={16}>
            <BorderTopBoxContent>
              <div className="box-header">Lựa chọn lớp học áp dụng</div>
              <div className="box-body">
                <ListItems
                  list={list}
                  chosenItems={chosenItems}
                  setChosenItems={setChosenItems}
                  discount={discount}
                  discountPercent={discountPercent}
                />
              </div>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateVoucherContent;
