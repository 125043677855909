import { Col, Row, Typography } from 'antd';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { ListPayReceiptType, PayOrderReport } from 'models/report.model';
import OrderReceiptTable from 'pages/report/finance/order-receipt/OrderReceiptTable';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectSale } from 'store/report.slice';
import OrderPayment from '../../../finance/order-payment/OrderPayment';

interface PropsType {
  data: PayOrderReport[];
}

const OrderPaymentReport = ({ data }: PropsType): ReactElement => {
  const sale = useSelector(selectSale);
  let paidAmount = 0;

  const payForOrder = data.filter((item) => {
    if (item.full_name === sale.full_name) {
      paidAmount -= item.amount;
      return true;
    }
  });

  return (
    <>
      <div style={{ marginBottom: '0.8rem' }}>
        <strong>Thống kê trong tháng</strong>
      </div>

      <Row style={{ maxWidth: '16rem', marginBottom: '1.16rem' }}>
        <Col span={12}>- Số tiền đã hoàn:</Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Typography.Text type="danger" strong>
            {formatMoneyVnd(paidAmount)}
          </Typography.Text>
        </Col>
      </Row>
      <OrderPayment reports={payForOrder ?? []} />
    </>
  );
};

export default OrderPaymentReport;
