export const formatMoney = (money: string | number): string =>
  money
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatMoneyVnd = (money: number): string =>
  new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
    money,
  );

export const formatNumber = (values: number | undefined) =>
  `${values}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
