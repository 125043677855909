export const countAllArrString = (arr: string[]) => {
  let count = 0;

  for (const str of arr) {
    count += str.length;
  }

  return count;
};

/*
Format lại các công thức toán học được sinh ra từ ChatGPT, Claude Sonnet, ...
về đúng chuẩn open tag, close tag hỗ trợ sẵn trong hệ thống
*/
export const formatAItoStandard = (s: string) => {
  // Chuyển inline math \(math_formula)\ thành $math_formula$
  const inlineFormatted = s.replace(/\\\(\s*(.*?)\s*\\\)/g, (match, content) => {
    return `${'$'}${content}${'$'}`;
  });

  // Chuyển block math \[ Block Math \] thành ```math Block Math ```
  const blockFormatted = inlineFormatted.replace(/^\s*\\\[\s*([\s\S]*?)\s*\\\]\s*$/gm, (match, p1) => {
    return `${'```math'}\n${p1.trim()}\n${'```'}`;
  });

  return blockFormatted;
};
