import { ReactElement } from 'react';
import { Button } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  hideSidebar,
  showSidebar,
  collapseSidebar,
  expandSidebar,
  selectIsSidebarOpen,
  selectIsSidebarCollapse,
} from 'store/general.slice';
import useMedia from 'utils/useMedia';
import Logo from 'assets/images/logo.png';
import User from './User';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #3c8dbc;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 2;
`;

const StyledLogoWrapper = styled.div`
  width: 256px;
  text-align: center;
  background-color: #367fa9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled.img`
  width: 100px;
`;

const StyledButton = styled(Button)`
  height: 100%;
  border-radius: 0;
  background: transparent;

  &&&:hover {
    background-color: #042f54;
  }
`;

const Header = (): ReactElement => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const isSidebarCollapse = useSelector(selectIsSidebarCollapse);
  const { xs, sm } = useMedia();

  const changeSidebar = () => {
    if (xs || sm) {
      dispatch(isSidebarOpen ? hideSidebar() : showSidebar());
    } else {
      dispatch(isSidebarCollapse ? expandSidebar() : collapseSidebar());
    }
  };

  return (
    <StyledHeader>
      <StyledLogoWrapper>
        <a href="https://techmaster.vn/">
          <StyledLogo src={Logo} alt="Techmaster Admin" />
        </a>
      </StyledLogoWrapper>

      <StyledButton type="text" onClick={changeSidebar}>
        {isSidebarOpen || (!xs && !sm && !isSidebarCollapse) ? (
          <MenuFoldOutlined style={{ color: '#fff', fontSize: 22 }} />
        ) : (
          <MenuUnfoldOutlined style={{ color: '#fff', fontSize: 22 }} />
        )}
      </StyledButton>

      <div style={{ marginLeft: 'auto', padding: '12px' }}>
        <User />
      </div>
    </StyledHeader>
  );
};

export default Header;
