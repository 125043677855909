import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { Advisory } from 'models/order.model';
import { ReactElement, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetListAdvisoryQuery } from 'services/order.service';
import AdvisoriesList from './AdvisoriesList';
import { downloadExcelFile, exportSingleSheet } from 'libraries/utils/excel';
import { currDate } from 'libraries/utils/datetime';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';

interface KeySearchType {
  fullname: string;
  status: string;
  phone: string;
  email: string;
  interested: string;
  sale_id: string;
  item: string;
  class_status: string;
}

const GetList = (): ReactElement => {
  const [keyword, setKeyword] = useState(
    '?page=1&fullname=&status=&phone=&email=&interested=&sale_id=&item=&class_status=',
  );

  const [currentPage, setCurrentPage] = useState(1);
  const { data, isError, error } = useGetListAdvisoryQuery(keyword);
  const { full_name, email } = useSelector(selectUser);

  const listAdvisories = (data && data.list_advisories) || ([] as Advisory[]);

  const total_page = data ? data.total_page : 0;

  const [state, setState] = useState<KeySearchType>({
    fullname: '',
    status: '',
    phone: '',
    email: '',
    interested: '',
    sale_id: '',
    item: '',
    class_status: '',
  });

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleExportExcel = () => {
    const info = {
      wsName: 'Danh sách tư vấn',
      creator: full_name,
      email: email,
      date: currDate(),
    };
    const header = [
      'Họ tên',
      'Email',
      'SĐT',
      'Khoá học quan tâm',
      'Ngày gửi',
      'Trạng thái',
      'Mức độ quan tâm',
    ];
    const data = listAdvisories.map((item) => [
      item.full_name,
      item.email,
      item.phone,
      item.item,
      item.date,
      item.status,
      item.interested,
    ]);

    const workbook = exportSingleSheet([header, ...data], info);

    downloadExcelFile(workbook, info.wsName);
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.order.list)}
        secondaryText="Đơn hàng"
        text="Tư vấn"
      />
      <LinksContainer>
        {listAdvisories.length > 0 && (
          <Link.Primary to="#" onClick={handleExportExcel}>
            Xuất Excel
          </Link.Primary>
        )}
      </LinksContainer>
      <BorderBoxContent>
        <AdvisoriesList
          advisories={listAdvisories}
          total_page={total_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setKeyword={setKeyword}
          state={state}
          handleSetState={handleSetState}
        />
      </BorderBoxContent>
    </>
  );
};
export default GetList;
