import { createApi } from '@reduxjs/toolkit/query/react';
import { mediaServerQuery } from './base.service';
import { FileResponseType, Image } from '../models/media.model';

interface ImageProps {
  images: Image[];
  total_page: number;
}

export const mediaApi = createApi({
  reducerPath: 'mediaApi',

  baseQuery: mediaServerQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getImages: builder.query<ImageProps, void>({
      query: () => {
        return {
          url: `/get-image`,
          method: 'GET',
        };
      },
    }),

    findImagesInSystem: builder.query<
      ImageProps,
      { pageNumber: number; limitNumber: number; keyword: string }
    >({
      query: (arg) => {
        const { pageNumber, limitNumber, keyword } = arg;
        return {
          url: `/get-image?page=${pageNumber}&limit=${limitNumber}&keyword=${keyword}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ImageProps) => {
        response.images =
          response.images === null ? ([] as Image[]) : response.images;
        return response;
      },
    }),

    getUserImages: builder.query<
      ImageProps,
      { pageNumber: number; limitNumber: number }
    >({
      query: (arg) => {
        const { pageNumber, limitNumber } = arg;
        return {
          url: `/images?page=${pageNumber}&limit=${limitNumber}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ImageProps) => {
        response.images =
          response.images === null ? ([] as Image[]) : response.images;
        return response;
      },
    }),

    uploadFile: builder.mutation<FileResponseType, { data: FormData }>({
      query: (arg) => {
        const { data } = arg;
        return {
          url: `upload-file`,
          method: 'POST',
          body: data,
        };
      },
    }),

    uploadImage: builder.mutation<Image, { data: FormData }>({
      query: (arg) => {
        const { data } = arg;

        return {
          url: `upload-image`,
          method: 'POST',
          body: data,
        };
      },
    }),

    deleteImage: builder.mutation<Image, { id: string }>({
      query: (arg) => {
        return {
          url: `delete-image`,
          method: 'DELETE',
          body: arg,
        };
      },
    }),
    updateImageInfo: builder.mutation<
      Image,
      { id: string; title: string; description: string }
    >({
      query: (arg) => {
        return {
          url: `update-image`,
          method: 'POST',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetImagesQuery,
  useFindImagesInSystemQuery,
  useGetUserImagesQuery,
  useUploadFileMutation,
  useUploadImageMutation,
  useDeleteImageMutation,
  useUpdateImageInfoMutation,
} = mediaApi;
