import { LeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { PayReceiptDetail } from 'models/report.model';
import { ReactElement } from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import styled from 'styled-components';

interface PropsType {
  receipt: PayReceiptDetail;
}

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoRow = styled(Row)`
  margin-bottom: 15px;
  .ant-col {
    padding: 12px 15px;
  }
`;

const ReceiptDetailContent = ({ receipt }: PropsType): ReactElement => {
  return (
    <>
      <GroupButton>
        <LinksContainer style={{ display: 'flex' }}>
          <Link.Default to={generatePath(route.report.finance)}>
            <LeftOutlined /> Quay lại
          </Link.Default>
        </LinksContainer>
      </GroupButton>
      <Row>
        <Col xs={24} md={16} lg={12}>
          <BorderTopBoxContent>
            <InfoRow>
              <Col xs={12} md={8}>
                <strong>Ngày giao dịch</strong>
              </Col>
              <Col xs={12} md={16}>
                : {receipt.datetime !== '' ? formatDate(receipt.datetime) : ''}
              </Col>
            </InfoRow>
            <InfoRow>
              <Col xs={12} md={8}>
                <strong>Số tiền</strong>
              </Col>
              <Col xs={12} md={16}>
                : {formatMoneyVnd(receipt.amount)}
              </Col>
            </InfoRow>
            <InfoRow>
              <Col xs={12} md={8}>
                <strong>Người nộp</strong>
              </Col>
              <Col xs={12} md={16}>
                :{' '}
                {receipt.user_id !== '' ? (
                  <RouterLink
                    to={generatePath(route.user.info, {
                      userId: receipt.user_id,
                    })}
                  >
                    {receipt.full_name}
                  </RouterLink>
                ) : (
                  receipt.full_name
                )}
              </Col>
            </InfoRow>
            <InfoRow>
              <Col xs={12} md={8}>
                <strong>Người nhận</strong>
              </Col>
              <Col xs={12} md={16}>
                :{' '}
                {receipt.receiver_id !== '' ? (
                  <RouterLink
                    to={generatePath(route.user.info, {
                      userId: receipt.receiver_id,
                    })}
                  >
                    {receipt.receiver_full_name}
                  </RouterLink>
                ) : (
                  receipt.receiver_full_name
                )}
              </Col>
            </InfoRow>
            <InfoRow>
              <Col xs={12} md={8}>
                <strong>Nội dung thu</strong>
              </Col>
              <Col xs={12} md={16}>
                : {receipt.content}
              </Col>
            </InfoRow>
          </BorderTopBoxContent>
        </Col>
      </Row>
    </>
  );
};

export default ReceiptDetailContent;
