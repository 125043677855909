/**
 * JS Implementation of MurmurHash3 (r136) (as of May 20, 2011)
 *
 * Modifed to support non-ascii string by encoding to utf-8
 *
 * @author <a href="mailto:gary.court@gmail.com">Gary Court</a>
 * @see http://github.com/garycourt/murmurhash-js
 * @author <a href="mailto:aappleby@gmail.com">Austin Appleby</a>
 * @see http://sites.google.com/site/murmurhash/
 *
 * @param {string} str string to hash
 * @param {number} seed Positive integer only
 * @return {number} 32-bit positive integer hash
 */

function murmurhash3_32_gc(str: string, seed: number): number {
  const key = new TextEncoder().encode(str);
  const remainder = key.length & 3; // key.length % 4
  const bytes = key.length - remainder;
  let h1 = seed;
  const c1 = 0xcc9e2d51;
  const c2 = 0x1b873593;

  for (let i = 0; i < bytes; i += 4) {
    let k1 = 
      ((key[i] & 0xff)) |
      ((key[i + 1] & 0xff) << 8) |
      ((key[i + 2] & 0xff) << 16) |
      ((key[i + 3] & 0xff) << 24);

    k1 = Math.imul(k1 & 0xffff, c1) + Math.imul((k1 >>> 16) * c1, 0x10000) & 0xffffffff;
    k1 = (k1 << 15) | (k1 >>> 17);
    k1 = Math.imul(k1 & 0xffff, c2) + Math.imul((k1 >>> 16) * c2, 0x10000) & 0xffffffff;

    h1 ^= k1;
    h1 = (h1 << 13) | (h1 >>> 19);
    const h1b = Math.imul(h1 & 0xffff, 5) + Math.imul((h1 >>> 16) * 5, 0x10000) & 0xffffffff;
    h1 = (h1b & 0xffff) + 0x6b64 + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16);
  }

  let k1 = 0;

  if (remainder === 3) {
    k1 ^= (key[bytes + 2] & 0xff) << 16;
  }
  if (remainder >= 2) {
    k1 ^= (key[bytes + 1] & 0xff) << 8;
  }
  if (remainder >= 1) {
    k1 ^= (key[bytes] & 0xff);
    k1 = Math.imul(k1 & 0xffff, c1) + Math.imul((k1 >>> 16) * c1, 0x10000) & 0xffffffff;
    k1 = (k1 << 15) | (k1 >>> 17);
    k1 = Math.imul(k1 & 0xffff, c2) + Math.imul((k1 >>> 16) * c2, 0x10000) & 0xffffffff;
    h1 ^= k1;
  }

  h1 ^= key.length;

  h1 ^= h1 >>> 16;
  h1 = Math.imul(h1 & 0xffff, 0x85ebca6b) + Math.imul((h1 >>> 16) * 0x85ebca6b, 0x10000) & 0xffffffff;
  h1 ^= h1 >>> 13;
  h1 = Math.imul(h1 & 0xffff, 0xc2b2ae35) + Math.imul((h1 >>> 16) * 0xc2b2ae35, 0x10000) & 0xffffffff;
  h1 ^= h1 >>> 16;

  return h1 >>> 0;
}

export default murmurhash3_32_gc;