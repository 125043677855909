import { Col, Row } from 'antd';
import { GeneralInfo } from 'models/dashboard.model';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';

interface PropsType {
  data: GeneralInfo;
}

interface BoxPropsType {
  title: string;
  content: string;
  className: string;
  link: string;
}
interface BoxProps1Type {
  title1: string;
  content1: string;
  title2: string;
  content2: string;
  title3: string;
  content3: string;
  className: string;
  link: string;
}

const ReportBox = styled.div`
  font-size: 16px;
  background-color: white;
  padding: 1.5rem 1rem;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  border-radius: 3px;
  color: #464646;
  margin-bottom: 1.2rem;
  border-left: 3px solid transparent;
  &.primary {
    border-left-color: rgb(60, 141, 188);
  }
  &.info {
    border-left-color: rgb(0, 166, 90);
  }
  &.warning {
    border-left-color: rgb(243, 156, 50);
  }
  &.danger {
    border-left-color: rgb(221, 75, 57);
  }
  .report-box-content {
    font-size: 1.6rem;
  }
`;

const SummaryBox = ({ title, content, className, link }: BoxPropsType) => {
  return (
    <Link to={link}>
      <ReportBox className={className}>
        <div className="report-box-title">{title}</div>
        <div className="report-box-content">{content}</div>
      </ReportBox>
    </Link>
  );
};
const SummaryBox1 = ({
  title1,
  content1,
  title2,
  content2,
  title3,
  content3,
  className,
  link,
}: BoxProps1Type) => {
  return (
    <Link to={link}>
      <ReportBox className={className}>
        <Row
          gutter={[30, 30]}
          style={{
            alignItems: 'center',
          }}
        >
          <Col xs={24} md={8}>
            <div className="report-box-title">{title1}</div>
            <div className="report-box-content">{content1}</div>
          </Col>
          <Col
            xs={24}
            md={8}
            style={{ padding: '0 32px', borderLeft: '1px solid #ccc' }}
          >
            <div className="report-box-title">{title2}</div>
            <div className="report-box-content">{content2}</div>
          </Col>
          <Col
            xs={24}
            md={8}
            style={{ padding: '0 32px', borderLeft: '1px solid #ccc' }}
          >
            <div className="report-box-title">{title3}</div>
            <div className="report-box-content">{content3}</div>
          </Col>
        </Row>
      </ReportBox>
    </Link>
  );
};

const DashboardSummary = ({ data }: PropsType): ReactElement => {
  return (
    <>
      <Col xs={24}>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={8}>
            <SummaryBox
              title="User mới/ Tổng số user"
              content={`${data.total_month_user} / ${data.total_all_user}`}
              className="primary"
              link={generatePath(route.user.manage)}
            />
          </Col>
          <Col xs={24} md={8}>
            <SummaryBox
              title="Bài viết mới/ Tổng số bài"
              content={`${data.total_month_post} / ${data.total_all_post}`}
              className="info"
              link={generatePath(route.blog.list)}
            />
          </Col>
          <Col xs={24} md={8}>
            <SummaryBox
              title="Số đơn mới"
              content={
                data.count_new_register
                  ? data.count_new_register.toString()
                  : '0'
              }
              className="danger"
              link={generatePath(route.order.list)}
            />
          </Col>
        </Row>
      </Col>

      <Col xs={24}>
        <SummaryBox1
          title1="Thu"
          content1={formatMoneyVnd(data.pay_amount)}
          title2="Chi"
          content2={formatMoneyVnd(data.withdrawal_amount)}
          title3="Net"
          content3={formatMoneyVnd(data.pay_amount - data.withdrawal_amount)}
          className="warning"
          link={generatePath(route.report.finance)}
        />
      </Col>
    </>
  );
};

export default DashboardSummary;
