import { Post } from 'models/blog.model';
import { ReactElement, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetBlogPostsQuery, useGetTagsQuery } from 'services/blog.service';

import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { Tag } from 'models/tag.model';
import ListPostsTable from './ListPostsTable';

interface KeySearchType {
  title: string;
  status: string;
  order: string;
  author: string;
  type: string;
  direction: 'asc' | 'desc';
  tagId: string;
}

const GetListPosts = (): ReactElement => {
  const [keySearch, setKeySearch] = useState(
    'page=1&title=&status=&order=&tagId=&author=&type=&direction=desc',
  );

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isError, error } = useGetBlogPostsQuery(keySearch);
  const { data: tagsList } = useGetTagsQuery();

  const tags = tagsList ? tagsList : ([] as Tag[]);

  const posts = data ? data.posts : ([] as Post[]);
  const total_page = data ? data.total_page : 0;

  const [state, setState] = useState<KeySearchType>({
    title: '',
    status: '',
    order: '',
    author: '',
    type: '',
    direction: 'desc',
    tagId: '',
  });

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleOrderList = (order: string, direction: 'asc' | 'desc') => {
    setState({
      ...state,
      order,
      direction,
    });
  };

  const resetKeySearch = () => {
    setState({
      title: '',
      status: '',
      order: '',
      type: '',
      author: '',
      direction: 'desc',
      tagId: '',
    });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Danh sách bài viết" />
      <LinksContainer>
        <Link.Primary to={generatePath(route.blog.create)}>
          <PlusOutlined /> Viết bài
        </Link.Primary>
        <Link.Info to="#" onClick={resetKeySearch}>
          <ReloadOutlined /> Refresh
        </Link.Info>
      </LinksContainer>
      <BorderBoxContent>
        <ListPostsTable
          posts={posts}
          tags={tags}
          total_page={total_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setKeySearch={setKeySearch}
          state={state}
          handleSetState={handleSetState}
          handleOrderList={handleOrderList}
        />
      </BorderBoxContent>
    </>
  );
};
export default GetListPosts;
