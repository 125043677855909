import { DeleteFilled, ImportOutlined } from '@ant-design/icons';
import { Empty, message, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Button from 'libraries/components/commons/Button';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { orderItemValues } from 'libraries/utils/globalVariable';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSaveItemToOrderMutation } from 'services/order.service';
import {
  deleteItemInOrder,
  ItemOrderDetail,
  selectOrder,
  selectTotalPrice,
} from 'store/order.slice';
import styled from 'styled-components';
import ItemModal from '../modals/ItemModal';

const { Text } = Typography;

interface Props {
  refetchClasses: () => void;
}

const ContainerButtons = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
`;

interface SendItemToOrderType {
  price_id: number;
  type: string;
}

const ItemDetail = ({ refetchClasses }: Props): ReactElement => {
  const { orderId } = useParams<{ orderId: string }>();
  const dispatch = useDispatch();
  const [isChangeItems, setIsChangeItems] = useState(false);

  const [saveItemToOrder] = useSaveItemToOrderMutation();

  const orderData = useSelector(selectOrder);
  const totalPrice = useSelector(selectTotalPrice);

  const { items: listItem } = orderData;

  const newItems: SendItemToOrderType[] = [];

  const [itemModallVisible, setItemModalVisible] = useState(false);

  const handleOpenItemModal = () => {
    setItemModalVisible(true);
  };

  const handleOk = () => {
    setItemModalVisible(false);
  };

  const handleCancel = () => {
    setItemModalVisible(false);
  };
  const handleDelete = (currentItem: ItemOrderDetail) => {
    const index = listItem.findIndex(
      (item) => item.item_id === currentItem.item_id,
    );
    setIsChangeItems(true);
    dispatch(deleteItemInOrder(index));
  };

  const handleSubmit = () => {
    listItem.forEach((item) =>
      newItems.push({
        price_id: item.price_id,
        type: item.choose_type,
      }),
    );

    const data = { items: newItems, total: totalPrice };

    saveItemToOrder({ order_id: orderId, data: data })
      .unwrap()
      .then(() => {
        setIsChangeItems(false);
        refetchClasses();
        message.success('Thêm item thành công');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const columns: ColumnsType<ItemOrderDetail> = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '2%',
      render: (_, item) => (
        <div style={{ textAlign: 'center' }}>
          <DeleteFilled onClick={() => handleDelete(item)} />
        </div>
      ),
    },

    {
      // TODO: Addition link to track
      title: 'Tên Item',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },

    {
      title: 'Hình thức học',
      dataIndex: 'choose_type',
      key: 'choose_type',
      width: '10%',
      render: (item) =>
        item === '' || item === 'lab'
          ? 'Phòng lab'
          : item === 'live'
            ? 'Phòng lab'
            : item,
    },

    {
      title: 'Loại lớp học',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      render: (item) => {
        for (let i = 0; i < orderItemValues.length; i++) {
          if (item === orderItemValues[i].value) {
            return <>{orderItemValues[i].label}</>;
          }
        }
      },
    },

    {
      title: 'Giá gốc',
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (_, item) => {
        let price: number;

        if (item.choose_type === '') {
          price = item.price[0].price;
          return <>{formatMoneyVnd(price)}</>;
        } else {
          const result = item.price.filter((i) => i.type === item.choose_type);
          price = result[0].price;
          return <>{formatMoneyVnd(price)}</>;
        }
      },
    },

    {
      title: 'Giảm giá',
      dataIndex: '',
      key: '',
      width: '10%',
      render: (_, item) => {
        if (item.discount > 0) {
          return formatMoneyVnd(item.discount);
        } else if (item.discount_percent > 0) {
          return `${item.discount_percent}%`;
        } else {
          return formatMoneyVnd(item.discount);
        }
      },
    },

    {
      title: 'Thành tiền',
      dataIndex: '',
      key: '',
      width: '10%',
      render: (_, item) => {
        const result = item.price.map((item) => item.price);
        if (item.discount_percent > 0) {
          return formatMoneyVnd(
            (result[0] * (100 - item.discount_percent)) / 100,
          );
        } else {
          return formatMoneyVnd(result[0] - item.discount);
        }
      },
    },
  ];

  return (
    <>
      {listItem.length > 0 ? (
        <>
          <Table
            dataSource={listItem}
            pagination={false}
            columns={columns}
            bordered
            rowKey="item_id"
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={6} index={1}>
                      <div style={{ textAlign: 'end' }}>Tạm tính</div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Text>{formatMoneyVnd(totalPrice)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={6} index={1}>
                      <div style={{ textAlign: 'end' }}>
                        <Text strong>Tổng hóa đơn</Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Text strong style={{ color: 'red' }}>
                        {formatMoneyVnd(totalPrice)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </>
      ) : (
        <Empty description={'Không có dữ liệu'} />
      )}
      <ContainerButtons>
        <Button.Primary onClick={handleSubmit} disabled={!isChangeItems}>
          Lưu thông tin item
        </Button.Primary>
        <Button.History onClick={handleOpenItemModal}>
          <ImportOutlined /> Thêm item vào đơn
        </Button.History>
      </ContainerButtons>

      {/* Modal import track */}
      <ItemModal
        itemModalVisible={itemModallVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setIsChangeItems={setIsChangeItems}
      />
    </>
  );
};

export default ItemDetail;
