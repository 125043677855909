import { Checkbox, Input, message, Select, Tooltip } from 'antd';
import Modal from 'components/commons/Modal';
import Search from 'components/commons/Search';
import Button from 'libraries/components/commons/Button';
import { ClassInfo } from 'models/class.model';
import { AddStudentResponse, Student, UsersType } from 'models/user.model';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  useAddStudentToClassNoOrderMutation,
  useGetAllStudentsClassQuery,
} from 'services/classes.service';
import {
  addStudentToList,
  selectClass,
  selectClassStudents,
} from 'store/classes.slice';
import CreateUser from './CreateUser';
import FindStudent from './FindStudent';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
  status: 'pending' | 'active';
}

const { TextArea } = Input;

const checkUserRole = (
  students: Student[],
  classInfo: ClassInfo,
  user: UsersType,
): { accept: boolean; message: string } => {
  let result = {
    accept: true,
    message: '',
  };
  const { teachers, sale } = classInfo;
  if (students.filter((item) => item.id === user.id).length > 0) {
    result = {
      accept: false,
      message: 'Học viên này đã có trong lớp học!',
    };
  } else if (
    teachers.length > 0 &&
    teachers.filter((item) => item.id === user.id).length > 0
  ) {
    result = {
      accept: false,
      message: `${user.full_name} là giảng viên của lớp học! Không thể thêm vào lớp!`,
    };
  } else if (sale.id === user.id) {
    result = {
      accept: false,
      message: `Không thể thêm nhân viên marketing vào lớp!`,
    };
  }
  return result;
};

const { Option } = Select;

const AddStudentToClass = ({
  isModalVisible,
  closeModal,
  status,
}: PropsType): ReactElement => {
  const classInfo = useSelector(selectClass);
  const { name, type: classType } = classInfo;
  const isClassTypeDisabled = ['live', 'video'].includes(classType);

  const { classId } = useParams<{ classId: string }>();
  const { data: students = [] as Student[] } =
    useGetAllStudentsClassQuery(classId);
  const [keyword, setKeyword] = useState('');
  const [tmpKeyword, setTmpKeyword] = useState('');

  const [isSearchDone, setIsSearchDone] = useState(false);
  const [hasResult, setHasResult] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as UsersType);
  const [tmpNote, setTmpNote] = useState('');
  const [type, setType] = useState(isClassTypeDisabled ? 'live' : 'lab');

  const dispatch = useDispatch();
  const [addStudent] = useAddStudentToClassNoOrderMutation();

  const addExistUser = () => {
    const result = checkUserRole(students, classInfo, selectedUser);
    if (result.accept) {
      addStudent({
        class_id: classId,
        data: {
          note:
            status === 'pending'
              ? tmpNote
              : 'Thêm học viên (không tự tạo đơn hàng)',
          id: selectedUser.id,
          full_name: selectedUser.full_name,
          email: selectedUser.email,
          status: status,
          class_name: name,
          type,
        },
      })
        .unwrap()
        .then(() => {
          message.success('Thêm học viên mới thành công!');
          closeModal();
        })
        .catch((error) => {
          message.error(error.data);
        });
    } else {
      message.error(result.message);
    }
  };
  const onSearch = () => {
    setIsSearchDone(true);
    setKeyword(tmpKeyword);
    setHasResult(true);
  };

  return (
    <Modal
      title="Chọn học viên"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      keyboard={false}
      width={800}
      footer={[]}
    >
      <label>Nhập email hoặc số điện thoại học viên</label>
      <Search
        allowClear
        enterButton="Tìm học viên"
        size="large"
        value={tmpKeyword}
        onChange={(e) => {
          setHasResult(false);
          setTmpKeyword(e.target.value);
        }}
        onSearch={onSearch}
      />
      <Checkbox
        checked={createNew}
        onChange={(e) => setCreateNew(e.target.checked)}
      >
        Tạo tài khoản cho học viên mới
      </Checkbox>

      {isSearchDone && !createNew && (
        <FindStudent
          keyword={keyword}
          hasResult={hasResult}
          setSelectedUser={(value: UsersType) => setSelectedUser(value)}
        />
      )}
      {createNew ? (
        <CreateUser
          classId={classId}
          status={status}
          class_name={name}
          closeModal={closeModal}
        />
      ) : selectedUser.id ? (
        <>
          {status === 'pending' && (
            <div>
              <label>Ghi chú học viên:</label>
              <TextArea
                rows={2}
                value={tmpNote}
                onChange={(e) => setTmpNote(e.target.value)}
              />
            </div>
          )}
          <div>
            <label>Hình thức:</label>
            <Select
              value={type}
              defaultValue={type}
              onChange={(value) => setType(value)}
              style={{ width: '200px', marginLeft: '15px' }}
            >
              <Option value="lab" disabled={isClassTypeDisabled}>
                <Tooltip
                  title={
                    isClassTypeDisabled
                      ? 'Các lớp chỉ học online không thể thay đổi hình thức học'
                      : ''
                  }
                  placement="topRight"
                >
                  <div style={{ width: '100%' }}>Onlab</div>
                </Tooltip>
              </Option>
              <Option value="live">Trực tuyến có tương tác</Option>
            </Select>
          </div>
          <div style={{ textAlign: 'end' }}>
            <Button.Info onClick={addExistUser}>Lựa chọn</Button.Info>
          </div>
        </>
      ) : (
        ''
      )}
    </Modal>
  );
};
export default AddStudentToClass;
