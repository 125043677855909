import { HeaderBackgroundContent } from 'components/commons/ComponentContent';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Input, message, Row, Select } from 'antd';

import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import {
  advisoryInterestedValues,
  advisoryTypeValues,
} from 'libraries/utils/globalVariable';
import Button from 'libraries/components/commons/Button';
import { useAddAdvisoryHistoryMutation } from 'services/order.service';
import { useDispatch } from 'react-redux';
import { updateAdvisoryHistory } from 'store/order.slice';

const { Option } = Select;
const { TextArea } = Input;

const AddAdvisoryHistory = (): ReactElement => {
  const dispatch = useDispatch();
  const { advisoryId } = useParams<{ advisoryId: string }>();

  const [addAdvisory] = useAddAdvisoryHistoryMutation();
  const [form] = Form.useForm();

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    addAdvisory({
      id: advisoryId,
      data: { ...values, interested: Number(values.interested) },
    })
      .unwrap()
      .then((res) => {
        message.success('Thêm thông tin tư vấn thành công');
        form.setFieldsValue({
          interested: '',
          type: '',
          note: '',
        });
        dispatch(
          updateAdvisoryHistory({
            date: res.date,
            sale: res.sale,
            interested: res.interested,
            type: res.type,
            note: res.note,
          }),
        );
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <HeaderBackgroundContent>
      <div className="box-header">Thông tin người tư vấn</div>
      <div className="box-body">
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[20, 20]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={<FormLabel label="Hình thức tư vấn" />}
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn hình thức tư vấn!',
                  },
                ]}
              >
                <Select
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  placeholder="Chọn hình thức..."
                >
                  {advisoryTypeValues.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={<FormLabel label="Mức độ quan tâm" />}
                name="interested"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn mức độ quan tâm!',
                  },
                ]}
              >
                <Select
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  placeholder="Chọn mức độ..."
                >
                  {advisoryInterestedValues.map((item, index) => {
                    return (
                      <Option key={index} value={item.value.toString()}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="note"
                label={
                  <FormLabel
                    label="Ghi chú tư vấn viên"
                    smallText="(ít nhất 30 kí tự)"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập nội dung ghi chú!',
                  },
                  {
                    min: 30,
                    message: 'Nội dung ghi chú tối thiểu 30 ký tự!',
                  },
                ]}
              >
                <TextArea rows={3} showCount />
              </Form.Item>
            </Col>
            <Col>
              <Button.Primary type="primary" htmlType="submit">
                Lưu thông tin
              </Button.Primary>
            </Col>
          </Row>
        </Form>
      </div>
    </HeaderBackgroundContent>
  );
};

export default AddAdvisoryHistory;
