import NotAuthorized from 'components/commons/NotAuthorized';
import NotFound from 'components/commons/NotFound';
import { highestRoles } from 'libraries/utils/globalVariable';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import { useGetClassInfoQuery } from 'services/classes.service';
import checkUserRole from 'utils/checkUserRole';
import AttendancePageContent from './AttendancePageContent';

const GetAttendances = (): ReactElement => {
  const checkRole = checkUserRole(highestRoles);

  const { classId } = useParams<{ classId: string }>();
  const { isError, error } = useGetClassInfoQuery(classId);

  if (!checkRole) {
    return <NotAuthorized />;
  }

  if (isError) return <NotFound subTitle="Không tìm thấy lớp học" />;

  return <AttendancePageContent />;
};
export default GetAttendances;
