import ServerError from 'components/commons/ServerError';
import { Banner } from 'models/banner.model';
import { ReactElement, useState } from 'react';
import { useGetBannersListQuery } from 'services/configure.service';

import { BorderBoxContent } from 'components/commons/ComponentContent';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import BannersTable from './BannersTable';
import ShowServerError from 'components/commons/ShowServerError';

interface KeySearchType {
  keyStatus: '' | 'true' | 'false';
  keyWord: string;
  keyDirection: 'asc' | 'desc';
}

const ListBannersContent = (): ReactElement => {
  const [keySearch, setKeySearch] = useState(
    'page=1&status=&keyword=&direction=desc',
  );
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isError, error } = useGetBannersListQuery(keySearch);

  const banners = data ? data.banners : ([] as Banner[]);
  const total_page = data ? data.total_pages : 0;

  const [state, setState] = useState<KeySearchType>({
    keyStatus: '',
    keyWord: '',
    keyDirection: 'desc',
  });

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <LinksContainer>
        <Link.Primary to={generatePath(route.configure.banner.create)}>
          <PlusOutlined /> Tạo banner
        </Link.Primary>
        <Link.Info to={generatePath(route.configure.banner.sort)}>
          <ReloadOutlined /> Sắp xếp vị trí banner đang kích hoạt
        </Link.Info>
      </LinksContainer>
      <BorderBoxContent>
        <BannersTable
          banners={banners}
          total_page={total_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setKeySearch={setKeySearch}
          state={state}
          handleSetState={handleSetState}
        />
      </BorderBoxContent>
    </>
  );
};

export default ListBannersContent;
