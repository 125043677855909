import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { SpinProps } from 'antd';

export const Container = styled.div<{ fit?: boolean }>`
  width: 100%;
  height: ${({ fit }) => (fit ? '100%' : '100vh')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface PropType extends SpinProps {
  text?: string;
  fit?: boolean;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LoadingSpin = ({ text, fit, size = 'large' }: PropType): ReactElement => (
  <Container fit={fit}>
    <Spin indicator={antIcon} size={size} tip={text} />
  </Container>
);

LoadingSpin.defaultProps = {
  text: 'Đang tải trang...',
  size: 'large',
};

export default LoadingSpin;
