import { ArrowLeftOutlined, DeleteFilled } from '@ant-design/icons';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { route } from 'routers/routeNames';
import {
  useDeleteOrderMutation,
  useGetListClassQuery,
  useGetListItemQuery,
  useGetOrderInfoQuery,
} from 'services/order.service';
import { selectOrder } from 'store/order.slice';
import styled from 'styled-components';
import OrderStatus from './content/OrderStatus';
import OrderTabs from './content/OrderTabs';
import AdjustModal from './modals/AdjustModal';

import { message } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import ShowServerError from 'components/commons/ShowServerError';
import PayInfo from './content/PayInfo';
import BackMoneyModal from './modals/BackMoneyModal';
import EnrollModal from './modals/EnrollModal';
import PaymentModal from './modals/PaymentModal';
import BonusModal from './modals/BonusModal';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const OrderDetail = (): ReactElement => {
  const { orderId } = useParams<{ orderId: string }>();

  useGetListItemQuery();
  const { refetch } = useGetListClassQuery({ orderId });
  const {
    isError,
    error,
    refetch: refetchs,
  } = useGetOrderInfoQuery({ orderId });
  const [deleteOrder] = useDeleteOrderMutation();

  const [isAdjustModalVisible, setIsAdjustModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isBackMoneyModalVisible, setIsBackMoneyModalVisible] = useState(false);
  const [isBonusModalVisible, setIsBonusModalVisible] = useState(false);
  const [isEnrollModalVisibile, setIsEnrollModalVisibile] = useState(false);

  const dataOrder = useSelector(selectOrder);

  const { status } = dataOrder;

  const showAdjustModal = (): void => {
    setIsAdjustModalVisible(true);
  };

  const showPaymentModal = (): void => {
    setIsPaymentModalVisible(true);
  };

  const showBackMoneyModal = (): void => {
    setIsBackMoneyModalVisible(true);
  };
  const showBonusModal = (): void => {
    setIsBonusModalVisible(true);
  };

  const showEnrollModal = (): void => {
    setIsEnrollModalVisibile(true);
  };

  const handleOk = (): void => {
    setIsAdjustModalVisible(false);
  };

  const handleCancel = (): void => {
    setIsAdjustModalVisible(false);
  };

  const handleCancelPayment = (): void => {
    setIsPaymentModalVisible(false);
  };

  const handleCanelBackMoney = (): void => {
    setIsBackMoneyModalVisible(false);
    refetchs();
  };
  const handleCanelBonusModal = (): void => {
    setIsBonusModalVisible(false);
    refetchs();
  };

  const handleCancelEnroll = (): void => {
    setIsEnrollModalVisibile(false);
  };

  const handleDeleteOrder = () => {
    deleteOrder(orderId)
      .unwrap()
      .then(() => message.success('Huỷ đơn hàng thành công'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.order.list)}
        secondaryText="Danh sách đơn hàng"
        text="Chi tiết đơn hàng"
      />
      <GroupButton>
        <LinksContainer>
          <Link.Default to={generatePath(route.order.list)}>
            <ArrowLeftOutlined /> Quay lại
          </Link.Default>

          {status !== 'cancel' && (
            <>
              {status !== 'paid' && (
                <Link.Success to="#" onClick={showPaymentModal}>
                  Nộp tiền
                </Link.Success>
              )}
              <Link.Primary to="#" onClick={showAdjustModal}>
                Chỉnh tổng đơn hàng
              </Link.Primary>
              {status !== 'created' && (
                <>
                  <Link.Warning to="#" onClick={showEnrollModal}>
                    Nhập học
                  </Link.Warning>
                  <Link.Danger to="#" onClick={showBackMoneyModal}>
                    Hoàn tiền
                  </Link.Danger>
                  <Link.Success to="#" onClick={showBonusModal}>
                    Thưởng
                  </Link.Success>
                </>
              )}
            </>
          )}
        </LinksContainer>

        {status !== 'cancel' && (
          <LinksContainer>
            <Link.Danger to="#" onClick={handleDeleteOrder}>
              <DeleteFilled /> Huỷ đơn hàng
            </Link.Danger>
          </LinksContainer>
        )}
      </GroupButton>

      <OrderStatus />
      <PayInfo />
      <OrderTabs refetchClasses={refetch} />

      <AdjustModal
        isAdjustModalVisible={isAdjustModalVisible}
        closeModal={() => setIsAdjustModalVisible(false)}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />

      <PaymentModal
        isPaymentModalVisible={isPaymentModalVisible}
        handleCancel={handleCancelPayment}
      />

      <BackMoneyModal
        isBackMoneyModalVisible={isBackMoneyModalVisible}
        handleCancel={handleCanelBackMoney}
      />
      <BonusModal
        isBonusModalVisible={isBonusModalVisible}
        handleCancel={handleCanelBonusModal}
      />

      <EnrollModal
        isEnrollModalVisibile={isEnrollModalVisibile}
        handleCancel={handleCancelEnroll}
      />
    </>
  );
};

export default OrderDetail;
