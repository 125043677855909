import { LeftOutlined, MailOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Tabs } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generatePath, Link as RouterLink, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { selectStudent } from 'store/classes.slice';
import styled from 'styled-components';

import DefaultAvatar from 'assets/images/avatar.png';
import useQuery from 'libraries/hooks/query';
import { studentStatusValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { ClassStudentLessonStatus, UserLearnProcess } from 'models/user.model';
import LearnProcessStudent from 'pages/users/user/tabs/learnProcess/LearnProcessStudent';
import { useGetStudentInfoQuery } from 'services/user.service';
import ListExcercise from './ListExcercise';

const { TabPane } = Tabs;
const Wrapper = styled.div`
  a.email {
    display: inline-block;
    font-weight: 600;
    padding: 6px 12px;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #00c0ef;
    color: #fff;
    border-color: #00acd6;
    &:hover,
    &:active {
      color: #fff;
      background-color: #00acd6;
      border-color: #00acd6;
    }
  }
`;

const InfoRow = styled(Row)`
  margin-bottom: 15px;
  .ant-col {
    padding: 12px 15px;
  }
`;

const StatusSpan = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

interface LessonStatusProps {
  lesson: ClassStudentLessonStatus;
}

const LessonStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid #f4f4f4;
  }
`;

const LessonStatus = ({ lesson }: LessonStatusProps): ReactElement => {
  const { date, status } = lesson;

  return (
    <LessonStatusWrapper>
      <span>{date !== '' ? formatDate(date) : ''}</span>
      <StatusSpan
        style={{ backgroundColor: status ? '#008d4c' : 'red' }}
      ></StatusSpan>
    </LessonStatusWrapper>
  );
};

const StudentInfo = (): ReactElement => {
  const { classId, studentId } = useParams<{
    classId: string;
    studentId: string;
  }>();

  const { learn_process = [] as UserLearnProcess[] } = useGetStudentInfoQuery(
    { student_id: studentId, class_id: classId },
    {
      selectFromResult: ({ data }) => ({
        learn_process: data?.learn_process,
      }),
    },
  );

  const student = useSelector(selectStudent);
  const activeKey = useQuery().get('tab') ?? '1';
  const history = useHistory();
  const searchParams = useQuery();
  const onChange = (activeKey: string) => {
    searchParams.set('tab', activeKey);
    history.replace({ search: searchParams.toString() });
  };
  const {
    class_name,
    full_name,
    email,
    phone,
    avatar,
    status,
    join_date,
    end_date,
    note,
    attends,
    total_lessons,
    status_lessons,
    phone_sponsor,
    dob,
  } = student;

  let studentStatus = '';
  if (status !== '') {
    for (let i = 0; i < studentStatusValues.length; i++) {
      const item = studentStatusValues[i];
      if (item.value === status) {
        studentStatus = item.label;
      }
    }
  }
  return (
    <Wrapper>
      <Breadcrumb
        secondaryLink={generatePath(route.class.list)}
        secondaryText="Lớp học"
        thirdLink={generatePath(route.class.detail, { classId })}
        thirdText={class_name}
        text={full_name}
      />
      <LinksContainer>
        <Link.Default to={generatePath(route.class.detail, { classId })}>
          <LeftOutlined /> Quay lại
        </Link.Default>
        <a href={`mailto:${email}`} className="email">
          <MailOutlined /> Gửi email
        </a>
      </LinksContainer>
      {student.student_id && (
        <>
          <BorderTopBoxContent>
            <div className="box-header">Thông tin chung</div>
            <div className="box-body">
              <Row gutter={[20, 20]}>
                <Col xs={24} md={8} lg={4}>
                  <Avatar
                    src={avatar !== '' ? avatar : DefaultAvatar}
                    alt={full_name}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </Col>
                <Col xs={24} md={16} lg={20}>
                  <InfoRow>
                    <Col xs={24} md={12}>
                      <strong>Họ tên:</strong>&nbsp;
                      <RouterLink
                        to={generatePath(route.user.info, {
                          userId: studentId,
                        })}
                      >
                        {full_name}
                      </RouterLink>
                    </Col>

                    <Col xs={24} md={12}>
                      <strong>Trạng thái:</strong>&nbsp;{studentStatus}
                    </Col>
                  </InfoRow>
                  <InfoRow>
                    <Col xs={24} md={12}>
                      <strong>Ngày sinh:</strong>&nbsp;
                      {dob}
                    </Col>

                    <Col xs={24} md={12}>
                      <strong>Điện thoại:</strong>&nbsp;
                      <a href={`tel:${phone}`}>{phone}</a>
                    </Col>
                  </InfoRow>
                  <InfoRow>
                    <Col xs={24} md={12}>
                      <strong>Email:</strong>&nbsp;
                      <a href={`mailto:${email}`}>{email}</a>
                      {}
                    </Col>
                    <Col xs={24} md={12}>
                      <strong>SDT nhà tài trợ:</strong>&nbsp;
                      {phone_sponsor}
                    </Col>
                  </InfoRow>
                  <InfoRow>
                    <Col xs={24} md={12}>
                      <strong>Ngày nhập học:</strong>&nbsp;
                      {join_date !== '' ? formatDate(join_date) : ''}
                    </Col>
                    <Col xs={24} md={12} style={{ padding: '12px 15px' }}>
                      <strong>Ghi chú:</strong>&nbsp;{note}
                    </Col>
                  </InfoRow>
                </Col>
              </Row>
            </div>
          </BorderTopBoxContent>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={6} lg={4}>
              <BorderTopBoxContent>
                <div
                  className="box-header"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Điểm danh</span>
                  <span
                    style={{
                      color: '#db5050',
                      fontWeight: 'bold',
                      fontSize: '2.5rem',
                    }}
                  >
                    {attends}/{total_lessons}
                  </span>
                </div>
                <div className="box-body">
                  {status_lessons.length > 0 &&
                    status_lessons.map((item, index) => {
                      return <LessonStatus lesson={item} key={index} />;
                    })}
                </div>
              </BorderTopBoxContent>
            </Col>
            <Col xs={24} md={18} lg={20}>
              <BorderTopBoxContent>
                <Tabs activeKey={activeKey} onChange={onChange}>
                  <TabPane tab="Kết quả học tập" key="1">
                    <LearnProcessStudent />
                  </TabPane>

                  <TabPane tab="Danh sách bài tập" key="2">
                    {learn_process.length !== 0 ? (
                      <ListExcercise exercise={learn_process[0]?.exercises} />
                    ) : (
                      false
                    )}
                  </TabPane>
                </Tabs>
                <div className="box-body"></div>
              </BorderTopBoxContent>
            </Col>
          </Row>
        </>
      )}
    </Wrapper>
  );
};
export default StudentInfo;
