import { ReactElement, useState } from 'react';
import { useGetOwnPostsQuery, useGetTagsQuery } from 'services/blog.service';
import { Post } from 'models/blog.model';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import Breadcrumb from 'components/commons/Breadcrumb';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import ListPostsTable from './ListPostsTable';
import { Tag } from 'models/tag.model';
import ShowServerError from 'components/commons/ShowServerError';

interface KeySearchType {
  title: string;
  status: string;
  order: string;
  direction: 'asc' | 'desc';
  tagId: string;
}

const GetListPosts = (): ReactElement => {
  const [keySearch, setKeySearch] = useState(
    'page=1&title=&status=&order=&tagId=&type=normal&direction=desc',
  );

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isError, error } = useGetOwnPostsQuery(keySearch);
  const { data: tagsList } = useGetTagsQuery();

  const tags = tagsList ? tagsList : ([] as Tag[]);

  const posts = data ? data.posts : ([] as Post[]);
  const total_page = data ? data.total_page : 0;

  const [state, setState] = useState<KeySearchType>({
    title: '',
    status: '',
    order: '',
    direction: 'desc',
    tagId: '',
  });

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleOrderList = (order: string, direction: 'asc' | 'desc') => {
    setState({
      ...state,
      order,
      direction,
    });
  };

  const resetKeySearch = () => {
    setState({
      title: '',
      status: '',
      order: '',
      direction: 'desc',
      tagId: '',
    });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Danh sách bài viết" />

      <LinksContainer>
        <Link.Primary to={generatePath(route.blog.create)}>
          <PlusOutlined /> Viết bài
        </Link.Primary>

        <Link.Info to="#" onClick={resetKeySearch}>
          <ReloadOutlined /> Refresh
        </Link.Info>
      </LinksContainer>

      <BorderBoxContent>
        <ListPostsTable
          tags={tags}
          posts={posts}
          state={state}
          total_page={total_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setKeySearch={setKeySearch}
          handleSetState={handleSetState}
          handleOrderList={handleOrderList}
        />
      </BorderBoxContent>

      <div>
        <span
          style={{
            fontSize: '1.3em',
            textDecoration: 'underline',
            fontWeight: 'bolder',
            color: '#305a8c',
          }}
        >
          Công thức tính tiền blog:
        </span>
        <ul style={{ marginBlockStart: '0.3em', paddingLeft: '1.6em' }}>
          <li style={{ fontSize: '1em' }}>
            Số tiền bài viết sẽ được thanh toán trong khoảng 1-2 tuần đầu tháng.
          </li>
          <li style={{ fontSize: '1em' }}>
            Số lượt xem được chốt sau ngày thứ 20 kể từ khi bài viết được public
            trên blog.
          </li>
          <li style={{ fontSize: '1em' }}>
            Ngày thứ 20 chốt bài viết mà nằm ở tháng tiếp theo thì số tiền sẽ
            được chi trả vào tháng sau đó.
          </li>
        </ul>
      </div>
    </>
  );
};
export default GetListPosts;
