import { ReactElement } from 'react';
import { Table, Typography } from 'antd';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

import { TeacherAttendanceSalary } from 'models/report.model';

import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { formatMoney } from 'libraries/utils/changeFormatData';
import {
  classTypeValues,
  salaryTypeValues,
} from 'libraries/utils/globalVariable';
import { v4 } from 'uuid';
import { SalaryTypeValue } from '../../../../models/globalVariable.model';

interface PropsType {
  attendances: TeacherAttendanceSalary[];
}
type TableData = TeacherAttendanceSalary & { key: React.Key };

const salaryTypes = (types: 'per_student' | 'per_total' | 'fixed' | '') => {
  const { label } =
    salaryTypeValues.find((item) => item.value === types) ??
    ({} as SalaryTypeValue);
  return typeof label === 'undefined' ? '' : label;
};

const { Text } = Typography;

const AttendancesSalaryTable = ({ attendances }: PropsType): ReactElement => {
  const tableData: TableData[] = attendances.map((item) => ({
    ...item,
    key: v4(),
  }));
  const columns: ColumnsType<TableData> = [
    {
      key: 'date',
      width: '15%',
      title: 'Ngày điểm danh',
      dataIndex: 'date',
      render: (date) => (date !== '' ? formatDate(date) : ''),
    },
    {
      key: 'class_id',
      width: '20%',
      title: 'Lớp học',
      dataIndex: 'class_id',
      render: (_text, item) => (
        <RouterLink
          to={{
            pathname: generatePath(route.class.detail, {
              classId: item.class_id,
            }),
            search: '?tab=1',
          }}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
          target="_blank"
        >
          {item.class_name}
        </RouterLink>
      ),
      ellipsis: true,
    },
    {
      key: 'class_size',
      width: '10%',
      title: 'Điểm danh',
      dataIndex: 'class_size',
    },
    {
      key: 'profit',
      width: '20%',
      title: 'Kiểu tính lương',
      dataIndex: 'profit',
      render: (_: void, item) =>
        item.profit !== 0 ? (
          <span>
            <Text strong>{formatMoney(item.profit)}</Text> /
            <Text> {salaryTypes(item.salary_type)}</Text>
          </span>
        ) : (
          0
        ),
    },
    {
      key: 'salary',
      width: '10%',
      title: 'Lương (đ)',
      dataIndex: 'salary',
      render: (salary) => (salary !== 0 ? formatMoney(salary) : 0),
    },

    {
      key: 'lesson_type',
      width: '10%',
      title: 'Kiểu buổi học',
      dataIndex: 'lesson_type',
    },
    {
      key: 'class_type',
      width: '10%',
      title: 'Kiểu khóa học',
      dataIndex: 'class_type',
      render: (class_type) => {
        if (class_type !== '') {
          for (let i = 0; i < classTypeValues.length; i++) {
            const item = classTypeValues[i];
            if (item.value === class_type) {
              return item.label;
            }
          }
        }
        return '';
      },
    },
    {
      key: 'branch_name',
      width: '15%',
      title: 'Cơ sở',
      dataIndex: 'branch_name',
      ellipsis: true,
    },
  ];
  return (
    <TableContainer>
      <Table<TableData>
        columns={columns}
        dataSource={tableData}
        pagination={{ showSizeChanger: false }}
        bordered
      />
    </TableContainer>
  );
};

export default AttendancesSalaryTable;
