import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { TrackList, TrackMaster } from 'models/track.model';
import { ReactElement, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useGetTrackMasterQuery,
  useGetTracksListQuery,
} from 'services/track.service';
import TrackTable from './TrackTable';

const GetTrack = (): ReactElement => {
  const { data, isError, error } = useGetTracksListQuery(`keyword=&master_id=`);
  const { data: trackMasterList } = useGetTrackMasterQuery();

  const [tmpName, setTmpName] = useState<any>('');
  const [status, setStatus] = useState<any>('');
  const tracksMaster = trackMasterList
    ? trackMasterList
    : ([] as TrackMaster[]);

  const tracks = data ? data : ([] as TrackList[]);
  const [currentTrack, setCurrentTrack] = useState<any>(tracks);
  const resetKeySearch = () => {
    setTmpName('');
    setStatus('');
    setCurrentTrack(tracks);
  };
  const [isShowTrackMaster, setIsShowTrackMaster] = useState(false);

  if (isError) {
    return <ShowServerError error={error} />;
  }
  return (
    <>
      <Breadcrumb text="Danh sách lộ trình" />
      <LinksContainer>
        <Link.Primary to={generatePath(route.track.create)}>
          <PlusOutlined /> Tạo track
        </Link.Primary>
        <Link.Info to={generatePath(route.track.createTrackMaster)}>
          <PlusOutlined /> Tạo track master
        </Link.Info>

        <Link.Success
          to={'#'}
          onClick={() => setIsShowTrackMaster(!isShowTrackMaster)}
        >
          {isShowTrackMaster
            ? 'Xem danh sách track'
            : 'Xem danh sách track master'}
        </Link.Success>
        <Link.Info to="#" onClick={resetKeySearch}>
          <ReloadOutlined /> Refresh
        </Link.Info>
      </LinksContainer>
      <TrackTable
        tracks={tracks}
        tracksMaster={tracksMaster}
        isShowTrackMaster={isShowTrackMaster}
        tmpName={tmpName}
        status={status}
        setTmpName={setTmpName}
        setStatus={setStatus}
        currentTrack={currentTrack}
        setCurrentTrack={setCurrentTrack}
      />
    </>
  );
};
export default GetTrack;
