export const deleteOneBlogLocalStore = (
  blogLocalStore: any,
  lectureId: any,
) => {
  console.log(blogLocalStore);
  const length = blogLocalStore.length;
  let data = [] as any;
  if (length > 1) {
    data = blogLocalStore.filter((item: any) => item.id != lectureId);
  }

  window.localStorage.setItem('blog', JSON.stringify(data));
};
