import styled from 'styled-components';
import { Button as AntButton } from 'antd';

const Button = styled(AntButton)`
  font-size: 14px;
  height: 36px;
  line-height: 1;
`;

// const ButtonNoBorder = styled(AntButton)`
//   font-size: 16px;
//   height: 30px;
//   line-height: 1;
// `;

// Default Button
const Default = styled(Button)`
  color: #fff;
  background-color: #2074a1;
  border-color: #2074a1;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
`;
const DefaultNoBorder = styled(Button)`
  color: #fff;
  background-color: #2074a1;
  border-color: #2074a1;
  border-radius: 0;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #2074a1;
    border-color: #2074a1;
  }
`;

// Primary Button
const Primary = styled(Button)`
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
`;

// Action Button
const Action = styled(Button)`
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
`;

// Cancel Button
const Cancel = styled(Button)`
  color: #000000;
  background-color: #ffffff;
  border-color: #8c8c8d;
  &:hover,
  &:focus {
    background-color: #ffffff;
    border-color: #8c8c8d;
  }
`;

// History Button
const History = styled(Button)`
  background-color: #ffc107;
  border-color: #ffc107;
  &:hover,
  &:focus {
    background-color: #ffc107;
    border-color: #ffc107;
    color: black;
  }
`;

// Info Button
const Info = styled(Button)`
  background-color: #00acd6;
  border-color: #ffc107;
  color: #fff;
  &:hover,
  &:focus {
    background-color: #00acd6;
    border-color: #269abc;
    color: #fff;
  }
`;

const Outline = styled(Button)`
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
  &:hover,
  &:active {
    color: #333;
    background-color: #e7e7e7;
    border-color: #adadad;
  }
`;

// Danger Button
const Danger = styled(Button)`
  background-color: #dd4b39;
  border-color: #d73925;
  color: #fff;
  &:hover,
  &:focus {
    background-color: #d73925;
    border-color: #d73925;
    color: #fff;
  }
`;

const Warning = styled(Button)`
  background-color: #f39c12;
  border-color: #e08e0b;
  color: #fff;
  &:hover,
  &:focus {
    background-color: #e08e0b;
    border-color: #e08e0b;
    color: #fff;
  }
`;

export default {
  Default,
  DefaultNoBorder,
  Primary,
  Action,
  Cancel,
  History,
  Info,
  Outline,
  Danger,
  Warning,
};
