import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { ReactElement, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

import { BorderBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { Class } from 'models/class.model';
import {
  useGetClassesQuery,
  useGetListCoursesQuery,
} from 'services/classes.service';
import ClassesTable from './ClassesTable';
import { Button } from 'antd';

interface KeySearchType {
  keyStatus: '' | 'active' | 'scheduled' | 'completed';
  keyType: '' | 'lab' | 'live' | 'mixed' | 'video' | 'enterprise';
  keyCourse: string;
  keyClass: string;
  keyDirection: 'asc' | 'desc';
  keyBranch: '' | 'Cơ sở Nguyễn Đình Chiểu' | 'Cơ sở Tố Hữu';
  keyOrder: string;
}

const ListClassesContent = (): ReactElement => {
  const [keySearch, setKeySearch] = useState<any>(
    `page=1&status=&type=&course_id=&keyword=&branch=&orderby=&direction=desc&today=false`,
  );
  const [listClassId, setListClassId] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isError, error } = useGetClassesQuery(keySearch);
  useGetListCoursesQuery();
  const classes = data ? data.classes : ([] as Class[]);
  const total_page = data ? data.total_page : 0;
  const [state, setState] = useState<KeySearchType>({
    keyStatus: '',
    keyType: '',
    keyCourse: '',
    keyClass: '',
    keyDirection: 'desc',
    keyBranch: '',
    keyOrder: '',
  });
  const [linkExportExcel, setLinkExportExcel] = useState<string>('');
  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleOrderList = (keyOrder: string, keyDirection: 'asc' | 'desc') => {
    setState({
      ...state,
      keyOrder,
      keyDirection,
    });
  };

  const resetKeySearch = () => {
    setState({
      keyStatus: '',
      keyType: '',
      keyCourse: '',
      keyClass: '',
      keyDirection: 'desc',
      keyBranch: '',
      keyOrder: '',
    });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <LinksContainer>
        <Link.Primary to={generatePath(route.class.create)}>
          <PlusOutlined /> Tạo lớp mới
        </Link.Primary>
        <Link.Info to="#" onClick={resetKeySearch}>
          <ReloadOutlined /> Refresh
        </Link.Info>
        <Link.Success to={generatePath(route.class.listCurrent)}>
          Các lớp hôm nay
        </Link.Success>
        {linkExportExcel != '' && listClassId.length !== 0 ? (
          <Button type="primary" style={{ height: '35px' }}>
            <a href={linkExportExcel} download>
              Xuất Excel
            </a>
          </Button>
        ) : (
          false
        )}
      </LinksContainer>
      <BorderBoxContent>
        <ClassesTable
          setLinkExportExcel={setLinkExportExcel}
          listClassId={listClassId}
          setListClassId={setListClassId}
          classes={classes}
          total_page={total_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setKeySearch={setKeySearch}
          state={state}
          handleSetState={handleSetState}
          handleOrderList={handleOrderList}
        />
      </BorderBoxContent>
    </>
  );
};
export default ListClassesContent;
