import { ReactElement } from 'react';

import NotAuthorized from 'components/commons/NotAuthorized';
import { highestRoles } from 'libraries/utils/globalVariable';
import checkUserRole from 'utils/checkUserRole';
import GetList from './GetList';
import { useSelector } from 'react-redux';
import { selectUserRole } from 'store/auth.slice';
import { Redirect } from 'react-router-dom';
import { route } from 'routers/routeNames';

const Advisory = (): ReactElement => {
  const { isOnlyAuthorRole, isOnlyTrainerRole } = useSelector(selectUserRole);
  const checkRole = checkUserRole(highestRoles);

  if (!checkRole) {
    if (isOnlyAuthorRole || isOnlyTrainerRole) {
      return <Redirect to={route.blog.own} />;
    } else return <NotAuthorized />;
  }

  return <GetList />;
};

export default Advisory;
