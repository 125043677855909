import { createApi } from '@reduxjs/toolkit/query/react';
import {
  NewPost,
  Post,
  Post20Days,
  PostInfo,
  PostTag,
  UpdatePost,
} from 'models/blog.model';
import { Tag } from 'models/tag.model';
import { baseQuery } from './base.service';

interface ListPostType {
  total_page: number;
  posts: Post[];
}

export const blogApi = createApi({
  reducerPath: 'blogApi',
  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getBlogPosts: builder.query<ListPostType, string>({
      query: (keySearch) => {
        return {
          url: `blogs?${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ListPostType) => {
        response.posts = response.posts ?? ([] as Post[]);
        response.posts.length > 0 &&
          response.posts.map((item) => {
            item.created_at = item.created_at.includes('0001-01-01T00')
              ? ''
              : item.created_at;
            item.tags = item.tags ?? ([] as PostTag[]);
          });

        return response;
      },
    }),
    getBlog20Days: builder.query<Post20Days[], string>({
      query: (keySearch) => {
        return {
          url: `blogs-qualify?${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: Post20Days[]) => {
        response = response === null ? ([] as Post20Days[]) : response;
        return response;
      },
    }),
    getOwnPosts: builder.query<ListPostType, string>({
      query: (keySearch) => {
        return {
          url: `blogs/own-posts?${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ListPostType) => {
        response.posts = response.posts ?? ([] as Post[]);
        response.posts.length > 0 &&
          response.posts.map((item) => {
            item.created_at = item.created_at.includes('0001-01-01T00')
              ? ''
              : item.created_at;
            item.tags = item.tags ?? ([] as PostTag[]);
          });

        return response;
      },
    }),

    getTags: builder.query<Tag[], void>({
      query: () => {
        return {
          url: `tags`,
          method: 'GET',
        };
      },
      transformResponse: (response: Tag[]) => {
        response = response === null ? ([] as Tag[]) : response;
        return response;
      },
    }),

    createBlogPost: builder.mutation<
      {
        post_id: string;
      },
      NewPost
    >({
      query: (data) => {
        return {
          url: `blogs`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getPostDetail: builder.query<PostInfo, string>({
      query: (id) => {
        return {
          url: `blogs/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: PostInfo) => {
        response.tags =
          response.tags !== null ? response.tags : ([] as PostTag[]);
        response.tag_ids = response.tags.reduce((arr: string[], item) => {
          arr.push(item.id);
          return arr;
        }, [] as string[]);
        return response;
      },
    }),

    updateBlogPost: builder.mutation<
      PostInfo,
      { id: number; data: UpdatePost }
    >({
      query: ({ id, data }) => {
        return {
          url: `blogs/${id}`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    deleteBlogPost: builder.mutation<string, number>({
      query: (id) => {
        return {
          url: `blogs/${id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetBlog20DaysQuery,
  useGetBlogPostsQuery,
  useGetOwnPostsQuery,
  useGetTagsQuery,
  useCreateBlogPostMutation,
  useGetPostDetailQuery,
  useUpdateBlogPostMutation,
  useDeleteBlogPostMutation,
} = blogApi;
