import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

const Modal = styled(AntModal)`
  .ant-modal-header {
    /* border-bottom: none; */
    .ant-modal-title {
      font-size: 18px;
      font-weight: 700;
      max-width: 95%;
      @media screen and (min-width: 1200px) {
        font-size: 20px;
      }
    }
  }
  .ant-modal-body {
    font-size: 16px;
    & > * {
      margin-bottom: 15px;
    }
  }
  .ant-modal-footer {
    border-top: none;
  }
`;

export default Modal;
