import { createSlice } from '@reduxjs/toolkit';
import { ActiveBanner } from 'models/banner.model';
import { Promotion } from 'models/promotion.model';
import { configureApi } from 'services/configure.service';
import { promotionApi } from 'services/promotion.service';
import { RootState } from 'store';

export interface PromotionState {
  promotions: Promotion[];
  filteredPromotions: Promotion[];
  banners: ActiveBanner[];
}

const initialState: PromotionState = {
  promotions: [] as Promotion[],
  filteredPromotions: [] as Promotion[],
  banners: [] as ActiveBanner[],
};

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    filterListByName: (state, { payload }) => {
      if (payload === '') {
        state.filteredPromotions = state.promotions;
      } else {
        state.filteredPromotions = [...state.promotions].filter((item) =>
          item.name.toLowerCase().includes(payload.toLowerCase()),
        );
      }
    },
    filterListByContent: (state, { payload }) => {
      if (payload === '') {
        state.filteredPromotions = state.promotions;
      } else {
        state.filteredPromotions = [...state.promotions].filter((item) =>
          item.content.toLowerCase().includes(payload.toLowerCase()),
        );
      }
    },
    filterListByStatus: (state, { payload }) => {
      if (payload === '') {
        state.filteredPromotions = state.promotions;
      } else {
        state.filteredPromotions = [...state.promotions].filter(
          (item) => item.status === payload,
        );
      }
    },
    updateBannersPosition: (state, { payload }) => {
      const { fromIndex, toIndex } = payload;
      const element = state.banners[fromIndex];

      state.banners.splice(fromIndex, 1);
      state.banners.splice(toIndex, 0, element);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      promotionApi.endpoints.getPromotions.matchFulfilled,
      (state, { payload }) => {
        state.promotions = payload;
        state.filteredPromotions = payload;
      },
    );
    builder.addMatcher(
      configureApi.endpoints.getActiveBannersList.matchFulfilled,
      (state, { payload }) => {
        state.banners = payload;
      },
    );
  },
});

export const {
  filterListByName,
  filterListByContent,
  filterListByStatus,
  updateBannersPosition,
} = promotionSlice.actions;
export const selectPromotions = (state: RootState): Promotion[] =>
  state.promotion.filteredPromotions;
export const selectBanners = (state: RootState): ActiveBanner[] =>
  state.promotion.banners;

export default promotionSlice.reducer;
