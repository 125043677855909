import {
  DeleteFilled,
  ExclamationCircleFilled,
  LeftOutlined,
} from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import ServerError from 'components/commons/ServerError';
import ShowServerError from 'components/commons/ShowServerError';
import Button from 'libraries/components/commons/Button';
import CallOut from 'libraries/components/commons/CallOut';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import { useDateRange } from 'libraries/hooks/date';
import { promotionStatusValues } from 'libraries/utils/globalVariable';
import { Voucher } from 'models/promotion.model';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useDeletePromotionMutation,
  useGetItemsListQuery,
  useUpdateVoucherInfoMutation,
} from 'services/promotion.service';
import { ListItemType } from 'store/order.slice';
import styled from 'styled-components';
import ListItems from './Listitems';

interface PropsType {
  voucher: Voucher;
}

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { Option } = Select;
const { RangePicker } = DatePicker;

const VoucherDetail = ({ voucher }: PropsType): ReactElement => {
  const history = useHistory();
  const { discount, discount_percent } = voucher;
  const [deletePromotion] = useDeletePromotionMutation();
  const [updateVoucherInfo] = useUpdateVoucherInfoMutation();

  const { data, isError, error } = useGetItemsListQuery();
  const list = data ?? ([] as ListItemType[]);

  const [chosenItems, setChosenItems] = useState<string[]>([]);

  const [form] = Form.useForm();
  const { startOfDay, endOfDay } = useDateRange();

  const canEdit =
    voucher.status === 'Đã kích hoạt' || voucher.status === 'Đang kích hoạt'
      ? false
      : true;

  const showDeleteConfirm = () => {
    confirm({
      title: 'Xác nhận xoá',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá voucher này không ?',
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deletePromotion(voucher.name)
          .unwrap()
          .then(() => {
            message.success('Xoá voucher thành công!');
            setTimeout(() => {
              history.push(generatePath(route.promotion.list));
            }, 700);
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    updateVoucherInfo({
      name: values.name,
      content: values.content,
      start_date: startOfDay(values.time[0].utc(true)).toISOString(),
      end_date: endOfDay(values.time[1].utc(true)).toISOString(),
      discount: values.discount,
      discount_percent: values.discount_percent,
      item_ids: chosenItems,
    })
      .unwrap()
      .then(() => {
        message.success('Cập nhật thành công!');

        if (values.name !== voucher.name) {
          setTimeout(() => {
            history.push(
              generatePath(route.promotion.detail, {
                voucherName: values.name,
              }),
            );
          }, 700);
        }
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      name: voucher.name,
      content: voucher.content,
      status: voucher.status,
      discount_percent: voucher.discount_percent,
      discount: voucher.discount,
      time: [
        moment.utc(voucher.start_date),
        moment.utc(voucher.end_date).startOf('D'),
      ],
    });
    const arr1 = voucher.list_items
      ? voucher.list_items.reduce((arr, item) => {
          arr.push(item.id);
          return arr;
        }, [] as string[])
      : ([] as string[]);
    setChosenItems(arr1);
  }, [voucher]);

  if (isError) return <ShowServerError error={error} />;

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={voucher}
      >
        <GroupButton>
          <LinksContainer style={{ display: 'flex' }}>
            <Link.Default to={generatePath(route.promotion.list)}>
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
            >
              Lưu
            </Button.Primary>
          </LinksContainer>
          {voucher.status === 'Chưa kích hoạt' && (
            <LinksContainer>
              <Link.Danger to="#" onClick={showDeleteConfirm}>
                <DeleteFilled /> Xoá
              </Link.Danger>
            </LinksContainer>
          )}
        </GroupButton>
        {!canEdit && (
          <CallOut.Warning>
            <h4>Voucher không được phép thay đổi thông tin</h4>
            <p>
              Tránh gây ảnh hưởng lịch sử các đơn hàng đã áp dụng. Chỉ có thể
              thay đổi tiêu đề và gia hạn thời gian.
            </p>
          </CallOut.Warning>
        )}

        <Row gutter={[30, 30]}>
          <Col xs={24} md={12} lg={8}>
            <BorderTopBoxContent>
              <div className="box-header">Thông tin khuyến mại</div>
              <div className="box-body">
                <Form.Item label={<FormLabel label="Tiêu đề" />} name="name">
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Mã kích hoạt voucher" />}
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập mã kích hoạt!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Trạng thái" require={false} />}
                  name="status"
                >
                  <Select disabled={!canEdit}>
                    {promotionStatusValues.map((item, index) => (
                      <Option value={item.value} key={index}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={<FormLabel label="Khuyến mại theo" />}>
                  <Select
                    value={voucher.discount ? 'discount' : 'discount_percent'}
                    disabled={!canEdit}
                  >
                    {voucher.discount ? (
                      <Option value="discount">Khoản tiền trực tiếp</Option>
                    ) : (
                      <Option value="discount_percent">Phần trăm %</Option>
                    )}
                  </Select>
                </Form.Item>
                {voucher.discount_percent !== undefined ? (
                  <Form.Item
                    label={<FormLabel label="Mức giảm" />}
                    name="discount_percent"
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng nhập mức giảm giá!',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={<FormLabel label="Mức giảm" />}
                    name="discount"
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng nhập mức giảm giá!',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="time"
                  label={<FormLabel label="Khoảng thời gian" require={false} />}
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập khoảng thời gian!',
                    },
                  ]}
                >
                  <RangePicker format="DD-MM-YYYY" />
                </Form.Item>
              </div>
            </BorderTopBoxContent>
          </Col>
          <Col xs={24} md={12} lg={16}>
            <BorderTopBoxContent>
              <div className="box-header">Lựa chọn lớp học áp dụng</div>
              <div className="box-body">
                <ListItems
                  list={list}
                  chosenItems={chosenItems}
                  setChosenItems={setChosenItems}
                  discount={discount}
                  discountPercent={discount_percent}
                  canEdit={canEdit}
                />
              </div>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default VoucherDetail;
