import ShowServerError from 'components/commons/ShowServerError';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetListItemQuery,
  useGetListOrdersQuery,
} from 'services/order.service';
import { selectKeySearch } from 'store/order.slice';
import ListOrdersContent from './ListOrdersContent';

const GetListOrder = (): ReactElement => {
  const keySearch = useSelector(selectKeySearch);
  const { isError, error } = useGetListOrdersQuery({ keySearch });
  useGetListItemQuery();
  if (isError) {
    return <ShowServerError error={error} />;
  }
  return <ListOrdersContent />;
};
export default GetListOrder;
