import Breadcrumb from 'components/commons/Breadcrumb';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetLessonAttendancesQuery } from 'services/classes.service';
import { selectClass } from 'store/classes.slice';
import AttendanceTable from './AttendanceTable';

const AttendancePageContent = (): ReactElement => {
  const { classId, lessonId } = useParams<{
    classId: string;
    lessonId: string;
  }>();
  const { name } = useSelector(selectClass);

  const { isLoading } = useGetLessonAttendancesQuery({
    class_id: classId,
    lesson_id: lessonId,
  });

  if (isLoading) {
    return <LoadingSpin text="Đang tải dữ liệu" />;
  }
  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.class.list)}
        secondaryText="Lớp học"
        thirdLink={generatePath(route.class.detail, { classId })}
        thirdText={name}
        text="Điểm danh"
      />
      <AttendanceTable />
    </>
  );
};
export default AttendancePageContent;
