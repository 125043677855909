import { LeftOutlined } from '@ant-design/icons';
import { Col, DatePicker, Input, InputNumber, message, Row } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import AddUserToPayment from 'components/report/AddUserToPayment';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import disabledSubmissionDate from 'libraries/utils/disabledSubmissionDate';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useCreatePaymentReceiptMutation } from 'services/report.service';
import { selectUser } from 'store/auth.slice';
import styled from 'styled-components';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';
import useQuery from '../../../../libraries/hooks/query';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const today = new Date();
const { TextArea } = Input;

const CreateReceiptContent = (): ReactElement => {
  const user = useSelector(selectUser);
  const history = useHistory();
  const searchParams = useQuery();
  const [numberMoney, setNumberMoney] = useState('');
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [receiverId, setReceiverId] = useState(user.id ? user.id : '');
  const [receiverName, setReceiverName] = useState(
    user.full_name ? user.full_name : '',
  );

  useEffect(() => {
    return () => {
      searchParams.set('tab', '2');
      history.replace({ search: searchParams.toString() });
    };
  }, []);

  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isReceiverModalVisible, setIsReceiverModalVisible] = useState(false);

  const [createReceipt] = useCreatePaymentReceiptMutation();

  const [form] = Form.useForm();

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    createReceipt({
      datetime:
        values.datetime !== undefined
          ? values.datetime.add(7, 'hours').toISOString()
          : '',
      user_id: userId,
      full_name: userName,
      receiver_id: receiverId,
      amount: values.amount,
      content: values.content,
      note: '',
      is_paid: false,
      purpose: 'money_from_other',
    })
      .unwrap()
      .then(() => {
        message.success('Tạo phiếu thu thành công');
        setTimeout(() => {
          history.push({
            pathname: generatePath(route.report.finance),
            search: '?tab=2',
          });
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.report.finance)}
        secondaryText="Báo cáo"
        text="Tạo phiếu thu"
      />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          datetime: moment(moment(today).format('DD/MM/YYYY'), 'DD/MM/YYYY'),
          user: user.full_name,
          is_paid: false,
        }}
      >
        <GroupButton>
          <LinksContainer style={{ display: 'flex' }}>
            <Link.Default
              to={{
                pathname: generatePath(route.report.finance),
                search: '?tab=2',
              }}
            >
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
            >
              Tạo phiếu
            </Button.Primary>
          </LinksContainer>
        </GroupButton>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={16} lg={12}>
            <BorderTopBoxContent>
              <Form.Item
                name="datetime"
                label={<FormLabel label="Ngày giao dịch" />}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập ngày giao dịch!',
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledSubmissionDate}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
              <div style={{ position: 'relative' }}>
                <Form.Item
                  label={<FormLabel label="Số tiền" />}
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập số tiền đã thu!',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    onChange={(value: any) => setNumberMoney(value)}
                  />
                </Form.Item>
                <p
                  style={{
                    marginTop: '-24px',
                    fontWeight: '600',
                    position: 'absolute',
                    top: '135%',
                    left: '0',
                    fontSize: '14px',
                  }}
                >
                  {convertCurrencyToWords(+numberMoney)}
                </p>
              </div>
              <Form.Item
                label={<FormLabel label="Người nộp tiền" />}
                name="user"
              >
                <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 150px)' }}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <Button.Primary
                    style={{ height: 32, width: 150 }}
                    onClick={() => setIsUserModalVisible(true)}
                  >
                    Chọn người nộp
                  </Button.Primary>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Người nhận tiền" />}
                name="receiver"
              >
                <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 150px)' }}
                    value={receiverName}
                    disabled
                  />
                  <Button.Primary
                    style={{ height: 32, width: 150 }}
                    onClick={() => setIsReceiverModalVisible(true)}
                  >
                    Chọn người nhận
                  </Button.Primary>
                </Input.Group>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Nội dung chi" />}
                name="content"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập nội dung chi!',
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
      {isUserModalVisible && (
        <AddUserToPayment
          isModalVisible={isUserModalVisible}
          closeModal={() => setIsUserModalVisible(false)}
          setUserId={setUserId}
          setUserName={setUserName}
        />
      )}
      {isReceiverModalVisible && (
        <AddUserToPayment
          isModalVisible={isReceiverModalVisible}
          closeModal={() => setIsReceiverModalVisible(false)}
          setUserId={setReceiverId}
          setUserName={setReceiverName}
        />
      )}
    </>
  );
};

export default CreateReceiptContent;
