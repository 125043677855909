import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { Input, message, Pagination, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import Link from 'libraries/components/commons/Link';
import Span from 'libraries/components/commons/Span';
import {
  advisoryInterestedValues,
  advisoryStatusValues,
  ROLE_SALE,
} from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { Advisory } from 'models/order.model';
import { ReactElement, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetListCoursesQuery } from 'services/classes.service';
import { useGetUsersByRoleQuery } from 'services/user.service';
import { useDeleteAdvisoryMutation } from 'services/order.service';
import confirm from 'antd/lib/modal/confirm';
import { useDispatch } from 'react-redux';
import { deleteAdvisoryFromList } from 'store/order.slice';
import { useGetTracksListQuery } from 'services/track.service';

interface KeySearchType {
  fullname: string;
  status: string;
  phone: string;
  email: string;
  interested: string;
  sale_id: string;
  item: string;
  class_status: string;
}

interface PropsType {
  advisories: Advisory[];
  total_page: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  setKeyword: (value: string) => void;
  state: KeySearchType;
  handleSetState: (field: string, value: string) => void;
}

const { Option } = Select;

const AdvisoriesList = ({
  advisories,
  total_page,
  currentPage,
  setCurrentPage,
  setKeyword,
  state,
  handleSetState,
}: PropsType): ReactElement => {
  console.log(advisories);

  const { data: courses } = useGetListCoursesQuery();
  const { data: salers } = useGetUsersByRoleQuery(ROLE_SALE);
  const { data: track } = useGetTracksListQuery(`keyword=&master_id=`);

  const [tmpName, setTmpName] = useState('');
  const [tmpEmail, setTmpEmail] = useState('');
  const [tmpPhone, setTmpPhone] = useState('');
  const [tmpCourse, setTmpCourse] = useState('');
  const [tmpStatus, setTmpStatus] = useState('');
  const [tmpSale, setTmpSale] = useState('');
  const [tmpClassStatus, setTmpClassStatus] = useState('');

  const {
    fullname,
    status,
    phone,
    email,
    interested,
    sale_id,
    item,
    class_status,
  } = state;

  const [deleteAdvisory] = useDeleteAdvisoryMutation();
  const distpatch = useDispatch();

  const showDeleteConfirm = (id: number) => {
    confirm({
      title: 'Xác nhận xoá',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá không ?',
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deleteAdvisory({ id })
          .unwrap()
          .then(() => {
            message.success('Xoá bài tư vấn thành công!');
            distpatch(deleteAdvisoryFromList(id));
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleOnchangePage = (page: number) => {
    setCurrentPage(page);
    setKeyword(
      `?page=${page}&fullname=${fullname}&status=${status}&phone=${phone}&email=${email}&interested=${interested}&sale_id=${sale_id}&item=${item}&class_status=${class_status}`,
    );
  };

  const columns: ColumnsType<Advisory> = [
    {
      title: 'Họ tên',
      children: [
        {
          title: (
            <Input
              value={tmpName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('fullname', tmpName);
                }
              }}
              onChange={(e) => setTmpName(e.target.value)}
            />
          ),
          dataIndex: 'full_name',
          key: 'full_name',
        },
      ],
    },
    {
      key: 'phone',
      title: 'Số điện thoại',
      children: [
        {
          title: (
            <Input
              value={tmpPhone}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('phone', tmpPhone);
                }
              }}
              onChange={(e) => setTmpPhone(e.target.value)}
            />
          ),
          dataIndex: 'phone',
          key: 'phone',
        },
      ],
    },
    {
      title: 'Email',
      children: [
        {
          title: (
            <Input
              value={tmpEmail}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('email', tmpEmail);
                }
              }}
              onChange={(e) => setTmpEmail(e.target.value)}
            />
          ),
          dataIndex: 'email',
          key: 'email',
        },
      ],
    },
    {
      title: 'Khoá học quan tâm',
      children: [
        {
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              showSearch
              optionFilterProp="children"
              value={tmpCourse}
              onChange={(value) => {
                setTmpCourse(value);
                handleSetState('item', value);
              }}
            >
              <Option value="">Tất cả</Option>
              {courses &&
                track &&
                [...courses, ...track].map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          ),
          dataIndex: 'item',
          key: 'item',
        },
      ],
    },
    {
      title: 'Ngày gửi',
      children: [
        {
          dataIndex: 'date',
          key: 'date',
          render: (date) => (date !== '' ? formatDate(date) : ''),
        },
      ],
    },
    {
      title: 'Trạng thái',
      children: [
        {
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={tmpStatus}
              onChange={(value) => {
                setTmpStatus(value);
                handleSetState('status', value);
              }}
            >
              <Option value="">Tất cả</Option>
              {advisoryStatusValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          key: 'status',
          dataIndex: 'status',
          render: (_text, col) => {
            for (let i = 0; i < advisoryStatusValues.length; i++) {
              const item = advisoryStatusValues[i];

              if (item.value === col.status) {
                return <Span className={item.className}>{item.label}</Span>;
              }
            }
          },
        },
      ],
    },
    {
      title: 'Mức độ quan tâm',
      children: [
        {
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={interested}
              onChange={(value) => handleSetState('interested', value)}
            >
              <Option value="">Tất cả</Option>
              {advisoryInterestedValues.map((item, index) => (
                <Option value={item.value.toString()} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          key: 'interested',
          dataIndex: 'interested',
          render: (_text, col) => {
            for (let i = 0; i < advisoryInterestedValues.length; i++) {
              const item = advisoryInterestedValues[i];

              if (item.value === col.interested) {
                return <Span className={item.className}>{item.label}</Span>;
              }
            }
          },
        },
      ],
    },
    {
      title: 'Tình trạng học viên',
      children: [
        {
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={tmpClassStatus}
              onChange={(value) => {
                setTmpClassStatus(value);
                handleSetState('class_status', value);
              }}
            >
              <Option value="">Tất cả</Option>
              <Option value="not_start">Chưa học</Option>
              <Option value="active">Đang học</Option>
              <Option value="completed">Đã học</Option>
            </Select>
          ),
          dataIndex: 'class_status',
          key: 'class_status',
          render: (class_status, record) => (
            <>
              { class_status === undefined || class_status.length === 0 ? (
                'Chưa học'
              ) : (
                <span>{`${record.class_name} (${class_status === 'active' ? 'Đang học' : 'Đã học'})`}</span>
              )}
            </>
          ),
        },
      ],
    },
    {
      title: 'Người phụ trách',
      children: [
        {
          title: (
            <Select
              showSearch
              dropdownMatchSelectWidth={200}
              value={tmpSale}
              onChange={(value) => {
                handleSetState('sale_id', value);
                setTmpSale(value);
              }}
            >
              <Option value="">Tất cả</Option>
              {salers &&
                salers.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.full_name}
                  </Option>
                ))}
            </Select>
          ),
          key: 'sale',
          dataIndex: 'sale',
        },
      ],
    },
    {
      children: [
        {
          key: 'action',
          dataIndex: 'action',
          render: (_text, item) => (
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <Link.Default
                to={generatePath(route.order.advisoryDetail, {
                  advisoryId: item.id,
                })}
              >
                <EditOutlined />
              </Link.Default>
              <Link.Danger to="#" onClick={() => showDeleteConfirm(item.id)}>
                <DeleteOutlined />
              </Link.Danger>
            </div>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    setCurrentPage(1);
    setKeyword(
      `?page=1&fullname=${fullname}&status=${status}&phone=${phone}&email=${email}&interested=${interested}&sale_id=${sale_id}&item=${item}&class_status=${class_status}`,
    );
    if (fullname === '') setTmpName('');
  }, [state]);

  return (
    <>
      <TableContainer>
        <Table<Advisory>
          columns={columns}
          dataSource={advisories}
          pagination={false}
          rowKey={(record) =>
            `${record.date}-${record.class_status}-${record.class_name}-${record.id}`
          }
          bordered
        />
      </TableContainer>
      <Pagination
        current={currentPage}
        pageSize={10}
        showSizeChanger={false}
        defaultCurrent={currentPage}
        total={total_page * 10}
        style={{ marginTop: '20px' }}
        onChange={handleOnchangePage}
      />
    </>
  );
};

export default AdvisoriesList;
