export const updateBlogLocalStore = (
  blogLocalStore: any,
  blogId: any,
  valueObject: any,
) => {
  const length = blogLocalStore.length;
  const findIndexblog = blogLocalStore.findIndex(
    (item: any) => item.id == blogId,
  );
  let data = [] as any;

  if (length == 0) {
    data = [{ id: blogId, ...valueObject }];
  } else if (findIndexblog != -1) {
    data = blogLocalStore.map((item: any) => {
      if (item.id != blogId) {
        return item;
      } else {
        return { ...item, ...valueObject };
      }
    });
  } else if (findIndexblog == -1 && length < 10) {
    data = [...blogLocalStore, { id: blogId, ...valueObject }];
  } else if (findIndexblog == -1 && length >= 10) {
    blogLocalStore.shift();
    data = [...blogLocalStore, { id: blogId, ...valueObject }];
  }

  window.localStorage.setItem('blog', JSON.stringify(data));
};
