import { ReactElement } from 'react';
import Breadcrumb from 'components/commons/Breadcrumb';
import { useGetBaseSalaryQuery } from 'services/report.service';
import ServerError from 'components/commons/ServerError';
import ConfigCommision from './tabs/ConfigCommision';
import ConfigFixedSalary from './tabs/ConfigFixedSalary';
import { ComponentContent } from 'components/commons/ComponentContent';
import Tabs from 'components/commons/Tabs';
import { useHistory } from 'react-router-dom';
import useQuery from 'libraries/hooks/query';
import LoadingSpin from 'libraries/components/LoadingSpin';

const { TabPane } = Tabs;

const ConfigSalaries = (): ReactElement => {
  const history = useHistory();
  const search = useQuery();
  const tabsKey = search.get('tabs') ?? '1';
  const {
    data: baseSalary,
    isLoading,
    isError,
    error,
  } = useGetBaseSalaryQuery();

  const handleChange = (value: string) => {
    history.replace({ search: `?tabs=${value}` });
  };

  return (
    <>
      <Breadcrumb text="Cấu hình" />
      <ComponentContent>
        <Tabs defaultActiveKey={tabsKey} onChange={handleChange}>
          <TabPane
            tab={'Cấu hình lương cho sale'}
            tabKey={'1'}
            key={'1'}
            forceRender
          >
            {isError ? (
              <ServerError
                title={`Status ${
                  !!('originalStatus' in error) && error.originalStatus
                }: ${!!('status' in error) && error.status},${
                  !!('error' in error) && error.error
                } `}
              />
            ) : isLoading ? (
              <LoadingSpin />
            ) : (
              <ConfigCommision baseSalary={baseSalary} />
            )}
          </TabPane>
          <TabPane
            tab={'Cấu hình lương cứng'}
            tabKey={'2'}
            key={'2'}
            forceRender
          >
            <ConfigFixedSalary />
          </TabPane>
        </Tabs>
      </ComponentContent>
    </>
  );
};

export default ConfigSalaries;
