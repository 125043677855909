import { Empty, Form, Typography } from 'antd';
import { ReactElement } from 'react';
import ShowMarkdown from '../../../../libraries/components/markdown/ShowMarkdown';
import { formatMoneyVnd } from '../../../../libraries/utils/changeFormatData';
import { HistorySalary } from '../../../../models/report.model';
import { formatDate } from '../../../../utils/formatDate';
import { v4 } from 'uuid';

interface HistoryItems {
  history: HistorySalary | undefined;
}

const { Text } = Typography;

const HistoryItems = ({ history }: HistoryItems): ReactElement => {
  if (!history) {
    return (
      <Empty
        description="Có vẻ như là lỗi rồi !, bạn thử reload lại nhé"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  }

  const labelData = Object.entries(history).map(([key, value]) => {
    switch (key) {
      case 'date':
        return {
          label: 'Ngày tháng',
          data: formatDate(value),
        };
      case 'paid':
        return { label: 'Đã trả', data: formatMoneyVnd(value) };
      case 'note':
        return { label: 'Ghi chú', data: value };
      default:
        return { label: '', data: '' };
    }
  });
  return (
    <>
      {labelData.map((item) => {
        if (!item.label.length) return <></>;
        return (
          <Form.Item key={v4()} label={<Text strong>{item.label}</Text>}>
            {item.label === 'Ghi chú' ? (
              <ShowMarkdown markdown={item.data} short_content />
            ) : (
              <Text>{item.data}</Text>
            )}
          </Form.Item>
        );
      })}
    </>
  );
};

export default HistoryItems;
