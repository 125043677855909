import core, { HighlightOptions } from './core'
import MarkdownIt from 'markdown-it'
declare const hljs: any;

export default function highlightjs (md: MarkdownIt, opts?: HighlightOptions): void {
  opts = { ...highlightjs.defaults, ...opts }

  if (opts.hljs == null) {
    opts.hljs = hljs
  }

  return core(md, opts)
}

highlightjs.defaults = {
  auto: true,
  code: true,
  inline: false,
  ignoreIllegals: true
}