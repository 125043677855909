import { LeftOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import AddUserToPayment from 'components/report/AddUserToPayment';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import disabledSubmissionDate from 'libraries/utils/disabledSubmissionDate';
import { PayReceiptDetail } from 'models/report.model';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useUpdatePaymentReceiptMutation } from 'services/report.service';
import styled from 'styled-components';
import Editor from 'libraries/editor/Editor';
import { useGetUserInfoQuery } from 'services/user.service';
const Image = styled.div`
  margin-bottom: 12px;
  max-width: 300px;
  @media (max-width: 1250px) {
    max-width: 200px;
  }
`;
const QrImage = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  @media (max-width: 576px) {
    position: relative;
  }
`;
interface PropsType {
  payment: PayReceiptDetail;
  refetch: any;
}

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { Option } = Select;
const { TextArea } = Input;

const PaymentDetailContent = ({
  payment,
  refetch,
}: PropsType): ReactElement => {
  const [updatePayment] = useUpdatePaymentReceiptMutation();

  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [receiverId, setReceiverId] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const { data: userInfo, isError: isUserInfoError } = useGetUserInfoQuery({
    user_id: payment.user_id,
    code: '',
  });

  const [purpose, setPurpose] = useState('');

  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isReceiverModalVisible, setIsReceiverModalVisible] = useState(false);

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    updatePayment({
      id: payment.id,
      data: {
        datetime:
          values.datetime !== undefined ? moment(values.datetime).format() : '',
        user_id: userId,
        full_name: userName,
        receiver_id: receiverId,
        amount: values.amount,
        content: values.content,
        note: values.note,
        is_paid: values.is_paid,
        purpose: values.purpose,
      },
    })
      .unwrap()
      .then(() => {
        message.success('Cập nhật phiếu chi thành công');
        refetch();
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      purpose: payment.purpose,
      amount: payment.amount,
      content: payment.content,
      note: payment.note,
      is_paid: payment.is_paid,
      datetime:
        payment.datetime !== ''
          ? moment(moment(payment.datetime).format('DD/MM/YYYY'), 'DD/MM/YYYY')
          : '',
    });
    setUserId(payment.user_id);
    setUserName(payment.full_name);
    setReceiverId(payment.receiver_id);
    setReceiverName(payment.receiver_full_name);
    setPurpose(payment.purpose);
  }, [payment]);

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ note: payment.note }}
      >
        <GroupButton>
          <LinksContainer style={{ display: 'flex' }}>
            <Link.Default to={generatePath(route.report.finance)}>
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
            >
              Cập nhật
            </Button.Primary>
          </LinksContainer>
        </GroupButton>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={16} lg={16}>
            <BorderTopBoxContent>
              <Form.Item
                valuePropName="checked"
                label={
                  <FormLabel label="Xác nhận thanh toán" require={false} />
                }
                name="is_paid"
              >
                <Checkbox>Công ty đã thanh toán</Checkbox>
              </Form.Item>
              {userInfo?.bank_code !== '' && (
                <QrImage>
                  <Image>
                    <img
                      src={`https://img.vietqr.io/image/${userInfo?.bank_code}-${userInfo?.bank_account}-print.jpg?accountName=${userInfo?.full_name}&amount=${payment.amount}&addInfo= ${payment.content}`}
                      alt="QrCode"
                    />
                  </Image>
                </QrImage>
              )}
              <Form.Item
                name="datetime"
                label={<FormLabel label="Ngày giao dịch" />}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập ngày giao dịch!',
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledSubmissionDate}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
              <Form.Item
                name="purpose"
                label={<FormLabel label="Mục đích chi" />}
              >
                <Select
                  style={{ width: '50%' }}
                  placeholder="Chọn mục đích"
                  onChange={(value: string) => setPurpose(value)}
                >
                  <Option value="pay_for_office">Chi phí văn phòng</Option>
                  <Option value="internet_fee">Phí dịch vụ Internet</Option>
                  <Option value="license_fee">Mua bản quyền phần mềm </Option>
                  <Option value="office_renting_fee">Phí thuê nhà</Option>
                  <Option value="electric_fee">Tiền điện </Option>
                  <Option value="buy_equipment">Mua thiết bị</Option>
                  <Option value="advance_payment">Ứng tiền</Option>
                  <Option value="other_service">Dịch vụ khác</Option>
                  <Option value="adhoc_task">Thù lao trả một lần</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Số tiền" />}
                name="amount"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập số tiền chi!',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '50%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Người chi tiền" />}
                name="user"
              >
                <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 150px)' }}
                    value={userName}
                    disabled
                  />
                  <Button.Primary
                    style={{ height: 32, width: 150 }}
                    onClick={() => setIsUserModalVisible(true)}
                  >
                    Chọn người chi
                  </Button.Primary>
                </Input.Group>
              </Form.Item>
              {purpose !== 'pay_for_office' && (
                <Form.Item
                  label={<FormLabel label="Người nhận tiền" require={false} />}
                  name="receiver"
                >
                  <Input.Group compact>
                    <Input
                      style={{ width: 'calc(100% - 150px)' }}
                      value={receiverName}
                      disabled
                    />
                    <Button.Primary
                      style={{ height: 32, width: 150 }}
                      onClick={() => setIsReceiverModalVisible(true)}
                    >
                      Chọn người nhận
                    </Button.Primary>
                  </Input.Group>
                </Form.Item>
              )}
              <Form.Item
                label={<FormLabel label="Nội dung chi" />}
                name="content"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập nội dung chi!',
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Ghi chú" require={false} />}
                name="note"
              >
                {/* <TextArea rows={3} /> */}
                <Editor height={300} />
              </Form.Item>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
      {isUserModalVisible && (
        <AddUserToPayment
          isModalVisible={isUserModalVisible}
          closeModal={() => setIsUserModalVisible(false)}
          setUserId={setUserId}
          setUserName={setUserName}
        />
      )}
      {isReceiverModalVisible && (
        <AddUserToPayment
          isModalVisible={isReceiverModalVisible}
          closeModal={() => setIsReceiverModalVisible(false)}
          setUserId={setReceiverId}
          setUserName={setReceiverName}
        />
      )}
    </>
  );
};

export default PaymentDetailContent;
