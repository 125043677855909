import { Key, ReactElement, useState } from 'react';
import { useFindStudentQuery } from 'services/classes.service';
import { ColumnsType } from 'antd/lib/table';
import { UsersType } from 'models/user.model';
import { message, Table } from 'antd';

import TableContainer from 'components/table/TableContainer';

interface PropsType {
  keyword: string;
  hasResult: boolean;
  setSelectedUser: (value: UsersType) => void;
}

const FindStudent = ({
  keyword,
  hasResult,
  setSelectedUser,
}: PropsType): ReactElement => {
  const { data } = useFindStudentQuery(keyword);
  const students = data ? data : ([] as UsersType[]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  // if (data && data.length === 0 && hasResult) {
  //   message.warning('Không tìm thấy user nào phù hợp');
  // }

  const columns: ColumnsType<UsersType> = [
    {
      key: 'full_name',
      title: 'Họ tên',
      width: '35%',
      dataIndex: 'full_name',
    },
    {
      key: 'email',
      title: 'Email',
      width: '35%',
      dataIndex: 'email',
    },
    {
      key: 'phone',
      width: '30%',
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
  ];

  const onSelectChange = (selected: Key[], selectedRows: UsersType[]): void => {
    setSelectedRowKeys(selected);
    setSelectedUser(selectedRows[0]);
  };

  return (
    <>
      <TableContainer>
        <Table<UsersType>
          rowSelection={{
            type: 'radio',
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          columns={columns}
          dataSource={students}
          pagination={{ showSizeChanger: false }}
          rowKey="email"
          bordered
        />
      </TableContainer>
    </>
  );
};
export default FindStudent;
