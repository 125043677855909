import { ItemReport } from 'models/dashboard.model';
import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';

interface PropsType {
  items: ItemReport[];
}

const ItemsTable = ({ items }: PropsType): ReactElement => {
  const columns: ColumnsType<ItemReport> = [
    {
      key: 'item_id',
      width: '70%',
      title: 'Khoá học',
      dataIndex: 'item_id',
      render: (_text, item) =>
        item.item_type === 'course' ? (
          <RouterLink
            to={generatePath(route.course.detail, { courseId: item.item_id })}
          >
            {item.item_name}
          </RouterLink>
        ) : (
          <RouterLink
            to={generatePath(route.track.detail, { trackId: item.item_id })}
          >
            {item.item_name}
          </RouterLink>
        ),
    },
    {
      key: 'register_count',
      width: '30%',
      title: 'Số lượng đăng ký',
      dataIndex: 'register_count',
    },
  ];

  return (
    <TableContainer>
      <Table<ItemReport>
        columns={columns}
        dataSource={items}
        pagination={{ showSizeChanger: false }}
        rowKey="item_id"
        bordered
      />
    </TableContainer>
  );
};

export default ItemsTable;
