import {
  autocompletion,
  closeBrackets,
  closeBracketsKeymap,
  completionKeymap,
} from '@codemirror/autocomplete';
import {
  defaultKeymap,
  history,
  historyKeymap,
  indentWithTab,
} from '@codemirror/commands';
import {
  markdown,
  markdownKeymap,
  markdownLanguage,
} from '@codemirror/lang-markdown';
import {
  bracketMatching,
  defaultHighlightStyle,
  indentOnInput,
  indentUnit,
  syntaxHighlighting,
} from '@codemirror/language';
import { languages } from '@codemirror/language-data';
import { lintKeymap } from '@codemirror/lint';
import { highlightSelectionMatches, searchKeymap } from '@codemirror/search';
import { EditorState as CMEditorState, EditorSelection } from '@codemirror/state';

import {
  EditorView as CMEditorView,
  ViewUpdate,
  crosshairCursor,
  drawSelection,
  dropCursor,
  highlightActiveLineGutter,
  highlightSpecialChars,
  keymap,
  rectangularSelection,
} from '@codemirror/view';
import { Form, Input, Modal, message } from 'antd';
import Button from 'libraries/components/commons/Button';
import FormLabel from 'libraries/components/form/FormLabel';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce';
import { EditorContext as EC } from './EditorContext';
import { customHighlight, customTheme } from './EditorTheme';
import {
  Highlight,
  InlineCode,
  InlineMath,  //Cuong thêm inlinemath
  MarkStylingExtension,
  Strikethrough,
  Underline,
  Table, //Cuong thêm table custom CSS
} from './CustomMarkdownSyntax';
import markers from './markers';
import { formatAItoStandard } from './string';

const View = styled.div<{
  isPreview: boolean;
  editorHeight: number;
  showSplitScreen: boolean;
}>`
  ${({ isPreview, showSplitScreen }) =>
    isPreview
      ? showSplitScreen
        ? css`
            & {
              display: block;
              width: 50%;
              border-right: none;
              border-bottom-right-radius: 0px;
              min-height: 100%;
            }
          `
        : css`
            display: none;
            width: 0;
            min-height: 100%;
          `
      : css`
          display: block;
          width: 100%;
          border-bottom-right-radius: 4px;
          min-height: 100%;
        `}

  ${({ editorHeight }) => css`
    & {
      height: ${editorHeight}px;
    }
  `}
`;

const EditorView: React.FC = () => {
  const editorRef = useRef<HTMLDivElement>(null);

  const [upload, setUpload] = useState<{
    file: File;
    base64: string | ArrayBuffer | null;
  } | null>(null);

  const [form] = Form.useForm();

  const { state, dispatch } = useContext(EC);

  const {
    md,
    editorView,
    validateFile,
    uploadFunction,
    currentActive,
    scrollPosition,
    preview,
    splitScreen,
    editorHeight,
  } = state;

  const boldCommand = useCallback((target: CMEditorView): boolean => {
    markers.bold(target);
    return true;
  }, []);
  const italicCommand = useCallback((target: CMEditorView): boolean => {
    markers.italic(target);
    return true;
  }, []);

  const commands = useMemo(
    () =>
      keymap.of([
        {
          mac: 'cmd-b',
          win: 'ctrl-b',
          run: boldCommand,
        },
        {
          mac: 'cmd-i',
          win: 'ctrl-i',
          run: italicCommand,
        },
      ]),
    [],
  );

  const onScroll = useThrottledCallback((e: Event, view: CMEditorView) => {
    dispatch({
      type: 'scrollPositionChanged',
      payload: view.scrollDOM.scrollTop / view.scrollDOM.scrollHeight,
    });
  }, 100);

  const readImageAsURL = useCallback((file: File): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setUpload({
        file: file,
        base64: reader.result,
      });
    };
    reader.onerror = (error) => {
      message.error(`Lỗi ${error} vui lòng thử lại sau`);
    };
  }, []);

  const onCancel = useCallback(() => {
    setUpload(null);
  }, []);

  const onFinish = (values: { title: string }) => {
    if (!editorView || !uploadFunction || !upload) return;

    form
      .validateFields()
      .then(() => {
        uploadFunction(upload.file, values.title, (url) => {
          markers.image(editorView, values.title, url);
          setUpload(null);
          form.resetFields();
        });
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const onChange = useDebouncedCallback(
    (update: ViewUpdate) => {
      if (update.changes) {
        const doc = update.state.doc.toString();

        dispatch({ type: 'docChanged', payload: doc });
      }
    },
    666,
    { leading: true, trailing: true },
  );

  /*
  Hứng sự kiện người dùng paste text hoặc ảnh vào CodeMirror
  */
  const onPaste = useCallback((e: ClipboardEvent, view: CMEditorView) => {
    // Format lại các công thức toán học được sinh ra từ ChatGPT, Claude Sonnet, ...
    e.preventDefault();
    const pastedText = e.clipboardData?.getData('text');
    
    if (pastedText?.length !== 0) {

      const processedText = formatAItoStandard(pastedText!);
      const { from, to } = view.state.selection.main;
      const transaction = view.state.update({
        changes: {
          from: from,
          to: to,
          insert: processedText,
        },
      });
      view.dispatch(transaction);
      const newState = view.state;
      const newPos = Math.min(
        from + processedText.length,
        newState.doc.length,
      );
      const cursorTransaction = newState.update({
        selection: EditorSelection.cursor(newPos),
      });
      view.dispatch(cursorTransaction);
      return;
    }

    // Khi paste ảnh
    const file = e.clipboardData?.files[0];

    if (!uploadFunction || !file) {
      return;
    }
    if (!validateFile(file)) {
      message.error('File không hợp lệ!');
      return;
    }
    readImageAsURL(file);
  }, [uploadFunction, validateFile, readImageAsURL]);
  /*
  Hứng sự kiện người dùng kéo thả ảnh vào CodeMirror
  */
  const onDrop = useCallback((e: DragEvent, view: CMEditorView) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer?.files[0];
    if (!uploadFunction || !file) {
      message.error(`Không hợp lệ, thử kéo thả ảnh`);
      return;
    }
  
    if (!validateFile(file)) {
      message.error('File không hợp lệ!');
      return;
    }
  
    readImageAsURL(file);
  }, [uploadFunction, validateFile, readImageAsURL]);

  /*
  Cấu hình CodeMirror xử lý Markdown
  */
  const markdownExtension = () => {
    return markdown({
      base: markdownLanguage,
      codeLanguages: languages,
      addKeymap: true,
      extensions: [
        Table, //Cuong thêm table custom CSS
        MarkStylingExtension,
        Highlight,
        Underline,
        InlineCode,
        InlineMath,
        Strikethrough
      ],
    });
  };
  /*
  Cấu hình trình soạn thảo với một loạt các phần mở rộng (extensions), bao gồm:
  - Xử lý các sự kiện DOM như cuộn, dán và kéo thả.
  - Cung cấp các lệnh (commands) để thực hiện các hành động như bold, italic, ...
  */
  useEffect(() => {
    if (!editorRef.current) return;
    const editorState = CMEditorState.create({
      doc: md,
      extensions: [
        CMEditorView.domEventHandlers({
          scroll: onScroll,
          paste: onPaste,
          drop: onDrop,
        }),
        commands,
        indentUnit.of('   '),
        CMEditorView.lineWrapping,
        highlightActiveLineGutter(),
        highlightSpecialChars(),
        history(),
        drawSelection(),
        dropCursor(),
        indentOnInput(),
        bracketMatching(),
        closeBrackets(),
        autocompletion(),
        rectangularSelection(),
        crosshairCursor(),
        highlightSelectionMatches(),
        syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
        markdownExtension(),
        customTheme,
        syntaxHighlighting(customHighlight),
        CMEditorView.updateListener.of(onChange),
        keymap.of([
          ...closeBracketsKeymap,
          ...defaultKeymap,
          ...historyKeymap,
          ...completionKeymap,
          ...lintKeymap,
          indentWithTab,
          ...markdownKeymap,
          ...searchKeymap,
        ]),
      ],
    });

    const editorView =
      editorRef.current &&
      new CMEditorView({
        state: editorState,
        parent: editorRef.current,
      });

    dispatch({ type: 'init', payload: editorView });
  }, [editorRef]);

  useEffect(() => {
    if (!editorView) return;
    if (currentActive === 'editor') return;

    editorView.scrollDOM.scrollTop =
      editorView.scrollDOM.scrollHeight * scrollPosition;
  }, [editorView, currentActive, scrollPosition]);

  const onMouseEnter = useCallback(() => {
    if (state.currentActive !== 'editor')
      dispatch({ type: 'currentActiveChanged', payload: 'editor' });
  }, [currentActive]);

  return (
    <>
      <View
        showSplitScreen={splitScreen}
        className="editor-view"
        ref={editorRef}
        isPreview={preview}
        editorHeight={editorHeight}
        onMouseEnter={onMouseEnter}
      />

      <Modal
        zIndex={101}
        open={!!upload}
        keyboard={true}
        width={400}
        footer={null}
        onCancel={onCancel}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item style={{ paddingTop: '26px' }}>
            <img
              alt="example"
              style={{ width: '100%' }}
              src={
                upload && typeof upload.base64 === 'string'
                  ? upload.base64
                  : undefined
              }
            />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Title (Description)" require={true} />}
            name="title"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên ảnh!',
              },
            ]}
          >
            <Input autoFocus={true} />
          </Form.Item>
          <Button.Primary type="primary" htmlType="submit">
            Save
          </Button.Primary>
        </Form>
      </Modal>
    </>
  );
};

export default EditorView;
