import { ReactElement } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import Button from './commons/Button';

const acceptTypes = ['application/pdf'];

interface PropsType {
  // eslint-disable-next-line
  fileList: any; // state mảng chứa file upload
  // eslint-disable-next-line
  setFileList: (value: any) => void; // set state mảng
  uploading: boolean; // state hiện trạng thái uploading
  setUploading: (value: boolean) => void; // set uploading
  handleUploadFile: (value: FormData) => void; // hàm handle upload file
}

const UploadFileCV = ({
  fileList,
  setFileList,
  uploading,
  setUploading,
  handleUploadFile,
}: PropsType): ReactElement => {
  const props = {
    accept: acceptTypes.join(','),
    maxCount: 1,
    onRemove: () => {
      setFileList([]);
    },
    // eslint-disable-next-line
    beforeUpload: (file: any) => {
      if (acceptTypes.includes(file.type)) {
        if (file.size < 5242880) {
          setFileList([...fileList, file]);
        } else {
          message.error(`${file.name} có kích thước lớn hơn 5 MB`);
        }
      } else {
        message.error(`${file.name} không phải định dạng cho phép upload`);
      }
      return acceptTypes.includes(file.type) ? false : Upload.LIST_IGNORE;
    },
    fileList,
    showUploadList: false,
    onChange: () => {
      setUploading(true);
      const formData = new FormData();
      formData.append('upload', fileList[0]);

      handleUploadFile(formData);
    },
  };

  return (
    <Upload {...props}>
      <Button.Cancel
        disabled={fileList.length === 1}
        icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}
      >
        {uploading ? 'Đang upload' : 'Upload file'}
      </Button.Cancel>
    </Upload>
  );
};

export default UploadFileCV;
