import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import NotFound from 'components/commons/NotFound';
import { useGetTeacherInfoQuery } from 'services/report.service';
import TeacherDetailContent from './TeacherDetailContent';

const GetTeacherInfo = (): ReactElement => {
  const { userId } = useParams<{ userId: string }>();
  const { isError } = useGetTeacherInfoQuery(userId);

  if (isError) {
    <NotFound subTitle="Lỗi khi tìm thông tin giảng viên" />;
  }

  return <TeacherDetailContent />;
};

export default GetTeacherInfo;
