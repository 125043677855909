import { createSlice } from '@reduxjs/toolkit';
import { CourseInfo } from 'models/course.model';
import { courseApi } from 'services/course.service';
import { RootState } from 'store';

export interface CourseState {
  course: CourseInfo;
}

const initialState: CourseState = {
  course: {} as CourseInfo,
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      courseApi.endpoints.getCourseInfo.matchFulfilled,
      (state, { payload }) => {
        state.course = payload;
      },
    );
  },
});

export const {} = courseSlice.actions;
export const selectCourse = (state: RootState): CourseInfo =>
  state.course.course;

export default courseSlice.reducer;
