import { ReactElement, useEffect, useState } from 'react';
import Modal from 'components/commons/Modal';
import { selectOrder } from 'store/order.slice';
import { AdjustStatus } from './AdjustModal';
import { useSelector } from 'react-redux';
import { DatePicker, Input, InputNumber, message, Select } from 'antd';
import Form from 'libraries/components/form/Form';
import Button from 'libraries/components/commons/Button';
import FormLabel from 'libraries/components/form/FormLabel';
import { useParams } from 'react-router';
import {
  usePayOrderMutation,
  useRefundOrderMutation,
} from 'services/order.service';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';
interface Bank {
  id: number;
  name: string;
  code: string;
  bin: string;
  shortName: string;
  logo: string;
  transferSupported: number;
  lookupSupported: number;
  short_name: string;
  support: number;
  isTransfer: number;
  swift_code: string;
}
interface BackMoneyModalProps {
  isBackMoneyModalVisible: boolean;
  handleCancel: () => void;
}

const { TextArea } = Input;
function getShortNameByBin(banks: Bank[], bin: string): string {
  const bank = banks.find((b) => b.bin === bin);
  return bank ? bank.shortName : '';
}
const BackMoneyModal = ({
  handleCancel,
  isBackMoneyModalVisible,
}: BackMoneyModalProps): ReactElement => {
  const [numberMoney, setNumberMoney] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const dataOrder = useSelector(selectOrder);
  const [banks, setBanks] = useState<Bank[]>([]);
  const { full_name, email, phone, items } = dataOrder;
  const [isAdjust, setIsAdjust] = useState(false);
  const { paid } = dataOrder;
  const { orderId } = useParams<{ orderId: string }>();
  const [refundOrder] = useRefundOrderMutation();
  const listItemString = items ? items.map((item) => item.name).toString() : '';
  useEffect(() => {
    fetch('https://api.vietqr.io/v2/banks')
      .then((res) => res.json())
      .then((data) => setBanks(data.data));
  }, []);
  const options = banks?.map((bank: any) => ({
    value: bank.bin,
    label: ` ${bank.shortName} (${bank.name})`,
    shortName: bank.shortName,
  }));
  const handleOk = () => {
    console.log('OK');
  };
  const handleClose = () => {
    handleCancel();
  };
  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    const { pay_amount, note, bank_code, bank_account } = values;
    if (paid < pay_amount) {
      message.error(
        'Số tiền hoàn lớn hơn số tiền đã nộp, vui lòng kiểm tra lại !',
      );
      return;
    }
    const data = {
      full_name,
      email,
      phone,
      bank_name: getShortNameByBin(banks, values.bank_code),
      bank_account: bank_account,
      pay_amount: pay_amount,
      payment_type: '',
      items: listItemString,
      note: note,
    };
    if (!buttonClicked) {
      setButtonClicked(true);
      refundOrder({ order_id: orderId, data: data })
        .unwrap()
        .then(() => {
          message.success('Gửi yêu cầu hoàn tiền thành công !');
          handleCancel();
          setButtonClicked(false);
        })
        .catch((error) => {
          message.error(error.data);
        });
    }
  };

  const handleChange = () => {
    setIsAdjust(true);
  };

  return (
    <Modal
      title="Hoàn tiền"
      visible={isBackMoneyModalVisible}
      onOk={handleOk}
      onCancel={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[]}
    >
      <AdjustStatus
        title="Số tiền đã thanh toán"
        money={paid}
        className="success"
      />
      <hr />
      <Form
        name="basic"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={handleSubmit}
        onChange={handleChange}
      >
        <Form.Item
          label={<FormLabel label="Tên ngân hàng" require={true} />}
          name="bank_code"
          rules={[{ required: true, message: 'Vui lòng chọn tên ngân hàng' }]}
        >
          {banks.length != 0 && (
            <Select
              showSearch
              placeholder="Chọn ngân hàng"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.shortName ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={options}
            />
          )}
        </Form.Item>
        <Form.Item
          label={<FormLabel label="Số tài khoản" require={true} />}
          name="bank_account"
          rules={[
            {
              pattern: /^(?:\d*)$/,
              message: 'Số tài khoản phải là dạng số',
            },
            {
              required: true,
              message: 'Vui lòng nhập số tài khoản',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <Form.Item
            name="pay_amount"
            label={<FormLabel label="Số tiền hoàn" require={true} />}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số tiền cần hoàn',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              onChange={(value: any) => setNumberMoney(value)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
          <p
            style={{
              marginTop: '-24px',
              fontWeight: '600',
              position: 'absolute',
              top: '135%',
              left: '0px;',
            }}
          >
            {convertCurrencyToWords(+numberMoney)}
          </p>
        </div>
        <Form.Item
          name="note"
          label={<FormLabel label="Ghi chú" require={false} />}
        >
          <TextArea />
        </Form.Item>
        <div style={{ textAlign: 'end' }}>
          <Button.Danger htmlType="submit" disabled={!isAdjust}>
            Yêu cầu hoàn tiền
          </Button.Danger>
        </div>
      </Form>
    </Modal>
  );
};

export default BackMoneyModal;
