import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Course,
  CourseInfo,
  CourseNew,
  CourseUpdate,
} from 'models/course.model';
import { CourseTag, Tag } from 'models/tag.model';
import { baseQuery } from './base.service';

interface CoursesListType {
  total_page: number;
  courses: Course[];
}

export const courseApi = createApi({
  reducerPath: 'courseApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getTags: builder.query<Tag[], void>({
      query: () => {
        return {
          url: `tags`,
          method: 'GET',
        };
      },
      transformResponse: (response: Tag[]) => {
        response = response === null ? ([] as Tag[]) : response;
        return response;
      },
    }),
    getAllCourses: builder.query<CoursesListType, string>({
      query: (keyword) => {
        return {
          url: `all-courses?${keyword}`,
          method: 'GET',
        };
      },
      transformResponse: (response: CoursesListType) => {
        response.courses =
          response.courses === null ? ([] as Course[]) : response.courses;
        if (response.courses.length > 0) {
          response.courses.map((item) => {
            item.create_date = item.create_date.includes('0001-01-01T00')
              ? ''
              : item.create_date;
            item.interactive = item.is_interactive
              ? 'true'
              : !item.is_interactive
                ? 'false'
                : '';
          });
        }
        return response;
      },
    }),
    getCourseInfo: builder.query<CourseInfo, string>({
      query: (course_id) => {
        return {
          url: `course/${course_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: CourseInfo) => {
        response = response ?? ({} as CourseInfo);
        response.tags = response.tags ?? ([] as CourseTag[]);
        response.tag_ids = response.tags.reduce((arr: string[], item) => {
          arr.push(item.id);
          return arr;
        }, [] as string[]);
        response.sale_id = response.sale_id ?? '';
        response.teachers = response.teachers ?? ([] as string[]);

        return response;
      },
    }),
    updateCourseInfo: builder.mutation<string, CourseUpdate>({
      query: (data) => {
        return {
          url: `course/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    createNewCourse: builder.mutation<string, CourseNew>({
      query: (data) => {
        return {
          url: `create-course`,
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteCourse: builder.mutation<string, string>({
      query: (course_id) => {
        return {
          url: `course/${course_id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetTagsQuery,
  useGetAllCoursesQuery,
  useGetCourseInfoQuery,
  useUpdateCourseInfoMutation,
  useCreateNewCourseMutation,
  useDeleteCourseMutation,
} = courseApi;
