import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base.service';

import {
  Advisory,
  AdvisoryDetail,
  AdvisoryHistory,
  NewAdvisoryHistory,
  OrdersListType,
  OrderType,
  ResponseOrderType,
} from 'models/order.model';
import {
  Classes,
  ClassesType,
  ItemOrderDetail,
  ListItemType,
} from 'store/order.slice';

interface OrdersListGetType {
  total_page: number;
  order_list: OrdersListType[];
}

interface AdvisoryGetList {
  total_page: number;
  list_advisories: Advisory[];
}
interface CreateOrderRequestType {
  user_id: string;
  full_name: string;
  phone: string;
  email: string;
  is_company?: boolean;
  note?: string;
  payment_type?: string;
  company_info?: CompanyInfoType;
}

interface CompanyInfoType {
  company: string;
  address: string;
  mobile: string;
}

interface PayOrderType {
  order_id: string;
  data: {
    full_name: string;
    email: string;
    phone: string;
    pay_amount: number;
    payment_type: string;
    items: string;
    note?: string;
  };
}
interface RefundOrderType {
  order_id: string;
  data: {
    full_name: string;
    email: string;
    phone: string;
    pay_amount: number;
    items: string;
    note?: string;
    bank_name: string;
    bank_account: string;
  };
}

interface SaveItemToOrderType {
  order_id: string;
  data: {
    items: {
      price_id: number;
      type?: string;
    }[];
    total: number;
  };
}
interface StudentType {
  id: string;
  full_name: string;
  phone: string;
  email: string;
}
interface AddStudentToOrderType {
  order_id: string;
  data: StudentType[];
}

interface AdjustOrderType {
  order_id: string;
  data: {
    should_pay_amount: number;
    note: string;
  };
}

export interface UpdateOrderInfo {
  orderId: string;
  data: {
    user_id: string;
    payment_type: string;
    note: string;
    is_company: boolean;
    company_info?: {
      company: string;
      tax_code: string;
      address: string;
      mobile: string;
    };
  };
}
export interface SendMailOrder {
  orderId: string;
  data: {
    debt: string;
    content: string;
    email: string;
    phone: string;
    full_name: string;
    items: string[];
    url_qr_code: string;
  };
}

interface AddStudentToClassType {
  orderId: string;
  classId: string;
  class_type: 'lab' | 'live';
}

interface AddAdvisoryHistoryRes {
  advisory_id: number;
  date: string;
  interested: number;
  note: string;
  sale: string;
  sale_id: string;
  type: string;
}

interface DeleteAdvisory {
  id: number;
}

export const orderApi = createApi({
  reducerPath: 'orderApi',

  baseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ['OrderInfo'],
  endpoints: (builder) => ({
    addStudentToOrder: builder.mutation<StudentType[], AddStudentToOrderType>({
      query: (arg) => {
        const { order_id, data } = arg;
        return {
          url: `orders/${order_id}/students`,
          method: 'POST',
          body: data,
        };
      },
    }),
    // eslint-disable-next-line
    adjustOrder: builder.mutation<any, AdjustOrderType>({
      query: (arg) => {
        const { order_id, data } = arg;
        return {
          url: `orders/${order_id}/payment`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    getListOrders: builder.query<OrdersListGetType, { keySearch: string }>({
      query: ({ keySearch }) => {
        return {
          url: `list-orders?${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: OrdersListGetType) => {
        response.order_list =
          response.order_list === null
            ? ([] as OrdersListType[])
            : response.order_list;
        if (response.order_list.length > 0) {
          response.order_list.map(
            (item) =>
              (item.item_names =
                item.item_names === null ? ([] as string[]) : item.item_names),
          );
        }
        return response;
      },
    }),

    getListItem: builder.query<ListItemType[], void>({
      query: () => `list-items`,
    }),

    getOrderInfo: builder.query<OrderType, { orderId: string }>({
      query: ({ orderId }) => {
        return {
          url: `orders/${orderId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: OrderType) => {
        response.items =
          response.items === null ? ([] as ItemOrderDetail[]) : response.items;
        return response;
      },
      providesTags: ['OrderInfo'],
    }),

    createOrder: builder.mutation<OrderType, CreateOrderRequestType>({
      query: (data) => {
        return {
          url: `create-order`,
          method: 'POST',
          body: data,
        };
      },
    }),

    deleteOrder: builder.mutation<void, string>({
      query: (orderId) => {
        return {
          url: `orders/${orderId}`,
          method: 'DELETE',
        };
      },
    }),

    payOrder: builder.mutation<OrderType, PayOrderType>({
      query: (arg) => {
        const { order_id, data } = arg;

        return {
          url: `orders/${order_id}/payment`,
          method: 'POST',
          body: data,
        };
      },
    }),
    refundOrder: builder.mutation<ResponseOrderType, RefundOrderType>({
      query: (arg) => {
        const { order_id, data } = arg;

        return {
          url: `orders/${order_id}/refund`,
          method: 'POST',
          body: data,
        };
      },
    }),
    bonusOrder: builder.mutation<ResponseOrderType, RefundOrderType>({
      query: (arg) => {
        const { order_id, data } = arg;

        return {
          url: `orders/${order_id}/refund?event=bonus`,
          method: 'POST',
          body: data,
        };
      },
    }),
    verifyRefund: builder.mutation<
      void,
      {
        event_id: any;
        status: string;
        reply: string;
      }
    >({
      query: (arg) => {
        // const { event_id } = arg;

        return {
          url: `/verify-refund`,
          method: 'PUT',
          body: arg,
        };
      },
    }),
    // eslint-disable-next-line
    saveItemToOrder: builder.mutation<any, SaveItemToOrderType>({
      query: (arg) => {
        const { order_id, data } = arg;
        return {
          url: `orders/${order_id}/items`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['OrderInfo'],
    }),

    getListClass: builder.query<ClassesType, { orderId: string }>({
      query: ({ orderId }) => {
        return {
          url: `orders/${orderId}/list-class`,
          method: 'GET',
        };
      },
      transformResponse: (response: ClassesType) => {
        response.classes_added =
          response.classes_added === null
            ? ([] as Classes[])
            : response.classes_added;

        response.classes_not_add =
          response.classes_not_add === null
            ? ([] as Classes[])
            : response.classes_not_add;

        return response;
      },
    }),

    addStudentToClass: builder.mutation<string, AddStudentToClassType>({
      query: (arg) => {
        const { orderId, classId, class_type } = arg;
        return {
          url: `/orders/${orderId}/add-class`,
          method: 'POST',
          body: { class_id: classId, type: class_type },
        };
      },
    }),

    // eslint-disable-next-line
    updateOrderInfo: builder.mutation<any, UpdateOrderInfo>({
      query: (arg) => {
        const { orderId, data } = arg;
        return {
          url: `/orders/${orderId}`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    sendMailOrder: builder.mutation<any, SendMailOrder>({
      query: (arg) => {
        const { orderId, data } = arg;
        return {
          url: `/orders/${orderId}/notify-debt`,
          method: 'POST',
          body: data,
        };
      },
    }),

    deleteAdvisory: builder.mutation<number, DeleteAdvisory>({
      query: ({ id }) => {
        return {
          url: `/advisory/${id}`,
          method: 'DELETE',
        };
      },
    }),

    getListAdvisory: builder.query<AdvisoryGetList, string>({
      query: (keySearch) => {
        return {
          url: `list-advisory${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: AdvisoryGetList) => {
        response.list_advisories =
          response.list_advisories === null
            ? ([] as Advisory[])
            : response.list_advisories;
        if (response.list_advisories.length > 0) {
          response.list_advisories.map((item) => {
            item.date = item.date.includes('0001-01-01T00') ? '' : item.date;
          });
        }
        return response;
      },
    }),
    getAdvisoryDetail: builder.query<AdvisoryDetail, string>({
      query: (id) => {
        return {
          url: `advisory/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: AdvisoryDetail) => {
        response.date = response.date.includes('0001-01-01T00')
          ? ''
          : response.date;
        response.history =
          response.history !== null
            ? response.history
            : ([] as AdvisoryHistory[]);
        response.history.map(
          (item) =>
            (item.date = item.date.includes('0001-01-01T00') ? '' : item.date),
        );
        return response;
      },
    }),
    addAdvisoryHistory: builder.mutation<
      AddAdvisoryHistoryRes,
      { id: string; data: NewAdvisoryHistory }
    >({
      query: (arg) => {
        const { id, data } = arg;
        return {
          url: `/advisory/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetListOrdersQuery,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useDeleteAdvisoryMutation,
  useGetOrderInfoQuery,
  useLazyGetListClassQuery,
  useSaveItemToOrderMutation,
  usePayOrderMutation,
  useRefundOrderMutation,
  useBonusOrderMutation,
  useVerifyRefundMutation,
  useLazyGetOrderInfoQuery,
  useGetListItemQuery,
  useAdjustOrderMutation,
  useAddStudentToOrderMutation,
  useGetListClassQuery,
  useAddStudentToClassMutation,
  useGetListAdvisoryQuery,
  useUpdateOrderInfoMutation,
  useSendMailOrderMutation,
  useGetAdvisoryDetailQuery,
  useAddAdvisoryHistoryMutation,
} = orderApi;
