/*
CuongTM đã refactor thư viện này https://github.com/tylingsoft/markdown-it-latex
1. Chuyển nó sang TypeScript
2. Bỏ phụ thuộc asciimath-to-latex
3. Giờ chỉ hỗ trợ inline math `$...$` và block math
  ```math
   ...
  ```
*/
import type MarkdownIt from 'markdown-it';
import type { RenderRule } from 'markdown-it/lib/renderer';

declare const katex: any;

/*
Hàm này render block math
*/
const mathBlock = (code: string): string => {
  let tex = '';
  code.split(/(?:\n\s*){2,}/).forEach((line) => {
    // consecutive new lines means a new formula
    try {
      tex += katex.renderToString(line.trim(), { displayMode: true });
    } catch (err) {
      tex += `<pre>${err instanceof Error ? err.message : String(err)}</pre>`;
    }
  });
  return `<div>${tex}</div>`;
};

const KatexPlugin = (md: MarkdownIt): void => {
  // inline math
  const temp1: RenderRule = md.renderer.rules.text!.bind(
    md.renderer.rules,
  );
  md.renderer.rules.text = (tokens, idx, options, env, slf) => {
    const content = tokens[idx].content;
    const parts = content.split(/(\$[^\$]+\$)/);

    if (parts.length > 1) {
      let text_after_process = '';
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        if (part === '') {
          continue; // Bỏ qua phần rỗng
        }
        if (part.startsWith('$') && part.endsWith('$')) {
          const code = part.slice(1, -1);
          try {
            const result = katex.renderToString(code);
            text_after_process += result;
          } catch (err) {
            text_after_process += `<code>${err instanceof Error ? err.message : String(err)}</code>`;
          }
        } else {
          text_after_process += part;
        }
      }
      return text_after_process;
    }

    return temp1(tokens, idx, options, env, slf);
  };

  // fenced math block
  const temp2: RenderRule = md.renderer.rules.fence!.bind(md.renderer.rules);
  md.renderer.rules.fence = (tokens, idx, options, env, slf) => {
    const token = tokens[idx];
    const code = token.content.trim();
    if (token.info === 'math') {
      return mathBlock(code);
    }
    return temp2(tokens, idx, options, env, slf);
  };
};

export default KatexPlugin;
