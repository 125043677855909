import Modal from 'components/commons/Modal';
import { UsersType } from 'models/user.model';
import { Col, Input, InputNumber, Row, Typography, message } from 'antd';
import Search from 'components/commons/Search';
import { Moment } from 'moment';
import { ReactElement, useState } from 'react';
import Button from 'libraries/components/commons/Button';
import FindStudent from 'components/report/FindStudent';
import { useGetUserInfoQuery } from 'services/user.service';
import { selectUserInfo } from 'store/user.slice';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import ButtonGroup from 'antd/lib/button/button-group';
import styled from 'styled-components';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';
import Editor from 'libraries/editor/Editor';
import { useSendSalaryMutation } from 'services/report.service';
const Image = styled.div`
  margin-bottom: 12px;
  max-width: 220px;
  @media (max-width: 1250px) {
    max-width: 200px;
  }
`;
const ContainerStyle = styled.p`
  margin-top: -24px;
  font-weight: 600;
  position: absolute;
  top: 172%;
  left: 0;

  @media (max-width: 767px) {
    top: 134%;
    left: 4px;
  }
`;
interface PaymentModalProps {
  isManualPayModalVisible: boolean;
  handleCancel: () => void;
  firstDay: any;
  lastDay: any;
  refetch: any;
}
interface ManualPayContentProps {
  userId: any;
  firstDay: any;
  lastDay: any;
  handleClose: any;
  refetch: any;
  resetData: any;
}

const { Text } = Typography;
const { TextArea } = Input;

const ManualPayContent = ({
  userId,
  firstDay,
  lastDay,
  handleClose,
  refetch,
  resetData,
}: ManualPayContentProps): ReactElement => {
  const [sendSalary] = useSendSalaryMutation();
  const [numberMoney, setNumberMoney] = useState('');
  const [note, setNote] = useState('');
  const { isError, isLoading } = useGetUserInfoQuery(userId);
  const user = useSelector(selectUserInfo);

  const handlePay = () => {
    if (numberMoney == '') {
      message.error('Vui lòng nhập số tiền !');
      return;
    }

    sendSalary({
      salary: +numberMoney,
      user_id: userId,
      start_date: firstDay.toISOString(),
      end_date: lastDay.toISOString(),
      note: note,
    })
      .unwrap()
      .then(({ id }) => {
        message.success('Trả lương thành công');
        resetData();
        handleClose();
        refetch();
      })
      .catch((error) => message.error(error));
  };
  return (
    <div>
      <h2 style={{ fontSize: '18px' }}>Thông tin</h2>
      <Row>
        <Col sm={16}>
          <Row style={{ marginBottom: '20px' }}>
            <Col sm={8}>STK</Col>
            <Col sm={16}>{user.bank_account}</Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col sm={8}>Ngân hàng</Col>
            <Col sm={16}>{user.bank_name}</Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col sm={8}>Tên</Col>
            <Col sm={16}>{user.full_name}</Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col sm={8}>Trả lương</Col>
            <Col sm={16}>
              <div style={{ position: 'relative' }}>
                <InputNumber
                  min={0}
                  style={{ width: '10rem' }}
                  step={100000}
                  onPressEnter={(e) => {
                    e.preventDefault();
                    // onFinish();
                  }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  onChange={(value: any) => setNumberMoney(value)}
                  //   disabled={bonus?.some((item) => item.status === 'pending')}
                />

                <ContainerStyle>
                  {convertCurrencyToWords(+numberMoney)}
                </ContainerStyle>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col sm={8}>Ghi chú</Col>
            <Col sm={16}>
              <Editor height={200} onChange={(value) => console.log(value)} />
            </Col>
          </Row>
        </Col>
        <Col sm={8}>
          {user.qr_code !== '' && (
            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <Image>
                <img
                  src={`https://img.vietqr.io/image/${user.bank_code}-${
                    user.bank_account
                  }-print.jpg?accountName=${
                    user.full_name
                  }&amount=${numberMoney}&addInfo= ${
                    'Trả lương tháng ' + new Date().getMonth()
                  }`}
                  alt="QrCode"
                />
              </Image>
            </div>
          )}
        </Col>
      </Row>
      <Button.Primary
        style={{ marginRight: 16 }}
        icon={<UploadOutlined />}
        type="primary"
        onClick={handlePay}
      >
        Trả lương
      </Button.Primary>
    </div>
  );
};
const ManualPayModal = ({
  isManualPayModalVisible,
  handleCancel,
  lastDay,
  firstDay,
  refetch,
}: PaymentModalProps): ReactElement => {
  const [keyword, setKeyword] = useState('');
  const [tmpKeyword, setTmpKeyword] = useState('');
  const [isSearchDone, setIsSearchDone] = useState(false);
  const [hasResult, setHasResult] = useState(false);
  // const [createNew, setCreateNew] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as UsersType);

  const [isSelection, setIsSelection] = useState<any>(false);
  const resetData = () => {
    setKeyword('');
    setTmpKeyword('');
    setSelectedUser({} as UsersType);
    setIsSelection(false);
    setIsSearchDone(false);
  };
  const onSearch = () => {
    setIsSearchDone(true);
    setKeyword(tmpKeyword);
    setSelectedUser({} as UsersType);
    setHasResult(true);
  };
  const handleClose = () => {
    resetData();
    handleCancel();
  };

  // eslint-disable-next-line

  return (
    <Modal
      title={'Trả lương thủ công'}
      visible={isManualPayModalVisible}
      width={1000}
      onCancel={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[]}
    >
      <label style={{ marginBottom: '12px', display: 'inline-block' }}>
        Nhập email hoặc số điện thoại
      </label>
      <Search
        allowClear
        enterButton="Tìm kiếm"
        size="large"
        value={tmpKeyword}
        onChange={(e) => {
          setHasResult(false);
          setTmpKeyword(e.target.value);
        }}
        onSearch={onSearch}
      />
      {!isSelection && (
        <>
          {isSearchDone && (
            <FindStudent
              keyword={keyword}
              hasResult={hasResult}
              setSelectedUser={(value: UsersType) => setSelectedUser(value)}
            />
          )}

          {Object.keys(selectedUser)?.length != 0 && (
            <div style={{ textAlign: 'end' }}>
              <Button.Info
                onClick={() => {
                  // setUserId(selectedUser.id);
                  // setUserName(selectedUser.full_name);
                  //   handleClose();
                  setIsSelection(true);
                }}
              >
                Lựa chọn
              </Button.Info>
            </div>
          )}
        </>
      )}
      {isSelection && Object.keys(selectedUser)?.length != 0 && (
        <ManualPayContent
          userId={selectedUser.id}
          firstDay={firstDay}
          lastDay={lastDay}
          handleClose={handleClose}
          refetch={refetch}
          resetData={resetData}
        />
      )}
    </Modal>
  );
};

export default ManualPayModal;
