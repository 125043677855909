import { ReactElement, useEffect, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import ShowMarkdown from '../ShowMarkdown';
import Modal from '../../commons/Modal';
import styled from 'styled-components';
import "../assets/css/MarkdownGuide.css"

function convertContentHtml(text: string) {
  return { __html: text };
}

const Wrapper = styled(TableContainer)``;

const guideComponents = [
  {
    content: 
`<h2>Nhấn mạnh chữ</h2>
<p>**In đậm**<br/>
*In nghiêng*<br/>
++Gạch chân++<br/>
~~Gạch ngang~~<br/>
==Highlight==</p>
`,
    markdown:
`## Nhấn mạnh chữ\n**In đậm**\n*In nghiêng*
++Gạch chân++
~~Gạch ngang~~
==Highlight==`,
  },
  {
    content: 
`<h2>Nhãn</h2>
#[Information](yellow)<br/>
#[mobile](blue)<br/>
#[web](red)<br/>
#[custom color](#624E88)</p>
`,
    markdown:
`## Nhãn\n#[Information](yellow)\n #[mobile](blue)\n#[web](red)\n#[custom color](#624E88)`,
  },
  {
    content: 
`<h2>Căn lề</h2>
<p><b>::: left</b><br/>
Contents to align left<br/>
<b>:::</b></p>

<p><b>::: center</b><br/>
Contents to align center<br/>
<b>:::</b></p>

<p><b>::: right</b><br/>
Contents to align right<br/>
<b>:::</b></p>

<p><b>::: justify</b><br/>
Trong một buổi sáng mùa thu se lạnh, khi những tia nắng đầu tiên len lỏi qua từng kẽ lá, tôi dạo bước trên con đường nhỏ dẫn vào công viên gần nhà. Tiếng chim hót líu lo hòa cùng tiếng lá xào xạc tạo nên một bản giao hưởng tự nhiên, khiến lòng người cảm thấy thư thái và bình yên lạ thường<br/>
<b>:::</b></p>
`,
    markdown: `## Căn lề\n\n::: left\nContents to align left\n:::\n\n::: center\nContents to align center\n:::\n\n::: right\nContents to align right\n:::\n\n\n\n::: justify\nTrong một buổi sáng mùa thu se lạnh, khi những tia nắng đầu tiên len lỏi qua từng kẽ lá, tôi dạo bước trên con đường nhỏ dẫn vào công viên gần nhà. Tiếng chim hót líu lo hòa cùng tiếng lá xào xạc tạo nên một bản giao hưởng tự nhiên, khiến lòng người cảm thấy thư thái và bình yên lạ thường\n:::\n\n`,
  },
  {
    content: `<h2>Mở rộng/thu gọn</h2>
    <p>+++> Click me!<br/>
      Hidden text<br/>
      +++ Nested<br/>
      Inner hidden text<br/>
      +++<br/>
      +++></p>
    `,
    markdown: `## Mở rộng/thu gọn\n\n+++> Click me!\nHidden text\n+++ Nested\nInner hidden text\n+++\n+++>`,
  },
  {
    content: `<h2>Tree list</h2>
    <p>root<br/>
    +-- item1<br/>
    &nbsp;&nbsp;+-- sub-item1<br/>
    +-- item2</p>
    `,
    markdown: `## Tree list\n\nroot\n+-- item1\n+-- sub-item1\n+-- item2`,
  },
  {
    content: `<h2>UML</h2>
    <p>@startuml<br/>
    User --> (registers an account)<br/>
    @enduml</p>
    `,
    markdown: `## UML\n\@startuml\nUser --> (registers an account)\n@enduml`,
  },
  {
    content: `<h2>Kiểu thông báo</h2>
    <p>> [!note]<br/>
    > note</p>

    <p>> [!tip]<br/>
    > tip</p>

    <p>> [!important]<br/>
    > important</p>

    <p>> [!caution]<br/>
    > caution</p>

    <p>> [!warning]<br/>
    > warning</p>
    `,
    markdown: `## Kiểu thông báo\n\n> [!note]\n> note\n\n> [!tip]\n> tip\n\n> [!important]\n> important\n\n> [!caution]\n> caution\n\n> [!warning]\n> warning\n\n`,
  },
  {
    content: `<h2> Danh sách không thứ tự </h2>
    <p>* Danh sách được bắt đầu bằng các dấu sau \`+\` \`,\` \`-\` hoặc \`*\` kèm theo 1 dấu cách  <br/>
    + Thay đổi dấu sẽ tạo ra danh sách mới :<br/>
    &nbsp;&nbsp;- Tạo danh sách phụ bằng cách ấn phím Tab:<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;* Ac tristique libero volutpat at<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;+ Facilisis in pretium nisl aliquet<br/>
    &nbsp;&nbsp;&nbsp;&nbsp;- Nulla volutpat aliquam velit</p>`,
    markdown: `## Danh sách không theo thứ tự \n\n* Danh sách được bắt đầu bằng các ==dấu== sau \`+\`, \`-\`, or \`*\` kèm theo một dấu cách \n- Thay đổi dấu sẽ tạo ra danh sách mới :\n  - Tạo danh sách phụ bằng cách ấn phím Tab:\n    * Ac tristique libero volutpat at\n    + Facilisis in pretium nisl aliquet\n    - Nulla volutpat aliquam velit`,
  },
  {
    content: `<h2> Danh sách theo thứ tự</h2>
    <p>1. Lorem ipsum dolor sit amet</p>
    <p>2. Consectetur adipiscing elit</p>
    <p>3. Integer molestie lorem at massa</p>
    <p>1. Bạn có thể dùng số theo thứ tự...</p>
    <p>1. ...hoặc để tất cả là \`1.\`</p>`,
    markdown: `## Danh sách theo thứ tự\n\n1. Lorem ipsum dolor sit amet\n2. Consectetur adipiscing elit\n3. Integer molestie lorem at massa\n\n\n1. Bạn có thể dùng số theo thứ tự ...\n1. ... hoặc để tất cả là \`1.\``,
  },
  {
    content: `<h2>Code</h2>
    <p>Inline \`code\`</p>
    <strong>Block code "fences"</strong><br/>
    <p>
    \`\`\`<br/>
    Sample text here...<br/>
    \`\`\`
    </p>
    <strong>Syntax highlighting</strong>
    <p>\`\`\`js<br/>
    var foo = function (bar) {<br/>
    &nbsp;&nbsp;return bar++;<br/>
    };<br/>
    console.log(foo(5));<br/>
    \`\`\`</p>
    <strong>Give it a name </strong>
    <p>\`\`\`js:hello.js<br/>
    console.log("hello man");<br/>
    \`\`\`</p>
    `,
    markdown: `## Code\n\nInline \`code\`\n**Block code "fences"**\n\`\`\`\nSample text here...\n\`\`\`\n\n**Syntax highlighting**\n\`\`\` js\nvar foo = function (bar) {\n  return bar++;\n};\n\nconsole.log(foo(5));\n\`\`\`\n**Give it a name**\n\`\`\`js:hello.js\n console.log("hello man")\n\`\`\``,
  },
  {
    content: `
<h2>Math</h2>
<b>Inline Math</b><br/>
<span style="font-family: monospace">$a^2 + b^2 = c^2$</span><br/>
<br/>
<b>Block Math</b><br/>
<p style="font-family: monospace">
\`\`\`math<br />
sum(i=1)^n i^3=((n(n+1))/2)^2 <br />
\`\`\`
</p>
  </li>
</ol>
    `,
    markdown: `
## Math
**Inline Math**
$a^2 + b^2 = c^2$

**Block Math**
\`\`\`math
sum(i=1)^n i^3=((n(n+1))/2)^2
\`\`\`
`,
  },
  {
    content: `<h2>Blockquotes</h2>
    <div> Blockquotes có thể được lồng với nhau...</div>
    <div>>> ...Bằng cách dùng thêm dấu lớn hơn bên cạnh nhau...</div>
    <div>> > > ...hoặc chèn thêm khoảng cách giữa bọn nó .</div>`,
    markdown: `## Blockquotes\n\nBlockquotes có thể được lồng với nhau...\n>> ...Bằng cách dùng thêm dấu lớn hơn bên cạnh nhau...\n> > > ...hoặc chèn thêm khoảng cách giữa bọn nó .`,
  },
  {
    content: `<h2>Links</h2>
    <p>[Tên link](http://dev.nodeca.com)</p>
    <p>[Link có tiêu đề](http://nodeca.github.io/pica/demo/ "Hover vào để xem tiêu đề ảnh")</p>
    <p>Tự động convert link https://github.com/nodeca/pica</p>`,
    markdown: `## Links\n\n[Tên link](http://dev.nodeca.com)\n[Link có tiêu đề](http://nodeca.github.io/pica/demo/ "Hover vào để xem tiêu đề ảnh")\n Tự động convert link https://github.com/nodeca/pica`,
  },
  {
    content: `<h2>Ảnh</h2>
    <strong> Đặt kích thước cho ảnh </strong><br/>
    <span> Bằng cú pháp </span> <span><code> =dàixrộng</code></span>
    <br/>
    <p>
    Nếu bạn chỉ điền mỗi chiều dài thì chiều cao sẽ sóng theo tỉ lệ 
    </p>
    <p>![Minion](https://octodex.github.com/images/minion.png =150x)</p>
    <p>![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg = "The Stormtroopocat" =200x)</p>
    <br />
    <strong>Căn phải</strong>
    <p>&lt;p align="right"&gt;
    <br/>
    &lt;img src="https://octodex.github.com/images/stormtroopocat.jpg"/&gt;
    &lt;/p&gt;</p>`,
    markdown: `## Ảnh\n\n**Đặt kích thước cho ảnh**\n\nBằng cú pháp \`=dàixrộng\`\n\n Nếu bạn chỉ điền mỗi chiều dài thì chiều cao sẽ sóng theo tỉ lệ \n\n ![Minion](https://octodex.github.com/images/minion.png =150x)\n![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg "The Stormtroopocat" =150x)\n**Căn phải**
    <div align='right'>
    <img style="margin:0;width:150px;height:auto;" src="https://octodex.github.com/images/stormtroopocat.jpg">
    <div/>
    `,
  },
  {
    content:
`<h2>Tables</h2>
<div style="font-family: monospace">
| Col 1 | Col 2 |<br/>
| ----- | ----- |<br/>
| Text &nbsp;| Text &nbsp;|<br/>
| Text &nbsp;| Text &nbsp;|<br/>
</div>
<br />
<h3>Căn chỉnh</h3>
<div style="font-family: monospace">
| Option | Description |<br/>
| Left &nbsp; | Center &nbsp;&nbsp;&nbsp;&nbsp; |<br/>
</div>`,
    markdown: `
## Tables
| Col 1 | Col 2 |
| ----- | ----- |
| Text | Text |
| Text | Text |

**Căn chỉnh**

| Option | Description |
| -----: | :---------: |
| Left | Center |
  `,
  },
  {
    content: 
`<h2>Memaid </h2>
<a href="https://mermaid.js.org/syntax/flowchart.html">Click để xem toàn bộ cú pháp của Mermaid </a>
<p style="font-family: monospace">
~~~mermaid <br/>
graph TD <br/>
  A[Christmas] -->|Get money| B(Go shopping) <br/>
  B --> C{Let me think} <br/>
  C -->|One| D[Laptop] <br/>
  C -->|Two| E[iPhone] <br/>
  C -->|Three| F[Car] <br/>
 ~~~
 </p>
    `,
    markdown: 
`## Mermaid\n[Click để xem toàn bộ cú pháp của Mermaid ](https://mermaid.js.org/syntax/flowchart.html)
\n\`\`\`mermaid
graph TD
    A[Christmas] -->|Get money| B(Go shopping)
    B --> C{Let me think}
    C -->|One| D[Laptop]
    C -->|Two| E[iPhone]
    C -->|Three| F[Car]
\`\`\``,
  },
  {
    content: 
`<h2>Sử dụng html</h2>
<p>Căn chỉnh ảnh</p>
<code>
&lt;div align="center"&gt;<br/>
![Minion](https://octodex.github.com/images/minion.png =150x)
<br/>
&lt;/div&gt;
</code>
</li>
<li>
<p>Tạo bảng bằng html giúp bạn có thêm nhiều tính năng hơn như block code </p>
<code>
&lt;table&gt;<br/>
&lt;tr&gt;<br/>
&lt;td&gt;
==Java==
&lt;/td&gt;<br/>
&lt;td&gt;<br/>
\`\`\`java
<br/>
public class Person {

}<br/>
\`\`\` <br/>
&lt;/td&gt;<br/>
&lt;/tr&gt;<br/>
&lt;tr&gt;<br/>
&lt;td&gt;Golang&lt;/td&gt;<br/>
&lt;td&gt;<br/>

\`\`\`go<br/>
package main<br/>

import &quot;fmt&quot;<br/>

func main() {
fmt.Println(&quot;Hello, 世界&quot;)
}<br/>
\`\`\`<br/>
&lt;/td&gt;<br/>
&lt;/tr&gt;<br/>
&lt;/table&gt;
</code>
</li>
</ol>`,
    markdown: `## Sử dụng html\n\n
<ol>
   <li>
   <p>
   \n\n
   Bạn có thể sử dụng html trong markdown để ==căn chỉnh==, hoặc cho thêm màu sắc cho chữ, tăng kích cỡ chữ
   </p>

   \n\n   
  <div align="center">
  \n\n
  ![Minion](https://octodex.github.com/images/minion.png =150x)\n\n
  </div>
   </li>
   <li>
   \n\n
   Tạo bảng bằng html giúp bạn có thêm nhiều tính năng hơn như \`blockcode\`
   <table>
   <tr>
      <td>
         
==Java==
</td>
 <td>

 \`\`\`java
 public class Person {
 
 }
 \`\`\`         
</td>
   </tr>
   <tr>
      <td>Golang</td>
<td>

\`\`\`go
package main

import "fmt"

func main() {
	fmt.Println("Hello, 世界")
}
\`\`\`
   </td>
   </tr>
</table>
</li>
</ol>
`,
  },
  {
    content:
      `<h2>Typographic</h2>
 (c) (C) (r) (R) (tm) (TM) (p) (P) +-
`,
    markdown:
      `## Typographic
(c) (C) (r) (R) (tm) (TM) (p) (P) +-`,
  }
];

const columns: ColumnsType<{ content: string; markdown: string }> = [
  {
    key: 'content',
    dataIndex: 'content',
    title: 'Component',
    render: (content) => (
      <div dangerouslySetInnerHTML={convertContentHtml(content)}></div>
    ),
  },
  {
    key: 'markdown',
    dataIndex: 'markdown',
    title: 'Markdown',
    render: (markdown) => <ShowMarkdown markdown={markdown} />,
  },
];

const isSeenTooltip: boolean = JSON.parse(
  window.localStorage.getItem('isSeenTooltip') ?? 'false',
);

// eslint-disable-next-line
const MarkdownGuide = (): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(!isSeenTooltip);

  useEffect(() => {
    if (isTooltipVisible) {
      window.localStorage.setItem('isSeenTooltip', JSON.stringify(true));
      const timeoutId = setTimeout(() => {
        setIsTooltipVisible(false);
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return (
    <Tooltip
      title={
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsModalVisible(true);
            setIsTooltipVisible(false);
          }}
        >
          Biểu đồ mermaid mới được cập nhật, click để xem hướng dẫn !
        </div>
      }
      open={isTooltipVisible}
      zIndex={100}
    >
      <QuestionCircleOutlined
        className="button button-type-link"
        title="Markdown Guide"
        onClick={() => setIsModalVisible(true)}
      />
      {isModalVisible && (
        <Modal
          title="Markdown Guide"
          className="markdown-guide-modal"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          keyboard={false}
          width={980}
          footer={[]}
        >
          <Wrapper>
            <Table<{ content: string; markdown: string }>
              className="markdown-guide-table"
              columns={columns}
              dataSource={guideComponents}
              pagination={false}
              rowKey="content"
              bordered
              size="middle"
            />
          </Wrapper>
        </Modal>
      )}
    </Tooltip>
  );
};

MarkdownGuide.align = 'left';
MarkdownGuide.pluginName = 'markdown-guide';

export default MarkdownGuide;
