import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { Table } from 'antd';

import { PayOrderReport } from 'models/report.model';

import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { formatDate } from 'utils/formatDate';
import { formatMoney } from 'libraries/utils/changeFormatData';
import { v4 } from 'uuid';

import { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectOrder } from 'store/order.slice';
import { useLocation, useParams } from 'react-router';
import FormRefundOrderModalPay from '../../../orders/detail/modals/FormRefundOrderModalPay';
interface PropsType {
  reports: PayOrderReport[];
  refetch?: any;
}

type TableData = PayOrderReport & { key: React.Key };

const OrderPayment = ({ reports, refetch }: PropsType): ReactElement => {
  const { orderId } = useParams<{ orderId: string }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get('event_id');
  const [isFormRefundModalVisible, setIsFormRefundModalVisible] =
    useState(false);
  const [isCheckReply, setIsCheckReply] = useState(false);
  const [dataModal, setDataModal] = useState();
  const orderData = useSelector(selectOrder);

  const handleCanelFormRefund = (): void => {
    setIsFormRefundModalVisible(false);
  };
  const showFormRefundModal = (data: any): void => {
    setDataModal(data);
    setIsFormRefundModalVisible(true);
  };
  useEffect(() => {
    if (eventId && orderData.history && isCheckReply == false) {
      const data2: any = orderData.history.filter(
        (item: any) => item.id == eventId,
      );
      if (data2.length != 0) {
        setDataModal(data2[0]);
        setIsFormRefundModalVisible(true);
      }
    }
  }, [eventId, orderData.history, isCheckReply]); // Đã xóa 'status' khỏi danh sách phụ thuộc

  const tableData: TableData[] = reports.map((item) => ({
    ...item,
    key: v4(),
  }));

  const columns: ColumnsType<TableData> = [
    {
      key: 'order_id',
      width: '10%',
      title: 'Mã đơn hàng',
      dataIndex: 'order_id',
      render: (order_id) => (
        <RouterLink
          to={generatePath(route.order.detail, { orderId: order_id })}
        >
          {order_id}
        </RouterLink>
      ),
    },

    {
      key: 'datetime',
      width: '10%',
      title: 'Ngày giao dịch',
      dataIndex: 'datetime',
      render: (datetime) => (datetime !== '' ? formatDate(datetime) : ''),
    },
    {
      key: 'receiver_id',
      width: '10%',
      title: 'Học viên',
      dataIndex: 'receiver_id',
      render: (_text, item) => (
        <RouterLink
          to={generatePath(route.user.info, { userId: item.receiver_id })}
        >
          {item.receiver_full_name}
        </RouterLink>
      ),
    },
    {
      key: 'amount',
      width: '10%',
      title: 'Số tiền',
      dataIndex: 'amount',
      render: (amount) => (amount !== 0 ? `- ${formatMoney(amount)}` : 0),
      className: 'text-right',
    },
    {
      key: 'note',
      width: '30%',
      title: 'Ghi chú',
      dataIndex: 'note',
      render: (note) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {note}
        </div>
      ),
    },
    {
      key: 'user_id',
      width: '10%',
      title: 'Người chi tiền',
      dataIndex: 'user_id',
      render: (_text, item) => (
        <RouterLink
          to={generatePath(route.user.info, { userId: item.user_id })}
        >
          {item.full_name}
        </RouterLink>
      ),
    },

    {
      key: 'status',
      width: '10%',
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status, data, item) => {
        let statusText = '';
        let statusColor = '';
        switch (status) {
          case 'approve':
            statusText = 'Đã duyệt';
            statusColor = '#008d4c';
            break;
          case 'reject':
            statusText = 'Từ chối';
            statusColor = 'red';
            break;
          case 'pending':
            statusText = 'Chưa duyệt';
            statusColor = '#f39c12';
            break;
          default:
            statusText = status;
        }

        return (
          <a
            onClick={() => showFormRefundModal(data)}
            style={{
              backgroundColor: statusColor,
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              color: 'white',
              display: 'flex',
              fontWeight: '600',
              justifyContent: 'center',
              paddingTop: '3px',
              paddingBottom: '3px',
            }}
          >
            {statusText}
          </a>
        );
      },
    },
  ];

  return (
    <TableContainer>
      {dataModal ? (
        <Table<TableData>
          columns={columns}
          dataSource={tableData}
          pagination={{ showSizeChanger: false }}
          bordered
        />
      ) : (
        <Table<TableData>
          columns={columns}
          dataSource={tableData}
          pagination={{ showSizeChanger: false }}
          bordered
        />
      )}
      {dataModal && (
        <FormRefundOrderModalPay
          isBackMoneyModalVisible={isFormRefundModalVisible}
          handleCancel={handleCanelFormRefund}
          dataModal={dataModal}
          setIsCheckReply={setIsCheckReply}
          refetch={refetch}
        />
      )}
    </TableContainer>
  );
};

export default OrderPayment;
