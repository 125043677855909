import { ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { Input, Pagination, Table, Select } from 'antd';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';

import {
  selectCurrentPage,
  selectItems,
  selectOrdersList,
  selectTotalPage,
  setCurrentPage,
  setKeySearch,
} from 'store/order.slice';
import { OrdersListType } from 'models/order.model';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import {
  orderPaymentTypeValues,
  orderStatusValues,
} from 'libraries/utils/globalVariable';
import Span from 'libraries/components/commons/Span';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import TablleContainer from 'components/table/TableContainer';

interface KeySearchType {
  orderId: string;
  keyName: string;
  keyEmail: string;
  keyPhone: string;
  paymentType: '' | 'bank' | 'cash' | 'ewwallet' | 'other';
  keyStatus: '' | 'paid' | 'partial' | 'created' | 'refund' | 'cancel';
  itemId: string;
}

const { Option } = Select;

const ListOrdersContent = (): ReactElement => {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrdersList);
  const total_page = useSelector(selectTotalPage);
  const currentPage = useSelector(selectCurrentPage);

  const items = useSelector(selectItems);

  const [state, setState] = useState<KeySearchType>({
    orderId: '',
    keyName: '',
    keyEmail: '',
    keyPhone: '',
    paymentType: '',
    keyStatus: '',
    itemId: '',
  });

  const {
    orderId,
    keyName,
    keyEmail,
    keyPhone,
    paymentType,
    keyStatus,
    itemId,
  } = state;

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleOnchangePage = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(
      setKeySearch(
        `page=${page}&id=${orderId}&fullname=${keyName}&email=${keyEmail}&phone=${keyPhone}&item=${itemId}&status=${keyStatus}&type=${paymentType}&order=`,
      ),
    );
  };

  const columns: ColumnsType<OrdersListType> = [
    {
      width: '5%',
      title: 'Mã đơn',
      children: [
        {
          key: 'order_id',
          dataIndex: 'order_id',
          title: (
            <Input
              value={orderId}
              onChange={(e) => handleSetState('orderId', e.target.value)}
            />
          ),
          render: (_text, item) => (
            <RouterLink
              to={generatePath(route.order.detail, {
                orderId: item.order_id,
              })}
            >
              {item.order_id}
            </RouterLink>
          ),
        },
      ],
    },
    {
      width: '10%',
      title: 'Họ tên',
      children: [
        {
          key: 'full_name',
          dataIndex: 'full_name',
          title: (
            <Input
              value={keyName}
              onChange={(e) => handleSetState('keyName', e.target.value)}
            />
          ),
          render: (_text, item) => (
            <RouterLink
              to={generatePath(route.order.detail, { orderId: item.order_id })}
            >
              {item.full_name}
            </RouterLink>
          ),
        },
      ],
    },

    {
      width: '10%',
      title: 'Email',
      children: [
        {
          key: 'email',
          dataIndex: 'email',
          title: (
            <Input
              value={keyEmail}
              onChange={(e) => handleSetState('keyEmail', e.target.value)}
            />
          ),
          render: (email) => (
            <a href={`mailto:${email}`}>
              <span>{email}</span>
            </a>
          ),
        },
      ],
    },
    {
      width: '5%',
      title: 'Điện thoại',
      children: [
        {
          key: 'phone',
          dataIndex: 'phone',
          title: (
            <Input
              value={keyPhone}
              onChange={(e) => handleSetState('keyPhone', e.target.value)}
            />
          ),
          render: (phone) => (
            <a href={`tel:${phone}`}>
              <span>{phone}</span>
            </a>
          ),
        },
      ],
    },
    {
      width: '8%',
      title: 'Ngày tạo đơn',
      children: [
        {
          key: 'order_at',
          dataIndex: 'order_at',
          render: (order_at) =>
            order_at !== '' ? formatDate(order_at, 'dd-MM-yyyy') : '',
        },
      ],
    },
    {
      title: 'Hình thức',
      children: [
        {
          key: 'payment_type',
          dataIndex: 'payment_type',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={paymentType}
              onChange={(value) => handleSetState('paymentType', value)}
            >
              <Option value="">Tất cả</Option>
              {orderPaymentTypeValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (payment_type) => {
            if (payment_type !== '') {
              for (let i = 0; i < orderPaymentTypeValues.length; i++) {
                const item = orderPaymentTypeValues[i];
                if (item.value === payment_type) {
                  return <Span className={item.className}>{item.label}</Span>;
                }
              }
            }
            return '';
          },
        },
      ],
    },
    {
      title: 'Trạng thái thanh toán',
      children: [
        {
          key: 'status',
          dataIndex: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={keyStatus}
              onChange={(value) => handleSetState('keyStatus', value)}
            >
              <Option value="">Tất cả</Option>
              {orderStatusValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (status) => {
            if (status !== '') {
              for (let i = 0; i < orderStatusValues.length; i++) {
                const item = orderStatusValues[i];
                if (item.value === status) {
                  return (
                    <Span
                      style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                      }}
                      className={item.className}
                    >
                      {item.label}
                    </Span>
                  );
                }
              }
            }
            return '';
          },
        },
      ],
    },
    {
      width: '10%',
      title: 'Items',
      children: [
        {
          key: 'items',
          dataIndex: 'item_names',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              style={{ width: 100 }}
              showSearch
              value={itemId}
              optionFilterProp="children"
              onChange={(value) => {
                handleSetState('itemId', value);
              }}
            >
              <Option value="">Tất cả</Option>
              {items.map((item) => (
                <Option value={item.item_id} key={item.item_id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ),
          render: (item_names) => {
            return item_names.map((item: string, index: number) => (
              <p key={index} style={{ marginBottom: '0' }}>
                - {item}
              </p>
            ));
          },
        },
      ],
    },
  ];

  const resetKeySearch = () => {
    setState({
      orderId: '',
      keyName: '',
      keyEmail: '',
      keyPhone: '',
      paymentType: '',
      keyStatus: '',
      itemId: '',
    });
  };

  useEffect(() => {
    const {
      orderId,
      keyName,
      keyEmail,
      keyPhone,
      paymentType,
      keyStatus,
      itemId,
    } = state;
    dispatch(setCurrentPage(1));
    dispatch(
      setKeySearch(
        `page=${currentPage}&id=${orderId}&fullname=${keyName}&email=${keyEmail}&phone=${keyPhone}&item=${itemId}&status=${keyStatus}&type=${paymentType}&order=`,
      ),
    );
  }, [state]);

  return (
    <>
      <Breadcrumb text="Danh sách đơn hàng" />
      <LinksContainer>
        <Link.Primary to={generatePath(route.order.create)}>
          <PlusOutlined /> Tạo đơn hàng
        </Link.Primary>
        <Link.Info to="#" onClick={resetKeySearch}>
          <ReloadOutlined /> Refresh
        </Link.Info>
      </LinksContainer>

      <BorderBoxContent>
        <TablleContainer>
          <Table<OrdersListType>
            columns={columns}
            dataSource={orders}
            pagination={false}
            rowKey="order_id"
            bordered
          />
          <Pagination
            current={currentPage}
            pageSize={10}
            showSizeChanger={false}
            defaultCurrent={currentPage}
            total={total_page * 10}
            style={{ marginTop: '20px' }}
            onChange={handleOnchangePage}
          />
        </TablleContainer>
      </BorderBoxContent>
    </>
  );
};

export default ListOrdersContent;
