import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Classes,
  Track,
  TrackClass,
  TrackList,
  TrackMaster,
} from 'models/track.model';
import { baseQuery } from './base.service';

interface CreateTrack {
  master_id: string;
  name: string;
  thumbnail: string;
  status: string;
  description: string;
  class_ids: string[];
  price: number;
}

export const trackApi = createApi({
  reducerPath: 'trackApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getTracksList: builder.query<TrackList[], string>({
      query: (keySearch) => {
        return {
          url: `list-track?${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: TrackList[]) => {
        response = response ?? ([] as TrackList[]);

        response.length > 0 &&
          response.map((item) => {
            item.create_date = item.create_date.includes('0001-01-01T00')
              ? ''
              : item.create_date;

            item.price = item.price ?? 0;
            item.classes = item.classes ?? ([] as TrackClass[]);
          });

        return response;
      },
    }),

    getTrackInfo: builder.query<Track, string>({
      query: (track_id) => {
        return {
          url: `track/${track_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: Track) => {
        response = response ?? ({} as Track);
        if (response) {
          response.create_date = response.create_date.includes('0001-01-01T00')
            ? ''
            : response.create_date;

          response.classes = response.classes ?? ([] as TrackClass[]);

          response.price = response.price ? response.price : 0;

          response.class_ids = response.classes.reduce((arr, item) => {
            arr.push(item.id);
            return arr;
          }, [] as string[]);
        }

        return response;
      },
    }),

    deleteTrack: builder.mutation<void, { trackId: string }>({
      query: ({ trackId }) => {
        return {
          url: `track/${trackId}`,
          method: 'DELETE',
        };
      },
    }),

    getClassesList: builder.query<Classes[], void>({
      query: () => {
        return {
          url: `list-classes`,
          method: 'GET',
        };
      },
      transformResponse: (response: Classes[]) => {
        response = response === null ? ([] as Classes[]) : response;
        if (response.length > 0) {
          response.map((item) => {
            item.price = item.price ? item.price : 0;
          });
        }
        return response;
      },
    }),

    getTrackMaster: builder.query<TrackMaster[], void>({
      query: () => {
        return {
          url: `list-track-master`,
          method: 'GET',
        };
      },
    }),

    createNewTrack: builder.mutation<void, { data: CreateTrack }>({
      query: (data) => {
        return {
          url: `create-track`,
          method: 'POST',
          body: data,
        };
      },
    }),

    updateTrack: builder.mutation<void, { data: CreateTrack; trackId: string }>(
      {
        query: (arg) => {
          const { data, trackId } = arg;
          return {
            url: `track/${trackId}`,
            method: 'PUT',
            body: data,
          };
        },
      },
    ),
  }),
});

export const {
  useGetTracksListQuery,
  useGetTrackInfoQuery,
  useGetClassesListQuery,
  useGetTrackMasterQuery,
  useCreateNewTrackMutation,
  useUpdateTrackMutation,
  useDeleteTrackMutation,
} = trackApi;
