import { ReactElement } from 'react';

import LinksContainer from 'libraries/components/commons/LinksContainer';
import { generatePath, Redirect } from 'react-router';
import { LeftOutlined } from '@ant-design/icons';
import Link from 'libraries/components/commons/Link';
import { route } from 'routers/routeNames';
import { useSelector } from 'react-redux';
import { selectOrder } from 'store/order.slice';
import Breadcrumb from 'components/commons/Breadcrumb';

import CreateOrderForm from './CreateOrderForm';

const CreateOrderContent = (): ReactElement => {
  const { id: orderId } = useSelector(selectOrder);

  if (orderId) {
    return <Redirect to={generatePath(route.order.detail, { orderId })} />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.order.list)}
        secondaryText="Danh sách đơn hàng"
        text="Tạo đơn hàng mới"
      />
      <LinksContainer>
        <Link.Default to={generatePath(route.order.list)}>
          <LeftOutlined /> Quay lại
        </Link.Default>
      </LinksContainer>

      <CreateOrderForm />
    </>
  );
};
export default CreateOrderContent;
