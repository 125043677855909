import { Button, DatePicker } from 'antd';
import { useDateRange } from 'libraries/hooks/date';
import moment, { Moment } from 'moment';
import { ReactElement, useState } from 'react';
import Menu from './Menu';

interface PropsType {
  setVisible: (value: boolean) => void;
  firstDay: Moment;
  lastDay: Moment;
  setFirstDay: (value: Moment) => void;
  setLastDay: (value: Moment) => void;
  onUpdateDate?: (firstDay: Moment, lastDay: Moment) => void;
}

const { RangePicker } = DatePicker;

const DropdownMenu = ({
  setVisible,
  firstDay,
  lastDay,
  setFirstDay,
  setLastDay,
  onUpdateDate,
}: PropsType): ReactElement => {
  const [showPicker, setShowPicker] = useState(false);
  const {
    startOfDay,
    endOfDay,
    startOfMonth,
    endOfMonth,
    subtractDay,
    subtractMonth,
  } = useDateRange();

  const [customFirstDay, setCustomFirstDay] = useState(firstDay);
  const [customLastDay, setCustomLastDay] = useState(lastDay);

  const handleChooseDay = (firstDay: Moment, lastDay: Moment): void => {
    setFirstDay(startOfDay(firstDay));
    setLastDay(endOfDay(lastDay));
    setVisible(false);
    setShowPicker(false);
  };

  const handlePickerRange = (dates: any) => {
    if (dates !== null) {
      setCustomFirstDay(startOfDay(dates[0]));
      setCustomLastDay(endOfDay(dates[1]));
    }
  };

  const handleUpdateDate = () => {
    setFirstDay(customFirstDay);
    setLastDay(customLastDay);
    setShowPicker(false);
    setVisible(false);
  };

  return (
    <Menu.Main>
      <Menu.Item
        key="today"
        onClick={() => {
          const today = moment();
          handleChooseDay(today, today);
        }}
      >
        Hôm nay
      </Menu.Item>
      <Menu.Item
        key="yesterday"
        onClick={() => {
          const today = moment();
          const yesterday = subtractDay(today, 1);
          handleChooseDay(yesterday, yesterday);
        }}
      >
        Hôm qua
      </Menu.Item>
      <Menu.Item
        key="7days"
        onClick={() => {
          const today = moment();
          const yesterday = subtractDay(today, 1);
          const sevenDaysAgo = subtractDay(today, 7);
          handleChooseDay(startOfDay(sevenDaysAgo), endOfDay(yesterday));
          setVisible(false);
        }}
      >
        7 ngày trước
      </Menu.Item>
      <Menu.Item
        key="30days"
        onClick={() => {
          const today = moment();
          const yesterday = subtractDay(today, 1);
          const aMonthAgo = subtractDay(today, 30);
          handleChooseDay(startOfDay(aMonthAgo), endOfDay(yesterday));
        }}
      >
        30 ngày trước
      </Menu.Item>
      <Menu.Item
        key="thismonth"
        onClick={() => {
          const today = moment();

          handleChooseDay(startOfMonth(today), endOfMonth(today));
        }}
      >
        Tháng này
      </Menu.Item>
      <Menu.Item
        key="lastmonth"
        onClick={() => {
          const today = moment();
          const lastmonth = subtractMonth(today, 1);

          handleChooseDay(startOfMonth(lastmonth), endOfMonth(lastmonth));
        }}
      >
        Tháng trước
      </Menu.Item>
      <Menu.Item key="custom" onClick={() => setShowPicker(true)}>
        Custom Range
      </Menu.Item>
      {showPicker && (
        <Menu.Item key="rangepicker" className="range-picker">
          <RangePicker
            style={{ width: '80%' }}
            format="DD/MM/YYYY"
            defaultValue={[customFirstDay, customLastDay]}
            onChange={handlePickerRange}
            inputReadOnly={true}
          />

          <Button
            onClick={handleUpdateDate}
            style={{
              width: '20%',
              padding: '0',
              backgroundColor: '#3c8dbc',
              color: '#fff',
              fontWeight: 600,
              borderRadius: '5px',
            }}
          >
            Update
          </Button>
        </Menu.Item>
      )}
    </Menu.Main>
  );
};

export default DropdownMenu;
