import { ReactElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsSidebarOpen } from 'store/general.slice';
import { hideSidebar } from 'store/general.slice';
import SimpleBar from 'simplebar-react';
import Sidebar from 'layout/Sidebar';
import Menu from 'layout/Menu';

const SlideableSidebar = (): ReactElement => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  useEffect(() => {
    return () => {
      dispatch(hideSidebar());
    };
  }, []);

  return (
    <Sidebar
      style={{
        width: 256,
        transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
      }}
    >
      <SimpleBar style={{ height: '100%' }}>
        <Menu onSelect={() => dispatch(hideSidebar())} />
      </SimpleBar>
    </Sidebar>
  );
};

export default SlideableSidebar;
