import {
  ReactNode,
  createContext,
  ReactElement,
  useState,
  useRef,
  useContext,
  useEffect,
} from 'react';
import { Form, Input, message, Select, Tooltip } from 'antd';
import type { InputRef } from 'antd';
import { Student } from 'models/user.model';
import type { FormInstance } from 'antd/lib/form';
import { usePatchNoteEditMutation } from 'services/classes.service';
import type { BaseSelectRef } from 'rc-select';
import { useSelector } from 'react-redux';
import { selectClass } from 'store/classes.slice';

interface EditableRowProps {
  index: number;
}

interface IEditableCells {
  title: ReactNode;
  children: ReactNode;
  record: Student;
  editable: boolean;
  dataIndex: keyof Student;
  handleSave: (arg: Student) => void;
  classId: string;
}

const EditableContext = createContext<FormInstance<any> | null>(null);

export const EditableRow = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  children,
  record,
  editable,
  dataIndex,
  handleSave,
  classId,
  ...restProps
}: IEditableCells): ReactElement => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const selectRef = useRef<BaseSelectRef>(null);
  const [patchEditNote] = usePatchNoteEditMutation();
  const { type: classType } = useSelector(selectClass);

  const isClassTypeDisabled = ['video', 'live'].includes(classType);

  const form = useContext(EditableContext)!;
  const options = [
    {
      label: 'Trực tuyến',
      value: 'live',
    },
    {
      label: (
        <Tooltip
          title={
            isClassTypeDisabled
              ? 'Các lớp chỉ học online không thể thay đổi hình thức học'
              : ''
          }
          placement="topLeft"
        >
          <div style={{ width: '100%' }}>Onlab</div>
        </Tooltip>
      ),
      value: 'lab',
      disabled: isClassTypeDisabled,
    },
  ];

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
    if (editing && selectRef.current) {
      selectRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    const values = await form.validateFields();
    if (values[dataIndex] === record[dataIndex]) {
      toggleEdit();
      return;
    }
    patchEditNote({
      class_id: classId,
      student_id: record.id,
      note: values.note ?? record.note,
      type: values.type ?? record.type,
    })
      .unwrap()
      .then((res) => message.success(res))
      .catch((err) => message.error(err));
    toggleEdit();
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0, padding: 0 }} name={dataIndex}>
        {dataIndex === 'note' ? (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        ) : (
          <Select
            ref={selectRef}
            style={{ width: '100%' }}
            onSelect={save}
            options={options}
            onBlur={save}
            open={editing}
            defaultValue={classType}
            onClick={() => setEditing(true)}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
