import { LeftOutlined } from '@ant-design/icons';
import { Col, DatePicker, message, Row, Select, TimePicker } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import disabledSubmissionDate from 'libraries/utils/disabledSubmissionDate';
import {
  classBranchValues,
  classStudiedDayValues,
} from 'libraries/utils/globalVariable';
import moment from 'moment';
import { ReactElement, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetListCoursesQuery } from 'services/classes.service';
import {
  useCreateScheduleMutation,
  useGetTracksListQuery,
} from 'services/schedule.service';

const { Option } = Select;

const CreateScheduleContent = (): ReactElement => {
  const today = new Date();
  const history = useHistory();

  const start_date = moment(today, 'DD-MM-YYYY');

  const { data: courses } = useGetListCoursesQuery();
  const { data: tracks } = useGetTracksListQuery();

  const [type, setType] = useState('course');

  const [createSchedule] = useCreateScheduleMutation();

  const [form] = Form.useForm();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (values: any) => {
    createSchedule({
      ...values,
      start_date: values.start_date.utc(true).toISOString(),
      start_at: values.start_at.format('HH:mm'),
      end_at: values.end_at.format('HH:mm'),
      day_of_week:
        values.day_of_week && values.day_of_week.length > 0
          ? values.day_of_week.map((item: string) => Number(item))
          : ([] as number[]),
    })
      .unwrap()
      .then(() => {
        message.success('Tạo lịch mới thành công!');
        setTimeout(() => {
          history.push(generatePath(route.schedule.list));
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.schedule.list)}
        secondaryText="Lịch khai giảng"
        text="Tạo lịch"
      />
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={{
          start_date,
          start_at: moment('10:00', 'HH:mm'),
          end_at: moment('10:00', 'HH:mm'),
          item_type: 'course',
        }}
      >
        <LinksContainer>
          <Link.Default to={generatePath(route.schedule.list)}>
            <LeftOutlined /> Quay lại
          </Link.Default>
          <Button.Primary
            type="primary"
            htmlType="submit"
            style={{ height: '36px' }}
          >
            Tạo mới
          </Button.Primary>
        </LinksContainer>

        <Row>
          <Col xs={24} sm={18} md={16}>
            <BorderTopBoxContent>
              <Row gutter={[10, 10]}>
                <Col xs={12}>
                  <Form.Item
                    label={<FormLabel label="Khai giảng cho" />}
                    name="item_type"
                  >
                    <Select onChange={(value: string) => setType(value)}>
                      <Option value="course">Khoá học</Option>
                      <Option value="track">Lộ trình</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  {type === 'course' && courses && (
                    <Form.Item
                      label={<FormLabel label="Khoá học" />}
                      name="item_id"
                      rules={[
                        {
                          required: true,
                          message: 'Xin vui lòng chọn khoá học!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        placeholder="Danh sách khoá học"
                      >
                        {courses.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                  {type === 'track' && tracks && (
                    <Form.Item
                      label={<FormLabel label="Lộ trình" />}
                      name="item_id"
                      rules={[
                        {
                          required: true,
                          message: 'Xin vui lòng chọn lộ trình!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        placeholder="Danh sách lộ trình"
                      >
                        {tracks.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="start_date"
                    label={<FormLabel label="Ngày khai giảng" />}
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng chọn ngày khai giảng!',
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledSubmissionDate}
                      format="DD-MM-YYYY"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={<FormLabel label="Cơ sở" />}
                    name="branch_name"
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng chọn cơ sở!',
                      },
                    ]}
                  >
                    <Select placeholder="Chọn cơ sở">
                      {classBranchValues.map((item, index) => (
                        <Option value={item.value} key={index}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="day_of_week"
                    label={<FormLabel label="Ngày học trong tuần" />}
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng chọn ngày học!',
                      },
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Chọn ngày học"
                    >
                      {classStudiedDayValues.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="start_at"
                    label={<FormLabel label="Giờ bắt đầu" />}
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng chọn giờ bắt đầu!',
                      },
                    ]}
                  >
                    <TimePicker format="HH:mm" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="end_at"
                    label={<FormLabel label="Giờ kết thúc" />}
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng chọn giờ kết thúc!',
                      },
                    ]}
                  >
                    <TimePicker format="HH:mm" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateScheduleContent;
