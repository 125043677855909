import '@mdi/font/css/materialdesignicons.css';
import '../components/markdown/assets/css/atom-one-light.css';
import '../components/markdown/assets/css/index.css';
import '../components/markdown/assets/css/code.css';
import '../components/markdown/assets/css/expand_collapse.css';
import '../components/markdown/assets/css/images.css';
import '../components/markdown/assets/css/label.css';
import '../components/markdown/assets/css/table.css';
import '../components/markdown/assets/css/code-tabs.css';
import '../components/markdown/assets/css/tree.css';
import '../components/markdown/assets/css/y-custom-github-markdown.css';
// import 'github-markdown-css/github-markdown-light.css';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useThrottledCallback } from 'use-debounce';
import { EditorContext as EC } from './EditorContext';
import processMermaidCharts from './markdown-it-mermaid/index';

export const Preview = styled.div<{
  previewHeight?: number;
  showSplitScreen?: boolean;
}>`
  ${({ showSplitScreen }) =>
    showSplitScreen
      ? css`
          width: 50%;
          border-bottom-left-radius: 0px;
        `
      : css`
          width: 100%;
          border-bottom-left-radius: 4px;
        `}

  border-left: 1px solid #e3e3e3;
  min-height: 100%;
  height: ${({ previewHeight }) => previewHeight}px;
`;

const EditorPreview: React.FC<{
  className?: string;
  markdown?: string;
}> = ({ className }) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const { state, dispatch } = useContext(EC);
  const [processedHtml, setProcessedHtml] = useState('');

  const { currentActive, md, html } = state;

  const onMouseEnter = useCallback(() => {
    dispatch({ type: 'currentActiveChanged', payload: 'preview' });
  }, [dispatch]);

  useEffect(() => {
    const processHtml = async () => {
      const processed = await processMermaidCharts.processMermaidCharts(html);
      setProcessedHtml(processed);
    };
    processHtml();
  }, [html]);

  const onScroll = useThrottledCallback(() => {
    if (!previewRef || !previewRef.current) return;
    dispatch({
      type: 'scrollPositionChanged',
      payload: previewRef.current.scrollTop / previewRef.current.scrollHeight,
    });
  }, 100);

  useEffect(() => {
    if (!previewRef.current) return;
    if (currentActive === 'preview') return;
    previewRef.current.scrollTop = previewRef.current.scrollHeight * state.scrollPosition;
  }, [previewRef, currentActive, state.scrollPosition]);

  useEffect(() => {
    dispatch({ type: 'updateHtml', payload: md });
  }, [md, dispatch]);

  return (
    <Preview
      className={`markdown-body editor-preview ${className}`}
      ref={previewRef}
      previewHeight={state.editorHeight}
      showSplitScreen={state.splitScreen}
      onMouseEnter={onMouseEnter}
      onScroll={onScroll}
      dangerouslySetInnerHTML={{ __html: processedHtml }}
    ></Preview>
  );
};

export default React.memo(EditorPreview);