import { ReactElement } from 'react';
import useTitle from '../utils/useTitle';
import NotFound from '../components/commons/NotFound';

const NotFoundPage = (): ReactElement => {
  useTitle('404 Page Not Found');

  return <NotFound />;
};

export default NotFoundPage;
