import Modal from 'components/commons/Modal';
import { ColumnsType } from 'antd/lib/table';

import {
  orderItemValues,
  orderPaymentTypeValues,
} from 'libraries/utils/globalVariable';
import {
  Col,
  DatePicker,
  Row,
  Table,
  Typography,
  Select,
  InputNumber,
  Input,
  message,
} from 'antd';

import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ItemOrderDetail,
  selectOrder,
  selectTotalPrice,
} from 'store/order.slice';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import Button from 'libraries/components/commons/Button';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import { usePayOrderMutation } from 'services/order.service';
import { useParams } from 'react-router';
import { Moment } from 'moment';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';

const disabledSubmissionDate = (submissionValue: Moment): boolean => {
  if (!submissionValue) {
    return false;
  }
  return submissionValue.valueOf() > Date.now();
};

interface PaymentModalProps {
  isPaymentModalVisible: boolean;
  handleCancel: () => void;
}

const { Text } = Typography;
const { TextArea } = Input;

const PaymentModal = ({
  isPaymentModalVisible,
  handleCancel,
}: PaymentModalProps): ReactElement => {
  const [payOrder] = usePayOrderMutation();
  const dataOrder = useSelector(selectOrder);
  const totalPrice = useSelector(selectTotalPrice);
  const [isAdjust, setIsAdjust] = useState(false);
  const [numberMoney, setNumberMoney] = useState('');

  const { items: listItem, paid, debt, full_name, email, phone } = dataOrder;
  const listItemString = listItem
    ? listItem.map((item) => item.name).toString()
    : '';

  const { orderId } = useParams<{ orderId: string }>();

  const columns: ColumnsType<ItemOrderDetail> = [
    {
      title: 'Tên Item',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },

    {
      title: 'Loại lớp học',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      render: (item) => {
        for (let i = 0; i < orderItemValues.length; i++) {
          if (item === orderItemValues[i].value) {
            return <>{orderItemValues[i].label}</>;
          }
        }
      },
    },

    {
      title: 'Giá gốc',
      dataIndex: 'price',
      key: 'price',
      width: '10%',
      render: (_, item) => {
        let price: number;

        if (item.choose_type === '') {
          price = item.price[0].price;
          return <>{formatMoneyVnd(price)}</>;
        } else {
          const result = item.price.filter((i) => i.type === item.choose_type);
          price = result[0].price;
          return <>{formatMoneyVnd(price)}</>;
        }
      },
    },

    {
      title: 'Giảm giá',
      dataIndex: 'discount',
      key: 'discount',
      width: '10%',
      render: (item) => {
        return <>{formatMoneyVnd(item)}</>;
      },
    },

    {
      title: 'Thành tiền',
      dataIndex: '',
      key: '',
      width: '10%',
      render: (item) => {
        let price: number;
        if (item.choose_type === '') {
          price = item.price[0].price - item.discount;
          // totalPrice += price;

          return <>{formatMoneyVnd(price)}</>;
        } else {
          const result = item.price.filter(
            (i: ItemOrderDetail) => i.type === item.choose_type,
          );
          price = result[0].price - item.discount;

          return <>{formatMoneyVnd(price)}</>;
        }
      },
    },
  ];

  const handleClose = () => {
    handleCancel();
  };

  const handleChange = () => {
    setIsAdjust(true);
  };
  // eslint-disable-next-line
  const onFinish = (values: any) => {
    const data = {
      full_name,
      email,
      phone,
      pay_amount: values.pay_amount,
      payment_type: values.payment_type,
      items: listItemString,
      note: values.note,
    };

    payOrder({ order_id: orderId, data })
      .unwrap()
      .then(() => {
        setIsAdjust(false);
        message.success('Nộp tiền thành công');
        setTimeout(() => setIsAdjust(true), 1000);
        handleCancel();
      })
      .catch(() => {
        message.error('Nộp tiền thất bại');
      });
  };

  return (
    <Modal
      title={'Xác nhận thông tin đơn hàng'}
      visible={isPaymentModalVisible}
      width={1000}
      onCancel={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[]}
    >
      <>
        <Table
          dataSource={listItem}
          pagination={false}
          columns={columns}
          bordered
          rowKey="item_id"
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text strong>Tổng hóa đơn</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text strong style={{ color: 'red' }}>
                      {formatMoneyVnd(totalPrice)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text strong>Đã thanh toán</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text strong style={{ color: '#389e0d' }}>
                      {formatMoneyVnd(paid)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text strong>Còn lại</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text strong style={{ color: '#faad14' }}>
                      {formatMoneyVnd(debt)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />

        <hr />
        <Form onFinish={onFinish} onChange={handleChange}>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="date"
                label={<FormLabel label="Ngày nộp tiền" require={false} />}
              >
                <DatePicker
                  disabledDate={disabledSubmissionDate}
                  format="DD-MM-YYYY"
                  superNextIcon={null}
                  superPrevIcon={null}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="payment_type"
                label={
                  <FormLabel label="Hình thức thanh toán" require={false} />
                }
              >
                <Select>
                  <Select.Option value="">Chưa chọn</Select.Option>
                  {orderPaymentTypeValues.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <div style={{ position: 'relative' }}>
                <Form.Item
                  name="pay_amount"
                  label={<FormLabel label="Số tiền nộp" require={true} />}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập số tiền thanh toán',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    onChange={(value: any) => setNumberMoney(value)}
                  />
                </Form.Item>
                <p
                  style={{
                    marginTop: '-24px',
                    fontWeight: '600',
                    position: 'absolute',
                    top: '172%',
                    left: '104px',
                  }}
                >
                  {convertCurrencyToWords(+numberMoney)}
                </p>
              </div>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="note"
                label={<FormLabel label="Ghi chú" require={false} />}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button.Primary
              htmlType="submit"
              key="payment"
              disabled={!isAdjust}
            >
              Nộp tiền
            </Button.Primary>
          </div>
        </Form>
      </>
    </Modal>
  );
};

export default PaymentModal;
