import {
  Col,
  Input,
  message,
  Row,
  Select,
  Checkbox,
  Space,
  Button,
} from 'antd';
import Modal from 'components/commons/Modal';
// import Button from 'libraries/components/commons/Button';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import { orderPaymentTypeValues } from 'libraries/utils/globalVariable';
import { Student } from 'models/user.model';
import { ReactElement, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useCreateOrderMutation } from 'services/order.service';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
  student: Student;
}

const { TextArea } = Input;

const CreateOrder = ({
  isModalVisible,
  closeModal,
  student,
}: PropsType): ReactElement => {
  const { full_name, email, phone } = student;
  const [form] = Form.useForm();
  const [isCompanyOrder, setIsCompanyOrder] = useState(false);
  const [createOrder] = useCreateOrderMutation();

  const history = useHistory();

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    const data = {
      user_id: student.id,
      full_name: values.full_name,
      phone: values.phone,
      email: values.email,
      note: values.note,
      payment_type: values.payment_type,
      is_company: isCompanyOrder,
      company_info: {
        company: values.company || '',
        tax_code: values.tax_code || '',
        address: values.address || '',
        mobile: values.mobile || '',
      },
    };

    createOrder(data)
      .unwrap()
      .then((response) => {
        message.success('Tạo đơn hàng thành công');
        history.push(
          generatePath(route.order.detail, { orderId: response.id }),
        );
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      full_name,
      email,
      phone,
    });
  }, [student]);

  return (
    <Modal
      title="Tạo đơn hàng"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      keyboard={false}
      width={isCompanyOrder ? 800 : 400}
      footer={[]}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        // initialValues={selectedUser}
        form={form}
      >
        <Row gutter={[30, 0]}>
          <Col xs={24} md={isCompanyOrder ? 12 : 24}>
            <Form.Item
              label={<FormLabel label="Họ tên người mua" require={true} />}
              name="full_name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập đầy đủ họ tên người mua',
                },
              ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              hidden
              label={<FormLabel label="Email" require={true} />}
              name="email"
              rules={[{ required: true, message: 'Vui lòng nhập email' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              hidden
              label={<FormLabel label="Số điện thoại" require={false} />}
              name="phone"
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="payment_type"
              label={<FormLabel label="Hình thức thanh toán" />}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn hình thức thanh toán',
                },
              ]}
            >
              <Select>
                {orderPaymentTypeValues.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={<FormLabel label="Ghi chú đơn hàng" require={false} />}
              name="note"
            >
              <TextArea rows={5} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            {isCompanyOrder && (
              <Row style={{ marginBottom: 0 }}>
                <Col span={24}>
                  <Form.Item
                    label={<FormLabel label="Tên công ty" />}
                    name="company"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập tên công ty',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={<FormLabel label="Mã số thuế" />}
                    name="tax_code"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng mã số thuế',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={<FormLabel label="Địa chỉ" />}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập địa chỉ',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={<FormLabel label="Số điện thoại" require={false} />}
                    name="mobile"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>

          <Col span={24}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Space size="middle">
                <Button type="primary" htmlType="submit">
                  Tạo đơn hàng
                </Button>

                <Checkbox onChange={() => setIsCompanyOrder(!isCompanyOrder)}>
                  Đơn hàng cho công ty
                </Checkbox>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateOrder;
