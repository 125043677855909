import Link from 'libraries/components/commons/Link';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { ReactElement, useMemo } from 'react';
import { BonusDetails } from 'models/report.model';
import { ListPayReceiptType } from 'models/report.model';
import { currDate } from 'libraries/utils/datetime';
import { downloadExcelFile, exportMultipleSheet } from 'libraries/utils/excel';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';

interface PropsType {
  name: string;
  receipts: ListPayReceiptType;
  firstDay: string;
  lastDay: string;
  bonuses: BonusDetails[];
  basePercentage: number;
  isError: boolean;
  isLoading: boolean;
  isBpError: boolean;
}

const ExportSaleExcel = ({
  name,
  receipts,
  firstDay,
  lastDay,
  bonuses,
  basePercentage,
}: PropsType): ReactElement => {
  const { full_name, email } = useSelector(selectUser);

  let paidAmount = 0;
  let refundAmount = 0;
  const approvedBonuses = useMemo(() => {
    if (!bonuses || !bonuses.length) return [];
    return (
      bonuses.filter((bonus) => bonus.status === 'approve') ??
      ([] as BonusDetails[])
    );
  }, [bonuses]);
  const receiptsFromOrder = useMemo(() => {
    if (Object.keys(receipts).length === 0) return [];
    return (
      receipts.receipt_from_order.filter(({ receiver_full_name, amount }) => {
        if (receiver_full_name !== name) return false;

        paidAmount += amount || 0;
        return true;
      }) ?? []
    );
  }, [receipts]);
  const payForOrder = useMemo(() => {
    if (Object.keys(receipts).length === 0) return [];
    return (
      receipts?.pay_for_order.map((item) => {
        refundAmount -= item.amount;
        return {
          ...item,
          amount: -item.amount,
        };
      }) ?? []
    );
  }, [receipts]);

  const salary = (paidAmount * (basePercentage ?? 1)) / 100;
  const bonusTotal =
    approvedBonuses?.reduce((prev, cur) => (prev += cur.bonus), 0) ?? 0;

  const exportExcel = () => {
    const info = {
      creator: full_name,
      email: email,
      date: currDate(),
    };

    const sheetData = [
      {
        sheetName: 'Lương',
        data: [
          [
            'Mã đơn hàng',
            'Ngày giao dịch',
            'Khóa học',
            'Người nộp tiền',
            'Số tiền đã đóng (đ)',
            'Tổng đơn hàng (đ)',
            'Ghi chú',
          ],
          ...receiptsFromOrder.map((col) => [
            col.order_id,
            col.datetime !== '' ? formatDate(col.datetime) : '',
            col.items.length !== 0 ? col.items.join(',') : '',
            col.full_name,
            col.amount !== 0 ? col.amount : 0,
            col.total !== 0 ? col.total : 0,
            col.note,
          ]),
        ],
      },
      {
        sheetName: 'Tổng tiền hoàn',
        data: [
          [
            'Mã đơn hàng',
            'Ngày giao dịch',
            'Người nộp tiền',
            'Học viên',
            'Số tiền',
            'Ghi chú',
          ],
          ...payForOrder.map((col) => [
            col.order_id,
            col.datetime !== '' ? formatDate(col.datetime) : '',
            col.full_name,
            col.receiver_full_name,
            col.amount !== 0 ? col.amount : 0,
            col.note,
          ]),
        ],
      },
      {
        sheetName: 'Lương thưởng',
        data: [
          ['Ngày thực hiện', 'Nội dung', 'Lương thưởng (đ)'],
          ...approvedBonuses.map((col) => [
            col.date !== '' ? formatDate(col.date) : '',
            col.content,
            col.bonus !== 0 ? col.bonus : 0,
          ]),
        ],
      },
      {
        sheetName: 'Tổng',
        data: [
          ['Tổng lương', 'Tổng thưởng', 'Tổng tiền hoàn', 'Số dư'],
          [
            salary,
            bonusTotal,
            refundAmount,
            bonusTotal + salary + refundAmount,
          ],
        ],
      },
    ];

    const workbook = exportMultipleSheet(sheetData, info);

    const fileName = `Báo cáo chi tiết thu nhập của nhân viên bán hàng ${name} từ ${firstDay} đến ${lastDay}.xlsx`;
    downloadExcelFile(workbook, fileName);
  };

  return (
    <Link.Primary to="#" onClick={exportExcel}>
      Xuất Excel
    </Link.Primary>
  );
};

export default ExportSaleExcel;
