import { ReactElement } from 'react';
import { v4 } from 'uuid';
import Table, { ColumnsType } from 'antd/lib/table';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { formatDate } from 'utils/formatDate';
import { formatMoney } from 'libraries/utils/changeFormatData';
import TableContainer from 'components/table/TableContainer';
import { Typography } from 'antd';
import { SummaryReport as Report } from 'models/report.model';
import { useSelector } from 'react-redux';
import { selectSale } from '../../../../../store/report.slice';

interface PropsType {
  data: Report[];
  hideBonus?: boolean;
}
type TableData = Report & { key: React.Key };

const { Text } = Typography;
const SalaryTable = ({ data, hideBonus }: PropsType): ReactElement => {
  const tableData: TableData[] = data.map((item) => ({
    ...item,
    key: v4(),
  }));
  const sale = useSelector(selectSale);
  const columns: ColumnsType<TableData> = [
    {
      key: 'order_id',
      width: '10%',
      title: 'Mã đơn hàng',
      dataIndex: 'order_id',
      render: (_: void, { order_id, bonus_id }) => {
        return order_id ? (
          <RouterLink
            to={generatePath(route.order.detail, { orderId: order_id })}
            target="_blank"
          >
            {order_id}
          </RouterLink>
        ) : (
          <RouterLink
            to={{
              pathname: generatePath(route.report.sale.detail, {
                saleId: sale.id,
              }),
              search: `?bonus=${bonus_id}`,
            }}
            target="_blank"
          >
            {bonus_id ?? 0}
          </RouterLink>
        );
      },
    },
    {
      key: 'datetime',
      width: '10%',
      title: 'Ngày giao dịch',
      dataIndex: 'datetime',
      render: (datetime) => (datetime !== '' ? formatDate(datetime) : ''),
    },
    {
      key: 'items',
      width: '25%',
      title: 'Items',
      dataIndex: 'items',
      render: (_: void, cell) => {
        const items = cell.items ?? [''];
        return items.map((i: string, index: number) => (
          <div
            key={index}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
          >
            {i}
          </div>
        ));
      },
    },
    {
      key: 'note',
      width: '20%',
      title: 'Ghi chú',
      dataIndex: 'note',
      ellipsis: true,
      render: (note) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {note}
        </div>
      ),
    },
    // {
    //   key: 'basesalary',
    //   width: '20%',
    //   title: 'Mức hoa hông',
    //   dataIndex: 'base_salary',
    //   render: (base_salary) => {
    //     if (history) {
    //       const historyItem = history.find((item) => {
    //         const itemTime = new Date(item.start_date);
    //         const salaryTime = new Date(datetime);
    //         return salaryTime.getTime() <= itemTime.getTime();
    //       });

    //       return <div>{historyItem?.percent_finance ?? 0} %</div>;
    //     }
    //   },
    // },
    {
      key: 'refund',
      width: '10%',
      title: 'Số tiền đã hoàn',
      dataIndex: 'amount',
      onCell: (record) => ({ record, className: 'text-right' }),
      render: (amount) => {
        if (amount && amount < 0) {
          return <Text type={'danger'}>{'- ' + formatMoney(amount)}</Text>;
        } else return 0;
      },
    },
    {
      key: 'bonus',
      width: '10%',
      title: 'Số tiền thưởng',
      dataIndex: 'bonus',
      onCell: (record) => ({ record, className: 'text-right' }),
      render: (_: void, cell) => {
        if (hideBonus) return <></>;
        const bonus = cell.bonus ?? 0;
        if (bonus) {
          return <Text type={'warning'}>{formatMoney(bonus)}</Text>;
        } else return 0;
      },
    },
    {
      key: 'amount',
      width: '10%',
      title: 'Số tiền đã đóng',
      dataIndex: 'amount',
      onCell: (record) => ({ record, className: 'text-right' }),
      render: (amount) => {
        if (amount && amount > 0) {
          return formatMoney(amount);
        } else return 0;
      },
    },
  ];

  if (hideBonus) {
    const index = columns.findIndex((item) => item.key === 'bonus');
    columns.splice(index, 1);
  }

  return (
    <TableContainer>
      <Table<TableData>
        columns={columns}
        dataSource={tableData}
        pagination={{ showSizeChanger: false }}
        bordered
        summary={(pageData) => {
          if (pageData.length === 10 && pageData.length !== tableData.length)
            return <></>;
          let totalReceipt = 0;
          let totalRepayment = 0;
          let totalBonus = 0;

          tableData.forEach(({ amount, bonus }) => {
            if (amount) {
              if (amount > 0) {
                totalReceipt += amount;
              } else totalRepayment += amount;
            } else if (bonus) totalBonus += bonus;
          });
          const totals = [totalRepayment, totalBonus, totalReceipt];
          if (hideBonus) {
            totals.splice(1, 1);
          }
          const totalsNodes = totals.map((item, index) => {
            const type = () => {
              switch (item) {
                case totalRepayment:
                  return 'danger';
                case totalBonus:
                  return 'warning';
                default:
                  return undefined;
              }
            };
            return (
              <Table.Summary.Cell key={index} align="right" index={2}>
                <Text strong type={type()}>
                  {(item < 0 ? '- ' : '') + formatMoney(item)}
                </Text>
              </Table.Summary.Cell>
            );
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell
                  align="left"
                  index={0}
                  colSpan={columns.length - totals.length}
                >
                  <Text strong>Tổng</Text>
                </Table.Summary.Cell>
                {totalsNodes}
              </Table.Summary.Row>
              {!hideBonus ? (
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={columns.length - totals.length}
                  >
                    <Text strong>Tổng đơn đã thu và hoàn</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    align="right"
                    index={1}
                    colSpan={totals.length}
                  >
                    <Text style={{ color: '#1890ff' }} strong>
                      {formatMoney(totalReceipt + totalRepayment)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ) : (
                <></>
              )}
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={0}
                  colSpan={columns.length - totals.length}
                >
                  <Text strong>Số dư</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  align="right"
                  index={1}
                  colSpan={totals.length}
                >
                  <Text type="success" strong>
                    {formatMoney(
                      totalReceipt +
                        totalRepayment +
                        (!hideBonus ? totalBonus : 0),
                    )}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </TableContainer>
  );
};

export default SalaryTable;
