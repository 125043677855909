import { createApi } from '@reduxjs/toolkit/query/react';
import { Schedule } from 'models/schedule.model';
import { TrackList } from 'models/track.model';
import { baseQuery } from './base.service';

export const scheduleApi = createApi({
  reducerPath: 'scheduleApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getSchedulesList: builder.query<Schedule[], void>({
      query: () => {
        return {
          url: `list-schedule`,
          method: 'GET',
        };
      },
      transformResponse: (response: Schedule[]) => {
        response = response ?? ([] as Schedule[]);
        response.length > 0 &&
          response.map((item) => {
            item.start_date = item.start_date.includes('0001-01-01T00')
              ? ''
              : item.start_date;
          });
        return response;
      },
    }),

    createSchedule: builder.mutation<void, Schedule>({
      query: (data) => {
        return {
          url: `schedule`,
          method: 'POST',
          body: data,
        };
      },
    }),

    updateSchedule: builder.mutation<void, Schedule & { current_date: string }>(
      {
        query: (data) => {
          const { item_id, item_type, current_date } = data;

          return {
            url: `schedule/${item_id}/${item_type}/${current_date}`,
            method: 'PUT',
            body: data,
          };
        },
      },
    ),

    getTracksList: builder.query<TrackList[], void>({
      query: () => {
        return {
          url: `list-track`,
          method: 'GET',
        };
      },
      transformResponse: (response: TrackList[]) => {
        response = response ?? ([] as TrackList[]);
        return response;
      },
    }),

    getScheduleInfo: builder.query<
      Schedule,
      { item_id: string; type: string; start_date: string }
    >({
      query: ({ item_id, type, start_date }) => {
        return {
          url: `schedule/${item_id}/${type}/${start_date}`,
          method: 'GET',
        };
      },
      transformResponse: (response: Schedule) => {
        response.start_date = response.start_date.includes('0001-01-01T00')
          ? ''
          : response.start_date;

        response.day_of_week = response.day_of_week ?? ([] as number[]);
        return response;
      },
    }),

    deleteSchedule: builder.mutation<
      string,
      { item_id: string; type: string; start_date: string }
    >({
      query: ({ item_id, type, start_date }) => {
        return {
          url: `schedule/${item_id}/${type}/${start_date}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetSchedulesListQuery,
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useGetTracksListQuery,
  useGetScheduleInfoQuery,
  useDeleteScheduleMutation,
} = scheduleApi;
