import Table, { ColumnsType } from 'antd/lib/table';
import { SummarySalaryList } from 'models/report.model';
import React, { ReactElement } from 'react';
import { route } from '../../../../routers/routeNames';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import TableContainer from '../../../../components/table/TableContainer';
import { formatMoney } from 'libraries/utils/changeFormatData';
import { v4 } from 'uuid';
import Tag from 'antd/es/tag';

interface PropsType {
  users: SummarySalaryList[];
}
type TableData = SummarySalaryList & { key: React.Key };

const SummaryList = ({ users }: PropsType): ReactElement => {
  const tableData = users.map((user) => {
    return { ...user, key: v4() };
  });
  const columns: ColumnsType<TableData> = [
    {
      key: 'full_name',
      width: '15%',
      title: 'Họ tên',
      dataIndex: 'full_name',
      render: (_text, item) => (
        <RouterLink
          to={{
            pathname: generatePath(route.report.summary.detail, {
              userId: item.id,
            }),
            search: `?salary_id=${item.salary_id}`,
          }}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          {item.full_name}
        </RouterLink>
      ),
    },
    {
      key: 'email',
      width: '15%',
      title: 'Email',
      dataIndex: 'email',
      render: (email) => <a href={`mailto:${email}`}>{email}</a>,
    },
    {
      key: 'phone',
      width: '10%',
      title: 'Số điện thoại',
      dataIndex: 'phone',
      render: (phone) => <a href={`tel:${phone}`}>{phone}</a>,
    },
    {
      key: 'bank_name',
      width: '15%',
      title: 'Ngân hàng',
      dataIndex: 'bank_name',
    },
    {
      key: 'bank_account',
      width: '15%',
      title: 'Số tài khoản',
      dataIndex: 'bank_account',
    },
    {
      key: 'paid_amount',
      width: '10%',
      title: 'Đã trả',
      dataIndex: 'paid',
      render: (paid) => (paid !== 0 ? formatMoney(paid) : 0),
    },
    {
      key: 'salary',
      width: '10%',
      title: 'Lương',
      dataIndex: 'salary',
      render: (salary) => (salary !== 0 ? formatMoney(salary) : 0),
    },
    {
      key: 'status',
      width: '20%',
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (_: void, { status }) => {
        const props = () => {
          switch (status) {
            case 'not_paid':
              return {
                text: 'Chưa trả',
                color: 'error',
              };
            case 'paid':
              return { text: 'Đã trả', color: 'success' };
            default:
              return { text: '', color: '' };
          }
        };
        const { color, text } = props();
        if (!text.length) return <></>;
        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  return (
    <TableContainer>
      <Table<TableData>
        columns={columns}
        dataSource={tableData}
        bordered
        pagination={{ showSizeChanger: false, pageSize: 20 }}
      />
    </TableContainer>
  );
};

export default SummaryList;
