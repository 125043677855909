import { current } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Class,
  Lecture,
  ClassAttendance,
  ClassFind,
  ClassInfo,
  ClassUpdateType,
  CreateNewClassType,
  LessonAttendance,
  TeacherFind,
} from 'models/class.model';
import { Course } from 'models/course.model';
import {
  AddStudentResponse,
  ClassStudent,
  ClassStudentLessonStatus,
  Student,
  StudentRank,
  UsersType,
  UserWithRole,
} from 'models/user.model';
import { baseQuery } from './base.service';

interface CreateUserData {
  note: string;
  email: string;
  phone: string;
  full_name: string;
  status: string;
  type: string;
  class_name: string;
}
interface CreateUserThenAddClass {
  id: string;
  full_name: string;
  status: string;
  join_date: string;
  sale_name: string;
  note: string;
}
interface ClassesListType {
  total_page: number;
  classes: Class[];
}
export interface CurriculumListType {
  name: string;
  display_order: number;
  lectures: Lecture[];
}
interface UpdateLessonAttendance {
  class_id: string;
  lesson_id: string;
  // eslint-disable-next-line
  data: any[];
}

interface AddSalary {
  class_id: string;
  data: {
    salary_per_student: number;
    salary_fixed: number;
    min_salary: number;
  };
}
interface SelectClass {
  course_id: string;
  data: {
    id: string;
  };
}

export const classesApi = createApi({
  reducerPath: 'classesApi',

  baseQuery,
  tagTypes: ['Students'],
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getClasses: builder.query<ClassesListType, string>({
      query: (keySearch: string) => {
        return {
          url: `classes?${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ClassesListType) => {
        response.classes = response.classes ?? ([] as Class[]);
        response.classes.length > 0 &&
          response.classes.map((item) => {
            item.course_id = item.course_id ?? '';
            item.learned = item.learned ? item.learned : 0;
            item.total_learns = item.total_learns ? item.total_learns : 0;
            item.schedule = item.schedule.includes('0001-01-01T00')
              ? ''
              : item.schedule;
          });

        return response;
      },
    }),
    getClassesCurriculum: builder.query<CurriculumListType[], string>({
      query: (value: string) => {
        return {
          url: `https://techmaster.vn/api/class/${value}/outline`,
          method: 'GET',
        };
      },
      transformResponse: (response: CurriculumListType[]) => {
        // response.classes = response.classes ?? ([] as Class[]);
        // response.classes.length > 0 &&
        //   response.classes.map((item) => {
        //     item.course_id = item.course_id ?? '';
        //     item.learned = item.learned ? item.learned : 0;
        //     item.total_learns = item.total_learns ? item.total_learns : 0;
        //     item.schedule = item.schedule.includes('0001-01-01T00')
        //       ? ''
        //       : item.schedule;
        //   });

        return response;
      },
    }),
    getAllClasses: builder.query<ClassesListType, string>({
      query: (course_id: string) => {
        return {
          url: `classes?page=1&course_id=${course_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ClassesListType) => {
        response.classes = response.classes ?? ([] as Class[]);
        response.classes.length > 0 &&
          response.classes.map((item) => {
            item.course_id = item.course_id ?? '';
            item.learned = item.learned ? item.learned : 0;
            item.total_learns = item.total_learns ? item.total_learns : 0;
            item.schedule = item.schedule.includes('0001-01-01T00')
              ? ''
              : item.schedule;
          });

        return response;
      },
    }),
    getClassesList: builder.query<ClassFind[], string>({
      query: (course_id) => {
        return {
          url: `list-classes?status=active&status=scheduled&course_id=${course_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ClassFind[]) => {
        response = response === null ? ([] as ClassFind[]) : response;
        if (response.length > 0) {
          response.map((item) => {
            item.total_students = item.total_students ? item.total_students : 0;
            item.schedule = item.schedule.includes('0001-01-01T00')
              ? ''
              : item.schedule;
            item.teachers = item.teachers
              ? item.teachers
              : ([] as TeacherFind[]);
            item.location = item.location ? item.location : '';
          });
        }
        return response;
      },
    }),

    getAllStudentsClass: builder.query<Student[], string>({
      query: (class_id) => {
        return {
          url: `classes/${class_id}/students`,
          method: 'GET',
        };
      },
      providesTags: (result = []) => [
        'Students',
        ...result.map(({ id }) => ({ type: 'Students' as const, id })),
      ],
      transformResponse: (response: Student[]) => {
        response = response === null ? ([] as Student[]) : response;
        if (response.length > 0) {
          response.map((item, index) => {
            item.join_date = item.join_date.includes('0001-01-01T00')
              ? ''
              : item.join_date;
            item.end_date = item.end_date.includes('0001-01-01T00')
              ? ''
              : item.end_date;
            item.index = index + 1;
          });
          response.sort((a, b) => {
            const aDate = new Date(a.join_date);
            const bDate = new Date(b.join_date);
            if (a.status === b.status) {
              return aDate.getTime() < bDate.getTime() ? -1 : 1;
            } else {
              return a.status < b.status ? -1 : 1;
            }
          });
        }
        return response;
      },
    }),

    createClass: builder.mutation<void, CreateNewClassType>({
      query: (data) => {
        return {
          url: `create-class`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getListCourses: builder.query<Course[], void>({
      query: () => {
        return {
          url: `list-courses`,
          method: 'GET',
        };
      },
      transformResponse: (response: Course[]) => {
        response = response === null ? ([] as Course[]) : response;

        return response;
      },
    }),

    getClassInfo: builder.query<ClassInfo, string>({
      query: (class_id) => {
        return {
          url: `classes/${class_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ClassInfo) => {
        response.schedule = response.schedule.includes('0001-01-01T00')
          ? ''
          : response.schedule;
        response.teachers = response.teachers
          ? response.teachers
          : ([] as UserWithRole[]);

        if (response.sale.id) {
          response.sale.info = `${response.sale.full_name} - ${
            response.sale.email
          } - ${response.sale.phone ? response.sale.phone : ''}`;
        }
        if (response.teachers.length > 0) {
          response.teachers.map(
            (item) =>
              (item.info = `${item.full_name} - ${item.email} - ${
                item.phone ? item.phone : ''
              }`),
          );
        }
        response.teacher_list = response.teachers.reduce((arr, item) => {
          arr.push(item.id);
          return arr;
        }, [] as string[]);
        response.branch = response.branch ? response.branch : '';

        response.day_of_week =
          response.day_of_week === null
            ? ([] as number[])
            : response.day_of_week;
        response.min_salary = response.min_salary ?? 0;
        return response;
      },
    }),

    updateClass: builder.mutation<
      {
        class_id: string;
      },
      { class_id: string; data: ClassUpdateType }
    >({
      query: ({ class_id, data }) => {
        return {
          url: `classes/${class_id}`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    removedStudentFromClass: builder.mutation<
      void,
      { class_id: string; student_id: string; data: { note: string } }
    >({
      query: ({ class_id, student_id, data }) => {
        return {
          url: `classes/${class_id}/students/${student_id}/remove`,
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(
        { class_id, student_id, data },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          classesApi.util.updateQueryData(
            'getAllStudentsClass',
            class_id,
            (draft) => {
              const index = draft.findIndex((item) => item.id === student_id);
              if (index < 0) return;
              const student = draft[index];
              student.note = data.note;
              if (student.status === 'removed') {
                draft.splice(index, 1);
                return draft;
              }
              student.status = 'removed';
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    findStudent: builder.query<UsersType[], string>({
      query: (keyword) => {
        return {
          url: `users?keyword=${keyword}`,
          method: 'GET',
        };
      },
    }),

    addStudentToClassNoOrder: builder.mutation<
      AddStudentResponse,
      {
        class_id: string;
        data: {
          note: string;
          id: string;
          full_name: string;
          status: string;
          type: string;
          class_name: string;
          email: string;
        };
      }
    >({
      query: ({ class_id, data }) => {
        return {
          url: `classes/${class_id}/add`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Students'],
    }),

    movedStudentToOtherClass: builder.mutation<
      string,
      {
        class_id: string;
        student_id: string;
        data: { class_id: string };
      }
    >({
      query: ({ class_id, student_id, data }) => {
        return {
          url: `classes/${class_id}/students/${student_id}/move`,
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(
        { class_id, student_id },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          classesApi.util.updateQueryData(
            'getAllStudentsClass',
            class_id,
            (draft) => {
              const student = draft.find((item) => item.id === student_id);
              if (!student) return;
              student.status = 'move';
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    delayStudentFromClass: builder.mutation<
      string,
      {
        class_id: string;
        student_id: string;
      }
    >({
      query: ({ class_id, student_id }) => {
        return {
          url: `classes/${class_id}/students/${student_id}/delay`,
          method: 'POST',
        };
      },
      async onQueryStarted(
        { class_id, student_id },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          classesApi.util.updateQueryData(
            'getAllStudentsClass',
            class_id,
            (draft) => {
              const student = draft.find((item) => item.id === student_id);
              if (!student) return;
              student.status = 'delay';
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteClass: builder.mutation<string, string>({
      query: (class_id) => {
        return {
          url: `classes/${class_id}`,
          method: 'DELETE',
        };
      },
    }),

    createThenAddStudentToClass: builder.mutation<
      CreateUserThenAddClass,
      { class_id: string; data: CreateUserData }
    >({
      query: ({ class_id, data }) => {
        return {
          url: `classes/${class_id}/create-then-add`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Students'],
    }),

    getClassAttendances: builder.query<ClassAttendance[], string>({
      query: (class_id) => {
        return {
          url: `classes/${class_id}/attendances`,
          method: 'GET',
        };
      },
      transformResponse: (response: ClassAttendance[]) => {
        response = response === null ? ([] as ClassAttendance[]) : response;
        if (response.length > 0) {
          response.map((item) => {
            item.complete_date = item.complete_date.includes('0001-01-01T00')
              ? ''
              : item.complete_date;
          });
        }

        return response;
      },
    }),

    getLessonAttendances: builder.query<
      LessonAttendance[],
      { class_id: string; lesson_id: string }
    >({
      query: ({ class_id, lesson_id }) => {
        return {
          url: `classes/${class_id}/lesson/${lesson_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: LessonAttendance[]) => {
        response = response === null ? ([] as LessonAttendance[]) : response;

        return response;
      },
    }),

    updateLessonAttendance: builder.mutation<any, UpdateLessonAttendance>({
      query: (arg) => {
        const { class_id, lesson_id, data } = arg;
        return {
          url: `classes/${class_id}/lesson/${lesson_id}`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    returnStudentToClass: builder.mutation<
      string,
      { class_id: string; student_id: string }
    >({
      query: ({ class_id, student_id }) => ({
        url: `classes/${class_id}/students/${student_id}/active`,
        method: 'POST',
      }),
      async onQueryStarted(
        { class_id, student_id },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          classesApi.util.updateQueryData(
            'getAllStudentsClass',
            class_id,
            (draft) => {
              const student = draft.find((item) => item.id === student_id);
              if (!student) return;
              student.status = 'active';
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getClassStudentInfo: builder.query<
      ClassStudent,
      { class_id: string; student_id: string; order_id: string }
    >({
      query: ({ class_id, student_id, order_id }) => {
        return {
          url: `classes/${class_id}/student/${student_id}?order_id=${order_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: ClassStudent) => {
        response.status_lessons =
          response.status_lessons === null
            ? ([] as ClassStudentLessonStatus[])
            : response.status_lessons;
        response.join_date = response.join_date.includes('0001-01-01T00')
          ? ''
          : response.join_date;
        response.end_date = response.end_date.includes('0001-01-01T00')
          ? ''
          : response.end_date;
        return response;
      },
    }),
    addSalary: builder.mutation<void, AddSalary>({
      query: (arg) => {
        const { class_id, data } = arg;
        return {
          url: `classes/${class_id}/salary`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    selectClass: builder.mutation<void, SelectClass>({
      query: (arg) => {
        const { course_id, data } = arg;
        return {
          url: `/course/${course_id}/curriculum`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    patchNoteEdit: builder.mutation<
      any,
      {
        class_id: string;
        student_id: string;
        note: string;
        type: 'lab' | 'live';
      }
    >({
      query: ({ class_id, note, type, student_id }) => ({
        url: `classes/${class_id}/students/${student_id}`,
        method: 'PATCH',
        body: { note, type },
      }),
      async onQueryStarted(
        { class_id, note, type, student_id },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          classesApi.util.updateQueryData(
            'getAllStudentsClass',
            class_id,
            (draft) => {
              const student = draft.find((item) => item.id === student_id);
              if (student) {
                student.note = note;
                student.type = type;
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getStudentsRanking: builder.query<
      StudentRank,
      { class_id: string; student_id: string; code: string }
    >({
      query: ({ class_id, student_id, code }) =>
        `classes/${class_id}/students/${student_id}/rank?code=${code}`,
      transformResponse: (res: StudentRank) => {
        res.average = res.average ?? 0;
        res.rank = res.rank ?? 0;
        return res;
      },
    }),
  }),
});

export const {
  useGetClassesQuery,
  useGetAllClassesQuery,
  useGetClassesCurriculumQuery,
  useGetClassesListQuery,
  useGetAllStudentsClassQuery,
  useCreateClassMutation,
  useGetListCoursesQuery,
  useGetClassInfoQuery,
  useUpdateClassMutation,
  useRemovedStudentFromClassMutation,
  useFindStudentQuery,
  useAddStudentToClassNoOrderMutation,
  useMovedStudentToOtherClassMutation,
  useDelayStudentFromClassMutation,
  useDeleteClassMutation,
  useCreateThenAddStudentToClassMutation,
  useGetClassAttendancesQuery,
  useGetLessonAttendancesQuery,
  useGetClassStudentInfoQuery,
  useUpdateLessonAttendanceMutation,
  useAddSalaryMutation,
  useSelectClassMutation,
  useReturnStudentToClassMutation,
  usePatchNoteEditMutation,
  useGetStudentsRankingQuery,
} = classesApi;
