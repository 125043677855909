import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import { GeneralInfo } from 'models/dashboard.model';
import { ReactElement } from 'react';
import { Line } from 'react-chartjs-2';
import { useGetRevenuesDataQuery } from 'services/dashboard.service';
import ItemsTable from './ItemsTable';
import SummaryPayment from './SummaryPayment';

interface PropsType {
  summaryData: GeneralInfo;
}

const RevenuesComponent = ({ summaryData }: PropsType): ReactElement => {
  const { data, isError, error } = useGetRevenuesDataQuery();
  const dates_revenues = data ? data.dates_revenues : ([] as string[]);
  // const revenues = data ? data.revenues : ([] as number[]);
  const expenditures = data ? data.expenditures : ([] as number[]);
  const incomes = data ? data.incomes : ([] as number[]);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <BorderTopBoxContent>
      <div className="box-header">Thu chi tháng</div>
      <div className="box-body">
        <Line
          width="100%"
          data={{
            labels: dates_revenues,
            datasets: [
              // {
              //   data: revenues,
              //   label: '',
              //   borderColor: '#3e95cd',
              //   fill: true,
              // },
              {
                data: expenditures,
                label: 'expenditures',
                borderColor: '#f39c12',
                fill: true,
              },
              {
                data: incomes,
                label: 'incomes',
                borderColor: '#00a65a',
                fill: true,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            plugins: { legend: { display: false } },
          }}
        />
      </div>
      {/* <hr />
      <SummaryPayment summaryData={summaryData} /> */}
      <hr />
      <ItemsTable items={summaryData.items} />
    </BorderTopBoxContent>
  );
};

export default RevenuesComponent;
