import { ReactElement, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { Col, Input, message, Row, Select } from 'antd';
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Breadcrumb from 'components/commons/Breadcrumb';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import Button from 'libraries/components/commons/Button';
import { useGetUsersByRoleQuery } from 'services/user.service';
import { ROLE_SALE } from 'libraries/utils/globalVariable';
import { useCreateTrackMasterMutation } from 'services/trackmaster.service';
import Editor from 'libraries/editor';
import Hotkeys from 'react-hot-keys';

const { Option } = Select;

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const CreateTrackMasterContent = (): ReactElement => {
  const [form] = Form.useForm();
  const { data: salers } = useGetUsersByRoleQuery(ROLE_SALE);

  const [createTrackMaster] = useCreateTrackMasterMutation();

  const [avatar, setAvatar] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const handleChangeDescription = (value: string) => {
    form.setFieldsValue({
      description: value,
    });
  };
  // eslint-disable-next-line
  const onFinish = (values: any) => {
    createTrackMaster(values)
      .unwrap()
      .then(() => {
        setIsChange(false);
        message.success('Tạo track master thành công!');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handlersHotkeys = (event: KeyboardEvent) => {
    event.preventDefault();
    onFinish(form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldsValue({
      avatar,
    });
  }, [avatar]);

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.track.list)}
        secondaryText="Lộ trình"
        text="Tạo track master"
      />{' '}
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          onFieldsChange={() => setIsChange(true)}
        >
          <GroupButton>
            <LinksContainer>
              <Link.Default to={generatePath(route.track.list)}>
                <LeftOutlined /> Quay lại
              </Link.Default>
            </LinksContainer>
          </GroupButton>

          <Row gutter={[30, 30]}>
            <Col xs={24} md={16}>
              <Form.Item
                label={<FormLabel label="Tên" />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên lộ trình!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Giới thiệu" require={true} />}
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng viết mô tả về lộ trình!',
                  },
                ]}
              >
                <>
                  <Editor
                    value="description"
                    height={250}
                    onChange={handleChangeDescription}
                  />
                </>
              </Form.Item>

              <div style={{ marginTop: '8px' }}>
                <Button.Primary
                  style={{ fontSize: '14px' }}
                  htmlType="submit"
                  disabled={!isChange}
                >
                  Tạo track master mới
                </Button.Primary>
              </div>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label={<FormLabel label="Nhân viên sale" require={true} />}
                name="sale"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn nhân viên sale!',
                  },
                ]}
              >
                <Select optionFilterProp="children">
                  {salers &&
                    salers.length > 0 &&
                    salers.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.full_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="URL Landing Page" require={false}/>}
                name="link"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Hotkeys>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setAvatar}
        />
      )}
    </>
  );
};
export default CreateTrackMasterContent;
