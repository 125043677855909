import {
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import Button from 'libraries/components/commons/Button';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import disabledSubmissionDate from 'libraries/utils/disabledSubmissionDate';
import {
  classBranchValues,
  classStatusValues,
  classStudiedDayValues,
  classTypeValues,
  ROLE_SALE,
  ROLE_TRAINER,
} from 'libraries/utils/globalVariable';
import { ClassInfo } from 'models/class.model';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import {
  useGetListCoursesQuery,
  useUpdateClassMutation,
} from 'services/classes.service';
import { useGetTracksListQuery } from 'services/track.service';
import { useGetUsersByRoleQuery } from 'services/user.service';

interface PropsType {
  classroom: ClassInfo;
}

const { Option } = Select;
const { TextArea } = Input;

const ClassInfoContent = ({ classroom }: PropsType): ReactElement => {
  const {
    name,
    type,
    sale,
    course_id,
    teacher_list,
    branch,
    total_learns,
    price_lab,
    price_live,
    status,
    schedule,
    day_of_week,
    start_at,
    end_at,
    tracks,
  } = classroom;

  const { data: teachers } = useGetUsersByRoleQuery(ROLE_TRAINER);
  const { data: salers } = useGetUsersByRoleQuery(ROLE_SALE);
  const { data: courses } = useGetListCoursesQuery();
  const { data: listTrack } = useGetTracksListQuery(
    `keyword=&master_id=&status=active`,
  );
  const [valueTrack, setValueTrack] = useState<string>('');
  const [tmpType, setTmpType] = useState('lab');

  const [updateClass] = useUpdateClassMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: name,
      type: type,
      sale_id: sale?.id ?? '',
      course_id: course_id,
      teacher_list: teacher_list,
      branch_name: branch,
      lessons: total_learns,
      price_lab: price_lab,
      price_live: price_live,
      status: status,
      start_time:
        schedule !== ''
          ? moment(moment(schedule).format('DD/MM/YYYY'), 'DD/MM/YYYY')
          : '',
      day_of_week: day_of_week.map((item) => item.toString()),
      start_at: start_at,
      end_at: end_at,
      track_id: tracks ? tracks[0].id : '',
    });
    setTmpType(type);
  }, [classroom]);

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    updateClass({
      class_id: classroom.id,
      data: {
        ...values,
        start_time: values.start_time
          ? values.start_time.utc(true).startOf('D').toISOString()
          : null,
        day_of_week:
          values.day_of_week && values.day_of_week.length > 0
            ? values.day_of_week.map((item: string) => Number(item))
            : ([] as number[]),
      },
    })
      .unwrap()
      .then(() => {
        message.success('Cập nhật thông tin lớp học thành công!');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row gutter={[20, 20]}>
        <Col xs={24} md={14}>
          <Form.Item
            label={<FormLabel label="Tên lớp" />}
            name="name"
            rules={[{ required: true, message: 'Xin vui lòng nhập tên lớp!' }]}
          >
            <Input />
          </Form.Item>

          {courses && (
            <Form.Item
              label={<FormLabel label="Khoá học" />}
              name="course_id"
              rules={[
                { required: true, message: 'Xin vui lòng chọn khoá học!' },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                allowClear
                style={{ width: '100%' }}
                placeholder="Danh sách khoá học"
              >
                {courses.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          {listTrack && (
            <Form.Item
              label={<FormLabel label="Lộ trình" require={false} />}
              name="track_id"
            >
              <Select
                showSearch
                optionFilterProp="children"
                allowClear
                style={{ width: '100%' }}
                placeholder="Danh sách lộ trình"
                onChange={(value) => setValueTrack(value)}
              >
                {listTrack.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="note"
            label={<FormLabel label="Ghi chú" require={false} />}
          >
            <TextArea rows={2} />
          </Form.Item>

          {teachers && (
            <Form.Item
              label={<FormLabel label="Giảng viên" require={false} />}
              name="teacher_list"
            >
              <Select
                showSearch
                optionFilterProp="children"
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Danh sách giảng viên"
              >
                {teachers.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.info}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          {salers && (
            <Form.Item
              label={<FormLabel label="Nhân viên marketing" />}
              name="sale_id"
              rules={[
                {
                  required: true,
                  message: 'Xin vui lòng chọn nhân viên marketing!',
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                allowClear
                style={{ width: '100%' }}
                placeholder="Danh sách nhân viên"
              >
                {salers.map((item, index) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.info}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          <Form.Item label={<FormLabel label="Hình thức lớp" />} name="type">
            <Select onChange={(value: string) => setTmpType(value)}>
              {classTypeValues.map((item, index) => (
                <Select.Option value={item.value} key={index}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {(tmpType === 'lab' || tmpType === 'mixed' || tmpType === 'video') &&
            !tracks &&
            valueTrack === '' && (
              <Form.Item
                label={
                  <FormLabel
                    label={tmpType === 'video' ? 'Giá tiền' : 'Giá học Onlab'}
                    require={tmpType === 'video' ? true : false}
                  />
                }
                rules={[
                  {
                    required: tmpType === 'video' ? true : false,
                    message: 'Xin vui lòng nhập giá tiền!',
                  },
                ]}
                name="price_lab"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </Form.Item>
            )}

          {(tmpType === 'live' || tmpType === 'mixed') && (
            <Form.Item
              label={
                <FormLabel
                  label="Giá học trực tuyến có tương tác"
                  require={false}
                />
              }
              name="price_live"
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Form.Item>
          )}
        </Col>

        <Col xs={24} md={10}>
          <Form.Item label={<FormLabel label="Trạng thái" />} name="status">
            <Select>
              {classStatusValues.map((item, index) => (
                <Select.Option value={item.value} key={index}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {tmpType !== 'video' && (
            <>
              {' '}
              <Form.Item
                name="start_time"
                label={<FormLabel label="Ngày khai giảng" require={false} />}
              >
                <DatePicker
                  disabledDate={disabledSubmissionDate}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
              <Form.Item
                name="day_of_week"
                label={
                  <FormLabel label="Ngày học trong tuần" require={false} />
                }
              >
                <Select
                  optionFilterProp="children"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày học"
                >
                  {classStudiedDayValues.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <FormLabel label="Giờ bắt đầu buổi học" require={false} />
                }
                name="start_at"
              >
                <Input placeholder="18h30" />
              </Form.Item>
              <Form.Item
                label={
                  <FormLabel label="Giờ kết thúc buổi học" require={false} />
                }
                name="end_at"
              >
                <Input placeholder="21h30" />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Cơ sở" require={false} />}
                name="branch_name"
              >
                <Select placeholder="Chọn cơ sở">
                  {classBranchValues.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.location}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Số buổi" require={false} />}
                name="lessons"
                rules={[{ type: 'number' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </>
          )}
        </Col>

        <Col>
          <Button.Primary
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
          >
            Lưu thông tin
          </Button.Primary>
        </Col>
      </Row>
    </Form>
  );
};
export default ClassInfoContent;
