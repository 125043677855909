import { ReactElement } from 'react';

import checkUserRole from 'utils/checkUserRole';
import {
  highestRoles,
  ROLE_AUTHOR,
  ROLE_TRAINER,
} from 'libraries/utils/globalVariable';
import NotAuthorized from 'components/commons/NotAuthorized';
import GetListPosts from './GetListPosts';

const OwnPosts = (): ReactElement => {
  const checkRole = checkUserRole([...highestRoles, ROLE_AUTHOR, ROLE_TRAINER]);

  if (!checkRole) {
    return <NotAuthorized />;
  }

  return <GetListPosts />;
};

export default OwnPosts;
