import { ReactElement } from 'react';
import { Col, Row } from 'antd';
import { ClassFind } from 'models/class.model';

import { classTypeValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';

interface PropsType {
  classInfo: ClassFind;
}

const FindClassInfo = ({ classInfo }: PropsType): ReactElement => {
  return (
    <Row gutter={[20, 20]}>
      <Col xs={8}>
        <strong>Hình thức:</strong>
      </Col>
      <Col xs={16}>
        {classTypeValues.map((item) => {
          if (item.value === classInfo.type) return item.label;
          else return '';
        })}
      </Col>
      {classInfo.teachers.length > 0 && (
        <>
          <Col xs={8}>
            <strong>Giảng viên:</strong>
          </Col>
          <Col xs={16}>
            {classInfo.teachers.map((item, index) => {
              return (
                <p key={item.id}>
                  {item.full_name}
                  {index < classInfo.teachers.length - 1 && ', '}
                </p>
              );
            })}
          </Col>
        </>
      )}
      <Col xs={8}>
        <strong>Số học viên:</strong>
      </Col>
      <Col xs={16}>{classInfo.total_students}</Col>
      {classInfo.schedule !== '' && (
        <>
          <Col xs={8}>
            <strong>Ngày khai giảng dự kiến:</strong>
          </Col>
          <Col xs={16}>
            {classInfo.schedule !== '' && formatDate(classInfo.schedule)}
          </Col>
        </>
      )}
      {classInfo.location !== '' && (
        <>
          <Col xs={8}>
            <strong>Cơ sở:</strong>
          </Col>
          <Col xs={16}>{classInfo.location}</Col>
        </>
      )}
    </Row>
  );
};
export default FindClassInfo;
