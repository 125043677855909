import { AlertOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { ReactElement, useMemo } from 'react';
import { alerts } from '../markers';
import '../toolBarBtns/css/menu.css';

interface iHeaderBtn {
  handleAlert?: (type: alerts) => void;
}

const DropdownAlert = ({ handleAlert }: iHeaderBtn): ReactElement => {
  const onClick: MenuProps['onClick'] = ({ key }) => {
    handleAlert && handleAlert(key as alerts);
  };

  const items: MenuProps['items'] = useMemo(
    () => [
      { key: 'note', label: 'Note', className: 'menu-item note-alert' },
      { key: 'tip', label: 'Tip', className: 'menu-item tip-alert' },
      {
        key: 'important',
        label: 'Important',
        className: 'menu-item important-alert',
      },
      {
        key: 'caution',
        label: 'Caution',
        className: 'menu-item caution-alert',
      },
      {
        key: 'warning',
        label: 'Warning',
        className: 'menu-item warning-alert',
      },
    ],
    [],
  );

  return (
    <Dropdown menu={{ items, onClick }}>
      <AlertOutlined />
    </Dropdown>
  );
};

const AlertBtn = ({ handleAlert }: iHeaderBtn) => {
  return (
    <Button
      title="Alert"
      type="text"
      icon={<DropdownAlert handleAlert={handleAlert} />}
    />
  );
};

export default AlertBtn;
