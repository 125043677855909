import { Col, Row } from 'antd';
import { GeneralInfo } from 'models/dashboard.model';
import { ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { route } from 'routers/routeNames';
import styled from 'styled-components';

interface PropsType {
  data: GeneralInfo;
}

const SummaryRow = styled(Row)`
  .ant-col {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    a {
      color: inherit;
    }
    .description-block {
      margin: 10px 0;
    }
    @media screen and (min-width: 768px) {
      &:not(:last-child) {
        .description-block {
          border-right: 1px solid #ddd;
        }
      }
    }
  }
`;

const DescriptionText = styled.span`
  display: block;
  text-transform: uppercase;
`;
const Price = styled.span`
  font-size: 18px;
  font-weight: 600;
  &.success {
    color: #00a65a;
  }
  &.warning {
    color: #f39c12;
  }
  &.danger {
    color: #dd4b39;
  }
`;

const SummaryAdvisory = ({ data }: PropsType): ReactElement => {
  return (
    <SummaryRow gutter={[30, 30]}>
      <Col xs={24} md={8}>
        <Link to={generatePath(route.order.list)}>
          <div className="description-block">
            <Price className="success">{data.count_order}</Price>
            <DescriptionText>Đơn hàng chưa xử lý</DescriptionText>
          </div>
        </Link>
      </Col>
      <Col xs={24} md={8}>
        <Link to={generatePath(route.order.advisory)}>
          <div className="description-block">
            <Price className="warning">{data.count_advisory}</Price>
            <DescriptionText>Cần tư vấn</DescriptionText>
          </div>
        </Link>
      </Col>
      <Col xs={24} md={8}>
        <Link to={generatePath(route.promotion.list)}>
          <div className="description-block">
            <Price className="danger">{data.count_voucher}</Price>
            <DescriptionText>Khuyến mãi đang chạy</DescriptionText>
          </div>
        </Link>
      </Col>
    </SummaryRow>
  );
};

export default SummaryAdvisory;
