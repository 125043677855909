import { Collapse, Panel } from 'components/commons/Collapse';
import { SaleClassReport } from 'models/report.model';
import { ReactElement } from 'react';
import ClassStudentTable from './ClassStudentTable';
import CollapseHeader from './CollapseHeader';

interface PropsType {
  classes: SaleClassReport[];
}

const ClassesCollapse = ({ classes }: PropsType): ReactElement => {
  return (
    <Collapse accordion>
      {classes.map((item) => {
        return (
          <Panel
            key={item.id}
            header={
              <CollapseHeader
                name={item.name}
                type={item.type}
                location={item.branch_name}
              />
            }
          >
            <ClassStudentTable students={item.list_students} />
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default ClassesCollapse;
