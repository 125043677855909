import {
  BarChartOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DesktopOutlined,
  GiftOutlined,
  LaptopOutlined,
  ReadOutlined,
  RiseOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TagsOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  highestRoles,
  ROLE_ADMIN,
  ROLE_AUTHOR,
  ROLE_MAINTAINER,
  ROLE_TRAINER,
} from 'libraries/utils/globalVariable';
import { CSSProperties, FC } from 'react';
import { route } from 'routers/routeNames';

interface IMenu {
  href: string;
  label: string;
  icon?: FC<{ style?: CSSProperties }>;
  submenu?: IMenu[];
  neededRoles?: number[];
}

const menu: IMenu[] = [
  {
    href: route.dashboard,
    label: 'Dashboard',
    icon: DashboardOutlined,
    neededRoles: highestRoles,
  },
  {
    href: 'report',
    label: 'Báo cáo',
    icon: BarChartOutlined,
    neededRoles: [...highestRoles, ROLE_TRAINER],
    submenu: [
      {
        href: route.report.finance,
        label: 'Báo cáo thu chi',
        neededRoles: highestRoles,
      },
      {
        href: route.report.teacher.list,
        label: 'Giảng viên',
        neededRoles: [...highestRoles, ROLE_TRAINER],
      },
      {
        href: route.report.sale.list,
        label: 'Nhân viên tuyển sinh',
        neededRoles: highestRoles,
      },
      {
        href: route.report.kpi_sale.list,
        label: 'KPI Nhân viên tuyển sinh',
        neededRoles: highestRoles,
      },
      // {
      //   href: route.report.classDebt,
      //   label: 'Nộp học phí từng lớp',
      //   neededRoles: highestRoles,
      // },
      // {
      //   href: route.report.attendance,
      //   label: 'Tình hình đi học từng lớp',
      //   neededRoles: highestRoles,
      // },
      {
        href: route.report.summary.list,
        label: 'Tổng kết lương',
        neededRoles: [ROLE_ADMIN], //todo cho tất cả mọi người xem phần tổng kết lương nhưng mà, nhưng không cho xem lương người khác
      },
    ],
  },
  {
    href: 'blogs',
    label: 'Blog',
    icon: ReadOutlined,
    submenu: [
      {
        href: route.blog.list,
        label: 'Tất cả bài viết',
        neededRoles: highestRoles,
      },
      {
        href: route.blog.own,
        label: 'Bài viết của tôi',
        neededRoles: [...highestRoles, ROLE_AUTHOR, ROLE_TRAINER],
      },
      {
        href: route.blog.other,
        label: 'Trang tĩnh',
        neededRoles: highestRoles,
      },
      {
        href: route.blog.list_twenty_days,
        label: 'Bài viết 20 ngày',
        neededRoles: [ROLE_ADMIN, ROLE_MAINTAINER],
      },
    ],
  },
  {
    href: 'orders',
    label: 'Đơn hàng',
    icon: ShoppingCartOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.order.list,
        label: 'Danh sách',
      },
      {
        href: route.order.create,
        label: 'Tạo đơn',
      },
      {
        href: route.order.advisory,
        label: 'Tư vấn',
      },
      // {
      //   href: route.order.debt,
      //   label: 'Nợ học phí',
      // },
    ],
  },
  {
    href: 'promotions',
    label: 'Khuyến mại',
    icon: GiftOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.promotion.list,
        label: 'Danh sách',
      },
      {
        href: route.promotion.createVoucher,
        label: 'Tạo voucher',
      },
      // {
      //   href: route.promotion.banner.list,
      //   label: 'Banner',
      // },
    ],
  },
  {
    href: 'classes',
    label: 'Lớp học',
    icon: LaptopOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.class.list,
        label: 'Danh sách lớp',
      },
      {
        href: route.class.create,
        label: 'Tạo lớp',
      },
      // {
      //   href: route.class.pre,
      //   label: 'Lớp sắp khai giảng',
      // },
      // {
      //   href: route.class.reserve,
      //   label: 'Bảo lưu',
      // },
    ],
  },
  {
    href: 'schedule',
    label: 'Lịch khai giảng',
    icon: CalendarOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.schedule.list,
        label: 'Danh sách',
      },
      {
        href: route.schedule.create,
        label: 'Tạo lịch',
      },
    ],
  },
  {
    href: 'courses',
    label: 'Khoá học',
    icon: DesktopOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.course.list,
        label: 'Danh sách khoá học',
      },
      {
        href: route.course.create,
        label: 'Tạo khoá học',
      },
    ],
  },
  {
    href: 'tracks',
    label: 'Lộ trình',
    icon: RiseOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.track.list,
        label: 'Danh sách',
      },
      {
        href: route.track.create,
        label: 'Tạo mới',
      },
    ],
  },
  {
    href: 'projects',
    label: 'Sản phẩm học viên',
    icon: LaptopOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.project.list,
        label: 'Danh sách',
      },
      {
        href: route.project.create,
        label: 'Tạo mới',
      },
    ],
  },
  // {
  //   href: 'job',
  //   label: 'Jobs',
  //   icon: DribbbleOutlined,
  //   neededRoles: highestRoles,
  //   submenu: [
  //     {
  //       href: route.job.jobs,
  //       label: 'List Jobs',
  //     },
  //     {
  //       href: route.job.companies,
  //       label: 'List Companies',
  //     },
  //     {
  //       href: route.job.employees,
  //       label: 'List Employees',
  //     },
  //   ],
  // },
  {
    href: 'categories',
    label: 'Tag',
    icon: TagsOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.category.tags,
        label: 'Danh sách tag',
      },
      // {
      //   href: route.category.subjectsList,
      //   label: 'Danh sách chủ đề',
      // },
      // {
      //   href: route.category.convertTag,
      //   label: 'Chuyển đổi tag bài viết',
      // },
    ],
  },
  {
    href: 'users',
    label: 'Quản lý users',
    icon: UserOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.user.manage,
        label: 'Danh sách users',
      },
      {
        href: route.user.manage_teacher,
        label: 'Danh sách giảng viên',
      },
      {
        href: route.user.create,
        label: 'Tạo user',
      },
    ],
  },
  // {
  //   href: 'branch',
  //   label: 'Branch',
  //   icon: EnvironmentOutlined,
  //   neededRoles: highestRoles,
  //   submenu: [
  //     {
  //       href: route.branch.list,
  //       label: 'List Branches',
  //     },
  //     {
  //       href: route.branch.create,
  //       label: 'Create Branch',
  //     },
  //   ],
  // },
  // {
  //   href: 'mail',
  //   label: 'Email',
  //   icon: MailOutlined,
  //   neededRoles: highestRoles,
  //   submenu: [
  //     {
  //       href: route.email.list,
  //       label: 'List Email',
  //     },
  //     {
  //       href: route.email.create,
  //       label: 'Create Email',
  //     },
  //   ],
  // },
  {
    href: 'configure',
    label: 'Cấu hình',
    icon: SettingOutlined,
    neededRoles: highestRoles,
    submenu: [
      {
        href: route.configure.banner.list,
        label: 'Banner trang chủ',
      },
      {
        href: route.configure.salary.index,
        label: 'Cấu hình lương',
        neededRoles: [ROLE_ADMIN],
      },
      // {
      //   href: route.configure.videoHomePage,
      //   label: 'Video Homepage',
      // },
      // {
      //   href: route.configure.logError,
      //   label: 'Log Error',
      // },
      // {
      //   href: route.configure.salary,
      //   label: 'Salary',
      // },
    ],
  },
];

export default menu;
