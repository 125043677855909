import {
  DeleteFilled,
  ExclamationCircleFilled,
  LeftOutlined,
} from '@ant-design/icons';
import { Col, Input, message, Row, Select } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import { projectClassValues } from 'libraries/utils/globalVariable';
import { ProjectInfo } from 'models/project.model';
import { ReactElement, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} from 'services/project.service';
import styled from 'styled-components';

interface PropsType {
  project: ProjectInfo;
}

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { Option } = Select;

const ProjectDetailContent = ({ project }: PropsType): ReactElement => {
  const { projectId } = useParams<{ projectId: string }>();
  const [deleteProject] = useDeleteProjectMutation();
  const [updateProject] = useUpdateProjectMutation();

  const history = useHistory();

  const [chosenThumbnail, setChosenThumbnail] = useState(project.thumbnail);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  const showDeleteConfirm = () => {
    confirm({
      title: 'Xác nhận xoá',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá sản phẩm này không ?',
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deleteProject(projectId)
          .unwrap()
          .then(() => {
            message.success('Xoá sản phẩm thành công!');
            setTimeout(() => {
              history.push(generatePath(route.project.list));
            }, 700);
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    updateProject({
      id: projectId,
      data: { ...values, status: values.status === 'true' ? true : false },
    })
      .unwrap()
      .then(() => {
        message.success('Cập nhật sản phẩm học viên thành công');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      title: project.title,
      class: project.class,
      link: project.link,
      student_name: project.student_name,
      thumbnail: project.thumbnail,
      status: project.status ? 'true' : 'false',
    });
    setChosenThumbnail(project.thumbnail);
  }, [project]);

  useEffect(() => {
    form.setFieldsValue({
      thumbnail: chosenThumbnail,
    });
  }, [chosenThumbnail]);

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.project.list)}
        secondaryText="Danh sách sản phẩm"
        text={project.title}
      />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={project}
      >
        <GroupButton>
          <LinksContainer style={{ display: 'flex' }}>
            <Link.Default to={generatePath(route.project.list)}>
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
            >
              Lưu
            </Button.Primary>
          </LinksContainer>
          <LinksContainer>
            <Link.Danger to="#" onClick={showDeleteConfirm}>
              <DeleteFilled /> Xoá
            </Link.Danger>
          </LinksContainer>
        </GroupButton>
        <Row>
          <Col xs={24} md={16} lg={10}>
            <BorderTopBoxContent>
              <Form.Item
                label={<FormLabel label="Sản phẩm lớp" />}
                name="class"
              >
                <Select>
                  {projectClassValues.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Tên sản phẩm" />}
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên sản phẩm!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Link sản phẩm" />}
                name="link"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên link sản phẩm!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Trạng thái" require={false} />}
                name="status"
              >
                <Select>
                  <Option value="true">Công khai</Option>
                  <Option value="false">Ẩn</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Tên học viên" />}
                name="student_name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên học viên!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Ảnh thumbnail" />}
                name="thumbnail"
              >
                {chosenThumbnail !== '' && <img src={chosenThumbnail} />}
              </Form.Item>
              <div>
                <Button.Info onClick={() => setIsModalVisible(true)}>
                  Chọn ảnh
                </Button.Info>
                <p>
                  <em>Kích thước ảnh: 400x300px. Định dạng jpg hoặc png.</em>
                </p>
              </div>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setChosenThumbnail}
        />
      )}
    </>
  );
};

export default ProjectDetailContent;
