import { createApi } from '@reduxjs/toolkit/query/react';
import { ProjectInfo, Project } from 'models/project.model';
import { baseQuery } from './base.service';

interface ProjectListType {
  total_page: number;
  projects: Project[];
}

export const projectApi = createApi({
  reducerPath: 'projectApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getProjectsList: builder.query<ProjectListType, string>({
      query: (keyword) => {
        return {
          url: `list-project${keyword}`,
          method: 'GET',
        };
      },
    }),
    getProjectDetail: builder.query<ProjectInfo, string>({
      query: (id) => {
        return {
          url: `project/${id}`,
          method: 'GET',
        };
      },
    }),
    createProject: builder.mutation<string, ProjectInfo>({
      query: (data) => {
        return {
          url: `project`,
          method: 'POST',
          body: data,
        };
      },
    }),
    updateProject: builder.mutation<string, { id: string; data: ProjectInfo }>({
      query: ({ id, data }) => {
        return {
          url: `project/${id}`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    deleteProject: builder.mutation<string, string>({
      query: (id) => {
        return {
          url: `project/${id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetProjectsListQuery,
  useGetProjectDetailQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectApi;
