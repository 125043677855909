import { Col, Row } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import ShowServerError from 'components/commons/ShowServerError';
import { GeneralInfo } from 'models/dashboard.model';
import { ReactElement } from 'react';
import { useGetGeneralInfoQuery } from 'services/dashboard.service';
import styled from 'styled-components';
import AdvisoryComponent from './advisory/AdvisoryComponent';
import RevenuesComponent from './revenues/RevenuesComponent';
import DashboardSummary from './summary/DashboardSummary';
import TrafficChart from './traffic/TrafficChart';

const Wrapper = styled.div`
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }
`;

const DashboardContent = (): ReactElement => {
  const { data, isError, error } = useGetGeneralInfoQuery();

  const summaryData = data ?? ({} as GeneralInfo);

  if (isError) return <ShowServerError error={error} />;

  return (
    <Wrapper>
      <Breadcrumb text="" />
      <Row gutter={[30, 30]}>
        <DashboardSummary data={summaryData} />
        <Col xs={24}>
          <TrafficChart />
        </Col>
        <Col xs={24} md={12}>
          <RevenuesComponent summaryData={summaryData} />
        </Col>
        <Col xs={24} md={12}>
          <AdvisoryComponent data={summaryData} />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default DashboardContent;
