import { PlusOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { Schedule } from 'models/schedule.model';
import { ReactElement } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetSchedulesListQuery } from 'services/schedule.service';
import ScheduleTable from './ScheduleTable';

const GetList = (): ReactElement => {
  const { data, isError, error } = useGetSchedulesListQuery();

  const schedules = data ?? ([] as Schedule[]);

  if (isError) return <ShowServerError error={error} />;

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.schedule.list)}
        secondaryText="Lịch khai giảng"
        text="Danh sách"
      />
      <LinksContainer>
        <Link.Primary to={generatePath(route.schedule.create)}>
          <PlusOutlined /> Tạo lịch khai giảng
        </Link.Primary>

        <Link.Success to={generatePath(route.class.create)}>
          <PlusOutlined /> Tạo lớp
        </Link.Success>
      </LinksContainer>
      <BorderBoxContent>
        <ScheduleTable schedules={schedules} />
      </BorderBoxContent>
    </>
  );
};

export default GetList;
