import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(RouterLink)`
  display: inline-block;
  font-weight: 600;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 3px;
  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
`;

const Primary = styled(Link)`
  color: #fff;
  background-color: #3c8dbc;
  border-color: #367fa9;
  &:hover,
  &:active {
    color: #fff;
    background-color: #367fa9;
    border-color: #367fa9;
  }
`;

const Default = styled(Link)`
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
  &:hover,
  &:active {
    color: #333;
    background-color: #e7e7e7;
    border-color: #adadad;
  }
`;

const Info = styled(Link)`
  background-color: #00c0ef;
  color: #fff;
  border-color: #00acd6;
  &:hover,
  &:active {
    color: #fff;
    background-color: #00acd6;
    border-color: #00acd6;
  }
`;

const Warning = styled(Link)`
  background-color: #f39c12;
  border-color: #e08e0b;
  color: #fff;
  &:hover,
  &:active {
    color: #fff;
    background-color: #e08e0b;
    border-color: #e08e0b;
  }
`;

const Danger = styled(Link)`
  background-color: #dd4b39;
  border-color: #d73925;
  color: #fff;
  &:hover,
  &:active {
    color: #fff;
    background-color: #d73925;
    border-color: #d73925;
  }
`;

const Success = styled(Link)`
  background-color: #00a65a;
  border-color: #008d4c;
  color: #fff;
  &:hover,
  &:active {
    color: #fff;
    background-color: #008d4c;
    border-color: #008d4c;
  }
`;

export default { Primary, Default, Info, Warning, Danger, Success };
