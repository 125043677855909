import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ShowServerError from 'components/commons/ShowServerError';
import TableContainer from 'components/table/TableContainer';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { SaleBlogReport } from 'models/report.model';
import { Moment } from 'moment';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSaleBlogReportQuery } from 'services/report.service';

interface PropsType {
  firstDay: Moment;
  lastDay: Moment;
}

const PostsReport = ({ firstDay, lastDay }: PropsType): ReactElement => {
  const { saleId } = useParams<{ saleId: string }>();
  const { data, isError, error } = useGetSaleBlogReportQuery({
    sale_id: saleId,
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });
  const posts = data ? data : ([] as SaleBlogReport[]);

  const columns: ColumnsType<SaleBlogReport> = [
    {
      width: '3%',
      title: '#',
      key: 'index',
      dataIndex: 'index',
    },
    {
      width: '67%',
      title: 'Tên bài viết',
      key: 'title',
      dataIndex: 'title',
      render: (_text, item) => (
        <a
          href={`https://techmaster.vn/posts/${item.id}/${item.slug}`}
          target="_blank"
          rel="noreferrer"
        >
          {item.title}
        </a>
      ),
    },
    {
      width: '10%',
      title: 'Lượt xem',
      key: 'view_count',
      dataIndex: 'view_count',
    },
    {
      width: '10%',
      title: 'Lượt thích',
      key: 'vote_score',
      dataIndex: 'vote_score',
    },
    {
      width: '10%',
      title: 'Ngày tạo',
      key: 'published_at',
      dataIndex: 'published_at',
      render: (published_at) =>
        published_at !== '' ? formatDate(published_at) : '',
    },
  ];

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <TableContainer>
      <Table<SaleBlogReport>
        columns={columns}
        dataSource={posts}
        pagination={{ showSizeChanger: false }}
        rowKey="id"
        bordered
      />
    </TableContainer>
  );
};

export default PostsReport;
