import { Image } from '../../../models/media.model';
import { ReactElement, useState } from 'react';
import { useFindImagesInSystemQuery } from '../../../services/media.service';
import { LIMIT_IMAGE_PER_PAGE } from '../../../utils/globalVariable';
import SystemImages from './SystemImages';

interface PropsType {
  keyword: string;
  selectedImage: Image;
  setSelectedImage: (value: Image) => void;
  getUrl: (value: string) => void;
  closeModal: () => void;
  setIsSearch: (value: boolean) => void;
  setKeyword: (valu: string) => void;
}

const GetAllImages = ({
  keyword,
  selectedImage,
  setSelectedImage,
  getUrl,
  closeModal,
  setIsSearch,
  setKeyword,
}: PropsType): ReactElement => {
  const [currentPage, setCurrentPage] = useState(1);

  useFindImagesInSystemQuery({
    pageNumber: currentPage,
    limitNumber: LIMIT_IMAGE_PER_PAGE,
    keyword,
  });

  return (
    <SystemImages
      selectedImage={selectedImage}
      setSelectedImage={setSelectedImage}
      getUrl={(value) => getUrl(value)}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      closeModal={closeModal}
      setIsSearch={(value: boolean) => setIsSearch(value)}
      setKeyword={(value: string) => setKeyword(value)}
    />
  );
};
export default GetAllImages;
