import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import { ReactElement } from 'react';
import { Line } from 'react-chartjs-2';
import { useGetTrafficDataQuery } from 'services/dashboard.service';

const TrafficChart = (): ReactElement => {
  const { data, isError, error } = useGetTrafficDataQuery();

  const dates_traffic = data ? data.dates_traffic : ([] as string[]);
  const traffics = data
    ? data.traffic.reduce((arr, item) => {
        arr.push(Number(item));
        return arr;
      }, [] as number[])
    : ([] as number[]);

  if (isError) return <ShowServerError error={error} />;
  return (
    <BorderTopBoxContent>
      <div className="box-header">Traffic</div>
      <div className="box-body">
        <Line
          width="100%"
          data={{
            labels: dates_traffic,
            datasets: [
              {
                data: traffics,
                label: '',
                borderColor: '#3e95cd',
                fill: true,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            plugins: { legend: { display: false } },
          }}
        />
      </div>
    </BorderTopBoxContent>
  );
};

export default TrafficChart;
