import { ReactElement } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { LeftOutlined, MailOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import Breadcrumb from 'components/commons/Breadcrumb';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { Col, Row } from 'antd';
import { HeaderBackgroundContent } from 'components/commons/ComponentContent';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import AddAdvisoryHistory from './AddAdvisoryHistory';
import { useSelector } from 'react-redux';
import { selectAdvisory } from 'store/order.slice';
import HistoryTable from './HistoryTable';

const Wrapper = styled.div`
  a.email {
    display: inline-block;
    font-weight: 600;
    padding: 6px 12px;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #00c0ef;
    color: #fff;
    border-color: #00acd6;
    &:hover,
    &:active {
      color: #fff;
      background-color: #00acd6;
      border-color: #00acd6;
    }
  }
`;

const AdvisoryContent = (): ReactElement => {
  const advisory = useSelector(selectAdvisory);

  return (
    <Wrapper>
      <Breadcrumb
        secondaryLink={generatePath(route.order.list)}
        secondaryText="Đơn hàng"
        thirdLink={generatePath(route.order.advisory)}
        thirdText="Danh sách tư vấn"
        text={advisory.full_name}
      />
      <LinksContainer>
        <Link.Default to={generatePath(route.order.advisory)}>
          <LeftOutlined /> Quay lại
        </Link.Default>
        {advisory.email !== '' && (
          <>
            <a href={`mailto:${advisory.email}`} className="email">
              <MailOutlined /> Gửi email
            </a>
          </>
        )}
      </LinksContainer>
      <Row gutter={[30, 30]}>
        <Col xs={24} md={12}>
          <HeaderBackgroundContent>
            <div className="box-header">Thông tin khách hàng</div>
            <div className="box-body">
              <Row gutter={[20, 20]}>
                <Col xs={24} lg={8}>
                  <strong>Họ tên:</strong>
                </Col>
                <Col xs={24} lg={16}>
                  {advisory.full_name}
                </Col>
                <Col xs={24} lg={8}>
                  <strong>Email:</strong>
                </Col>
                <Col xs={24} lg={16}>
                  {advisory.email}
                </Col>
                <Col xs={24} lg={8}>
                  <strong>Số điện thoại:</strong>
                </Col>
                <Col xs={24} lg={16}>
                  {advisory.phone}
                </Col>
                <Col xs={24} lg={8}>
                  <strong>Ghi chú khách hàng:</strong>
                </Col>
                <Col xs={24} lg={16}>
                  {advisory.note}
                </Col>
                <Col xs={24} lg={8}>
                  <strong>Khoá học quan tâm:</strong>
                </Col>
                <Col xs={24} lg={16}>
                  {advisory.item}
                </Col>
                <Col xs={24} lg={8}>
                  <strong>Ngày gửi:</strong>
                </Col>
                <Col xs={24} lg={16}>
                  {advisory.date && advisory.date !== ''
                    ? formatDate(advisory.date, 'dd-MM-yyyy H:mm')
                    : ''}
                </Col>
              </Row>
            </div>
          </HeaderBackgroundContent>
        </Col>
        <Col xs={24} md={12}>
          <AddAdvisoryHistory />
        </Col>
        <Col xs={24}>
          <HistoryTable history={advisory.history} />
        </Col>
      </Row>
    </Wrapper>
  );
};
export default AdvisoryContent;
