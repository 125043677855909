import { ReactElement, useContext, useState } from 'react';
import MediaManagement from '../../components/media/MediaManagement';
import { PictureOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectImages } from '../../store/image.slice';
import { Image } from '../../models/media.model';
import { message } from 'antd';

import { EditorContext as EC } from '../EditorContext';
import markers from '../markers';

const ChooseImage = (): ReactElement => {
  const images = useSelector(selectImages);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { state } = useContext(EC);
  const { editorView } = state;

  const handleImage = (value: string) => {
    if (typeof editorView !== 'undefined' && value !== '') {
      // nếu ổn thì điền markers
      const selectedImg = images.find((image: Image) => image.url === value);
      selectedImg
        ? markers.image(editorView, selectedImg.title, value)
        : message.error('Không tìm thấy url ành');
    }
  };

  return (
    <>
      <PictureOutlined onClick={() => setShowModal(true)} />
      {showModal && (
        <MediaManagement
          isModalVisible={showModal}
          closeModal={() => setShowModal(false)}
          getUrl={handleImage}
        />
      )}
    </>
  );
};

ChooseImage.align = 'left';
ChooseImage.pluginName = 'choose-image';

export default ChooseImage;
