import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactElement } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface PropType {
  text?: string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LoadingSpin = ({ text }: PropType): ReactElement => (
  <Container>
    <Spin indicator={antIcon} size="large" tip={text} />
  </Container>
);

LoadingSpin.defaultProps = {
  text: 'Đang tải trang...',
};

export default LoadingSpin;
