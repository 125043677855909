import { ReactElement, useEffect, useState } from 'react';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import {
  Col,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
} from 'antd';
import Button from 'libraries/components/commons/Button';
import { orderPaymentTypeValues } from 'libraries/utils/globalVariable';
import styled from 'styled-components';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import Modal from 'components/commons/Modal';
import { UsersType } from 'models/user.model';
import FindStudent from 'pages/classes/detail/student/FindStudent';
import {
  useGetOrderInfoQuery,
  useSendMailOrderMutation,
  useUpdateOrderInfoMutation,
} from 'services/order.service';
import { useParams } from 'react-router-dom';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { ComponentContent } from 'components/commons/ComponentContent';
import { useSelector } from 'react-redux';
import { selectOrder } from 'store/order.slice';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';
const { TextArea, Search } = Input;
const ImageStyle = styled.div`
  margin-bottom: 12px;
  max-width: 285px;
  @media (max-width: 1250px) {
    max-width: 200px;
  }
`;
const ContainerButtons = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 576px) {
    flex-wrap: wrap;
    button {
      width: 100%;
    }
  }
`;
const ContainerStyle = styled.p`
  margin-top: -24px;
  font-weight: 600;
  position: absolute;
  top: 135%;
  left: 0;
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

const PayInfo = (): ReactElement => {
  const { orderId } = useParams<{ orderId: string }>();
  // const orderInfo = useSelector(selectOrder);
  const { data: orderInfo, isLoading } = useGetOrderInfoQuery({ orderId });
  const [updateOrderInfo] = useUpdateOrderInfoMutation();
  const [sendMailOrder] = useSendMailOrderMutation();
  const is_company = orderInfo ? orderInfo.is_company : false;
  const [infoQr, setInfoQr] = useState({
    numberMoneyQr: '',
    descriptionQr: '',
  });
  const [selectedUser, setSelectedUser] = useState<
    Pick<UsersType, 'user_id' | 'phone' | 'email' | 'full_name'>
  >({
    user_id: '',
    phone: '',
    email: '',
    full_name: '',
  });
  useEffect(() => {
    if (!orderInfo) return;
    setSelectedUser({
      user_id: orderInfo.user_id,
      phone: orderInfo.phone,
      email: orderInfo.email,
      full_name: orderInfo.full_name,
    });
  }, [orderInfo]);

  const [form] = Form.useForm();
  const [keyword, setKeyword] = useState('');
  const [tmpKeyword, setTmpKeyword] = useState('');
  const [hasResult, setHasResult] = useState(false);
  const [isSearchDone, setIsSearchDone] = useState(false);
  const [visible, setVisible] = useState(false);
  const onSearch = () => {
    setIsSearchDone(true);
    setKeyword(tmpKeyword);
    setHasResult(true);
  };

  const addExistUser = () => {
    setIsChange(true);
    setIsModalVisible(false);
    form.setFields([
      {
        name: ['full_name'],
        value: selectedUser.full_name,
      },
      {
        name: ['email'],
        value: selectedUser.email,
      },
      {
        name: ['phone'],
        value: selectedUser.phone,
      },
    ]);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChange, setIsChange] = useState(false);
  // eslint-disable-next-line
  const onFinish = (values: any) => {
    const data = {
      user_id: selectedUser.user_id,
      payment_type: values.payment_type,
      note: values.note,
      is_company: is_company,
      company_info: orderInfo?.sponsor,
    };
    // console.log(data)
    updateOrderInfo({ orderId, data })
      .unwrap()
      .then(() => {
        setIsChange(false);
        message.success('Cập nhật thông tin thành công!');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleSendMail = (): void => {
    const data = {
      debt: infoQr.numberMoneyQr?.toString(),
      content: infoQr.descriptionQr,
      email: orderInfo?.email || '',
      phone: orderInfo?.phone || '',
      full_name: orderInfo?.full_name || '',
      items: orderInfo?.items.map((item) => item.name) || [],
      url_qr_code: `https://img.vietqr.io/image/970436-0011003224543-print.jpg?accountName=Trinh Minh Cuong&amount=${infoQr.numberMoneyQr}&addInfo=${infoQr.descriptionQr}`,
    };
    sendMailOrder({ orderId, data })
      .unwrap()
      .then(() => {
        setIsChange(false);
        message.success('Gửi mail thành công!');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  function handleClick() {
    setVisible(!visible);
  }
  const confirm = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleSendMail();
    setVisible(false);
  };

  const cancel = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setVisible(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    e.target.value.length >= 0 ? setIsChange(true) : setIsChange(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCopyImage = (): void => {
    const img: HTMLImageElement = new Image();
    img.crossOrigin = 'anonymous';
    img.src = `https://img.vietqr.io/image/970436-0011003224543-print.jpg?accountName=Trinh Minh Cuong&amount=${infoQr.numberMoneyQr}&addInfo=${infoQr.descriptionQr}`;
    img.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);

        canvas.toBlob((blob: Blob | null) => {
          if (blob) {
            navigator.clipboard.write([
              new ClipboardItem({ [blob.type]: blob }),
            ]);
            message.success('Copy ảnh thành công !');
          }
        });
      }
    };
  };

  if (isLoading) {
    return <LoadingSpin text="Đang tải dữ liệu" />;
  }

  return (
    <ComponentContent>
      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={orderInfo}
        form={form}
      >
        <Row gutter={[30, 0]}>
          <Col xs={24} md={12}>
            <h3 style={{ fontSize: '20px' }}>Thông tin thanh toán</h3>
            <Form.Item
              label={<FormLabel label="Họ & Tên người mua" require={true} />}
              name="full_name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập đầy đủ họ tên user',
                },
              ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label={<FormLabel label="Email" require={true} />}
              name="email"
              rules={[{ required: true, message: 'Vui lòng nhập email' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label={<FormLabel label="Số điện thoại" require={false} />}
              name="phone"
              // rules={[
              //   { required: true, message: 'Vui lòng nhập số điện thoại' },
              // ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={<FormLabel label="Hình thức thanh toán" require={false} />}
              name="payment_type"
            >
              <Select>
                <Select.Option value="">Chưa chọn</Select.Option>
                {orderPaymentTypeValues.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={<FormLabel label="Ghi chú đơn hàng" require={false} />}
              name="note"
            >
              <TextArea onChange={handleChange} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <h3 style={{ fontSize: '20px' }}>Thông tin thanh toán</h3>
            <div style={{ position: 'relative' }}>
              <Form.Item
                label={<FormLabel label="Số tiền chuyển:" require={false} />}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  value={infoQr.numberMoneyQr}
                  onChange={(value) =>
                    setInfoQr({ ...infoQr, numberMoneyQr: value ?? '' })
                  }
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </Form.Item>

              {infoQr.numberMoneyQr && (
                <ContainerStyle>
                  {convertCurrencyToWords(+infoQr.numberMoneyQr)}
                </ContainerStyle>
              )}
            </div>
            <Form.Item
              label={
                <FormLabel
                  label="Mô tả nội dung chuyển tiền:"
                  require={false}
                />
              }
            >
              <Input
                value={infoQr.descriptionQr}
                onChange={(e) =>
                  setInfoQr({ ...infoQr, descriptionQr: e.target.value })
                }
              />
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Popconfirm
                title="Bạn có chắc chắn muốn gửi mail ?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Có"
                cancelText="Không"
                visible={visible}
              >
                <Button.Primary
                  style={{ marginRight: '12px' }}
                  onClick={handleClick}
                  disabled={
                    infoQr.descriptionQr == '' || infoQr.numberMoneyQr == ''
                      ? true
                      : false
                  }
                >
                  Gửi mail
                </Button.Primary>
              </Popconfirm>
              <Button.Default
                style={{ width: '125px' }}
                onClick={handleCopyImage}
                icon={<CopyOutlined />}
              >
                Copy ảnh
              </Button.Default>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImageStyle>
                <img
                  src={`https://img.vietqr.io/image/VCB-0021000252385-print.png?accountName=C%C3%B4ng%20ty%20TNHH%20Techmaster%20Vi%E1%BB%87t%20Nam&amount=${infoQr.numberMoneyQr}&addInfo=${infoQr.descriptionQr}`}
                  alt="QrCode"
                />
              </ImageStyle>
            </div>
          </Col>
        </Row>

        <ContainerButtons>
          <Button.Default htmlType="submit" disabled={!isChange}>
            Lưu thông tin thanh toán
          </Button.Default>

          <Button.History onClick={showModal}>
            <EditOutlined />
            Thay đổi người mua
          </Button.History>
        </ContainerButtons>
      </Form>

      <Modal
        title="Thay đổi thông tin người mua"
        visible={isModalVisible}
        width={800}
        footer={[]}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Nhập email hoặc số điện thoại user</label>
        <Search
          allowClear
          enterButton="Tìm học viên"
          value={tmpKeyword}
          onChange={(e) => {
            setHasResult(false);
            setTmpKeyword(e.target.value);
          }}
          onSearch={onSearch}
        />

        {isSearchDone && (
          <FindStudent
            keyword={keyword}
            hasResult={hasResult}
            setSelectedUser={(value: UsersType) => {
              setSelectedUser({
                user_id: value.id,
                email: value.email,
                phone: value.phone,
                full_name: value.full_name,
              });
            }}
          />
        )}

        {selectedUser.user_id && (
          <div style={{ textAlign: 'end' }}>
            <Button.Info onClick={addExistUser}>Thay đổi</Button.Info>
          </div>
        )}
      </Modal>
    </ComponentContent>
  );
};

export default PayInfo;
