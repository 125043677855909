import { EditorView } from 'codemirror';
import React from 'react';
import parser from './parser';

interface EditorState {
  md: string;
  html: string;
  preview: boolean;
  splitScreen: boolean;
  fullscreen: boolean;
  currentActive: 'editor' | 'preview';
  scrollPosition: number;
  acceptTypes: string;

  editorHeight: number;
  editorView?: EditorView;

  uploadFunction: (
    file: File,
    title: string,
    onSuccess: (url: string) => void,
  ) => void;
  validateFile: (file: File) => boolean;
}

type EditorAction =
  | { type: 'init'; payload: EditorView }
  | { type: 'docChanged'; payload: string }
  | { type: 'scrollPositionChanged'; payload: number }
  | { type: 'currentActiveChanged'; payload: 'editor' | 'preview' }
  | { type: 'previewChanged' }
  | { type: 'fullscreenChanged' }
  | { type: 'splitScreenChanged' }
  | { type: 'editorHeightChanged'; payload: number }
  | { type: 'updateHtml'; payload: string };

type EditorContextType = {
  state: EditorState;
  dispatch: React.Dispatch<EditorAction>;
};

const EditorContext = React.createContext<EditorContextType>(
  {} as EditorContextType,
);
EditorContext.displayName = 'EditorContext';

const editorReducer = (
  state: EditorState,
  action: EditorAction,
): EditorState => {
  switch (action.type) {
    case 'init': {
      return { ...state, editorView: action.payload };
    }

    case 'docChanged': {
      // Chỉ cập nhật nếu nội dung thực sự thay đổi
      if (state.md !== action.payload) {
        return { ...state, md: action.payload };
      }
      return state;
    }

    case 'updateHtml': {
      return { ...state, html: parser.render(action.payload) };
    }

    case 'currentActiveChanged': {
      return { ...state, currentActive: action.payload };
    }

    case 'scrollPositionChanged': {
      return { ...state, scrollPosition: action.payload };
    }

    case 'previewChanged': {
      return { ...state, preview: !state.preview };
    }

    case 'fullscreenChanged': {
      return { ...state, fullscreen: !state.fullscreen };
    }

    case 'splitScreenChanged': {
      return { ...state, splitScreen: !state.splitScreen };
    }

    case 'editorHeightChanged': {
      return { ...state, editorHeight: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { EditorContext, editorReducer };
