import styled from 'styled-components';

const CallOut = styled.div`
  color: #fff;
  border-radius: 3px;
  margin: 15px 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
    font-weight: 600;
  }
  p {
    margin-bottom: 0;
  }
`;

const Warning = styled(CallOut)`
  background-color: #f39c12;
  border-color: #c87f0a;
`;

export default { Warning };
