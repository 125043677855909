import { createSlice } from '@reduxjs/toolkit';
import { ProfileProps } from 'libraries/models/user.model';
import { RootState } from 'store';
import { authApi } from 'libraries/services/auth.service';
import UserDefaultAvatar from 'assets/images/avatar.png';
import {
  highestRoles,
  ROLE_AUTHOR,
  ROLE_TRAINER,
} from 'libraries/utils/globalVariable';

interface UserState {
  user: ProfileProps;
  isLoginSuccess: boolean;
  loginMessage: string;
  roleCheck: {
    isFullRole: boolean;
    isOnlyAuthorRole: boolean;
    isOnlyTrainerRole: boolean;
  };
}

const checkUserRole = (roles: number[], neededRoles: number[]): boolean => {
  for (let i = 0; i < roles.length; i++) {
    for (let j = 0; j < neededRoles.length; j++) {
      if (roles[i] === neededRoles[j]) return true;
    }
  }
  return false;
};

const initialState: UserState = {
  user: {} as ProfileProps,
  isLoginSuccess: false,
  loginMessage: '',
  roleCheck: {
    isFullRole: false,
    isOnlyAuthorRole: false,
    isOnlyTrainerRole: false,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    // Update state user when login
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = {
          ...payload,
        };
        if (checkUserRole(payload.roles, highestRoles)) {
          state.roleCheck = { ...state.roleCheck, isFullRole: true };
        } else if (checkUserRole(payload.roles, [ROLE_TRAINER])) {
          state.roleCheck = { ...state.roleCheck, isOnlyTrainerRole: true };
        } else if (checkUserRole(payload.roles, [ROLE_AUTHOR])) {
          state.roleCheck = { ...state.roleCheck, isOnlyAuthorRole: true };
        }
      },
    );

    builder.addMatcher(
      authApi.endpoints.getLoggedInUser.matchFulfilled,
      (state, { payload }) => {
        state.user = {
          ...payload,
        };

        if (checkUserRole(payload.roles, highestRoles)) {
          state.roleCheck = { ...state.roleCheck, isFullRole: true };
        } else if (checkUserRole(payload.roles, [ROLE_TRAINER])) {
          state.roleCheck = { ...state.roleCheck, isOnlyTrainerRole: true };
        } else if (checkUserRole(payload.roles, [ROLE_AUTHOR])) {
          state.roleCheck = { ...state.roleCheck, isOnlyAuthorRole: true };
        }
      },
    );

    // Update state user when logout
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.user = {} as ProfileProps;
    });

    builder.addMatcher(
      authApi.endpoints.updateInformation.matchFulfilled,
      (state, { payload }) => {
        state.user = {
          ...payload,
        };
      },
    );
  },
});

export const { setUser } = authSlice.actions;

export const selectUser = (state: RootState): ProfileProps => state.auth.user;
export const selectUserRole = (
  state: RootState,
): {
  isFullRole: boolean;
  isOnlyAuthorRole: boolean;
  isOnlyTrainerRole: boolean;
} => state.auth.roleCheck;
export const selectUserAvatar = (state: RootState): string =>
  state.auth.user.avatar || UserDefaultAvatar;

export default authSlice.reducer;
