import styled from 'styled-components';

const LinksContainer = styled.div`
  font-size: 14px;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
  a,
  button {
    margin: 5px 10px 10px 0;
  }
`;
export default LinksContainer;
