import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {
  Card as AntCard,
  Image as AntImage,
  Form,
  Grid,
  Input,
  List,
  Pagination,
  message,
} from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { ReactElement, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Image } from '../../models/media.model';
import {
  useDeleteImageMutation,
  useUpdateImageInfoMutation,
} from '../../services/media.service';
import {
  removeImageFromList,
  updateImageInfoInList,
} from '../../store/image.slice';
import { LIMIT_IMAGE_PER_PAGE } from '../../utils/globalVariable';
import { matchScreen } from '../MediaQuery';
import { formatSize } from 'libraries/utils/formatSize';

const DeleteIcon = styled(DeleteOutlined)`
  font-size: 16px;
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0;
  transition: all 250ms ease-out;
  width: 28px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 1px solid #2222;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 29px 0px;
  background-color: #ffffff;
  color: #262626;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 3px 3px;
  &:hover {
    background-color: #ff4d4f;
    color: #fff1f0;
  }
`;
const Card = styled(AntCard)`
  border-width: 2px;
  &&& .ant-card-body {
    padding: 8px;
  }
  &.selected {
    border-color: #3c8dbc;
  }
  .ant-card-meta-title {
    text-overflow: unset;
    white-space: unset;
    text-align: center;
    display: flex;
    align-items: center;
  }
  &:hover + ${DeleteIcon}, & + ${DeleteIcon}:hover {
    opacity: 1;
  }
`;

interface PropsType {
  images: Image[];
  total: number;
  selectedImage: Image;
  setSelectedImage: (value: Image) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}
interface ITitleForm {
  item: Image;
  setSelectedImage: (value: Image) => void;
  iconVisible?: boolean;
  selectedImage: Image;
}
const TitleForm = ({
  item,
  setSelectedImage,
  iconVisible,
  selectedImage,
}: ITitleForm) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [updateImgInfo] = useUpdateImageInfoMutation();
  // const [visible, setVisible] = useState(false);
  const onFinish = (values: { title: string }) => {
    const title = values.title;
    form
      .validateFields()
      .then(() => {
        updateImgInfo({ id: item.id, title: title, description: '' })
          .unwrap()
          .then(() => {
            dispatch(
              updateImageInfoInList({
                id: item.id,
                title: title,
                description: '',
              }),
            );
            setSelectedImage(item);
            message.success('Cập nhật tên ành');
          })
          .catch((error) => {
            message.error(error.data);
            console.log('Error', error.data);
          });
      })
      .catch((error) => {
        message.error(error.data);
        console.log('Error', error.data);
      });
  };
  // Hiện tại form values bị update chậm hơn so với item.title
  // code bên dưới là để tự đặt item.title vào form value
  // onValues change để tự điền values nếu người dùng để trống
  const onBlur = () => {
    const title = form.getFieldValue('title').trim();
    if (title === '') {
      form.setFieldsValue({ title: item.title });
    } else if (title === item.title) {
      return;
    } else {
      updateImgInfo({ id: item.id, title: title, description: '' })
        .unwrap()
        .then(() => {
          dispatch(
            updateImageInfoInList({
              id: item.id,
              title: title,
              description: '',
            }),
          );
          setSelectedImage(item);
          message.success('Cập nhật tên ảnh');
        })
        .catch((error) => {
          message.error(error.data);
          console.log('Error', error.data);
        });
    }
    // setVisible(false);
  };
  useLayoutEffect(() => {
    const title = form.getFieldValue('title').trim();
    if (title === item.title) return;
    form.setFieldsValue({ title: item.title });
  }, [item]);
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        initialValue={item.title}
        name="title"
        rules={[{ required: true, message: 'Vui lòng nhập tên ảnh!' }]}
      >
        <Input
          suffix={
            (selectedImage.id === item.id ? true : iconVisible) && (
              <EditOutlined />
            )
          }
          onBlur={onBlur}
          // onFocus={() => setVisible(true)}
          bordered={false}
        />
        {/* <p>{item.title}</p> */}
      </Form.Item>
    </Form>
  );
};
interface ICardProps {
  item: Image;
  handleOnclick: (item: Image) => void;
  // setVisible: React.Dispatch<SetStateAction<boolean>>;
  // setCurrent: React.Dispatch<SetStateAction<number>>;
  // currentPreview: number;
  setSelectedImage: (arg: Image) => void;
  selectedImage: Image;
  showDeleteConfirm: (arg: string) => void;
}
const CardCustom = ({
  item,
  handleOnclick,
  // setVisible,
  // setCurrent,
  // currentPreview,
  selectedImage,
  setSelectedImage,
  showDeleteConfirm,
}: ICardProps) => {
  const [iconVisible, setIconVisible] = useState(false);
  return (
    <div
      onMouseEnter={() => setIconVisible(true)}
      onMouseLeave={() => setIconVisible(false)}
      style={{ padding: '4px', position: 'relative' }}
    >
      <Card
        onClick={() => handleOnclick(item)}
        hoverable
        cover={
          <AntImage
            style={{ height: '200px', objectFit: 'cover' }}
            alt={item.description || 'No Description'}
            src={item.url}
            preview={false}
            // onClick={() => {
            //   setVisible(true);
            //   setCurrent(currentPreview);
            // }}
          />
        }
        className={
          selectedImage.id && selectedImage.id === item.id ? 'selected' : ''
        }
      >
        {/* <Meta title={<TitleForm item={item} setSelectedImage={setSelectedImage} />} /> */}
        <TitleForm
          iconVisible={iconVisible}
          selectedImage={selectedImage}
          item={item}
          setSelectedImage={setSelectedImage}
        />
        <p
          style={{
            fontSize: '12px',
            color: '#000',
            textAlign: 'center',
            margin: '0',
          }}
        >
          <span>
            {item.width} x {item.height}
          </span>
          <span style={{margin: "0 8px", color: "#ccc"}}>|</span>
          <span>
            {formatSize(item.size)}
          </span>
        </p>
      </Card>
      <DeleteIcon
        onClick={() => {
          setSelectedImage(item);
          showDeleteConfirm(item.id);
        }}
      />
    </div>
  );
};

const MediaContent = ({
  images,
  total,
  selectedImage,
  setSelectedImage,
  currentPage,
  setCurrentPage,
}: PropsType): ReactElement => {
  const breakpoint = Grid.useBreakpoint();
  const isLargeScreen =
    matchScreen('xl', breakpoint) || matchScreen('xxl', breakpoint);
  const [deleteImage] = useDeleteImageMutation();
  const dispatch = useDispatch();
  // const [visible, setVisible] = useState(false);
  // const [current, setCurrent] = useState(0);

  const handleOnclick = (image: Image) => {
    setSelectedImage(image);
  };
  const handleOnchangePage = (page: number) => {
    setCurrentPage(page);
  };
  const showDeleteConfirm = (id: string) => {
    confirm({
      title: 'Bạn có chắc chắn muốn xoá ảnh này?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Xác nhận',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deleteImage({ id })
          .unwrap()
          .then(() => {
            message.success('Xoá ảnh thành công!');
            dispatch(removeImageFromList(id));
            setSelectedImage({} as Image);
          })
          .catch((error) => {
            message.error(error.data);
            console.log('Error: ', error.data.error);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <>
      <List
        grid={{
          gutter: 8,
          column: isLargeScreen ? 5 : 3,
        }}
        size="large"
        dataSource={images}
        renderItem={(item: Image) => {
          return (
            <div style={{ padding: '4px', position: 'relative' }}>
              <CardCustom
                showDeleteConfirm={showDeleteConfirm}
                handleOnclick={handleOnclick}
                item={item}
                // setVisible={setVisible}
                // setCurrent={setCurrent}
                // currentPreview={index}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            </div>
          );
        }}
      />
      <Pagination
        current={currentPage}
        pageSize={
          isLargeScreen ? LIMIT_IMAGE_PER_PAGE : LIMIT_IMAGE_PER_PAGE / 2
        }
        showSizeChanger={false}
        defaultCurrent={currentPage}
        total={total}
        style={{ marginTop: '20px' }}
        onChange={handleOnchangePage}
      />
      {/* <div style={{ display: 'none' }}>
        style cho preview group nam o index.css '.ant-image-preview-root'
        <AntImage.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis), current }}
        >
          {images.map((item: Image) => {
            return <AntImage key={item.id} src={item.url} />;
          })}
        </AntImage.PreviewGroup>
      </div> */}
    </>
  );
};

export default MediaContent;
