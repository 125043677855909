import ShowServerError from 'components/commons/ShowServerError';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAdvisoryDetailQuery } from 'services/order.service';
import AdvisoryContent from './AdvisoryContent';

const GetAdvisory = (): ReactElement => {
  const { advisoryId } = useParams<{ advisoryId: string }>();
  const { isError, error } = useGetAdvisoryDetailQuery(advisoryId);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return <AdvisoryContent />;
};

export default GetAdvisory;
