import { message, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import TableContainer from 'components/table/TableContainer';
import Button from 'libraries/components/commons/Button';
import { TrackList } from 'models/trackmaster.model';
import { Key, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useActiveTrackinTrackMasterMutation } from 'services/trackmaster.service';
import { selectTrack, selectTrackMasterInfo } from 'store/trackmaster.slice';

const TrackMasterTable = (): ReactElement => {
  const columns: ColumnsType<TrackList> = [
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      width: '30%',
      render: (_text, item) => {
        return (
          <RouterLink
            to={generatePath(route.track.detail, { trackId: item.id })}
          >
            {item.name}
          </RouterLink>
        );
      },
    },
  ];

  const trackMasterInfo = useSelector(selectTrackMasterInfo);
  const dispatch = useDispatch();

  const { trackMasterId } = useParams<{ trackMasterId: string }>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isSelect, setIsSelect] = useState(false);
  const [activeTrackInTrackMaster] = useActiveTrackinTrackMasterMutation();

  const onSelectChange = (selected: Key[]): void => {
    setSelectedRowKeys(selected);
    dispatch(selectTrack(selected[0]));
    setIsSelect(true);
  };

  const saveTrack = () => {
    const data = {
      active_id: selectedRowKeys[0],
    };

    activeTrackInTrackMaster({ trackMasterId: trackMasterId, data: data })
      .unwrap()
      .then(() =>
        message.success('Cập nhật trạng thái hiển thị track thành công!'),
      )
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <>
      <BorderBoxContent>
        <TableContainer>
          {trackMasterInfo && (
            <Table<TrackList>
              rowSelection={{
                type: 'radio',
                selectedRowKeys,
                onChange: onSelectChange,
              }}
              columns={columns}
              dataSource={trackMasterInfo.list_tracks}
              pagination={false}
              rowKey="id"
              bordered
            />
          )}
        </TableContainer>
        <Button.Primary
          style={{ marginTop: '16px' }}
          disabled={!isSelect}
          onClick={saveTrack}
        >
          Lưu
        </Button.Primary>
      </BorderBoxContent>
    </>
  );
};

export default TrackMasterTable;
