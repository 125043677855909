import Breadcrumb from 'components/commons/Breadcrumb';
import { Voucher } from 'models/promotion.model';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVoucherDetailQuery } from 'services/promotion.service';
import VoucherDetail from './VoucherDetail';

import ShowServerError from 'components/commons/ShowServerError';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

const GetVoucher = (): ReactElement => {
  const { voucherName } = useParams<{ voucherName: string }>();
  const { data, isError, error } = useGetVoucherDetailQuery(voucherName);

  const voucher = data ? data : ({} as Voucher);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.promotion.list)}
        secondaryText="Khuyến mại"
        text={voucher.name ? `Voucher: ${voucher.name}` : ''}
      />
      <VoucherDetail voucher={voucher} />
    </>
  );
};

export default GetVoucher;
