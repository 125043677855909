import { Collapse as AntCollapse } from 'antd';
import styled from 'styled-components';

const { Panel: AntPanel } = AntCollapse;

export const Collapse = styled(AntCollapse)`
  border: none;
  font-size: 16px;
  background-color: transparent;
`;

// export const Panel = styled(AntPanel)`
//   && {
//     border-bottom: none;
//     background-color: #a2c1a2;
//     margin-bottom: 20px;
//     .ant-collapse-header {
//       color: #343a40;
//       font-weight: 600;
//     }
//     .ant-collapse-content {
//       &.ant-collapse-content-active {
//         border-top: none;
//         .ant-collapse-content-box {
//           padding: 12px 0;
//         }
//       }
//       & > .ant-collapse-content-box,
//       &.ant-collapse-content-active > .ant-collapse-content-box {
//         padding: 12px 0;
//       }
//     }
//   }
// `;

export const Panel = styled(AntPanel)`
  && {
    border-bottom: none;
    border-radius: 5px;
    background-color: #e3f2fd;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
    margin-bottom: 15px;
    .ant-collapse-header {
      color: #343a40;
      font-weight: 700;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition:
        all 0.3s,
        visibility 0s;
      &::before,
      &::after {
        content: none;
      }
    }
    .ant-collapse-content {
      &.ant-collapse-content-active {
        border-radius: 0 0 5px 5px;
        border-top: none;
        .ant-collapse-content-box {
          padding: 12px;
        }
      }
      & > .ant-collapse-content-box,
      &.ant-collapse-content-active > .ant-collapse-content-box {
        padding: 12px;
      }
    }
  }
`;
