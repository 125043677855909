import { AdmissionClass } from 'models/dashboard.model';
import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { formatDate } from 'libraries/utils/timeDistanceToNow';

interface PropsType {
  items: AdmissionClass[];
}

const ClassesTable = ({ items }: PropsType): ReactElement => {
  const columns: ColumnsType<AdmissionClass> = [
    {
      key: 'class_id',
      width: '55%',
      title: 'Tên lớp',
      dataIndex: 'item_id',
      render: (_text, item) => (
        <RouterLink
          to={generatePath(route.class.detail, { classId: item.class_id })}
        >
          {item.class_name}
        </RouterLink>
      ),
    },
    {
      key: 'student_count',
      width: '20%',
      title: 'Số học viên',
      dataIndex: 'student_count',
    },
    {
      key: 'start_date',
      width: '25%',
      title: 'Ngày khai giảng',
      dataIndex: 'start_date',
      render: (start_date) => (start_date !== '' ? formatDate(start_date) : ''),
    },
  ];

  return (
    <TableContainer>
      <Table<AdmissionClass>
        columns={columns}
        dataSource={items}
        pagination={{ showSizeChanger: false }}
        rowKey="class_id"
        bordered
      />
    </TableContainer>
  );
};

export default ClassesTable;
