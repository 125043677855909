import { Image, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import DefaultAvatar from 'assets/images/avatar.png';
import Container from 'components/table/TableContainer';
import { Student } from 'models/user.model';
// import CreateOrderForm from 'pages/orders/create/CreateOrderForm';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generatePath, Link as RouterLink, Redirect } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useDelayStudentFromClassMutation,
  useGetAllStudentsClassQuery,
  usePatchNoteEditMutation,
  useRemovedStudentFromClassMutation,
  useReturnStudentToClassMutation,
} from 'services/classes.service';
import { selectClass } from 'store/classes.slice';
import { selectOrder } from 'store/order.slice';
import styled from 'styled-components';
import { EditableCell, EditableRow } from './Editable';

export const NoteWrapper = styled.div`
  word-wrap: 'break-word';
  word-break: 'break-word';
  .anticon-edit {
    position: absolute;
    font-size: 20px;
    right: 4px;
    top: 6px;
    padding: 2px 4px;
    cursor: pointer;
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    display: none;
  }
  &:hover .anticon-edit {
    display: block;
  }
`;

const TableContainer = styled(Container)`
  .ant-table-wrapper {
    overflow: auto;
  }
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-height: 28px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
  img.ant-image-img {
    border-radius: 50%;
  }
`;

const StudentsDetailTable = (): ReactElement => {
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const { name: className, status: classStatus } = useSelector(selectClass);
  const { id: orderId } = useSelector(selectOrder);

  const [removedStudentFromClass] = useRemovedStudentFromClassMutation();
  const [delayStudent] = useDelayStudentFromClassMutation();
  const [returnStudentToClass] = useReturnStudentToClassMutation();
  const { data: students = [] as Student[], isFetching: isStudentsFetching } =
    useGetAllStudentsClassQuery(classId);

  const [_, { isLoading: isPatchLoading }] = usePatchNoteEditMutation();

  // * status cần target là delay và removed
  // * khi user select checkbox thì check status và hiện thị nút tương ứng

  const defaultColumns: (ColumnsType<Student>[number] & {
    editable?: boolean;
    dataIndex?: keyof Student;
  })[] = [
    {
      key: 'table',
      width: '13%',
      title: 'Danh sách học viên',
      dataIndex: 'avatar',
      render: (_text, item) => {
        return (
          <div style={{ display: 'flex', gap: '24px' }}>
            <Image
              width={100}
              src={item.avatar != '' ? item.avatar : DefaultAvatar}
            />
            <div>
              <RouterLink
                to={generatePath(route.class.student, {
                  classId: classId,
                  studentId: item.id,
                  orderId: item.order_id,
                })}
              >
                {item.full_name}
              </RouterLink>
              <div>
                <a href={`mailto:${item.email}`}>{item.email}</a>
              </div>
              <div>
                <a href={`tel:${item.phone}`}>{item.phone}</a>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const columns = defaultColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Student) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        classId,
        // handleSave,
      }),
    };
  });

  if (orderId) {
    return <Redirect to={generatePath(route.order.detail, { orderId })} />;
  }

  return (
    <>
      <TableContainer>
        <Table<Student>
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          loading={isStudentsFetching || isPatchLoading}
          rowClassName={() => 'editable-row'}
          columns={columns}
          dataSource={students}
          pagination={false}
          rowKey="index"
          bordered
        />
      </TableContainer>
    </>
  );
};
export default StudentsDetailTable;

const Total = styled.span`
  &.paid {
    color: #00a65a;
  }
  &.debt {
    color: #ff0800;
  }
`;
const Gain = styled.span`
  padding: 6px 12px;
  color: #f39c12;
  font-size: 16px;
  text-align: center;
`;
