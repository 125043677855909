import styled from 'styled-components';
import { Menu as AntMenu } from 'antd';

const Main = styled(AntMenu)`
  background-color: #fff;
  padding: 8px;
`;

const Item = styled(AntMenu.Item)`
  &:not(.range-picker) {
    &.range-picker {
      input {
        font-size: 13px;
      }
    }
    &:not(.range-picker) {
      font-size: 13px;
      height: 26px;
      line-height: normal;
      background-color: #f5f5f5;
      border: 1px solid #f5f5f5;
      border-radius: 4px;
      color: #08c;
      padding: 3px 12px;
      margin-bottom: 8px;
      cursor: pointer;
    }
    a {
      display: block;
      color: #000000;
    }
    &.ant-menu-item-selected {
      background-color: #08c;
      border: 1px solid #08c;
      color: #fff;
    }
  }
`;

export default { Main, Item };
