import { Checkbox } from 'antd';
import Modal from 'components/commons/Modal';
import Search from 'components/commons/Search';
import Button from 'libraries/components/commons/Button';
import { UsersType } from 'models/user.model';
import { ReactElement, useState } from 'react';
import CreateUser from './CreateUser';
import FindStudent from './FindStudent';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
  setUserId: (value: string) => void;
  setUserName: (value: string) => void;
}

const AddUserToPayment = ({
  isModalVisible,
  closeModal,
  setUserId,
  setUserName,
}: PropsType): ReactElement => {
  const [keyword, setKeyword] = useState('');
  const [tmpKeyword, setTmpKeyword] = useState('');
  const [isSearchDone, setIsSearchDone] = useState(false);
  const [hasResult, setHasResult] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as UsersType);

  const onSearch = () => {
    setIsSearchDone(true);
    setKeyword(tmpKeyword);
    setHasResult(true);
  };

  return (
    <Modal
      title="Chọn user"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      keyboard={false}
      width={800}
      footer={[]}
    >
      <label>Nhập email hoặc số điện thoại user</label>
      <Search
        allowClear
        enterButton="Tìm user"
        size="large"
        value={tmpKeyword}
        onChange={(e) => {
          setHasResult(false);
          setTmpKeyword(e.target.value);
        }}
        onSearch={onSearch}
      />
      <Checkbox
        checked={createNew}
        onChange={(e) => setCreateNew(e.target.checked)}
      >
        Tạo tài khoản cho học viên mới
      </Checkbox>

      {isSearchDone && !createNew && (
        <FindStudent
          keyword={keyword}
          hasResult={hasResult}
          setSelectedUser={(value: UsersType) => setSelectedUser(value)}
        />
      )}
      {createNew ? (
        <CreateUser
          setUserId={setUserId}
          setUserName={setUserName}
          closeModal={closeModal}
        />
      ) : selectedUser.id ? (
        <div style={{ textAlign: 'end' }}>
          <Button.Info
            onClick={() => {
              setUserId(selectedUser.id);
              setUserName(selectedUser.full_name);
              closeModal();
            }}
          >
            Lựa chọn
          </Button.Info>
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
};
export default AddUserToPayment;
