import { Col, DatePicker, Input, message, Select } from 'antd';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import Button from 'libraries/components/commons/Button';
import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateThenAddStudentToClassMutation } from 'services/classes.service';
import { addStudentToList } from 'store/classes.slice';
import moment from 'moment';
interface PropsType {
  classId: string;
  status: 'pending' | 'active';
  class_name: string;
  closeModal: () => void;
}
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
interface CreateUserThenAddClass {
  id: string;
  full_name: string;
  status: string;
  join_date: string;
  sale_name: string;
  note: string;
}

const { TextArea } = Input;
const { Option } = Select;

const CreateUser = ({
  classId,
  status,
  class_name,
  closeModal,
}: PropsType): ReactElement => {
  const dispatch = useDispatch();
  const [createUser] = useCreateThenAddStudentToClassMutation();

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    const dates = moment(values.dob);
    const times = moment(18, 'HH:mm');

    dates.set({
      hour: times.get('hour'),
      minute: times.get('minute'),
      second: times.get('second'),
    });

    createUser({
      class_id: classId,
      data: {
        ...values,
        dob: dates.toISOString(),
        note:
          status === 'pending'
            ? values.note
            : 'Thêm học viên (không tự tạo đơn hàng)',
        status,
        class_name,
      },
    })
      .unwrap()
      .then((res: CreateUserThenAddClass) => {
        dispatch(
          addStudentToList({
            student: res,
            avatar: '',
            email: values.email,
            phone: values.phone,
          }),
        );
        message.success('Thêm học viên mới thành công!');
        closeModal();
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <Form onFinish={onFinish} layout="vertical" initialValues={{ type: 'lab' }}>
      <Form.Item
        label={<FormLabel label="Họ tên học viên" />}
        name="full_name"
        rules={[{ required: true, message: 'Xin vui lòng nhập tên học viên!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={<FormLabel label="Ngày sinh (Không bắt buộc)" />}
        name="dob"
      >
        <DatePicker format={dateFormatList} />
      </Form.Item>
      <Form.Item
        label={<FormLabel label="Email" />}
        name="email"
        rules={[
          {
            type: 'email',
            message: 'Địa chỉ email không hợp lệ!',
          },
          { required: true, message: 'Vui lòng nhập email!' },
        ]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        label={<FormLabel label="Số điện thoại" />}
        name="phone"
        rules={[
          { required: true, message: 'Xin vui lòng nhập số điện thoại!' },
          {
            pattern: /^[0-9]{10,11}$/,
            message: 'Sai định dạng, số điện thoại phải có 10 hoặc 11 số!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      {status === 'pending' && (
        <Form.Item
          name="note"
          label={<FormLabel label="Ghi chú" require={false} />}
        >
          <TextArea rows={2} />
        </Form.Item>
      )}
      <Form.Item label={<FormLabel label="Hình thức lớp" />} name="type">
        <Select style={{ width: '200px' }}>
          <Option value="lab">Onlab</Option>
          <Option value="live">Trực tuyến có tương tác</Option>
        </Select>
      </Form.Item>
      <Col style={{ textAlign: 'end' }}>
        <Button.Info type="primary" htmlType="submit">
          Tạo tài khoản và thêm vào lớp
        </Button.Info>
      </Col>
    </Form>
  );
};
export default CreateUser;
