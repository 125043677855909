import { ReactElement } from 'react';
import { Result } from 'antd';
import styled from 'styled-components';

interface PropsType {
  title: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const subTitle = (
  <>
    Bạn có thể quay lại <a href="/">dashboard</a> hoặc liên hệ &nbsp;
    <a href="mailto:cuong@techmaster.vn">cuong@techmaster.vn</a>
  </>
);

const NotAuthorized = ({ title }: PropsType): ReactElement => {
  return (
    <Container>
      <Result status="403" title={title} subTitle={subTitle} />
    </Container>
  );
};

NotAuthorized.defaultProps = {
  title: 'Bạn không có quyền thực hiện tác vụ này',
};

export default NotAuthorized;
