import { highestRoles } from 'libraries/utils/globalVariable';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { selectUserRole } from 'store/auth.slice';
import checkUserRole from 'utils/checkUserRole';
import NotAuthorized from 'components/commons/NotAuthorized';
import GetList from './GetList';

const SchedulesList = (): ReactElement => {
  const { isOnlyAuthorRole, isOnlyTrainerRole } = useSelector(selectUserRole);
  const checkRole = checkUserRole(highestRoles);

  if (!checkRole) {
    if (isOnlyAuthorRole || isOnlyTrainerRole) {
      return <Redirect to={route.blog.own} />;
    } else return <NotAuthorized />;
  }
  return <GetList />;
};

export default SchedulesList;
