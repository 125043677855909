import { ReactElement } from 'react';
import { Form, Input, Button } from 'antd';
import { useLoginMutation } from 'libraries/services/auth.service';
import { Credentials } from 'models/user.model';
import styled from 'styled-components';
import LogoImage from 'assets/images/logo.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);
  height: 100%;
`;

const LoginContainer = styled.div`
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
  width: 350px;
  background: white;
`;

const LoginLogo = styled.div`
  border-radius: 5px 5px 0 0;
  background: linear-gradient(to right, #232526, #414345);
  display: flex;
  justify-content: center;
  padding: 24px 0;
`;

const ErrorStatus = styled.p`
  text-align: center;
  color: red;
  font-weight: 500;
`;

const LoginPage = (): ReactElement => {
  const [login, { isLoading, error }] = useLoginMutation();

  const onFinish = async (data: Credentials) => {
    login(data);
  };

  let errorStatus;
  if (error && 'status' in error) {
    errorStatus = error.status;
  }

  return (
    <Wrapper>
      <LoginContainer>
        <LoginLogo>
          <img src={LogoImage} />
        </LoginLogo>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          style={{ padding: '24px' }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Địa chỉ email không hợp lệ!',
              },
              {
                required: true,
                message: 'Xin vui lòng nhập địa chỉ email!',
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Xin vui lòng nhập mật khẩu!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <ErrorStatus>
            {(errorStatus === 401 || errorStatus === 500) &&
              'Thông tin đăng nhập không chính xác'}
          </ErrorStatus>

          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            style={{ width: '100%' }}
          >
            Đăng nhập
          </Button>
        </Form>
      </LoginContainer>
    </Wrapper>
  );
};

export default LoginPage;
