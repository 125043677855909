import { Input, message } from 'antd';
import Modal from 'components/commons/Modal';
import { ReactElement } from 'react';
import { useCreateNewTagMutation } from 'services/tag.service';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import Button from 'libraries/components/commons/Button';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
}

const CreateTagModal = ({
  isModalVisible,
  closeModal,
}: PropsType): ReactElement => {
  const [createTag] = useCreateNewTagMutation();

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    createTag(values)
      .unwrap()
      .then(() => {
        message.success('Tạo tag mới thành công');
        closeModal();
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <Modal
      title="Tạo tag"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      keyboard={false}
      width={600}
      footer={[]}
    >
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label={<FormLabel label="Tên tag" />}
          name="name"
          rules={[{ required: true, message: 'Xin vui lòng nhập tên tag!' }]}
        >
          <Input />
        </Form.Item>
        <Button.Primary type="primary" htmlType="submit">
          Lưu
        </Button.Primary>
      </Form>
    </Modal>
  );
};
export default CreateTagModal;
