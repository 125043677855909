import { ReactElement } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { PayReceiptDetail } from 'models/report.model';
import { route } from 'routers/routeNames';
import { useGetReceiptPaymentDetailQuery } from 'services/report.service';

import Breadcrumb from 'components/commons/Breadcrumb';
import ShowServerError from 'components/commons/ShowServerError';
import useTitle from 'libraries/utils/useTitle';
import LoadingSpin from '../../../../libraries/components/LoadingSpin';
import PaymentDetailContent from './PaymentDetailContent';

const GetPayment = (): ReactElement => {
  const { payId } = useParams<{ payId: string }>();
  const { data, isError, isLoading, error, refetch } =
    useGetReceiptPaymentDetailQuery(payId);

  const payment = data ?? ({} as PayReceiptDetail);

  // Di chuyển useTitle ra khỏi câu lệnh điều kiện
  useTitle(`Phiếu chi ${data?.id ?? ''}`);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.report.finance)}
        secondaryText="Báo cáo thu chi"
        text={`Phiếu chi ${payment.id}`}
      />
      {isLoading ? (
        <LoadingSpin />
      ) : (
        <PaymentDetailContent payment={payment} refetch={refetch} />
      )}
    </>
  );
};

export default GetPayment;
