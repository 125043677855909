import { Divider } from 'antd';
import {
  ListPayReceiptType,
  SalaryInfos,
  SummaryReport,
} from 'models/report.model';
import { useSelector } from 'react-redux';
import { selectSale } from 'store/report.slice';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import SalaryTable from '../tables/SalaryTable';
import { ReactElement } from 'react';
import { BonusDetails } from 'models/report.model';
import styled from 'styled-components';
import produce from 'immer';

interface PropsType {
  data: SalaryInfos;
  basePercentage: number;
  bonus: BonusDetails[];
}
const Row = styled.div`
  max-width: 24rem;
  display: flex;
  justify-content: space-between;
  p {
    margin-bottom: 0;
    padding: 0;
  }
`;

const SalaryReport = ({
  data,
  basePercentage,
  bonus,
}: PropsType): ReactElement => {
  const produceData = produce(data, (draft) => draft);
  const produceBonus = produce(bonus, (draft) => draft);
  const approvedBonus = produceBonus.filter(
    (item) => item.status === 'approve',
  );
  const bonusTotal = approvedBonus.reduce((prev, curr) => {
    return (prev += curr.bonus);
  }, 0);
  const transformBonus: SummaryReport[] = approvedBonus.map((item) => {
    return {
      bonus_id: item.id,
      order_id: -0,
      datetime: item.date,
      items: [item.content],
      note: item.note,
      bonus: item.bonus,
      amount: 0,
      status: item.status,
    };
  });
  const orderSalary = produceData.total_sale;

  const reports = [...produceData.salary_sale, ...(transformBonus ?? [])];
  return (
    <>
      <div style={{ maxWidth: '24rem' }}>
        <div style={{ marginBottom: '0.8rem' }}>
          <strong>Thống kê trong tháng</strong>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.4rem',
            marginBottom: '1rem',
          }}
        >
          <Row>
            <p>- Lương :</p>
            <p>
              <strong>{formatMoneyVnd(orderSalary)}</strong>
            </p>
          </Row>
          <Row>
            <p>- Lương thưởng:</p>
            <p>
              <strong>{formatMoneyVnd(bonusTotal)}</strong>
            </p>
          </Row>
          <Divider style={{ margin: '4px 0' }} />
          <Row>
            <p>- Tổng lương:</p>
            <p>
              <strong style={{ color: '#1890ff' }}>
                {formatMoneyVnd(bonusTotal + orderSalary)}
              </strong>
            </p>
          </Row>
        </div>
      </div>
      <SalaryTable data={reports} />
    </>
  );
};
export default SalaryReport;
