import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { FC, Key } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import React from 'react';

import {
  ClassStudentLessonStatus,
  StudentRank,
  UserExercises,
  UserLearnProcess,
} from 'models/user.model';
import { useGetStudentsRankingQuery } from 'services/classes.service';
import LoadingSpin from 'libraries/components/LoadingSpin';
import ServerError from 'components/commons/ServerError';
import { useGetUserInfoQuery } from '../../../../../services/user.service';
import { produce } from 'immer';
import { route } from '../../../../../routers/routeNames';
import InvoiceFooter from './ButtonRenderPDF';
import useQuery from '../../../../../libraries/hooks/query';

interface DataSource {
  total_lessons: number;
  attend: number;
  skip: number;
  total_exercises: number;
  submit: number;
  not_submit: number;
  class_name: string;
  key: Key;
  average: number;
  rank: number;
}

const LearnProcess: FC = () => {
  const [currentClassId, setCurrentClassId] = useState('');
  const [rankArr, setRankArr] = useState<StudentRank[]>([]);
  const { userId } = useParams<{ userId: string }>();
  const searchParams = useQuery();
  const code = searchParams.get('code') ?? '';
  const {
    data: studentRanking,
    isLoading: isStudentRankingLoading,
    isError: isStudentRankingError,
    error: studentRankingError,
    isFetching: isStudentRankingFetching,
  } = useGetStudentsRankingQuery(
    { class_id: currentClassId, student_id: userId, code: code },
    {
      skip: currentClassId === '',
    },
  );
  const { learn_process = [] as UserLearnProcess[] } = useGetUserInfoQuery(
    { user_id: userId, code: code },
    {
      selectFromResult: ({ data }) => ({
        learn_process: data?.learn_process,
      }),
    },
  );
  useEffect(() => {
    if (!studentRanking) return;
    setRankArr(
      produce((draft) => {
        draft.push(studentRanking);
      }),
    );
  }, [studentRanking]);

  useEffect(() => {
    return () => {
      setRankArr([]);
      setCurrentClassId('');
    };
  }, []);

  useEffect(() => {
    const index = learn_process.findIndex(
      ({ class_id }) => class_id === currentClassId,
    );
    if (index < 0 && !isStudentRankingFetching) {
      setCurrentClassId(learn_process[0]?.class_id);
    }
    if (index + 1 < learn_process.length && !isStudentRankingFetching) {
      setCurrentClassId(learn_process[index + 1].class_id);
    }
  }, [learn_process, studentRanking]);

  const dataSource = useMemo(
    () =>
      learn_process.map((item, index) => {
        return getDataSource({
          learn_process: item,
          average: rankArr[index]?.average,
          rank: rankArr[index]?.rank,
        });
      }),
    [learn_process, rankArr],
  );

  return (
    <div style={{ padding: '20px 0' }}>
      {isStudentRankingError ? (
        <ServerError
          title={`Status ${
            !!('originalStatus' in studentRankingError) &&
            studentRankingError.originalStatus
          }: ${
            !!('status' in studentRankingError) && studentRankingError.status
          },${
            !!('error' in studentRankingError) && studentRankingError.error
          } `}
        />
      ) : isStudentRankingLoading ? (
        <LoadingSpin />
      ) : (
        <div style={{ overflow: 'auto' }}>
          <Table
            bordered
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};

//fn & util
const getAttendanceNumber = (arg: ClassStudentLessonStatus[]) => {
  if (arg.length === 0) {
    return { attend: 0, online: 0, skip: 0 };
  }
  const record = arg.reduce(
    (acc, curr) => {
      if (curr.status) {
        acc.attend += 1;
      } else if (curr.status_online) {
        acc.online += 1;
      }
      return acc;
    },
    { attend: 0, online: 0 },
  );
  return { ...record, skip: arg.length - record.attend - record.online };
};
const getTotalSubmit = (arg: UserExercises[]) => {
  if (arg.length === 0) return 0;
  return arg.reduce((acc, curr) => {
    if (curr.status_submit) acc += 1;
    return acc;
  }, 0);
};
const getDataSource = ({
  learn_process,
  average,
  rank,
}: {
  learn_process: UserLearnProcess;
  average: number;
  rank: number;
}): DataSource => {
  const { class_name, class_id, total_lessons } = learn_process;
  const status_lessons =
    learn_process.status_lessons ?? ([] as ClassStudentLessonStatus[]);
  const exercises = learn_process.exercises ?? ([] as UserExercises[]);
  const { attend, skip } = getAttendanceNumber(status_lessons);
  const submit = getTotalSubmit(exercises);
  return {
    attend,
    skip,
    submit,
    total_exercises: exercises.length,
    not_submit: exercises.length - submit,
    average,
    rank,
    class_name,
    key: class_id,
    total_lessons,
  };
};

const columns: TableColumnsType<DataSource> = [
  {
    dataIndex: 'class_name',
    key: 'class_name',
    title: 'Tên lớp',
    render: (class_name, { key }) => (
      <Link
        to={generatePath(route.class.detail, { classId: key.toString() })}
        style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
      >
        {class_name}
      </Link>
    ),
  },
  {
    dataIndex: 'total_lessons',
    key: 'total_lessons',
    title: 'Tổng số buổi học',
    align: 'center',
  },
  {
    dataIndex: 'total_exercises',
    key: 'total_exercises',
    title: 'Tổng số bài tập',
    align: 'center',
  },
  {
    dataIndex: 'attend',
    key: 'attend',
    title: 'Đi học',
    align: 'center',
  },
  {
    dataIndex: 'skip',
    key: 'skip',
    title: 'Nghỉ học',
    align: 'center',
  },
  {
    dataIndex: 'submit',
    key: 'submit',
    title: 'Đã nộp',
    align: 'center',
  },
  {
    dataIndex: 'not_submit',
    key: 'not_submit',
    title: 'Không nộp',
    align: 'center',
  },
  {
    dataIndex: 'average',
    key: 'average',
    title: 'Điểm TB',
    align: 'center',
  },
  {
    dataIndex: 'rank',
    key: 'rank',
    title: 'Xếp hạng',
    align: 'center',
  },
];

export default LearnProcess;
