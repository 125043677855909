import { Checkbox, Input, message, Select } from 'antd';
import Modal from 'components/commons/Modal';
import Search from 'components/commons/Search';
import Button from 'libraries/components/commons/Button';
import { UsersType } from 'models/user.model';
import FindStudent from 'pages/classes/detail/student/FindStudent';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addStudentToOrder, selectOrder } from 'store/order.slice';
import CreateUserFromOrder from '../content/CreateUser';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
  setIsChange: (value: boolean) => void;
  status: 'pending' | 'active';
}

const { TextArea } = Input;

interface StudentInOrder {
  full_name: string;
  id: string;
  email: string;
  phone: string;
}

const checkExistStudent = (
  students: StudentInOrder[],
  selectedStudent: StudentInOrder,
): { accept: boolean; message: string } => {
  let result = {
    accept: true,
    message: '',
  };

  if (students.filter((item) => item.id === selectedStudent.id).length > 0) {
    result = {
      accept: false,
      message: 'Học viên này đã có trong order',
    };
  }

  return result;
};

const { Option } = Select;

const AddStudentToOrder = ({
  isModalVisible,
  closeModal,
  status,
  setIsChange,
}: PropsType): ReactElement => {
  const [keyword, setKeyword] = useState('');
  const [tmpKeyword, setTmpKeyword] = useState('');
  const [isSearchDone, setIsSearchDone] = useState(false);
  const [hasResult, setHasResult] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [selectedUser, setSelectedUser] = useState({} as UsersType);
  const [tmpNote, setTmpNote] = useState('');
  const [type, setType] = useState('lab');

  const orderData = useSelector(selectOrder);
  const { students } = orderData;

  const dispatch = useDispatch();

  const addExistUser = () => {
    const { id, full_name, phone, email } = selectedUser;
    const result = checkExistStudent(students, selectedUser);

    if (result.accept) {
      const user = {
        id: id,
        full_name: full_name,
        phone: phone,
        email: email,
      };
      dispatch(addStudentToOrder(user));
      setIsChange(true);
    } else {
      message.error(result.message);
    }
  };

  const onSearch = () => {
    setIsSearchDone(true);
    setKeyword(tmpKeyword);
    setHasResult(true);
  };

  return (
    <Modal
      title="Chọn học viên"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      keyboard={false}
      width={800}
      footer={[]}
    >
      <label>Nhập email hoặc số điện thoại học viên</label>
      <Search
        allowClear
        enterButton="Tìm học viên"
        value={tmpKeyword}
        onChange={(e) => {
          setHasResult(false);
          setTmpKeyword(e.target.value);
        }}
        onSearch={onSearch}
      />
      <Checkbox
        checked={createNew}
        onChange={(e) => setCreateNew(e.target.checked)}
      >
        Tạo tài khoản cho học viên mới
      </Checkbox>

      {isSearchDone && !createNew && (
        <FindStudent
          keyword={keyword}
          hasResult={hasResult}
          setSelectedUser={(value: UsersType) => setSelectedUser(value)}
        />
      )}
      {createNew ? (
        <CreateUserFromOrder />
      ) : selectedUser.id ? (
        <>
          {status === 'pending' && (
            <div>
              <label>Ghi chú học viên:</label>
              <TextArea
                rows={2}
                value={tmpNote}
                onChange={(e) => setTmpNote(e.target.value)}
              />
            </div>
          )}
          <div>
            <label>Hình thức:</label>
            <Select
              value={type}
              defaultValue={type}
              onChange={(value) => setType(value)}
              style={{ width: '200px', marginLeft: '15px' }}
            >
              <Option value="lab">Onlab</Option>
              <Option value="live">Trực tuyến có tương tác</Option>
            </Select>
          </div>
          <div style={{ textAlign: 'end' }}>
            <Button.Info onClick={addExistUser}>
              Thêm học viên vào đơn hàng
            </Button.Info>
          </div>
        </>
      ) : (
        ''
      )}
    </Modal>
  );
};
export default AddStudentToOrder;
