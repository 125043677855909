import { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Pagination, Table, Select, Checkbox } from 'antd';
import { selectCourses } from 'store/classes.slice';
import { ColumnsType } from 'antd/lib/table';
import { Class } from 'models/class.model';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

import {
  classStatusValues,
  classTypeValues,
  classBranchValues,
} from 'libraries/utils/globalVariable';
import Span from 'libraries/components/commons/Span';
import TableContainer from 'components/table/TableContainer';
import { formatDate } from 'libraries/utils/timeDistanceToNow';

const { Option } = Select;

interface KeySearchType {
  keyStatus: '' | 'active' | 'scheduled' | 'completed';
  keyType: '' | 'lab' | 'live' | 'mixed' | 'video' | 'enterprise';
  keyCourse: string;
  keyClass: string;
  keyDirection: 'asc' | 'desc';
  keyBranch: '' | 'Cơ sở Nguyễn Đình Chiểu' | 'Cơ sở Tố Hữu';
  keyOrder: string;
}

interface PropsType {
  classes: Class[];
  total_page: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  setKeySearch: (value: string) => void;
  state: KeySearchType;
  handleSetState: (field: string, value: string) => void;
  handleOrderList: (keyOrder: string, keyDirection: 'asc' | 'desc') => void;
  listClassId: string[];
  setListClassId: (value: string[]) => void;
  setLinkExportExcel: (value: string) => void;
}

const ClassesTable = ({
  classes,
  total_page,
  currentPage,
  setCurrentPage,
  setKeySearch,
  state,
  handleSetState,
  handleOrderList,
  listClassId,
  setListClassId,
  setLinkExportExcel,
}: PropsType): ReactElement => {
  const courses = useSelector(selectCourses);
  const [tmpName, setTmpName] = useState('');
  const [listCurrent, setListCurrent] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.pathname.includes('list-current')) {
      setListCurrent(true);
    }
  }, []);
  const {
    keyStatus,
    keyType,
    keyCourse,
    keyClass,
    keyDirection,
    keyBranch,
    keyOrder,
  } = state;

  const handleOnchangePage = (page: number) => {
    setCurrentPage(page);
    setKeySearch(
      `page=${page}&status=${keyStatus}&type=${keyType}&course_id=${keyCourse}&keyword=${keyClass}&branch=${keyBranch}&orderby=${keyOrder}&direction=${keyDirection}&today=${listCurrent}`,
    );
  };

  useEffect(() => {
    const {
      keyStatus,
      keyType,
      keyCourse,
      keyClass,
      keyDirection,
      keyBranch,
      keyOrder,
    } = state;
    setCurrentPage(1);
    setKeySearch(
      `page=1&status=${keyStatus}&type=${keyType}&course_id=${keyCourse}&keyword=${keyClass}&branch=${keyBranch}&orderby=${keyOrder}&direction=${keyDirection}&today=${listCurrent}`,
    );
    if (keyClass === '') setTmpName('');
  }, [state]);

  useEffect(() => {
    const prevSearchJson = sessionStorage.getItem('prevSearch');
    if (prevSearchJson) {
      const prevSearch = JSON.parse(prevSearchJson);
      setTmpName(prevSearch);
      handleSetState('keyClass', prevSearch);
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem('prevSearch', JSON.stringify(keyClass));
  }, [keyClass]);
  const handleCheck = (e: any, item: any) => {
    let data = 'https://admin.techmaster.vn/api/classes/certificates?';
    if (e.target.checked == true) {
      setListClassId([...listClassId, item.id]);
      [...listClassId, item.id].forEach((item, i) => {
        if (i == 0) {
          data += `class=${item}`;
        } else {
          data += `&class=${item}`;
        }
      });
    } else {
      const arr = listClassId.filter((data: any) => data != item.id);
      setListClassId(arr);
      arr.forEach((item, i) => {
        if (i == 0) {
          data += `class=${item}`;
        } else {
          data += `&class=${item}`;
        }
      });
    }
    setLinkExportExcel(data);
  };

  const columns: ColumnsType<Class> = [
    {
      width: '2%',
      sorter: true,
      showSorterTooltip: false,
      title: ' Chọn lớp',
      render: (_, item) => {
        return {
          children: (
            <Checkbox onChange={(e: any) => handleCheck(e, item)}></Checkbox>
          ),
        };
      },
    },
    {
      width: '20%',
      sorter: true,
      showSorterTooltip: false,
      title: (
        <div
          onClick={() => {
            handleOrderList('name', keyDirection === 'desc' ? 'asc' : 'desc');
          }}
        >
          Tên lớp
        </div>
      ),
      children: [
        {
          key: 'name',
          dataIndex: 'name',
          title: (
            <form
              onSubmit={(e: FormEvent) => {
                e.preventDefault();
                handleSetState('keyClass', tmpName);
              }}
            >
              <Input
                allowClear
                value={tmpName}
                onChange={(e) => setTmpName(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </form>
          ),
          render: (_text, item) => (
            <RouterLink
              to={generatePath(route.class.detail, { classId: item.id })}
              style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            >
              {item.name}
            </RouterLink>
          ),
        },
      ],
    },
    {
      width: '20%',
      title: 'Khoá học',
      children: [
        {
          key: 'course_name',
          dataIndex: 'course_name',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              showSearch
              optionFilterProp="children"
              value={keyCourse}
              onChange={(value) => handleSetState('keyCourse', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              {courses.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ),
          render: (_text, item) => {
            if (item.course_id === '') {
              return '';
            }
            return (
              <RouterLink
                to={generatePath(route.course.detail, {
                  courseId: item.course_id,
                })}
                style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
              >
                {item.course_name}
              </RouterLink>
            );
          },
        },
      ],
    },
    {
      width: '10%',
      ellipsis: true,
      title: 'Hình thức',
      children: [
        {
          key: 'type',
          dataIndex: 'type',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={keyType}
              onChange={(value) => handleSetState('keyType', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              {classTypeValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (type) => {
            if (type !== '') {
              for (let i = 0; i < classTypeValues.length; i++) {
                const item = classTypeValues[i];
                if (item.value === type) {
                  return <Span className={item.className}>{item.label}</Span>;
                }
              }
            }
            return '';
          },
        },
      ],
    },
    {
      width: '10%',
      title: (
        <div
          onClick={() => {
            handleOrderList('status', keyDirection === 'desc' ? 'asc' : 'desc');
          }}
        >
          Trạng thái
        </div>
      ),
      children: [
        {
          key: 'status',
          dataIndex: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={keyStatus}
              onChange={(value) => handleSetState('keyStatus', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              {classStatusValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (status) => {
            if (status !== '') {
              for (let i = 0; i < classStatusValues.length; i++) {
                const item = classStatusValues[i];
                if (item.value === status) {
                  return <Span className={item.className}>{item.label}</Span>;
                }
              }
            }
            return '';
          },
        },
      ],
      sorter: true,
      showSorterTooltip: false,
    },
    {
      width: '10%',
      title: (
        <div
          onClick={() => {
            handleOrderList(
              'start_date',
              keyDirection === 'desc' ? 'asc' : 'desc',
            );
          }}
        >
          Khai giảng
        </div>
      ),
      children: [
        {
          key: 'schedule',
          dataIndex: 'schedule',
          render: (schedule) => (schedule !== '' ? formatDate(schedule) : ''),
        },
      ],
      sorter: true,
      showSorterTooltip: false,
    },
    {
      width: '10%',
      title: (
        <div
          onClick={() => {
            handleOrderList(
              'branch_name',
              keyDirection === 'desc' ? 'asc' : 'desc',
            );
          }}
        >
          Địa điểm
        </div>
      ),
      children: [
        {
          key: 'branch',
          dataIndex: 'branch',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={keyBranch}
              onChange={(value) => handleSetState('keyBranch', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              {classBranchValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (branch) => (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
              {branch}
            </div>
          ),
        },
      ],
      sorter: true,
      showSorterTooltip: false,
    },

    {
      width: '10%',
      title: 'Số buổi',
      children: [
        {
          key: 'total_learns',
          dataIndex: 'total_learns',
        },
      ],
    },
    {
      width: '10%',
      title: 'Học viên',
      children: [
        {
          key: 'total_students',
          dataIndex: 'total_students',
        },
      ],
    },
  ];

  return (
    <TableContainer>
      <Table<Class>
        columns={columns}
        dataSource={classes}
        pagination={false}
        rowKey="id"
        bordered
      />
      <Pagination
        current={currentPage}
        pageSize={10}
        showSizeChanger={false}
        defaultCurrent={currentPage}
        total={total_page * 10}
        style={{ marginTop: '20px' }}
        onChange={handleOnchangePage}
      />
    </TableContainer>
  );
};
export default ClassesTable;
