import { Col, Form, Modal, Row, Timeline, Typography } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { Collapse, Panel } from 'components/commons/Collapse';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import {
  BonusDetails,
  HistorySalary,
  ReceiptOrderReport,
  TeacherAttendanceSalary,
} from 'models/report.model';
import BonusSalaryTable from 'pages/report/sale/detail/tables/BonusSalaryTable';
import {
  ReactElement,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetEverySalaryInfoQuery } from 'services/report.service';
import { selectUser } from 'store/auth.slice';
import styled from 'styled-components';
import { BorderTopBoxContent } from '../../../../components/commons/ComponentContent';
import ServerError from '../../../../components/commons/ServerError';
import LoadingSpin from '../../../../libraries/components/LoadingSpin';
import { UserInforType } from '../../../../models/user.model';
import { useGetUserInfoQuery } from '../../../../services/user.service';
import SalaryTable from '../../sale/detail/tables/SalaryTable';
import AttendancesSalaryTable from '../../teacher/detail/AttendancesSalaryTable';
import CollapseHeader from '../../teacher/detail/collapse/CollapseHeader';
import SummaryDetailForm from './SummaryDetailForm';
import useQuery from 'libraries/hooks/query';
import { formatDate } from '../../../../utils/formatDate';
import { v4 } from 'uuid';
import HistoryItems from './HistoryItems';
import ListTable from '../../../blogs/list20days/ListTable';
import { Post20Days } from '../../../../models/blog.model';

const TotalSpan = styled.span`
  display: inline-block;
  background-color: #00a65a;
  color: #fff;
  font-size: 16px;
  padding: 4px 16px;
  border-radius: 4px;

  @media screen and (max-width: 991px) {
    height: 32px;
    line-height: 32px;
  }
`;
const TimelineContainer = styled(BorderTopBoxContent)<{
  borderBoxInnerHeight?: number;
}>`
  height: ${({ borderBoxInnerHeight }) =>
    borderBoxInnerHeight + 'px' || 'auto'};
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;

const { Link, Title } = Typography;

const SummaryDetail = (): ReactElement => {
  const { roles } = useSelector(selectUser);
  const { userId } = useParams<{ userId: string }>();
  const salary_id = useQuery().get('salary_id') ?? '';
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [historyId, setHistoryId] = useState('');
  const divRef = useRef<HTMLDivElement>(null);
  const [borderBoxInnerHeight, setBorderBoxInnerHeight] = useState(0);
  //api
  const { data: userInfo, isError: isUserInfoError } = useGetUserInfoQuery({
    user_id: userId,
    code: '',
  });
  const {
    data: salaries,
    isError: isSalaryError,
    isLoading: isSalaryLoading,
    refetch: refetchSalary,
  } = useGetEverySalaryInfoQuery(salary_id);
  // life cycle
  useLayoutEffect(() => {
    if (divRef.current) {
      setBorderBoxInnerHeight(divRef.current.offsetHeight ?? 0);
    }
  });
  //data
  const user = userInfo ?? ({} as UserInforType);
  const bonus = salaries?.salary_bonus;
  const attendances =
    salaries?.salary_attendance ?? ([] as TeacherAttendanceSalary[]);
  const sale = salaries?.salary_sale ?? ([] as ReceiptOrderReport[]);
  const history = salaries?.history_salary ?? ([] as HistorySalary[]);
  const fix_salary = salaries?.fix_salary ?? 0;
  const posts = salaries?.salary_blogs ?? ([] as Post20Days[]);
  //total
  const bonus_total = useMemo(() => {
    return (
      bonus?.reduce((prev, curr) => {
        if (curr.status === 'approve') prev += curr.bonus;
        return prev;
      }, 0) ?? 0
    );
  }, [bonus]);
  const attendances_total = useMemo(() => {
    return attendances.reduce((prev, curr) => (prev += curr.salary), 0);
  }, [attendances]);
  const totalBlog = useMemo(() => {
    return posts.reduce((prev, curr) => (prev += curr.salary), 0);
  }, [posts]);
  const totalSale = salaries?.total_sale ?? 0;
  const everyTotal =
    bonus_total + attendances_total + totalSale + totalBlog + fix_salary;
  // handleEvent
  if (isSalaryError || isUserInfoError) return <ServerError />;
  if (isSalaryLoading) return <LoadingSpin />;

  const handleSetState = (...value: string[]) => {
    return;
  };
  return (
    <>
      <div style={{ marginBottom: 32 }}>
        <Breadcrumb
          secondaryLink={generatePath(route.report.summary.list)}
          secondaryText="Báo cáo thu nhập chung"
          text={`Thu nhập của nhân viên ${user.full_name}`}
        />
      </div>
      <Row gutter={16}>
        <Col span={12} lg={14} xs={24}>
          <BorderTopBoxContent ref={divRef}>
            <div style={{ display: 'flex' }}>
              <SummaryDetailForm
                qr_code={userInfo?.qr_code}
                total={everyTotal}
                bonus={bonus ?? []}
                user={user}
                salary_id={salary_id}
                history_salary={
                  salaries?.history_salary ?? ([] as HistorySalary[])
                }
                refetch={refetchSalary}
                isLoading={isSalaryLoading}
              />
            </div>
          </BorderTopBoxContent>
        </Col>
        {!!history?.length && (
          <Col span={12} lg={10} xs={24}>
            <TimelineContainer borderBoxInnerHeight={borderBoxInnerHeight}>
              <Title level={3} style={{ marginBottom: 32 }}>
                Lịch sử trả lương
              </Title>
              <Timeline mode="left" style={{ width: '100%', height: '100%' }}>
                {
                  history.reduce(
                    (acc, curr) => {
                      acc.total += curr.paid;
                      acc.nodeList.push(
                        <Timeline.Item
                          color={acc.total >= everyTotal ? 'green' : 'blue'}
                          key={v4()}
                          label={formatDate(curr.date)}
                        >
                          <Link
                            onClick={() => {
                              setIsModalVisible(true);
                              setHistoryId(curr.date);
                            }}
                            target="_blank"
                          >
                            {formatMoneyVnd(curr.paid)}
                          </Link>
                        </Timeline.Item>,
                      );
                      return acc;
                    },
                    { total: 0, nodeList: [] as JSX.Element[] },
                  ).nodeList
                }
              </Timeline>
            </TimelineContainer>
          </Col>
        )}
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <TotalSpan>Tổng lương: {formatMoneyVnd(everyTotal)}</TotalSpan>
      </div>
      <Collapse accordion defaultActiveKey={`${roles[0]}`}>
        {attendances_total && (
          <Panel
            key="2"
            header={
              <CollapseHeader title="Dạy học" total={attendances_total} />
            }
          >
            <AttendancesSalaryTable attendances={attendances} />
          </Panel>
        )}
        {totalSale && (
          <Panel
            key="4"
            header={<CollapseHeader title="Bán hàng" total={totalSale} />}
          >
            <SalaryTable hideBonus data={sale} />
          </Panel>
        )}
        {totalBlog && (
          <Panel
            key="5"
            header={<CollapseHeader title="Viết bài" total={totalBlog} />}
          >
            <ListTable
              posts={posts}
              handleSetState={handleSetState}
              isAll={false}
            />
          </Panel>
        )}
        <Panel
          key="1"
          header={<CollapseHeader title="Thưởng" total={bonus_total} />}
        >
          <BonusSalaryTable
            onChange={refetchSalary}
            bonus={bonus ?? ([] as BonusDetails[])}
            isError={isSalaryError}
            isLoading={isSalaryLoading}
            userId={userId}
            hideTotal
          />
        </Panel>
        {fix_salary && (
          <Panel
            collapsible={'disabled'}
            key="20"
            header={<CollapseHeader title="Lương cứng" total={fix_salary} />}
          ></Panel>
        )}
      </Collapse>

      <Modal
        title="Lịch sử đơn hàng"
        footer={null}
        closable
        onCancel={() => {
          setIsModalVisible(false);
          setHistoryId('');
        }}
        visible={isModalVisible}
      >
        <Form>
          <HistoryItems
            history={history?.find((item) => item.date === historyId)}
          />
        </Form>
      </Modal>
    </>
  );
};

export default SummaryDetail;
