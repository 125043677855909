/*
Tạo ra các cấu hình Markdown tùy chỉnh cho các tính năng như:
Highlight (==text==)
Gạch chân (++text++)
Chỉ số dưới (~text~)
Chỉ số trên (^text^)
Mã inline (`text`)
Mã inline math ($math_formula$)
Gạch ngang (~~text~~)
*/
import { styleTags, Tag, tags as defaultTags } from '@lezer/highlight';
import { MarkdownConfig, Table } from '@lezer/markdown';
export const tags = {
  highlight: Tag.define(),
  underline: Tag.define(),
  inlineCode: Tag.define(),
  inlineMath: Tag.define(),
  blockCode: Tag.define(),
  headingMark: Tag.define(),
  codeMark: Tag.define(),
  linkLabel: Tag.define(),
  linkMark: Tag.define(),
  linkTitle: Tag.define(),
  fencedCode: Tag.define(),
  image: Tag.define(),
};
export const MarkStylingExtension: MarkdownConfig = {
  props: [
    styleTags({
      HeaderMark: tags.headingMark,
      LinkLabel: tags.linkLabel,
      LinkTitle: tags.linkTitle,
      LinkMark: tags.linkMark,
      CodeMark: tags.codeMark,
      FencedCode: tags.fencedCode,
    }),
  ],
};
/*
Xử lý Highlight (==text==)
'=' (mã ASCII 61)
*/
const HighlightDelim = { resolve: 'Highlight' };
export const Highlight = {
  defineNodes: ['Highlight'],
  parseInline: [
    {
      name: 'Highlight',
      parse(cx, next, pos) {
        if (next !== 61 /* '=' */ || cx.char(pos + 1) !== 61) {
          return -1;
        }
        return cx.addDelimiter(HighlightDelim, pos, pos + 2, true, true);
      },
      after: 'Emphasis',
    },
  ],
  props: [
    styleTags({
      HighlightMark: defaultTags.processingInstruction,
      'Highlight/...': tags.highlight,
    }),
  ],
} as MarkdownConfig;
/*
Xử lý Gạch chân (++text++)
'+' (mã ASCII 43)
*/
const UnderlineDelim = { resolve: 'Underline', mark: 'UnderlineMark' };
export const Underline = {
  defineNodes: ['Underline', 'UnderlineMark'],
  parseInline: [
    {
      name: 'Underline',
      parse(cx, next, pos) {
        /* '+' == 43 */ 
        if (next !== 43 || cx.char(pos + 1) !== 43) {
          return -1;
        }
        return cx.addDelimiter(UnderlineDelim, pos, pos + 2, true, true);
      },
      after: 'Emphasis',
    },
  ],
  props: [
    styleTags({
      UnderlineMark: defaultTags.processingInstruction,
      'Underline/...': defaultTags.special(tags.underline),
    }),
  ],
} as MarkdownConfig;
/*
Xử lý inline code `inline` nằm giữa 2 dấu `
'`' (mã ASCII 96)
*/
const InlineCodeDelim = { resolve: 'InlineCode' };
export const InlineCode = {
  defineNodes: ['InlineCode'],
  parseInline: [
    {
      name: 'InlineCode',
      parse(cx, next, pos) {
        if (next !== 96 /* '`' */) {
          return -1;
        }

        if (cx.char(pos - 1) === 96 || cx.char(pos + 1) === 96) {
          return -1;
        }

        return cx.addDelimiter(InlineCodeDelim, pos, pos + 1, true, true);
      },
      after: 'FencedCode',
    },
  ],
  props: [
    styleTags({
      InlineCodeMark: defaultTags.processingInstruction,
      'InlineCode/...': defaultTags.special(tags.inlineCode),
    }),
  ],
} as MarkdownConfig;
/*
Xử lý Mã inline math: $math_formula$
'$' (mã ASCII 36)
*/
const InlineMathDelim = { resolve: 'InlineMath' };
export const InlineMath = {
  defineNodes: ['InlineMath'],
  parseInline: [
    {
      name: 'InlineMath',
      parse(cx, next, pos) {
        if (next !== 36 /* '$' */) {
          return -1;
        }

        if (cx.char(pos - 1) === 36 || cx.char(pos + 1) === 36) {
          return -1;
        }

        return cx.addDelimiter(InlineMathDelim, pos, pos + 1, true, true);
      }
    },
  ],
  props: [
    styleTags({
      InlineMathMark: defaultTags.processingInstruction,
      'InlineMath/...': defaultTags.special(tags.inlineMath),
    }),
  ],
} as MarkdownConfig;

export const Strikethrough = {
  defineNodes: ['Strikethrough'],
  props: [
    styleTags({
      'Strikethrough/...': defaultTags.deleted,
    }),
  ],
};

/*
Định dạng table hiển thị font monospace
*/
Table.props = [
  styleTags({
    'Table/...': [defaultTags.monospace],
    'TableDelimiter/...': defaultTags.monospace,
  })
]
export { Table };

/*
Xử lý image ![alt text](image_url)
*/
const ImageDelim = { resolve: 'Image' };
export const Image = {
  defineNodes: ['Image'],
  parseInline: [
    {
      name: 'Image',
      parse(cx, next, pos) {
        if (next !== 33 /* '!' */ || cx.char(pos + 1) !== 91 /* '[' */) {
          return -1;
        }
        let start = pos;
        pos += 2;
        while (pos < cx.end && cx.char(pos) !== 93 /* ']' */) {
          pos++;
        }
        if (cx.char(pos) !== 93 /* ']' */ || cx.char(pos + 1) !== 40 /* '(' */) {
          return -1;
        }
        pos += 2;
        while (pos < cx.end && cx.char(pos) !== 41 /* ')' */) {
          pos++;
        }
        if (cx.char(pos) !== 41 /* ')' */) {
          return -1;
        }
        return cx.addDelimiter(ImageDelim, start, pos + 1, true, false);
      }
    },
  ],
  props: [
    styleTags({
      Image: defaultTags.processingInstruction,
      'Image/...': defaultTags.link,
    }),
  ],
} as MarkdownConfig;