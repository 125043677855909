import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import authReducer from './auth.slice';
import generalReducer from './general.slice';
import imageSlice from 'libraries/store/image.slice';
import classesSlice from './classes.slice';
import orderSlice from './order.slice';
import userSlice from './user.slice';
import tagsSlice from './tag.slice';
import courseSlice from './course.slice';
import reportSlice from './report.slice';
import trackSlice from './track.slice';
import trackMasterSlice from './trackmaster.slice';
import promotionSlice from './promotion.slice';

import { authApi } from 'libraries/services/auth.service';
import { mediaApi } from 'libraries/services/media.service';
import { classesApi } from 'services/classes.service';
import { orderApi } from 'services/order.service';
import { userApi } from 'services/user.service';
import { tagsApi } from 'services/tag.service';
import { courseApi } from 'services/course.service';
import { reportApi } from 'services/report.service';
import { trackApi } from 'services/track.service';
import { blogApi } from 'services/blog.service';
import { trackMasterApi } from 'services/trackmaster.service';
import { promotionApi } from 'services/promotion.service';
import { projectApi } from 'services/project.service';
import { scheduleApi } from 'services/schedule.service';
import { configureApi } from 'services/configure.service';
import { dashboardApi } from 'services/dashboard.service';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    [classesApi.reducerPath]: classesApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [courseApi.reducerPath]: courseApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [trackApi.reducerPath]: trackApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [trackMasterApi.reducerPath]: trackMasterApi.reducer,
    [promotionApi.reducerPath]: promotionApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [configureApi.reducerPath]: configureApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    auth: authReducer,
    general: generalReducer,
    image: imageSlice,
    class: classesSlice,
    order: orderSlice,
    users: userSlice,
    tags: tagsSlice,
    course: courseSlice,
    report: reportSlice,
    trackMaster: trackMasterSlice,
    track: trackSlice,
    promotion: promotionSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(mediaApi.middleware)
      .concat(classesApi.middleware)
      .concat(orderApi.middleware)
      .concat(userApi.middleware)
      .concat(courseApi.middleware)
      .concat(tagsApi.middleware)
      .concat(reportApi.middleware)
      .concat(trackApi.middleware)
      .concat(blogApi.middleware)
      .concat(trackMasterApi.middleware)
      .concat(projectApi.middleware)
      .concat(promotionApi.middleware)
      .concat(scheduleApi.middleware)
      .concat(configureApi.middleware)
      .concat(dashboardApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
