import NotFound from 'components/commons/NotFound';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { ReactElement } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { route } from 'routers/routeNames';
import Breadcrumb from 'components/commons/Breadcrumb';
import TrackMasterTable from '../list/TrackMasterTable';
import { useGetTrackMasterInfoQuery } from 'services/trackmaster.service';
import TrackMasterContent from './TrackMasterContent';
import { useGetUsersByRoleQuery } from 'services/user.service';
import { ROLE_SALE } from 'libraries/utils/globalVariable';

const GetTrackMaster = (): ReactElement => {
  const { trackMasterId } = useParams<{ trackMasterId: string }>();
  const { data: salers } = useGetUsersByRoleQuery(ROLE_SALE);

  const {
    data: trackMasterInfo,
    isLoading,
    isError,
  } = useGetTrackMasterInfoQuery(trackMasterId);

  // const listTrackInTrackMaster = trackMasterInfo
  //   ? trackMasterInfo.list_tracks
  //   : ([] as TrackList[]);

  let trackMasterName = '';
  if (trackMasterInfo) {
    trackMasterName = trackMasterInfo.name;
  }

  if (isLoading) {
    <LoadingSpin text="Đang tải dữ liệu" />;
  }

  if (isError) {
    return <NotFound subTitle="Không tìm thấy lộ trình!" />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.track.list)}
        secondaryText="Lộ trình"
        text={trackMasterName}
      />

      {trackMasterInfo && salers && (
        <TrackMasterContent trackMasterInfo={trackMasterInfo} salers={salers} />
      )}

      <TrackMasterTable />
    </>
  );
};

export default GetTrackMaster;
