import { message } from 'antd';
import Modal from 'components/commons/Modal';
import Button from 'libraries/components/commons/Button';
import { ClassFind } from 'models/class.model';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetClassesListQuery,
  useMovedStudentToOtherClassMutation,
} from 'services/classes.service';
import { selectClass, updateStudentStatus } from 'store/classes.slice';
import FindClassInfo from './FindClassInfo';
import SelectClass from './SelectClass';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
  student_id: string;
  resetSelected: () => void;
}

const MoveStudentToAnotherClass = ({
  isModalVisible,
  closeModal,
  student_id,
  resetSelected,
}: PropsType): ReactElement => {
  const dispatch = useDispatch();
  const [moveStudent] = useMovedStudentToOtherClassMutation();
  const { id: classId, course_id } = useSelector(selectClass);
  const [destClass, setDestClass] = useState('');
  const [classInfo, setClassInfo] = useState({} as ClassFind);
  const { data } = useGetClassesListQuery(course_id);

  const classes = data
    ? data.filter((item) => item.id !== classId)
    : ([] as ClassFind[]);

  const handleMoveStudent = () => {
    moveStudent({
      class_id: classId,
      student_id,
      data: { class_id: destClass },
    })
      .unwrap()
      .then(() => {
        message.success('Chuyển học viên sang lớp mới thành công!');
        dispatch(updateStudentStatus({ id: student_id, status: 'move' }));
        resetSelected();
        closeModal();
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <Modal
      title="Chuyển lớp"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      keyboard={false}
      width={800}
      footer={[
        <Button.Cancel key="cancel" onClick={closeModal}>
          Huỷ
        </Button.Cancel>,
        <Button.Info
          key="submit"
          disabled={destClass !== '' ? false : true}
          onClick={handleMoveStudent}
        >
          Chuyển lớp
        </Button.Info>,
      ]}
    >
      {classes.length > 0 && (
        <SelectClass
          classes={classes}
          destClass={destClass}
          setDestClass={setDestClass}
          setClassInfo={setClassInfo}
        />
      )}
      {classInfo.id && <FindClassInfo classInfo={classInfo} />}
    </Modal>
  );
};
export default MoveStudentToAnotherClass;
