import { Tabs as AntTabs } from 'antd';
import styled from 'styled-components';

const Tabs = styled(AntTabs)`
  font-size: 16px;
  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 600;
    &:hover {
      color: #000;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
  }
`;

export default Tabs;
