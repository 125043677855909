import { Input, message } from 'antd';
import Modal from 'components/commons/Modal';
import { ReactElement } from 'react';
import { useEditTagMutation } from 'services/tag.service';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import Button from 'libraries/components/commons/Button';
import { Tag } from 'models/tag.model';
import { useDispatch } from 'react-redux';
import { updateTagName } from 'store/tag.slice';

interface PropsType {
  isModalVisible: boolean;
  closeModal: () => void;
  tag: Tag;
}

const EditTagModal = ({
  isModalVisible,
  closeModal,
  tag,
}: PropsType): ReactElement => {
  const dispatch = useDispatch();
  const [updateTag] = useEditTagMutation();

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    updateTag({ ...tag, name: values.name, types: values.types })
      .unwrap()
      .then(() => {
        message.success('Sửa tên tag thành công');
        dispatch(updateTagName({ id: tag.id, name: values.name }));
        closeModal();
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <Modal
      title="Tạo tag"
      visible={isModalVisible}
      onCancel={() => {
        closeModal();
      }}
      keyboard={false}
      width={600}
      footer={[]}
    >
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          name: tag.name,
        }}
      >
        <Form.Item
          label={<FormLabel label="Tên tag" />}
          name="name"
          rules={[{ required: true, message: 'Xin vui lòng nhập tên tag!' }]}
        >
          <Input />
        </Form.Item>
        <Button.Primary type="primary" htmlType="submit">
          Lưu
        </Button.Primary>
      </Form>
    </Modal>
  );
};
export default EditTagModal;
