import { ReactElement, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Post20Days } from '../../../models/blog.model';
import { Input, Table } from 'antd';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from '../../../routers/routeNames';
import TableContainer from '../../../components/table/TableContainer';
import { formatDate } from '../../../libraries/utils/timeDistanceToNow';
import { formatMoney } from '../../../libraries/utils/changeFormatData';

interface Props {
  posts: Post20Days[];
  handleSetState: (...value: string[]) => void;
  isAll: boolean;
}
const ListTable = ({ posts, handleSetState, isAll }: Props): ReactElement => {
  const [tmpTitle, setTmpTitle] = useState('');
  const [tmpAuthor, setTmpAuthor] = useState('');
  let columns: ColumnsType<Post20Days> = [
    {
      title: <div>Tiêu đề</div>,
      children: [
        {
          key: 'title',
          dataIndex: 'title',
          title: (
            <Input
              value={tmpTitle}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('author', tmpAuthor, 'title', tmpTitle);
                }
              }}
              onChange={(e) => setTmpTitle(e.target.value)}
            />
          ),
          render: (_text, item) => {
            return (
              <RouterLink
                to={generatePath(route.blog.detail, { postId: item.id })}
                style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
              >
                {item.title}
              </RouterLink>
            );
          },
        },
      ],
    },
    {
      sorter: {
        compare: (a, b) => {
          return Date.parse(a.published_at) - Date.parse(b.published_at);
        },
      },
      title: 'Ngày xuất bản',
      children: [
        {
          key: 'published_at',
          dataIndex: 'published_at',
          render: (published_at) => {
            return published_at !== '' ? formatDate(published_at) : '';
          },
        },
      ],
    },
    {
      sorter: {
        compare: (a, b) => a.view_count - b.view_count,
      },
      title: 'Lượt xem',
      children: [
        {
          key: 'view_count',
          dataIndex: 'view_count',

          render: (view_count) => {
            return view_count;
          },
        },
      ],
    },
    {
      sorter: {
        compare: (a, b) => a.salary - b.salary,
      },
      title: 'Thù lao',
      children: [
        {
          key: 'salary',
          dataIndex: 'salary',

          render: (salary) => {
            return formatMoney(salary);
          },
        },
      ],
    },
  ];
  if (isAll) {
    columns = [
      ...columns.slice(0, 1),
      {
        title: <div>Tác giả</div>,
        children: [
          {
            key: 'author',
            dataIndex: 'author',
            title: (
              <Input
                value={tmpAuthor}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSetState('author', tmpAuthor, 'title', tmpTitle);
                  }
                }}
                onChange={(e) => setTmpAuthor(e.target.value)}
              />
            ),
            render: (author) => {
              return (
                <RouterLink
                  to={generatePath(route.user.info, { userId: author.id })}
                  style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                >
                  {author.full_name}
                </RouterLink>
              );
            },
          },
        ],
      },
      ...columns.slice(1),
    ];
  }

  return (
    <TableContainer>
      <Table<Post20Days>
        columns={columns}
        dataSource={posts}
        pagination={{ showSizeChanger: false }}
        rowKey="id"
        bordered
      />
    </TableContainer>
  );
};

export default ListTable;
