import { Banner } from 'models/banner.model';
import { ReactElement, useEffect, useState } from 'react';
import { Input, Pagination, Table, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

import TableContainer from 'components/table/TableContainer';
import { formatDate } from 'libraries/utils/timeDistanceToNow';

interface KeySearchType {
  keyStatus: '' | 'true' | 'false';
  keyWord: string;
  keyDirection: 'asc' | 'desc';
}

interface PropsType {
  banners: Banner[];
  total_page: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  setKeySearch: (value: string) => void;
  state: KeySearchType;
  handleSetState: (field: string, value: string) => void;
}

const { Option } = Select;

const BannersTable = ({
  banners,
  total_page,
  currentPage,
  setCurrentPage,
  setKeySearch,
  state,
  handleSetState,
}: PropsType): ReactElement => {
  const [tmpName, setTmpName] = useState('');

  const { keyStatus, keyWord, keyDirection } = state;

  const handleOnchangePage = (page: number) => {
    setCurrentPage(page);
    setKeySearch(
      `page=${page}&status=${keyStatus}&keyword=${keyWord}&direction=${keyDirection}`,
    );
  };

  const columns: ColumnsType<Banner> = [
    {
      width: '35%',
      title: 'Tên banner',
      children: [
        {
          key: 'name',
          dataIndex: 'name',
          title: (
            <Input
              value={tmpName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('keyWord', tmpName);
                }
              }}
              onChange={(e) => setTmpName(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          ),
          render: (_text, item) => (
            <RouterLink
              to={generatePath(route.configure.banner.detail, {
                bannerId: item.id,
              })}
              style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            >
              {item.name}
            </RouterLink>
          ),
        },
      ],
    },
    {
      width: '25%',
      title: 'Trạng thái',
      children: [
        {
          key: 'status',
          dataIndex: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={keyStatus}
              onChange={(value) => handleSetState('keyStatus', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              <Option value="true">Kích hoạt</Option>
              <Option value="false">Vô hiệu hoá</Option>
            </Select>
          ),
          render: (status) => (status ? 'Kích hoạt' : 'Vô hiệu hoá'),
        },
      ],
    },
    {
      width: '20%',
      title: 'Redirect to URL',
      children: [
        {
          key: 'link',
          dataIndex: 'link',
          render: (link) =>
            link !== '' ? (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
              >
                {link}
              </a>
            ) : (
              ''
            ),
        },
      ],
    },
    {
      width: '20%',
      title: (
        <div
          onClick={() => {
            handleSetState(
              'keyDirection',
              keyDirection === 'desc' ? 'asc' : 'desc',
            );
            console.log(keyDirection);
          }}
        >
          Ngày tạo
        </div>
      ),
      children: [
        {
          key: 'created_at',
          dataIndex: 'created_at',
          render: (created_at) =>
            created_at !== '' ? formatDate(created_at) : '',
        },
      ],
      sorter: true,
      showSorterTooltip: false,
    },
  ];

  useEffect(() => {
    const { keyStatus, keyWord, keyDirection } = state;
    setCurrentPage(1);
    setKeySearch(
      `page=1&status=${keyStatus}&keyword=${keyWord}&direction=${keyDirection}`,
    );
  }, [state]);

  return (
    <TableContainer>
      <Table<Banner>
        columns={columns}
        dataSource={banners}
        pagination={false}
        rowKey="id"
        bordered
      />
      <Pagination
        current={currentPage}
        pageSize={10}
        showSizeChanger={false}
        defaultCurrent={currentPage}
        total={total_page * 10}
        style={{ marginTop: '20px' }}
        onChange={handleOnchangePage}
      />
    </TableContainer>
  );
};

export default BannersTable;
