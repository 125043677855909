import NotAuthorized from 'components/commons/NotAuthorized';
import {
  highestRoles,
  ROLE_AUTHOR,
  ROLE_TRAINER,
} from 'libraries/utils/globalVariable';
import { ReactElement } from 'react';
import checkUserRole from 'utils/checkUserRole';
import GetPostDetail from './GetPostDetail';

const PostDetail = (): ReactElement => {
  const checkRole = checkUserRole([...highestRoles, ROLE_AUTHOR, ROLE_TRAINER]);

  if (!checkRole) {
    return <NotAuthorized />;
  }
  return <GetPostDetail />;
};

export default PostDetail;
