import { ReactElement } from 'react';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { Table } from 'antd';

import { ReceiptOtherReport } from 'models/report.model';

import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { formatDate } from 'utils/formatDate';
import { formatMoney } from 'libraries/utils/changeFormatData';

interface PropsType {
  reports: ReceiptOtherReport[];
}

const OtherReceiptTable = ({ reports }: PropsType): ReactElement => {
  const columns: ColumnsType<ReceiptOtherReport> = [
    {
      key: 'id',
      width: '10%',
      title: 'Mã phiếu',
      dataIndex: 'id',
      render: (id) => (
        <RouterLink
          to={generatePath(route.report.receipt.detail, { receiptId: id })}
        >
          {id}
        </RouterLink>
      ),
    },
    {
      key: 'datetime',
      width: '10%',
      title: 'Ngày giao dịch',
      dataIndex: 'datetime',
      render: (datetime) => (datetime !== '' ? formatDate(datetime) : ''),
    },
    {
      key: 'user_id',
      width: '15%',
      title: 'Người nộp tiền',
      dataIndex: 'user_id',
      render: (_text, item) =>
        item.user_id !== '' ? (
          <RouterLink
            to={generatePath(route.user.info, { userId: item.user_id })}
          >
            {item.full_name}
          </RouterLink>
        ) : (
          item.full_name
        ),
    },
    {
      key: 'amount',
      width: '10%',
      title: 'Số tiền đã đóng',
      dataIndex: 'amount',
      render: (amount) => (amount !== 0 ? formatMoney(amount) : 0),
      className: 'text-right',
    },
    {
      key: 'content',
      width: '40%',
      title: 'Ghi chú',
      dataIndex: 'content',
      render: (content) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {content}
        </div>
      ),
    },
    {
      key: 'receiver_id',
      width: '15%',
      title: 'Người nhận tiền',
      dataIndex: 'receiver_id',
      render: (_text, item) => (
        <RouterLink
          to={generatePath(route.user.info, { userId: item.receiver_id })}
        >
          {item.receiver_full_name}
        </RouterLink>
      ),
    },
  ];

  return (
    <TableContainer>
      <Table<ReceiptOtherReport>
        columns={columns}
        dataSource={reports}
        pagination={{ showSizeChanger: false }}
        rowKey="id"
        bordered
      />
    </TableContainer>
  );
};

export default OtherReceiptTable;
