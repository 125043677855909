export const route = {
  // home
  dashboard: '/',

  // report
  report: {
    finance: '/report/finance',
    classDebt: '/report/class-debt',
    attendance: '/report/class-attendance',
    sale: {
      list: '/report/sale',
      kpi: '/report/kpi-sale',
      detail: '/report/sale/:saleId',
    },
    kpi_sale: {
      list: '/report/kpi-sale',
      detail: '/report/sale/:saleId',
    },
    teacher: {
      list: '/report/teacher',
      detail: '/report/user-income/:userId',
      bonus: '/report/bonus/:userId',
    },
    pay: {
      create: '/report/pay/create',
      detail: '/report/pay/:payId',
    },
    receipt: {
      create: '/report/receipt/create',
      detail: '/report/receipt/:receiptId',
    },
    summary: {
      list: '/report/summary',
      detail: '/report/summary/:userId',
    },
  },

  // blog
  blog: {
    list: '/blogs',
    list_twenty_days: '/blogs/20days',
    own: '/blogs/own-posts',
    other: '/blogs/other-posts',
    create: '/blogs/create-post',
    detail: '/blogs/:postId/detail',
  },

  // crawl
  crawl: {
    create: '/crawl/create',
  },

  // orders
  order: {
    list: '/orders',
    create: '/orders/create',
    debt: '/orders/debt',
    detail: '/orders/:orderId/detail',

    event: '/orders/:orderId/event',
    advisory: '/orders/advisory',
    advisoryDetail: '/orders/advisory/:advisoryId',
  },

  // promotions
  promotion: {
    list: '/promotions',
    createVoucher: '/promotions/create-voucher',
    detail: '/promotions/voucher/:voucherName',
  },

  // class
  class: {
    list: '/classes',
    create: '/classes/create',
    listCurrent: '/classes/list-current',
    pre: '/classes/pre-classes',
    reserve: '/classes/reserve',
    detail: '/classes/:classId',
    student: '/classes/:classId/hoc-vien/:studentId/order-id/:orderId',
    attendance: '/classes/:classId/diem-danh/:lessonId',
  },

  // schedule
  schedule: {
    list: '/schedule',
    create: '/schedule/create',
    detail: '/schedule/:itemId/:itemType/:startDate',
  },

  // course
  course: {
    list: '/courses',
    create: '/courses/create',
    detail: '/courses/:courseId',
  },

  // curriculum
  curriculum: {
    list: '/curriculums',
  },

  // track
  track: {
    list: '/tracks',
    create: '/tracks/create-new-track',
    createTrackMaster: '/tracks/master/create',
    master: '/tracks/master/:trackMasterId',
    detail: '/tracks/:trackId',
  },

  // project
  project: {
    list: '/projects',
    create: '/projects/create',
    detail: '/projects/:projectId',
  },

  // jobs
  job: {
    jobs: '/jobs',
    companies: '/jobs/companies',
    employees: '/jobs/employees',
  },

  // categories - tag
  category: {
    categories: '/categories',
    subjectsList: '/categories/subjects-list',
    tags: '/categories/tags',
    convertTag: '/categories/convert',
  },

  // user
  user: {
    manage: '/users',
    manage_teacher: '/users/teacher',
    create: '/users/create',
    info: '/users/:userId',
  },

  // branch
  branch: {
    list: '/branches',
    create: '/branches/create',
  },

  // email
  email: {
    list: '/email',
    create: '/email/create',
  },

  // configure
  configure: {
    banner: {
      list: '/configure/banners',
      create: '/configure/banners/create',
      sort: '/configure/banners/sort',
      detail: '/configure/banners/:bannerId',
    },
    videoHomePage: '/configure/video-home-page',
    logError: '/configure/log-error',
    salary: {
      index: '/configure/salary',
      fix_salary_history: '/configure/salary/fix_salary_history',
    },
  },

  profile: '/profile',

  login: '/login',

  notFound: 'not-found',
};
