declare const ExcelJS: any;

type infoType = {
  wsName?: string;
  creator?: string;
  email?: string;
  date?: string;
};

type sheetInfo = {
  sheetName: string;
  data: any[];
};

export const exportMultipleSheet = (data: sheetInfo[], info?: infoType) => {
  const workbook = new ExcelJS.Workbook();

  data.forEach((sheet) => {
    const worksheet = workbook.addWorksheet(sheet.sheetName);

    const excelData = sheet.data;
    const colLength = excelData[0].length;
    handleInfo(worksheet, colLength, { ...info, wsName: sheet.sheetName });

    worksheet.addRows(excelData);

    addHeadingStyle(worksheet, excelData[0]);
  });

  return workbook;
};

export const exportSingleSheet = (data: any[], info?: infoType) => {
  const { wsName = 'Sheet' } = info || {};

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(wsName);

  const colLength = data[0].length;
  handleInfo(worksheet, colLength, info!);

  worksheet.addRows(data);

  addHeadingStyle(worksheet, data[0]);

  return workbook;
};
const handleInfo = (worksheet: any, colLength: number, info: infoType) => {
  const {
    wsName = 'Sheet',
    creator = 'Unknown',
    email = 'Unknown',
    date = 'Unknown',
  } = info || {};

  const lastChar = getCharOfCol(colLength);
  mergeCells(worksheet, 'A', 1, lastChar, 1);

  handleTitle(worksheet, wsName);
  addEmptyRow(worksheet);

  worksheet.addRow([`Người tạo:`, creator, '', 'Email: ', email]);
  worksheet.getCell('A3').font = { bold: true };
  worksheet.getCell('D3').font = { bold: true };

  addEmptyRow(worksheet);

  worksheet.addRow([`Thời gian tạo:`, date]);
  worksheet.getCell('A5').font = { bold: true };

  addEmptyRow(worksheet);
};

const handleTitle = (worksheet: any, title: string) => {
  const titleCell = worksheet.getCell('A1');
  titleCell.value = title;
  titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
  titleCell.font = { bold: true, size: 20 };
};

const getCharOfCol = (col: number) => {
  const char = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return char[col - 1];
};

const mergeCells = (
  worksheet: any,
  startCol: string,
  startRow: number,
  endCol: string,
  endRow: number,
) => {
  worksheet.mergeCells(`${startCol}${startRow}:${endCol}${endRow}`);
};

const addHeadingStyle = (worksheet: any, header: any[]) => {
  const headerRow = worksheet.getRow(7);

  headerRow.eachCell({ includeEmpty: true }, (cell: any, colNumber: any) => {
    if (colNumber <= header.length) {
      cell.font = { bold: true };
    }
  });
};

const addEmptyRow = (worksheet: any) => {
  worksheet.addRow([]);
};

export const downloadExcelFile = async (workbook: any, fileName: string) => {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.xlsx`;
  a.click();
};
