import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AdvisoryHistory } from 'models/order.model';
import TableContainer from 'components/table/TableContainer';
import { HeaderBackgroundContent } from 'components/commons/ComponentContent';
import Span from 'libraries/components/commons/Span';
import {
  advisoryInterestedValues,
  advisoryTypeValues,
} from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';

interface PropsType {
  history: AdvisoryHistory[];
}

const HistoryTable = ({ history }: PropsType): ReactElement => {
  const columns: ColumnsType<AdvisoryHistory> = [
    {
      key: 'date',
      width: '15%',
      title: 'Thời gian',
      dataIndex: 'date',
      render: (date) =>
        date !== '' ? formatDate(date, 'dd-MM-yyyy H:mm') : '',
    },
    {
      key: 'sale',
      width: '15%',
      title: 'Người tư vấn',
      dataIndex: 'sale',
    },
    {
      key: 'type',
      width: '10%',
      title: 'Hình thức',
      dataIndex: 'type',
      render: (type) => {
        for (let i = 0; i < advisoryTypeValues.length; i++) {
          const item = advisoryTypeValues[i];
          if (item.value === type) {
            return <Span className={item.className}>{item.label}</Span>;
          }
        }
      },
    },
    {
      key: 'note',
      width: '45%',
      title: 'Ghi chú tư vấn viên',
      dataIndex: 'note',
    },
    {
      key: 'interested',
      width: '15%',
      title: 'Mức độ quan tâm',
      dataIndex: 'interested',
      render: (interested) => {
        for (let i = 0; i < advisoryInterestedValues.length; i++) {
          const item = advisoryInterestedValues[i];
          if (item.value === interested) {
            return <Span className={item.className}>{item.label}</Span>;
          }
        }
      },
    },
  ];

  return (
    <HeaderBackgroundContent>
      <div className="box-header">Lịch sử tư vấn</div>
      <div className="box-body">
        <TableContainer>
          <Table<AdvisoryHistory>
            columns={columns}
            dataSource={history}
            pagination={{ showSizeChanger: false }}
            rowKey="date"
            bordered
          />
        </TableContainer>
      </div>
    </HeaderBackgroundContent>
  );
};
export default HistoryTable;
