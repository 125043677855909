import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { Project } from 'models/project.model';
import { ReactElement, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetProjectsListQuery } from 'services/project.service';
import ProjectsTable from './ProjectsTable';

interface KeySearchType {
  title: string;
  student_name: string;
  className: string;
  status: '' | 'true' | 'false';
  order: 'student_name' | 'title' | 'class' | '';
  direction: 'desc' | 'asc';
}

const GetList = (): ReactElement => {
  const [keyword, setKeyword] = useState(
    '?page=1&title=&student_name=&class=&status=&order=&direction=desc',
  );

  const [currentPage, setCurrentPage] = useState(1);
  const { data, isError, error } = useGetProjectsListQuery(keyword);

  const projects = data ? data.projects : ([] as Project[]);
  const total_page = data ? data.total_page : 0;

  const [state, setState] = useState<KeySearchType>({
    title: '',
    student_name: '',
    className: '',
    status: '',
    order: '',
    direction: 'desc',
  });

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleOrderList = (
    order: 'student_name' | 'title' | 'class' | '',
    direction: 'asc' | 'desc',
  ) => {
    setState({
      ...state,
      order,
      direction,
    });
  };

  const resetKeySearch = () => {
    setState({
      title: '',
      student_name: '',
      className: '',
      status: '',
      order: 'class',
      direction: 'desc',
    });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Sản phẩm học viên" />
      <LinksContainer>
        <Link.Primary to={generatePath(route.project.create)}>
          <PlusOutlined /> Đăng sản phẩm
        </Link.Primary>
        <Link.Info to="#" onClick={resetKeySearch}>
          <ReloadOutlined /> Refresh
        </Link.Info>
      </LinksContainer>
      <BorderBoxContent>
        <ProjectsTable
          projects={projects}
          total_page={total_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setKeyword={setKeyword}
          state={state}
          handleSetState={handleSetState}
          handleOrderList={handleOrderList}
        />
      </BorderBoxContent>
    </>
  );
};
export default GetList;
