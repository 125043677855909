import { ReactElement } from 'react';
import { Result } from 'antd';
import styled from 'styled-components';

interface PropsType {
  title: string;
  subTitle: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ServerError = ({ title, subTitle }: PropsType): ReactElement => {
  return (
    <Container>
      <Result status="500" title={title} subTitle={subTitle} />
    </Container>
  );
};

ServerError.defaultProps = {
  title: '500',
  subTitle: '',
};

export default ServerError;
