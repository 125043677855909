import { Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import TableContainer from 'components/table/TableContainer';
import { formatMoney } from 'libraries/utils/changeFormatData';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { TrackList, TrackMaster } from 'models/track.model';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { setKeySearch } from 'store/track.slice';
const { Option } = Select;
interface PropsType {
  tracks: TrackList[];
  tracksMaster: TrackMaster[];
  isShowTrackMaster: boolean;
  tmpName: string;
  status: string;
  setTmpName: any;
  setStatus: any;
  currentTrack: any;
  setCurrentTrack: any;
}

const TrackTable = ({
  tracks,
  tracksMaster,
  isShowTrackMaster,
  tmpName,
  status,
  setTmpName,
  setStatus,
  currentTrack,
  setCurrentTrack,
}: PropsType): ReactElement => {
  const dispatch = useDispatch();

  const handleSearchName = (e: any) => {
    setTmpName(e.target.value);
    const newTracks = tracks.filter((item: any) => {
      if (status == '') {
        return item.name.toLowerCase().includes(e.target.value.toLowerCase());
      }
      return (
        item.name.toLowerCase().includes(e.target.value.toLowerCase()) &&
        item.status == status
      );
    });
    setCurrentTrack(newTracks);
  };
  const handleSearchStatus = (value: any) => {
    setStatus(value);
    if (value == '') {
      setCurrentTrack(tracks);
      return;
    }
    const newTracks = tracks.filter((item: any) => {
      return item.status == value && item.name.toLowerCase().includes(tmpName);
    });
    setCurrentTrack(newTracks);
  };

  useEffect(() => {
    setCurrentTrack(tracks);
  }, [tracks]);

  const columns: ColumnsType<TrackList> = [
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      width: '40%',
      children: [
        {
          key: 'title',
          dataIndex: 'title',
          title: <Input value={tmpName} onChange={handleSearchName} />,
          render: (_text, item) => {
            return (
              <RouterLink
                to={generatePath(route.track.detail, { trackId: item.id })}
              >
                {item.name}
              </RouterLink>
            );
          },
        },
      ],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '25%',
      children: [
        {
          key: 'status',
          dataIndex: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={150}
              value={status}
              onChange={(value) => handleSearchStatus(value)}
            >
              <Option value="">Tất cả</Option>
              <Option value="active">Công khai</Option>
              <Option value="draft">Nháp</Option>
              <Option value="hidden">Ẩn</Option>
            </Select>
          ),
          render: (status) =>
            status === 'active'
              ? 'Công khai'
              : status === 'draft'
                ? 'Nháp'
                : 'Ẩn',
        },
      ],
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      showSorterTooltip: false,
      width: '20%',
      render: (price) => (price > 0 ? formatMoney(price) : 0),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'create_date',
      key: 'create_date',
      width: '15%',
      render: (create_date) =>
        create_date !== '' ? formatDate(create_date) : '',
    },
  ];

  const columnsTrackMaster: ColumnsType<TrackMaster> = [
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      width: '40%',
      render: (_text, item) => {
        const handleClick = () => {
          dispatch(setKeySearch(`keyword=&master_id=${item.id}`));
        };

        return (
          <RouterLink
            to={generatePath(route.track.master, { trackMasterId: item.id })}
            onClick={handleClick}
          >
            {item.name}
          </RouterLink>
        );
      },
    },
  ];

  return (
    <BorderBoxContent>
      {isShowTrackMaster ? (
        <TableContainer>
          <Table<TrackMaster>
            columns={columnsTrackMaster}
            dataSource={tracksMaster}
            pagination={false}
            rowKey="id"
            bordered
          />
        </TableContainer>
      ) : (
        <TableContainer>
          <Table<TrackList>
            columns={columns}
            dataSource={currentTrack}
            pagination={false}
            rowKey="id"
            bordered
          />
        </TableContainer>
      )}
    </BorderBoxContent>
  );
};
export default TrackTable;
