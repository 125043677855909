import { ReactElement, useState } from 'react';
import { Checkbox, Empty, Form, Input, message, Table } from 'antd';
import Button from 'libraries/components/commons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStudentInOrder, selectOrder } from 'store/order.slice';
import { DeleteFilled, ImportOutlined } from '@ant-design/icons';
import AddStudentToOrder from '../modals/AddStudentToOrder';
import { useAddStudentToOrderMutation } from 'services/order.service';
import { useParams } from 'react-router';
import { ColumnsType } from 'antd/lib/table';

interface StudentColumn {
  full_name: string;
  phone: string;
  email: string;
  id: string;
}

const Students = (): ReactElement => {
  const [isChecked, setIsChecked] = useState(false);
  const [addStudentToOrder] = useAddStudentToOrderMutation();
  const { orderId } = useParams<{ orderId: string }>();
  const dispatch = useDispatch();

  const orderData = useSelector(selectOrder);
  const [isAddingModalVisible, setIsAddingModalVisible] = useState(false);
  const { students } = orderData;

  const [isChange, setIsChange] = useState(false);

  const onChange = () => {
    setIsChecked(!isChecked);
  };

  const onFinish = () => {
    addStudentToOrder({ order_id: orderId, data: students })
      .unwrap()
      .then(() => message.success('Thêm học viên thành công'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleDelete = (currentStudent: StudentColumn) => {
    const index = students.findIndex((item) => item.id === currentStudent.id);
    dispatch(deleteStudentInOrder(index));

    setIsChange(true);
  };

  const columns: ColumnsType<StudentColumn> = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '2%',
      render: (_, item) => (
        <div style={{ textAlign: 'center' }}>
          <DeleteFilled onClick={() => handleDelete(item)} />
        </div>
      ),
    },

    {
      title: 'Họ & tên học viên',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Address',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  return (
    <>
      <Checkbox onChange={onChange} style={{ marginBottom: '8px' }}>
        Không khai báo chi tiết thông tin học viên
      </Checkbox>
      <Form layout="vertical" onFinish={onFinish}>
        {isChecked ? (
          <>
            <p style={{ margin: '0' }}>
              * Trong trường hợp này, không hỗ trợ các chức năng quản lý lớp
              (điểm danh, chuyển lớp, nhập lớp, rút khỏi lớp)
            </p>
            <p>Khai báo số lượng học viên để tính tổng tiền đơn hàng</p>

            <Form.Item
              label="Số học viên"
              name="student_number"
              style={{ width: '50%' }}
            >
              <Input />
            </Form.Item>
          </>
        ) : students.length > 0 ? (
          <>
            {
              <Table
                dataSource={students}
                columns={columns}
                rowKey="id"
                bordered
              />
            }
          </>
        ) : (
          <Empty description={'Không có dữ liệu'} />
        )}
        <Form.Item>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button.Default htmlType="submit" disabled={!isChange}>
              Lưu thông tin học viên
            </Button.Default>
            <Button.Outline onClick={() => setIsAddingModalVisible(true)}>
              <ImportOutlined />
              Thêm học viên vào đơn
            </Button.Outline>
          </div>
        </Form.Item>
      </Form>
      {/* <AddStudentModal /> */}
      {isAddingModalVisible && (
        <AddStudentToOrder
          isModalVisible={isAddingModalVisible}
          closeModal={() => setIsAddingModalVisible(false)}
          setIsChange={setIsChange}
          status="active"
        />
      )}
    </>
  );
};

export default Students;
