import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { Course } from 'models/course.model';
import { ReactElement, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetAllCoursesQuery } from 'services/course.service';
import CourseListContent from './CourseListContent';

interface KeySearchType {
  keyInteractive: '' | 'true' | 'false';
  keyStatus: string;
  keyText: string;
  keyDirection: 'asc' | 'desc';
}

const GetList = (): ReactElement => {
  const [keyword, setKeyword] = useState(
    'page=1&interactive=&status=&keyword=&direction=desc',
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isError, error } = useGetAllCoursesQuery(keyword);

  const [state, setState] = useState<KeySearchType>({
    keyInteractive: '',
    keyStatus: '',
    keyText: '',
    keyDirection: 'desc',
  });

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const courses = data ? data.courses : ([] as Course[]);
  const total_page = data ? data.total_page : 0;

  const resetKeySearch = () => {
    setState({
      keyInteractive: '',
      keyStatus: '',
      keyText: '',
      keyDirection: 'desc',
    });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Danh sách khoá học" />
      <LinksContainer>
        <Link.Primary to={generatePath(route.course.create)}>
          <PlusOutlined /> Tạo khoá học mới
        </Link.Primary>
        <Link.Info to="#" onClick={resetKeySearch}>
          <ReloadOutlined /> Refresh
        </Link.Info>
      </LinksContainer>
      <BorderBoxContent>
        <CourseListContent
          courses={courses}
          total_page={total_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setKeyword={setKeyword}
          state={state}
          handleSetState={handleSetState}
        />
      </BorderBoxContent>
    </>
  );
};
export default GetList;
