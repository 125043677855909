import { ReactElement, useEffect, useState } from 'react';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { Select, Table, Typography } from 'antd';

import { PayOtherReport } from 'models/report.model';

import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { formatDate } from 'utils/formatDate';
import { formatMoney } from 'libraries/utils/changeFormatData';
import ShowMarkdown from '../../../../libraries/components/markdown/ShowMarkdown';

interface PropsType {
  reports: PayOtherReport[];
}

const { Option } = Select;

const OtherPayment = ({ reports }: PropsType): ReactElement => {
  const [tmpReports, setTmpReport] = useState<PayOtherReport[]>([]);
  const [isPaid, setIsPaid] = useState<'all' | 'true' | 'false'>('all');

  const columns: ColumnsType<PayOtherReport> = [
    {
      width: '10%',
      title: 'Mã phiếu',
      children: [
        {
          key: 'id',
          dataIndex: 'id',
          render: (id) => (
            <RouterLink
              to={generatePath(route.report.pay.detail, { payId: id })}
            >
              {id}
            </RouterLink>
          ),
        },
      ],
    },
    {
      width: '10%',
      title: 'Ngày giao dịch',
      children: [
        {
          key: 'datetime',
          dataIndex: 'datetime',
          render: (datetime) => (datetime !== '' ? formatDate(datetime) : ''),
        },
      ],
    },
    {
      width: '25%',
      title: 'Nội dung chi',
      children: [
        {
          key: 'content',
          dataIndex: 'content',
          render: (content) => (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
              {content}
            </div>
          ),
        },
      ],
    },
    {
      width: '10%',
      title: 'Số tiền',
      className: 'text-right',
      children: [
        {
          key: 'amount',
          dataIndex: 'amount',
          render: (amount) => (amount !== 0 ? formatMoney(amount) : 0),
        },
      ],
    },

    {
      key: 'user_id',
      width: '15%',
      title: 'Người chi tiền',
      children: [
        {
          key: 'user_id',
          dataIndex: 'user_id',
          render: (_text, item) => (
            <RouterLink
              to={generatePath(route.user.info, { userId: item.user_id })}
            >
              {item.full_name}
            </RouterLink>
          ),
        },
      ],
    },
    {
      title: 'Ghi chú',
      children: [
        {
          key: 'note',
          dataIndex: 'note',
          width: '20%',
          render: (note) => (
            <Typography.Text ellipsis={{ tooltip: 'Nhấp vào dấu cộng !' }}>
              {note}
            </Typography.Text>
          ),
        },
      ],
    },
    {
      width: '10%',
      title: 'Đã thanh toán',
      children: [
        {
          dataIndex: 'is_paid',
          key: 'is_paid',
          title: (
            <Select
              dropdownMatchSelectWidth={150}
              onChange={(value) => {
                setIsPaid(value);
              }}
              value={isPaid}
            >
              <Option value="all">Tất cả</Option>
              <Option value="true">Đã thanh toán</Option>
              <Option value="false">Chưa thanh toán</Option>
            </Select>
          ),
          render: (is_paid) => (is_paid ? 'Đã thanh toán' : 'Chưa thanh toán'),
        },
      ],
    },
  ];

  useEffect(() => {
    if (isPaid === 'true') {
      setTmpReport(reports.filter((item) => item.is_paid === true));
    } else if (isPaid === 'false') {
      setTmpReport(reports.filter((item) => item.is_paid === false));
    } else {
      setTmpReport(reports);
    }
  }, [reports, isPaid]);

  return (
    <TableContainer>
      <Table<PayOtherReport>
        columns={columns}
        dataSource={tmpReports}
        pagination={{ showSizeChanger: false }}
        rowKey="id"
        bordered
        expandable={{
          rowExpandable: (record) => !!record?.note && record.note !== '',
          expandedRowRender: (record) => (
            <ShowMarkdown markdown={record?.note ?? ''} />
          ),
        }}
        scroll={{ y: 500 }}
      />
    </TableContainer>
  );
};

export default OtherPayment;
