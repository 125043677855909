import { ReactElement, useEffect, useState } from 'react';
import { ListItemType } from 'store/order.slice';
import styled from 'styled-components';
import { Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link as RouterLink, generatePath } from 'react-router-dom';

import { route } from 'routers/routeNames';

import Button from 'libraries/components/commons/Button';
import TableContainer from 'components/table/TableContainer';
import { NewItem } from 'models/promotion.model';
import { classTypeValues } from 'libraries/utils/globalVariable';
import { formatMoney } from 'libraries/utils/changeFormatData';

interface PropsType {
  list: ListItemType[];
  chosenItems: string[];
  setChosenItems: (list: string[]) => void;
  discount: number;
  discountPercent: number;
}

interface NewListItem extends NewItem {
  name: string;
  class_type: string;
}

const Wrapper = styled.div``;

const GroupButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const { Option } = Select;

const ListItems = ({
  list,
  chosenItems,
  setChosenItems,
  discount,
  discountPercent,
}: PropsType): ReactElement => {
  const columns: ColumnsType<NewListItem> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Lớp',
      render: (_text, item) => (
        <RouterLink
          to={generatePath(route.class.detail, { classId: item.id })}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          {item.name}
        </RouterLink>
      ),
    },
    {
      key: 'class_type',
      dataIndex: 'class_type',
      title: 'Loại',
      render: (class_type) => {
        if (class_type !== '') {
          for (let i = 0; i < classTypeValues.length; i++) {
            const item = classTypeValues[i];
            if (item.value === class_type) {
              return item.label;
            }
          }
        }
        return '';
      },
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: 'Giá (VND)',
      render: (_text, item) =>
        item.price_lab > 0
          ? formatMoney(item.price_lab)
          : formatMoney(item.price_live),
    },

    {
      key: `${discount || discountPercent}`,
      dataIndex: `${discount || discountPercent}`,
      title: 'Giá sau khuyến mại',
      render: (_text, item) => {
        if (discount > 0) {
          if (item.price_live > 0) {
            return formatMoney(item.price_live - discount);
          }
          return formatMoney(item.price_lab - discount);
        }
        if (discountPercent > 0) {
          if (item.price_live > 0) {
            return formatMoney(
              (item.price_live * (100 - discountPercent)) / 100,
            );
          }
          return formatMoney((item.price_lab * (100 - discountPercent)) / 100);
        }
      },
    },
  ];

  const [listItems, setListItems] = useState<NewListItem[]>([]);

  useEffect(() => {
    const newList = list.reduce((arr, item) => {
      if (chosenItems.includes(item.item_id)) {
        const price_lab = item.price.reduce((price: number, i) => {
          if (i.type === 'lab') price = i.price;
          return price;
        }, 0);
        const price_live = item.price.reduce((price: number, i) => {
          if (i.type === 'live') price = i.price;
          return price;
        }, 0);
        arr.push({
          id: item.item_id,
          name: item.name,
          type: item.type,
          class_type: item.class_type,
          price_lab,
          price_live,
        });
      }
      return arr;
    }, [] as NewListItem[]);
    setListItems(newList);
  }, [chosenItems]);

  return (
    <Wrapper>
      <GroupButton>
        <Button.Default
          style={{ height: 30, fontSize: 12 }}
          onClick={() => {
            const arr = list.reduce((res: string[], item) => {
              res.push(item.item_id);
              return res;
            }, []);
            setChosenItems(arr);
          }}
        >
          Tất cả lớp học
        </Button.Default>
        <Button.Info
          style={{ height: 30, fontSize: 12 }}
          onClick={() => {
            const arr = list.reduce((res: string[], item) => {
              if (item.class_type === 'video') {
                res.push(item.item_id);
              }
              return res;
            }, []);
            setChosenItems(arr);
          }}
        >
          Tất cả lớp học online
        </Button.Info>
        <Button.Warning
          style={{ height: 30, fontSize: 12 }}
          onClick={() => {
            const arr = list.reduce((res: string[], item) => {
              if (item.class_type === 'lab') {
                res.push(item.item_id);
              }
              return res;
            }, []);
            setChosenItems(arr);
          }}
        >
          Tất cả lớp học onlab
        </Button.Warning>
        <Button.Danger
          style={{ height: 30, fontSize: 12 }}
          onClick={() => {
            setChosenItems([] as string[]);
          }}
        >
          Xoá lựa chọn
        </Button.Danger>
      </GroupButton>
      <div>
        <Select
          showSearch
          optionFilterProp="children"
          mode="multiple"
          style={{ width: '100%' }}
          value={chosenItems}
          onChange={(value: string[]) => setChosenItems(value)}
          placeholder="Nhập khoá học..."
        >
          {list.map((item, index) => {
            return (
              <Option key={index} value={item.item_id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>
      <TableContainer>
        <Table<NewListItem>
          columns={columns}
          dataSource={listItems}
          pagination={false}
          rowKey="id"
          bordered
        />
      </TableContainer>
    </Wrapper>
  );
};

export default ListItems;
