export interface SalaryTypeValue {
  value: 'per_student' | 'per_total' | 'fixed' | '';
  label: string;
}
export const classStatusValues = [
  {
    value: 'active',
    label: 'Mở',
    className: 'success',
  },
  {
    value: 'scheduled',
    label: 'Đã lên lịch',
    className: 'info',
  },
  {
    value: 'completed',
    label: 'Hoàn thành',
    className: 'primary',
  },
  {
    value: 'canceled',
    label: 'canceled',
    className: 'default',
  },
  {
    value: 'aborted',
    label: 'aborted',
    className: 'danger',
  },
  {
    value: 'merged',
    label: 'merged',
    className: 'info',
  },
];

export const classTypeValues = [
  {
    value: 'lab',
    label: 'Onlab',
    className: 'info',
  },
  {
    value: 'live',
    label: 'Trực tuyến có tương tác',
    className: 'warning',
  },
  {
    value: 'mixed',
    label: 'Mixed',
    className: 'warning',
  },
  {
    value: 'video',
    label: 'Video',
    className: 'success',
  },
  {
    value: 'enterprise',
    label: 'Doanh nghiệp',
    className: 'default',
  },
];

export const salaryTypeValues: SalaryTypeValue[] = [
  {
    value: 'per_student',
    label: '1 học sinh',
  },
  {
    value: 'per_total',
    label: 'tổng số hiện đang theo học',
  },
  {
    value: 'fixed',
    label: 'buổi',
  },
];

export const classBranchValues = [
  {
    value: 'Cơ sở Nguyễn Đình Chiểu',
    label: 'Cơ sở Nguyễn Đình Chiểu',
    location:
      'Cơ sở Nguyễn Đình Chiểu - 14 Ngõ 4 Nguyễn Đình Chiểu, Hai Bà Trưng, Hà Nội',
  },
  {
    value: 'Cơ sở Tố Hữu',
    label: 'Cơ sở Tố Hữu',
    location:
      'Cơ sở Tố Hữu - Tầng 12A, tòa nhà Viwaseen Tower, số 48, Tố Hữu, Lê Văn Lương kéo dài, Hà Nội',
  },
  {
    value: 'Cơ sở Dịch Vọng Hậu',
    label: 'Cơ sở Dịch Vọng Hậu',
    location:
      'Cơ sở Dịch Vọng Hậu - Tầng 5 số 13 Dịch Vọng Hậu, Cầu Giấy, Hà Nội',
  },
  {
    value: 'Trực tuyến',
    label: 'Trực tuyến',
    location: '',
  },
];

export const classStudiedDayValues = [
  {
    value: '2',
    label: 'Thứ 2',
  },
  {
    value: '3',
    label: 'Thứ 3',
  },
  {
    value: '4',
    label: 'Thứ 4',
  },
  {
    value: '5',
    label: 'Thứ 5',
  },
  {
    value: '6',
    label: 'Thứ 6',
  },
  {
    value: '7',
    label: 'Thứ 7',
  },
  {
    value: '8',
    label: 'Chủ nhật',
  },
];

export const courseActiveValues = [
  {
    value: 'true',
    label: 'Onlab',
    className: 'primary',
  },
  {
    value: 'false',
    label: 'Trực tuyến',
    className: 'info',
  },
];

export const courseStatusValues = [
  {
    value: 'active',
    label: 'Công khai',
    className: 'primary',
  },
  {
    value: 'draft',
    label: 'Nháp',
    className: 'default',
  },
  {
    value: 'hidden',
    label: 'Ẩn',
    className: 'info',
  },
];

export const lectureStatusValues = [
  {
    value: 'active',
    label: 'Cho giảng viên khác import bài giảng',
  },
  {
    value: 'hidden',
    label: 'Ẩn bài giảng với giảng viên khác',
  },
  {
    value: 'draft',
    label: 'Nháp',
  },
];

// Tag
export const tagTypeValues = [
  {
    value: 'course',
    label: 'Khoá học',
  },
  {
    value: 'post',
    label: 'Bài viết',
  },
  {
    value: 'job',
    label: 'Việc làm',
  },
  {
    value: 'track',
    label: 'Lộ trình',
  },
  {
    value: 'class',
    label: 'Lớp học',
  },
  {
    value: 'lesson',
    label: 'Buổi học',
  },
  {
    value: 'lecture',
    label: 'Bài giảng',
  },
];

// ORDERS
export const orderPaymentTypeValues = [
  {
    value: 'bank',
    label: 'Chuyển khoản',
    className: 'primary',
  },
  {
    value: 'cash',
    label: 'Trực tiếp',
    className: 'success',
  },
  {
    value: 'other',
    label: 'Khác',
    className: 'warning',
  },
  // 2 type mới, chưa biết
  // {
  //   value: 'ewwallet',
  //   label: 'Ví điện tử',
  //   className: 'info',
  // },
  // {
  //   value: 'other',
  //   label: 'Khác',
  //   className: 'warning',
  // },
];
export const orderStatusValues = [
  {
    value: 'paid',
    label: 'Hoàn tất thanh toán',
    className: 'success',
  },
  {
    value: 'partial',
    label: 'Thanh toán 1 phần',
    className: 'info',
  },
  {
    value: 'created',
    label: 'Đã tạo đơn',
    className: 'primary',
  },
  {
    value: 'refund',
    label: 'Hoàn học phí',
    className: 'warning',
  },
  {
    value: 'cancel',
    label: 'Đã huỷ đơn',
    className: 'default',
  },
];

export const orderHistoryEventValues = [
  {
    value: 'created',
    label: 'Tạo mới đơn hàng',
    className: 'created',
  },
  {
    value: 'pay',
    label: 'Đóng tiền',
    className: 'pay',
  },
  {
    value: 'cancel',
    label: 'Huỷ đơn hàng',
    className: 'cancel',
  },
  {
    value: 'update',
    label: 'Cập nhật chi tiết',
    className: 'update',
  },
  {
    value: 'adjust',
    label: 'Chỉnh tổng đơn hàng',
    className: 'adjust',
  },
  {
    value: 'withdraw',
    label: 'Yêu cầu hoàn tiền',
    className: 'withdraw',
  },
];

export const orderItemValues = [
  {
    value: 'track',
    label: 'Lộ trình',
  },
  {
    value: 'course',
    label: 'Khoá học',
  },
  {
    value: 'class',
    label: 'Lớp học',
  },
  //... will have some additional later
];

export const orderItemType = [
  {
    value: 'lab',
    label: 'Lớp học',
  },
  {
    value: 'live',
    label: 'Trực tuyến',
  },
];

// Assignment Type
export const assignmentType = [
  {
    value: 'exercise',
    label: 'Bài tập',
  },
  {
    value: 'mini-test',
    label: 'Mini test',
  },
  {
    value: 'mid-test',
    label: 'Kiểm tra giữa khoá',
  },
  {
    value: 'final-test',
    label: 'Kiểm tra cuối khoá',
  },
  {
    value: 'hackathon',
    label: 'Hackathon',
  },
  {
    value: 'miniproject',
    label: 'Đồ án',
  },
  {
    value: 'finalproject',
    label: 'Đồ án cuối khoá',
  },
];

// User role
export const ROLE_ADMIN = 1;
export const ROLE_STUDENT = 2;
export const ROLE_TRAINER = 3;
export const ROLE_SALE = 4;
export const ROLE_EMPLOYER = 5;
export const ROLE_AUTHOR = 6;
export const ROLE_EDITOR = 7;
export const ROLE_MAINTAINER = 8;
export const ROLE_MARKETING = 9;

export const highestRoles = [
  ROLE_ADMIN,
  ROLE_SALE,
  ROLE_MAINTAINER,
  ROLE_MARKETING,
];

export const rolesCheck = [
  ROLE_ADMIN,
  ROLE_TRAINER,
  ROLE_SALE,
  ROLE_EMPLOYER,
  ROLE_AUTHOR,
  ROLE_EDITOR,
  ROLE_MAINTAINER,
];

// Image per page
export const LIMIT_IMAGE_PER_PAGE = 10;

// Student
export const studentStatusValues = [
  {
    value: 'pending',
    label: 'Dự bị',
    className: 'warning',
  },
  {
    value: 'active',
    label: 'Đang học',
    className: 'success',
  },
  {
    value: 'move',
    label: 'Chuyển lớp',
    className: 'info',
  },
  {
    value: 'delay',
    label: 'Bảo lưu',
    className: 'info',
  },
  {
    value: 'removed',
    label: 'Rút khỏi lớp',
    className: 'danger',
  },
  {
    value: 'completed',
    label: 'Hoàn thành',
    className: 'success',
  },
];

// users
export const userStatusValues = [
  {
    value: true,
    label: 'Đã kích hoạt',
    className: 'success',
  },
  {
    value: false,
    label: 'Chưa kích hoạt',
    className: 'warning',
  },
];

export const userRoleValues = [
  // TODO: Fix className to similar with value
  {
    value: ROLE_ADMIN,
    label: 'Admin',
    className: 'success',
  },
  {
    value: ROLE_STUDENT,
    label: 'Student',
    className: 'primary',
  },
  {
    value: ROLE_TRAINER,
    label: 'Trainer',
    className: 'default',
  },
  {
    value: ROLE_SALE,
    label: 'Sale',
    className: 'info',
  },
  {
    value: ROLE_AUTHOR,
    label: 'Author',
    className: 'warning',
  },
  {
    value: ROLE_EMPLOYER,
    label: 'Employer',
    className: 'employer',
  },
  {
    value: ROLE_EDITOR,
    label: 'Editor',
    className: 'editor',
  },
  {
    value: ROLE_MAINTAINER,
    label: 'Maintainer',
    className: 'maintainer',
  },
];

export const STUDENT_EXERCISE_LINK = 'https://learn.techmaster.vn/lop-hoc/';
export const STUDENT_LEARN_LINK = 'https://learn.techmaster.vn';

// Track
export const trackStatusValues = [
  {
    value: 'active',
    label: 'Công khai',
    className: 'primary',
  },
  {
    value: 'draft',
    label: 'Nháp',
    className: 'default',
  },
  {
    value: 'hidden',
    label: 'Ẩn',
    className: 'info',
  },
];

// Blog
export const blogStatusValues = [
  {
    value: 'public',
    label: 'Công khai',
  },
  {
    value: 'draft',
    label: 'Nháp',
  },
];

export const postTypeValues = [
  {
    value: 'normal',
    label: 'Bài viết',
  },
  {
    value: 'experience',
    label: 'Trải nghiệm',
  },
  {
    value: 'special',
    label: 'Trang tĩnh',
  },
];

export const postTypeValuesInList = [
  {
    value: 'normal',
    label: 'Bài viết',
  },
  {
    value: 'experience',
    label: 'Trải nghiệm',
  },
];

export const postTypeValuesForAuthor = [
  {
    value: 'normal',
    label: 'Bài viết',
  },
];

// Advisory
export const advisoryInterestedValues = [
  {
    value: 1,
    label: 'Không liên hệ được',
    className: 'warning',
  },
  {
    value: 2,
    label: 'Ít quan tâm',
    className: 'danger',
  },
  {
    value: 3,
    label: 'Cân nhắc',
    className: 'warning',
  },
  {
    value: 4,
    label: 'Quan tâm',
    className: 'info',
  },
  {
    value: 5,
    label: 'Rất quan tâm',
    className: 'info',
  },
  {
    value: 6,
    label: 'Sẵn sàng đóng học phí',
    className: 'info',
  },
  {
    value: 7,
    label: 'Nhập học',
    className: 'info',
  },
];

export const advisoryStatusValues = [
  {
    value: 'Chưa xử lý',
    label: 'Chưa xử lý',
    className: 'danger',
  },
  {
    value: 'Đã xử lý',
    label: 'Đã xử lý',
    className: 'success',
  },
];

export const advisoryTypeValues = [
  {
    value: 'phone',
    label: 'Gọi điện thoại',
    className: 'info',
  },
  {
    value: 'zalo',
    label: 'Zalo',
    className: 'info',
  },
  {
    value: 'facebook',
    label: 'Nhắn tin Facebook',
    className: 'info',
  },
  {
    value: 'email',
    label: 'Gửi email',
    className: 'info',
  },
  {
    value: 'other',
    label: 'Stringee',
    className: 'info',
  },
];

// Promotion
export const promotionStatusValues = [
  {
    value: 'Đã kích hoạt',
    label: 'Đã kích hoạt',
  },
  {
    value: 'Chưa kích hoạt',
    label: 'Chưa kích hoạt',
  },
  {
    value: 'Đang kích hoạt',
    label: 'Đang kích hoạt',
  },
];

// Project
export const projectClassValues = [
  {
    value: 'web',
    label: 'Web',
  },
  {
    value: 'mobile',
    label: 'Mobile',
  },
];
