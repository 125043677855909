import { ReactElement } from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { Student } from 'models/user.model';

import DefaultAvatar from 'assets/images/avatar.png';

interface PropsType {
  student: Student;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 400px;
  .student_info p {
    margin-bottom: 6px;
    font-weight: 700;
    line-height: 28px;
    color: #727272;
  }
`;

const StudentInfo = ({ student }: PropsType): ReactElement => {
  const { avatar, email, full_name, phone } = student;
  const imgSrc = avatar !== '' ? avatar : DefaultAvatar;

  return (
    <Wrapper>
      <Avatar size={100} src={imgSrc} alt="avatar" />
      <div className="student-info">
        <p>{full_name}</p>
        {phone && phone !== '' && (
          <p>
            <a href={`to:${phone}`}>{phone}</a>
          </p>
        )}
        <p>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>
    </Wrapper>
  );
};
export default StudentInfo;
