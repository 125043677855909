import { Col, Input, message } from 'antd';
import Button from 'libraries/components/commons/Button';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import { ReactElement } from 'react';
import { useCreateUserMutation } from 'services/report.service';

interface PropsType {
  setUserId: (value: string) => void;
  setUserName: (value: string) => void;
  closeModal: () => void;
}

const CreateUser = ({
  setUserId,
  setUserName,
  closeModal,
}: PropsType): ReactElement => {
  const [createUser] = useCreateUserMutation();

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    createUser({
      ...values,
      roles: [2],
    })
      .unwrap()
      .then((res) => {
        setUserId(res.id);
        setUserName(res.full_name);
        closeModal();
      })
      .catch((error) => {
        message.error(error.data);
      });
  };
  return (
    <Form onFinish={onFinish} layout="vertical" initialValues={{ type: 'lab' }}>
      <Form.Item
        label={<FormLabel label="Họ tên" />}
        name="full_name"
        rules={[{ required: true, message: 'Xin vui lòng nhập tên user!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={<FormLabel label="Email" />}
        name="email"
        rules={[{ required: true, message: 'Xin vui lòng nhập email!' }]}
      >
        <Input type="email" />
      </Form.Item>
      <Form.Item
        label={<FormLabel label="Số điện thoại" />}
        name="phone"
        rules={[
          { required: true, message: 'Xin vui lòng nhập số điện thoại!' },
          {
            pattern: /^[0-9]{10,11}$/,
            message: 'Sai định dạng, số điện thoại phải có 10 hoặc 11 số!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Col style={{ textAlign: 'end' }}>
        <Button.Info type="primary" htmlType="submit">
          Tạo tài khoản mới
        </Button.Info>
      </Col>
    </Form>
  );
};
export default CreateUser;
