import { Col, Row } from 'antd';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { ReactElement } from 'react';

interface PropsType {
  total?: number;
  title: string;
}

const CollapseHeader = ({ total, title }: PropsType): ReactElement => {
  return (
    <Row
      justify="space-between"
      align="middle"
      gutter={[2, 0]}
      style={{ width: '100%' }}
    >
      <Col xs={12} sm={12} md={12}>
        {title}
      </Col>
      {total && (
        <Col xs={12} sm={12} md={12} style={{ textAlign: 'end' }}>
          {formatMoneyVnd(total)}
        </Col>
      )}
    </Row>
  );
};

export default CollapseHeader;
