import { CalendarOutlined } from '@ant-design/icons';
import { Dropdown, Input } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { ComponentContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Tabs from 'components/commons/Tabs';
import DropdownMenu from 'components/report/DropdownMenu';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { useDateRange } from 'libraries/hooks/date';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import {
  TeacherCurriculimReport,
  TeacherListReport,
} from 'models/report.model';
import { ReactElement, useState } from 'react';
import {
  useGetTeachersCurriculumReportQuery,
  useGetTeachersReportQuery,
} from 'services/report.service';
import styled from 'styled-components';
import CurriculumReport from './curriculum/CurriculumReport';
import ExportExcel from './ExportExcel';
import GeneralSalary from './general-salary/GeneralSalary';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const TotalSpan = styled.span`
  display: inline-block;
  background-color: #00a65a;
  color: #fff;
  font-size: 16px;
  padding: 0 10px;
  margin-bottom: 6px;
  @media screen and (max-width: 991px) {
    height: 32px;
    line-height: 32px;
  }
`;

const { TabPane } = Tabs;

const TeachersReportContent = (): ReactElement => {
  const [visible, setVisible] = useState(false);
  const { firstDay, lastDay, setFirstDay, setLastDay, format } = useDateRange();

  const {
    data: dataSalary,
    isError: errorSalary,
    error,
  } = useGetTeachersReportQuery({
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });

  const teachers = dataSalary ?? ([] as TeacherListReport[]);

  const { data: dataCurriculum, isError: errorCurriculum } =
    useGetTeachersCurriculumReportQuery({
      from: firstDay.toISOString(),
      to: lastDay.toISOString(),
    });

  const curriculums = dataCurriculum ?? ([] as TeacherCurriculimReport[]);

  const total = teachers.reduce((count, item) => {
    count += item.salary;
    return count;
  }, 0);

  if (errorSalary || errorCurriculum) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Báo cáo giảng viên" />
      <GroupButton>
        <LinksContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ marginTop: '5px' }}>
            <Dropdown
              overlay={
                <DropdownMenu
                  setVisible={setVisible}
                  firstDay={firstDay}
                  lastDay={lastDay}
                  setFirstDay={setFirstDay}
                  setLastDay={setLastDay}
                />
              }
              placement="bottomLeft"
              arrow
              trigger={['click']}
              visible={visible}
              onVisibleChange={(flag: boolean) => setVisible(flag)}
            >
              <Input
                style={{ width: 250 }}
                addonBefore={<CalendarOutlined />}
                value={`${format(firstDay)} - ${format(lastDay)}`}
              />
            </Dropdown>
          </div>
          <ExportExcel firstDay={firstDay} lastDay={lastDay} />
        </LinksContainer>
        <LinksContainer>
          <TotalSpan>Tổng thu nhập: {formatMoneyVnd(total)}</TotalSpan>
        </LinksContainer>
      </GroupButton>
      <ComponentContent>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Thu nhập chung" key="1">
            <GeneralSalary teachers={teachers} from={firstDay} to={lastDay} />
          </TabPane>
          <TabPane tab="Tiến độ làm giáo trình" key="2">
            <CurriculumReport curriculums={curriculums} />
          </TabPane>
          <TabPane tab="Bài viết" key="3"></TabPane>
        </Tabs>
      </ComponentContent>
    </>
  );
};

export default TeachersReportContent;
