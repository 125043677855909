import { UploadOutlined } from '@ant-design/icons';
import { Form, Tag, InputNumber, Button, message, Typography } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { formatMoneyVnd } from '../../../../libraries/utils/changeFormatData';
import { BonusDetails, HistorySalary } from '../../../../models/report.model';
import { UserInforType } from '../../../../models/user.model';
import { useSendPaidSalaryMutation } from '../../../../services/report.service';
import Editor from 'libraries/editor/Editor';
import { v4 } from 'uuid';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
const Image = styled.div`
  margin-bottom: 12px;
  max-width: 300px;
  @media (max-width: 1250px) {
    max-width: 200px;
  }
`;

const ContainerStyle = styled.p`
  margin-top: -24px;
  font-weight: 600;
  position: absolute;
  top: 172%;
  left: 21%;

  @media (max-width: 767px) {
    top: 134%;
    left: 4px;
  }
`;

interface ISummaryDetailForm {
  bonus: BonusDetails[];
  user: UserInforType;
  total: number;
  qr_code: any;
  salary_id: string;
  isLoading: boolean;
  history_salary: HistorySalary[];
  refetch: () => void;
}
const layout = {
  labelCol: {
    lg: { span: 5 },
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 5 },
  },
  wrapperCol: {
    lg: { span: 17 },
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 17 },
  },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 17 },
};
const { Text } = Typography;
const tagProps = (length: number) => {
  switch (length) {
    case 0:
      return {
        text: 'Chưa trả',
        color: 'error',
      };
    default:
      return { text: 'Đã trả', color: 'success' };
  }
};

const pattern = (data: any = '', label = '') => ({ data, label });

const formItems = (user: UserInforType, paid: number, total: number) => {
  const keyValue = Object.entries(user)
    .map(([key, value]) => {
      switch (key) {
        case 'full_name':
          return pattern(value, 'Tên');
        case 'bank_name':
          return pattern(value, 'Ngân hàng');
        case 'bank_account':
          return pattern(value, 'STK');
        default:
          return pattern();
      }
    })
    .filter((item) => item.label !== '');
  const items = [
    ...keyValue,
    pattern(formatMoneyVnd(paid), 'Đã trả'),
    pattern(formatMoneyVnd(total), 'Tổng lương'),
  ];
  return items;
};

const SummaryDetailForm = ({
  bonus,
  user,
  total,
  qr_code,
  salary_id,
  history_salary,
  refetch,
  isLoading,
}: ISummaryDetailForm): ReactElement => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [sendPaid] = useSendPaidSalaryMutation();
  const [numberMoney, setNumberMoney] = useState('');
  const [paid, setPaid] = useState(0);
  const [isCheck, setIsCheck] = useState(false);
  useEffect(() => {
    setPaid(
      history_salary?.reduce((prev, curr) => (prev += curr.paid), 0) ?? 0,
    );
  }, [history_salary]);
  //utils
  const { color: tagColor, text: tagTxt } = tagProps(
    history_salary.length ?? 0,
  );
  //fn
  const onFinish = async () => {
    try {
      const data = await form.validateFields();
      if (!data.note && isCheck) {
        message.error('Vui lòng nhập ghi chú !');
        return;
      }
      sendPaid({ id: salary_id, note: data.note, paid: data.paid })
        .unwrap()
        .then((res) => {
          setPaid((prev) => prev + res.paid);
          refetch();
          form.resetFields();
          message.success('Trả lương thành công');
          if (!isCheck) {
            history.push(generatePath(route.report.summary.list));
          }
        })
        .catch((err) => {
          message.error(err);
          return;
        });
    } catch (err) {
      console.log(err);
    }
  };
  //handleEvent
  const onClick = () => {
    const remain = total - paid;
    form.setFieldsValue({ paid: remain > 0 ? remain : 0 });
    onFinish();
  };

  return (
    <Form
      {...layout}
      onFinish={onFinish}
      form={form}
      labelAlign="left"
      style={{ position: 'relative' }}
    >
      {/* <Row gutter={16}>
        <Col span={12} lg={16} xs={16}> */}
      <Form.Item label={<Text strong>Trạng thái</Text>}>
        <Tag color={tagColor}>{tagTxt}</Tag>
      </Form.Item>
      {formItems(user, paid, total).map(({ data, label }) => (
        <Form.Item key={v4()} label={<Text strong>{label}</Text>}>
          <Text>{data}</Text>
        </Form.Item>
      ))}
      <div style={{ position: 'relative' }}>
        <Form.Item
          tooltip="Hãy duyệt hết tất cả các khoản thưởng trước khi trả"
          name="paid"
          label={<Text strong>Trả lương</Text>}
          rules={[
            {
              required: true,
              message: 'Mức trả không được để trống',
            },
          ]}
        >
          <InputNumber
            min={0}
            style={{ width: '10rem' }}
            step={100000}
            onPressEnter={(e) => {
              e.preventDefault();
              onFinish();
            }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(value: any) => setNumberMoney(value)}
            disabled={bonus?.some((item) => item.status === 'pending')}
          />
        </Form.Item>
        <ContainerStyle>{convertCurrencyToWords(+numberMoney)}</ContainerStyle>
      </div>
      <Form.Item
        name="note"
        label={<Text strong>Ghi chú</Text>}
        // rules={[{ required: true, message: 'Ghi chú không được để trống' }]}
      >
        <Editor height={200} />
      </Form.Item>
      {/* </Col>
        <Col span={12} lg={8} xs={8}> */}
      {qr_code !== '' && (
        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <Image>
            <img
              src={`https://img.vietqr.io/image/${user.bank_code}-${
                user.bank_account
              }-print.jpg?accountName=${
                user.full_name
              }&amount=${total}&addInfo= ${
                'Trả lương tháng ' + new Date().getMonth()
              }`}
              alt="QrCode"
            />
          </Image>
        </div>
      )}
      {/* </Col> */}
      {/* </Row> */}

      <Form.Item {...tailLayout}>
        <Button
          style={{ marginRight: 16 }}
          icon={<UploadOutlined />}
          type="primary"
          htmlType="submit"
          onMouseEnter={() => setIsCheck(true)}
        >
          Trả 1 phần
        </Button>
        <Button
          loading={isLoading}
          onClick={onClick}
          onMouseEnter={() => setIsCheck(false)}
        >
          Trả đủ
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SummaryDetailForm;
