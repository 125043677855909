import { ReactElement, useEffect, useState } from 'react';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import { Avatar, Col, DatePicker, Input, message, Row, Select } from 'antd';
import Button from 'libraries/components/commons/Button';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'store/user.slice';
import Editor from 'libraries/editor';
import Hotkeys from 'react-hot-keys';
import DefaultAvatar from 'assets/images/avatar.png';
import MediaManagement from 'libraries/components/media/MediaManagement';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { useUpdateUserInfoMutation } from 'services/user.service';
import {
  userRoleValues,
  userStatusValues,
} from 'libraries/utils/globalVariable';
import moment from 'moment';
import { useUploadFileMutation } from 'libraries/services/media.service';
import UploadFile from 'libraries/components/UploadFile';
import { CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import convertSizeFile from 'libraries/utils/convertSize';
import UploadFileCV from 'libraries/components/UploadFileCV';
interface Bank {
  id: number;
  name: string;
  code: string;
  bin: string;
  shortName: string;
  logo: string;
  transferSupported: number;
  lookupSupported: number;
  short_name: string;
  support: number;
  isTransfer: number;
  swift_code: string;
}
export const DownloadLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
  .anticon-close-circle {
    cursor: pointer;
  }
`;

export const DownloadLink = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
function getShortNameByBin(banks: Bank[], bin: string): string {
  const bank = banks.find((b) => b.bin === bin);
  return bank ? bank.shortName : '';
}
const { Option } = Select;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const GeneralInfo = (): ReactElement => {
  const [updateUserInfo] = useUpdateUserInfoMutation();
  const user = useSelector(selectUserInfo);
  const [fileList, setFileList] = useState<any>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadFile] = useUploadFileMutation();
  const [checkCv, setCheckCV] = useState(false);
  const [banks, setBanks] = useState<Bank[]>([]);
  useEffect(() => {
    fetch('https://api.vietqr.io/v2/banks')
      .then((res) => res.json())
      .then((data) => setBanks(data.data));
  }, []);
  const {
    id,
    full_name,
    phone,
    email,
    bank_account,
    bank_name,
    bank_code,
    description,
    avatar,
    rolesStr,
    statusStr,
    dob,
    link_cv,
  } = user;

  const [descriptions, setDescriptions] = useState(description);

  const [tmpAvatar, setTmpAvatar] = useState('');
  const [linkCV, setLinkCV] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Problem: When get data from store can't set prop value directly for Input of Form Antd.
  // Try use initialValues but cant.
  // Solution: https://stackoverflow.com/questions/53182493/defaultvalue-or-value-not-working-on-formitem-antd
  const [form] = Form.useForm();
  useEffect(() => {
    setLinkCV(link_cv);
    if (link_cv != '') {
      setCheckCV(true);
    }
    form.setFieldsValue({
      full_name,
      email,
      phone,
      bank_account,
      bank_name,
      description,
      avatar,
      statusStr,
      rolesStr,
      dob,
      link_cv,
    });
  }, [
    full_name,
    phone,
    email,
    bank_account,
    bank_code,
    description,
    avatar,
    statusStr,
    rolesStr,
    dob,
    link_cv,
  ]);

  const options = banks?.map((bank: any) => ({
    value: bank.bin,
    label: ` ${bank.shortName} (${bank.name})`,
    shortName: bank.shortName,
  }));
  const handleUploadFile = (value: FormData) => {
    uploadFile({ data: value })
      .unwrap()
      .then((res) => {
        setLinkCV(res.url);
        setCheckCV(true);
        const newArr = [
          // ...files,
          {
            name: res.filename,
            id: res.id,
            link: res.url,
          },
        ];
        // onChangeLectureField('files', newArr);
        message.success('Upload file thành công!');
        setFileList([]);
        setUploading(false);
        // hasEdit(true);
      })
      .catch((error) => {
        message.error(error.data);
        setUploading(false);
        setFileList([]);
      });
  };
  // TODO: cần sửa lại type của values
  // eslint-disable-next-line

  const onFinish = (values: any) => {
    const dates = moment(values.dobs);
    const times = moment(18, 'HH:mm');
    dates.set({
      hour: times.get('hour'),
      minute: times.get('minute'),
      second: times.get('second'),
    });

    updateUserInfo({
      id: id,
      full_name: values.full_name,
      phone: values.phone,
      email: values.email,
      dob: values.dobs != undefined ? dates.toISOString() : dob,
      bank_name:
        values.bank_code !== undefined
          ? getShortNameByBin(banks, values.bank_code)
          : bank_name,
      bank_code: values.bank_code !== undefined ? values.bank_code : bank_code,
      bank_account:
        values.bank_account !== undefined ? values.bank_account : '',
      description: descriptions,
      link_cv: linkCV,
      avatar: values.avatar ? values.avatar : avatar,
      roles: values.rolesStr.reduce((arr: number[], item: string): number[] => {
        arr.push(Number(item));
        return arr;
      }, [] as number[]),
    })
      .unwrap()
      .then(() => {
        message.success('Cập nhật thông tin user thành công!');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleChangeDescription = (value: string) => {
    setDescriptions(value);

    form.setFieldsValue({
      description: value,
    });
  };
  const handleRemoveLink = () => {
    setLinkCV('');

    setCheckCV(false);
  };
  const handlersHotkeys = (e: KeyboardEvent) => {
    e.preventDefault();
    onFinish(form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldsValue({
      avatar: tmpAvatar,
    });
  }, [tmpAvatar]);

  return (
    <>
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <LinksContainer>
            <Button.Primary type="primary" htmlType="submit">
              Cập nhật
            </Button.Primary>
          </LinksContainer>
          <Row gutter={[30, 30]}>
            <Col xs={24} md={14}>
              <Form.Item
                label={<FormLabel label="Họ tên" />}
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên user!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Ngày sinh (Không bắt buộc)" />}
                name="dobs"
              >
                <DatePicker
                  defaultValue={
                    dob != '0001-01-01T00:00:00Z' ? moment(dob) : undefined
                  }
                  format={dateFormatList}
                />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Email" />}
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Địa chỉ email không hợp lệ!',
                  },
                  { required: true, message: 'Vui lòng nhập email!' },
                ]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Số điện thoai" />}
                name="phone"
                rules={[
                  { required: true, message: 'Vui lòng nhập số điện thoại' },
                  {
                    pattern: /^[0-9]{10,11}$/,
                    message:
                      'Sai định dạng, số điện thoại phải có 10 hoặc 11 số!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Giới thiệu" require={false} />}
              >
                <Editor
                  value={description}
                  height={300}
                  onChange={handleChangeDescription}
                ></Editor>
              </Form.Item>
            </Col>
            <Col xs={24} md={10}>
              <Form.Item
                label={<FormLabel label="Tên ngân hàng" require={false} />}
                name="bank_code"
              >
                {banks.length != 0 && (
                  <Select
                    showSearch
                    placeholder="Chọn ngân hàng"
                    optionFilterProp="children"
                    defaultValue={bank_name}
                    // onChange={(value: any) => {
                    //   setTmpContent({
                    //     ...tmpContent,
                    //     bank_code: value,
                    //     bank_name: getShortNameByBin(banks, value) || '',
                    //   });
                    //   setIsChange(true);
                    // }}
                    // onChange={onChange}
                    filterOption={(input, option) =>
                      (option?.shortName ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={options}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Số tài khoản" require={false} />}
                name="bank_account"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Quyền" />}
                name="rolesStr"
                rules={[
                  { required: true, message: 'Vui lòng nhập quyền của user' },
                ]}
              >
                <Select optionFilterProp="children" mode="multiple">
                  {userRoleValues.map((item, index) => (
                    <Option value={item.value.toString()} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <FormLabel label="Trạng thái tài khoản" require={false} />
                }
                name="statusStr"
              >
                <Select disabled>
                  {userStatusValues.map((item, index) => (
                    <Option value={item.value ? 'true' : 'false'} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Cập nhật CV" name="CV">
                {checkCv && (
                  <DownloadLinkContainer>
                    <DownloadLink>
                      <a href={linkCV} target="_blank" rel="noreferrer">
                        Xem CV
                      </a>
                      <CloseCircleFilled onClick={handleRemoveLink} />
                    </DownloadLink>
                  </DownloadLinkContainer>
                )}

                <UploadFileCV
                  fileList={fileList}
                  // eslint-disable-next-line
                  setFileList={(value: any) => setFileList(value)}
                  handleUploadFile={handleUploadFile}
                  uploading={uploading}
                  setUploading={(value: boolean) => setUploading(value)}
                />
                <p style={{ marginTop: '6px' }}>
                  Định dạng PDF, kích thước &lt; 5 MB
                </p>
              </Form.Item>
              <Form.Item label="" name="avatar">
                <Avatar
                  size={200}
                  src={
                    tmpAvatar !== ''
                      ? tmpAvatar
                      : avatar !== ''
                        ? avatar
                        : DefaultAvatar
                  }
                />
              </Form.Item>

              <div>
                <Button.Default
                  style={{ fontSize: '14px' }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Thay đổi ảnh đại diện
                </Button.Default>
              </div>
            </Col>
          </Row>
        </Form>
      </Hotkeys>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setTmpAvatar}
        />
      )}
    </>
  );
};

export default GeneralInfo;
