import ShowServerError from 'components/commons/ShowServerError';
import { ProjectInfo } from 'models/project.model';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProjectDetailQuery } from 'services/project.service';
import ProjectDetailContent from './ProjectDetailContent';

const GetProject = (): ReactElement => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data, isError, error } = useGetProjectDetailQuery(projectId);

  const project = data ? data : ({} as ProjectInfo);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return <ProjectDetailContent project={project} />;
};

export default GetProject;
