import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteTwoTone,
  EditTwoTone,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Form as FormAntd,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import type { RangePickerProps } from 'antd/lib/date-picker';
import ServerError from 'components/commons/ServerError';
import LoadingSpinner from 'components/LoadingSpin';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import Editor from 'libraries/editor/Editor';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import moment from 'moment';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDeleteUserBonusSalaryReportMutation,
  useSendUserBonusSalaryReportMutation,
} from 'services/report.service';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { BonusDetails } from 'models/report.model';
import parser from '../../../../../libraries/editor/parser';
import { useHistory, useLocation } from 'react-router-dom';

interface PropsType {
  firstDay: moment.Moment;
  lastDay: moment.Moment;
  bonus: BonusDetails[];
  isLoading: boolean;
  isError: boolean;
}

export interface detailCell extends BonusDetails {
  key: React.Key;
}

type Row = Omit<detailCell, 'date'> & {
  date: moment.Moment;
};

const Form = styled(FormAntd)`
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
  label {
    font-weight: bold;
    margin: 16px;
  }
  .ant-col.ant-form-item-control {
    justify-content: center;
  }
`;

const deepClone = (item: any) => {
  const json = JSON.stringify(item);
  const copy = JSON.parse(json);
  return copy;
};

const deleteKey = (item: detailCell) => {
  const clone = deepClone(item);
  delete clone.key;
  return clone;
};

const BonusSalaryTable = ({
  firstDay,
  bonus,
  isLoading,
  isError,
}: PropsType): ReactElement => {
  const [form] = FormAntd.useForm();
  const [details, setDetail] = useState([] as detailCell[]);
  const [editingKey, setEditingKey] = useState<React.Key>('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [hoverKey, setHoverKey] = useState<React.Key>('');
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const [readOnly, setReadOnly] = useState(false);

  const { search } = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const bonusId = queryParams.get('bonus');
  useEffect(() => {
    if (bonusId && details.length) {
      const detail =
        details.find((item) => item.id === Number(bonusId)) ??
        ({} as detailCell);
      const key = detail.key;
      edit(key);
    }
  }, [bonusId, details]);

  const [sendBonus, { isLoading: isSendLoading }] =
    useSendUserBonusSalaryReportMutation();
  const [deleteBonus, { isLoading: isDeleteLoading }] =
    useDeleteUserBonusSalaryReportMutation();

  useEffect(() => {
    if (!bonus) return;
    const dataWthKey: detailCell[] = bonus.map((item) => ({
      ...item,
      key: v4(),
    }));
    setDetail(dataWthKey);
  }, [bonus]);

  const edit = (key: React.Key) => {
    const [item] = find(key);
    const newRecord = { ...item, date: moment(item.date) };
    form.setFieldsValue(newRecord);
    setEditingKey(item.key);
    setModalVisible(true);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Row;
      const [item, index] = find(key);
      const data = {
        ...item,
        ...row,
        date: row.date.toISOString(),
      };
      const body = deleteKey(data); // * không gửi react key
      sendBonus({ ...body, note_html: parser.render(body.note) })
        .unwrap()
        .then(({ id }) => {
          // * khi tạo một task mới thì id là 0, khi gửi lên server sẽ được trả về một id mới và mình dùng cái id này

          setDetail((prev) => {
            const copy = deepClone(prev);
            if (index === -1) {
              data.id = id;
              copy.splice(0, 0, data); //add
            } else copy.splice(index, 1, data); //edit

            return copy;
          });
          message.success('Cập nhật thành công');
          setModalVisible(false);
          setEditingKey('');
        })
        .catch((error) => message.error(error));
    } catch (errInfo) {
      message.error(`Xác thực thất bại: ${errInfo}`);
    }
  };

  const handleAdd = () => {
    setEditingKey('');
    edit('');
    setModalVisible(true);
  };

  const deleteDetail = (key: React.Key) => {
    const [item] = find(key);
    deleteBonus(item.id)
      .unwrap()
      .then(() => {
        setDetail((prev) => {
          const copy = deepClone(prev) as detailCell[];
          const newDetail = copy.filter((item) => item.key !== key);
          return newDetail;
        });
        message.success('Xóa thành công');
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const seeDetail = (key: React.Key) => {
    setEditingKey(key);
    edit(key);
    setModalVisible(true);
    setReadOnly(true);
  };

  const columns = [
    {
      title: 'Ngày thực hiện',
      dataIndex: 'date',
      width: '20%',
      render: (_: void, { date }: detailCell) => formatDate(date.toString()),
    },
    {
      width: '20%',
      title: 'Tên đầu việc',
      dataIndex: 'content',
      render: (_: void, { content, key }: detailCell) => (
        <Typography.Link onClick={() => seeDetail(key)} ellipsis={true}>
          {content}
        </Typography.Link>
      ),
    },
    {
      width: '13%',
      title: 'Trạng thái',
      render: (_: void, { status }: detailCell) => {
        switch (status) {
          case 'approve':
            return (
              <Tag color="success" icon={<CheckCircleOutlined />}>
                Chấp thuận
              </Tag>
            );
          case 'pending':
            return (
              <Tag color="processing" icon={<SyncOutlined />}>
                Chờ duyệt
              </Tag>
            );
          case 'reject':
            return (
              <Tag color="error" icon={<CloseCircleOutlined />}>
                Từ chối
              </Tag>
            );
        }
      },
    },
    {
      title: 'Thưởng (đ)',
      dataIndex: 'bonus',
      width: '17%',
      render: (_: void, { bonus }: detailCell) => formatMoneyVnd(bonus),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: '20%',
      render: (_: void, { note }: detailCell) => (
        <Tooltip overlay={'Click !'}>
          <Typography.Text ellipsis={true} style={{ cursor: 'pointer' }}>
            {note}
          </Typography.Text>
        </Tooltip>
      ),
      onCell: (record: detailCell) => {
        return {
          onClick: () =>
            setExpandedRowKeys((prev) => {
              const copy = [...prev];
              if (copy[0] === record.key) {
                copy.pop();
              } else copy[0] = record.key;
              return copy;
            }),
        };
      },
    },
    {
      title: 'Thao tác',
      width: '10%',
      render: (_: any, { key, status }: detailCell) => {
        if (status === 'approve' || status === 'reject' || hoverKey !== key)
          return <></>;
        return (
          <Space
            align="center"
            size={'small'}
            split={<Divider type="vertical" />}
          >
            <Button
              type="dashed"
              shape="circle"
              size="small"
              disabled={editingKey !== ''}
              onClick={() => edit(key)}
              icon={<EditTwoTone />}
            />
            <Popconfirm
              title="Bạn có chắc bạn muốn xóa không"
              onConfirm={() => deleteDetail(key)}
            >
              <Button
                type="dashed"
                shape="circle"
                size="small"
                disabled={editingKey !== ''}
                icon={<DeleteTwoTone />}
              />
            </Popconfirm>
          </Space>
        );
        //   );
      },
    },
  ];

  const find = (key: React.Key): [detailCell, number] => {
    const index = details.findIndex((item) => item.key === key);
    if (index === -1) {
      return [
        {
          bonus: 1000,
          date: moment().toISOString(),
          content: '',
          note: '',
          status: 'pending',
          key: v4(),
          id: 0,
          reason: '',
        },
        -1,
      ];
    }
    return [{ ...details[index] }, index];
  };

  const formItemsNodes = useCallback(
    (key: React.Key, readOnly?: boolean) => {
      const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return (
          current < moment(firstDay, 'DD-MM-YYYY') ||
          current > moment().endOf('day')
        );
      };
      const [item] = find(editingKey);
      const keys = Object.keys(item).filter((key) =>
        ['bonus', 'date', 'note', 'content', 'reason'].includes(key),
      );
      const nodes = keys.map((el: string) => {
        const input = () => {
          switch (el) {
            case 'bonus':
              return {
                node: (
                  <InputNumber
                    min={1}
                    style={{ width: '100%', border: readOnly ? 'none' : '' }}
                    step={10000}
                    onPressEnter={(e) => {
                      e.preventDefault();
                      save(key);
                    }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    readOnly={readOnly}
                  />
                ),
                label: 'Lương thưởng',
              };
            case 'date':
              return {
                node: readOnly ? (
                  <div>{formatDate(item.date)}</div>
                ) : (
                  <DatePicker format={'DD-MM-YYYY'} />
                ),
                label: 'Ngày thực hiện',
              };
            case 'note':
              return {
                node: readOnly ? (
                  <ShowMarkdown markdown={item.note} />
                ) : (
                  <Editor height={300} />
                ),
                label: 'Ghi chú',
              };
            case 'reason':
              if (!readOnly) break;
              return {
                node:
                  item.reason !== '' ? (
                    <div dangerouslySetInnerHTML={{ __html: item.reason }} />
                  ) : (
                    <Empty
                      description="Hiện chưa có phản hồi từ admin"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ),
                label: 'Phản hồi',
              };
            default:
              return {
                node: (
                  <Input
                    style={{ width: '100%', border: readOnly ? 'none' : '' }}
                    onPressEnter={(e) => {
                      e.preventDefault();
                      save(key);
                    }}
                    readOnly={readOnly}
                  />
                ),
                label: 'Tên đầu việc',
              };
          }
        };
        const { node, label } = input() ?? { node: null, label: '' };
        if (node === null) return null;
        return (
          <Form.Item
            name={el}
            label={label}
            key={el}
            rules={[
              {
                required: true,
                message: `${label} không được để trống`,
              },
            ]}
          >
            {node}
          </Form.Item>
        );
      });
      return nodes;
    },
    [editingKey],
  );
  const onCancel = () => {
    setModalVisible(false);
    setEditingKey('');
    setReadOnly(false);
    if (queryParams.has('bonus')) {
      queryParams.delete('bonus');
      history.replace({ search: queryParams.toString() });
    }
  };
  // const onChange = () => {
  //   const value = form.getFieldsValue();
  //   const date = value.date ?? new Date(0);
  //   setDetail((prev) => {
  //     const [item, index] = find(editingKey);
  //     if (index === -1) return prev;
  //     const copy = deepClone(prev) as detailCell[];
  //     copy[index] = { ...item, ...value, date };
  //     return copy;
  //   });
  // };

  if (isError) return <ServerError />;
  else
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Typography.Title type="success" level={5}>
            Tổng thưởng là:&nbsp;
            {formatMoneyVnd(
              details.reduce((prev, cur) => {
                if (cur.status === 'approve') {
                  prev += cur.bonus;
                }
                return prev;
              }, 0),
            )}
          </Typography.Title>
        </div>
        <Button
          icon={<PlusOutlined />}
          type="dashed"
          disabled={editingKey !== ''}
          style={{ margin: '16px 0' }}
          onClick={handleAdd}
        >
          Thêm yêu cầu
        </Button>
        <Table
          loading={isLoading || isDeleteLoading || isSendLoading}
          bordered
          dataSource={details}
          columns={columns}
          rowClassName="editable-row"
          scroll={{ y: 450 }}
          onRow={(record) => {
            return {
              onMouseEnter: () => {
                setHoverKey(record.key);
              },
              onMouseLeave: () => {
                setHoverKey('');
              },
            };
          }}
          expandable={{
            rowExpandable: (record) =>
              record.note !== '' || record.reason !== '',
            onExpand: (_, record) => {
              setExpandedRowKeys((prev) => {
                const copy = [...prev];
                if (copy[0] === record.key) {
                  copy.pop();
                } else copy[0] = record.key;
                return copy;
              });
            },
            expandedRowKeys: expandedRowKeys,
            expandedRowRender: (record) => {
              return (
                <Form labelCol={{ span: 4 }} layout="horizontal">
                  <Form.Item
                    className={record.note ? 'showMarkdown' : ''}
                    label="Ghi chú"
                  >
                    {record.note ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <ShowMarkdown markdown={record.note} />
                      </div>
                    ) : (
                      <Empty
                        description="Không có ghi chú"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    )}
                  </Form.Item>
                  <Divider />
                  <Form.Item
                    label="Phản hồi"
                    className={record.reason ? 'showMarkdown' : ''}
                  >
                    {record.reason ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <ShowMarkdown markdown={record.reason} />
                      </div>
                    ) : (
                      <Empty
                        description="Hiện chưa có phản hồi từ admin"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    )}
                  </Form.Item>
                </Form>
              );
            },
          }}
          /**
           * todo khi mà sẵn sàng hãy mở lại pagination
           * todo xóa className cho expandable form item of table row
           * * những việc cần lưu ý
           * * khi page không phải trang đầu tiên thì khi add item mới vẫn hoạt động nhưng sẽ hiện ở trang đầu tiên gây ra khó hiểu => khi bấm add thêm tự động roll về trang đầu tiên và nếu có thể scroll lên trên cùng
           */
          pagination={false}
          // pagination={{
          //   onChange: cancel,
          // }}
        />
        {isModalVisible && (
          <Modal
            width={'60%'}
            visible={isModalVisible}
            keyboard
            onCancel={onCancel}
            zIndex={100}
            title={readOnly ? 'Chi tiết' : 'Chỉnh sửa'}
            onOk={() => save(editingKey)}
            confirmLoading={isSendLoading || isLoading}
            okText={'Lưu'}
            cancelText={'Hủy'}
            okButtonProps={{ disabled: readOnly }}
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <Form
                preserve={false}
                name="createBonus"
                initialValues={deepClone(find(editingKey)[0])}
                form={form}
                labelCol={{ span: 4 }}
                layout="horizontal"
                // onFieldsChange={() => onChange()}
                labelAlign="left"
              >
                {formItemsNodes(editingKey, readOnly)}
              </Form>
            )}
          </Modal>
        )}
      </>
    );
};

export default BonusSalaryTable;
