import { ReactElement, useEffect, useState } from 'react';
import { Input, Pagination, Table, Select } from 'antd';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

import { ColumnsType } from 'antd/lib/table';
import { Course } from 'models/course.model';
import TableContainer from 'components/table/TableContainer';
import {
  courseActiveValues,
  courseStatusValues,
} from 'libraries/utils/globalVariable';

interface KeySearchType {
  keyInteractive: '' | 'true' | 'false';
  keyStatus: string;
  keyText: string;
  keyDirection: 'asc' | 'desc';
}

interface PropsType {
  courses: Course[];
  total_page: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  setKeyword: (value: string) => void;
  state: KeySearchType;
  handleSetState: (field: string, value: string) => void;
}

const { Option } = Select;

const CourseListContent = ({
  courses,
  total_page,
  currentPage,
  setCurrentPage,
  setKeyword,
  state,
  handleSetState,
}: PropsType): ReactElement => {
  const [tmpName, setTmpName] = useState('');

  const { keyInteractive, keyStatus, keyText, keyDirection } = state;

  const handleOnchangePage = (page: number) => {
    setCurrentPage(page);
    setKeyword(
      `page=${page}&interactive=${keyInteractive}&status=${keyStatus}&keyword=${keyText}&direction=${keyDirection}`,
    );
  };

  const columns: ColumnsType<Course> = [
    {
      title: 'Tên khóa học',
      children: [
        {
          key: 'name',
          dataIndex: 'name',
          title: (
            <Input
              value={tmpName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('keyText', tmpName);
                }
              }}
              onChange={(e) => setTmpName(e.target.value)}
            />
          ),
          render: (_text, item) => (
            <RouterLink
              to={generatePath(route.course.detail, { courseId: item.id })}
              style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            >
              {item.name}
            </RouterLink>
          ),
        },
      ],
    },
    {
      title: 'Ngày tạo',
      children: [
        {
          key: 'create_date',
          dataIndex: 'create_date',
          render: (create_date) =>
            create_date !== '' ? formatDate(create_date) : '',
        },
      ],
      width: '10%',
    },
    {
      title: 'Loại',
      children: [
        {
          key: 'interactive',
          dataIndex: 'interactive',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={keyInteractive}
              onChange={(value) => handleSetState('keyInteractive', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              {courseActiveValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (interactive) => {
            if (interactive !== '') {
              for (let i = 0; i < courseActiveValues.length; i++) {
                const item = courseActiveValues[i];
                if (item.value === interactive) {
                  return item.label;
                }
              }
            }
            return '';
          },
        },
      ],
      width: '15%',
    },
    {
      title: 'Trạng thái',
      children: [
        {
          key: 'status',
          dataIndex: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={keyStatus}
              onChange={(value) => handleSetState('keyStatus', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              {courseStatusValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (status) => {
            if (status !== '') {
              for (let i = 0; i < courseStatusValues.length; i++) {
                const item = courseStatusValues[i];
                if (item.value === status) {
                  return item.label;
                }
              }
            }
            return '';
          },
        },
      ],
      width: '15%',
    },
  ];

  useEffect(() => {
    const { keyInteractive, keyStatus, keyText, keyDirection } = state;
    setCurrentPage(1);
    setKeyword(
      `page=1&interactive=${keyInteractive}&status=${keyStatus}&keyword=${keyText}&direction=${keyDirection}`,
    );
    if (keyText === '') setTmpName('');
  }, [state]);

  return (
    <TableContainer>
      <Table<Course>
        columns={columns}
        dataSource={courses}
        pagination={false}
        rowKey="id"
        bordered
      />
      <Pagination
        current={currentPage}
        pageSize={10}
        showSizeChanger={false}
        defaultCurrent={currentPage}
        total={total_page * 10}
        style={{ marginTop: '20px' }}
        onChange={handleOnchangePage}
      />
    </TableContainer>
  );
};
export default CourseListContent;
