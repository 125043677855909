import { ReactElement } from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Container } from '../LoadingSpin';

interface PropsType {
  subTitle: string;
  returnLink: string;
  returnTitle: string;
}

const NotFound = ({
  subTitle,
  returnLink,
  returnTitle,
}: PropsType): ReactElement => {
  return (
    <Container>
      <Result
        status="404"
        title="404"
        subTitle={subTitle}
        extra={
          <Link to={returnLink}>
            <Button type="primary">{returnTitle}</Button>
          </Link>
        }
      />
    </Container>
  );
};

NotFound.defaultProps = {
  subTitle: 'Xin lỗi trang bạn đang truy cập không tồn tại',
  returnLink: '/',
  returnTitle: 'Quay về Dashboard',
};

export default NotFound;
