import { PlusOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';
import ServerError from 'components/commons/ServerError';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { ReactElement } from 'react';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetPromotionsQuery } from 'services/promotion.service';
import PromotionTable from './PromotionTable';

const GetPromotions = (): ReactElement => {
  const { isError, error } = useGetPromotionsQuery();

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Danh sách khuyến mãi" />
      <LinksContainer>
        <Link.Primary to={generatePath(route.promotion.createVoucher)}>
          <PlusOutlined /> Tạo voucher
        </Link.Primary>
      </LinksContainer>
      <PromotionTable />
    </>
  );
};

export default GetPromotions;
