import { ReactElement, useEffect, useState } from 'react';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Link from 'libraries/components/commons/Link';
import { LeftOutlined } from '@ant-design/icons';
import { generatePath, useHistory } from 'react-router';
import { route } from 'routers/routeNames';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import { Row, Col, Input, message, Avatar, DatePicker } from 'antd';
import Button from 'libraries/components/commons/Button';
import { useCreateUserMutation } from 'services/user.service';
import Breadcrumb from 'components/commons/Breadcrumb';
import Editor from 'libraries/editor';
import Hotkeys from 'react-hot-keys';

import DefaultAvatar from 'assets/images/avatar.png';
import MediaManagement from 'libraries/components/media/MediaManagement';
import moment from 'moment';

const CreateUserContent = (): ReactElement => {
  const history = useHistory();
  const [avatar, setAvatar] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createUser] = useCreateUserMutation();
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
  const [form] = Form.useForm();

  // TODO: cần sửa lại type của values
  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    const dates = moment(values.dob);
    const times = moment(18, 'HH:mm');

    dates.set({
      hour: times.get('hour'),
      minute: times.get('minute'),
      second: times.get('second'),
    });

    createUser({
      ...values,
      dob:
        values.dob != undefined ? dates.toISOString() : '0001-01-01T00:00:00Z',
      bank_name: values.bank_name !== undefined ? values.bank_name : '',
      bank_account:
        values.bank_account !== undefined ? values.bank_account : '',
      description: values.description !== undefined ? values.description : '',
    })
      .unwrap()
      .then((res) => {
        message.success('Tạo user mới thành công');
        setTimeout(() => {
          history.push(generatePath(route.user.info, { userId: res.id }));
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleChangeDescription = (value: string) => {
    form.setFieldsValue({
      description: value,
    });
  };

  const handlersHotkeys = (e: KeyboardEvent) => {
    e.preventDefault();
    handleSubmit(form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldsValue({
      avatar,
    });
  }, [avatar]);

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.user.manage)}
        secondaryText="User"
        text="Tạo user"
      />
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <LinksContainer>
            <Link.Default to={generatePath(route.user.manage)}>
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
            >
              Tạo user
            </Button.Primary>
          </LinksContainer>
          <BorderTopBoxContent>
            <Row gutter={[30, 30]}>
              <Col xs={24} md={14}>
                <Form.Item
                  label={<FormLabel label="Họ tên" />}
                  name="full_name"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập tên user!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Ngày sinh (Không bắt buộc)" />}
                  name="dob"
                >
                  <DatePicker format={dateFormatList} />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Email" />}
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'Địa chỉ email không hợp lệ!',
                    },
                    { required: true, message: 'Vui lòng nhập email!' },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Số điện thoai" />}
                  name="phone"
                  rules={[
                    { required: true, message: 'Vui lòng nhập số điện thoại' },
                    {
                      pattern: /^[0-9]{10,11}$/,
                      message:
                        'Sai định dạng, số điện thoại phải có 10 hoặc 11 số!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Giới thiệu" require={false} />}
                  name="description"
                >
                  <Editor
                    value={'description'}
                    height={220}
                    onChange={handleChangeDescription}
                  ></Editor>
                </Form.Item>
              </Col>
              <Col xs={24} md={10}>
                <Form.Item
                  label={<FormLabel label="Tên ngân hàng" require={false} />}
                  name="bank_name"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Số tài khoản" require={false} />}
                  name="bank_account"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="" require={false} />}
                  name="avatar"
                >
                  <Avatar
                    size={200}
                    src={avatar !== '' ? avatar : DefaultAvatar}
                  />
                </Form.Item>
                <div>
                  <Button.Default
                    style={{ fontSize: '14px' }}
                    onClick={() => setIsModalVisible(true)}
                  >
                    Thay đổi ảnh đại diện
                  </Button.Default>
                </div>
              </Col>
            </Row>
          </BorderTopBoxContent>
        </Form>
      </Hotkeys>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setAvatar}
        />
      )}
    </>
  );
};
export default CreateUserContent;
