import { createSlice } from '@reduxjs/toolkit';
import { Classes, Track, TrackMaster } from 'models/track.model';
import { trackApi } from 'services/track.service';

import { RootState } from 'store';

export interface TrackState {
  track: Track;
  trackMaster: TrackMaster[];
  classes: Classes[];
  classesId: string[];
  keySearch: string;
}

const initialState: TrackState = {
  track: {} as Track,
  classes: [] as Classes[],
  classesId: [] as string[],
  trackMaster: [] as TrackMaster[],
  keySearch: `keyword=&master_id=`,
};

export const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    setKeySearch: (state, { payload }) => {
      state.keySearch = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      trackApi.endpoints.getTrackInfo.matchFulfilled,
      (state, { payload }) => {
        state.track = payload;
        state.classesId = payload.classes.reduce((prev, curr) => {
          prev.push(curr.id);
          return prev;
        }, [] as string[]);
      },
    );

    builder.addMatcher(
      trackApi.endpoints.getClassesList.matchFulfilled,
      (state, { payload }) => {
        state.classes = payload;
      },
    );

    builder.addMatcher(
      trackApi.endpoints.getTrackMaster.matchFulfilled,
      (state, { payload }) => {
        state.trackMaster = payload;
      },
    );

    builder.addMatcher(
      trackApi.endpoints.createNewTrack.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        // state.classes = payload;
      },
    );

    builder.addMatcher(
      trackApi.endpoints.updateTrack.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        // state.classes = payload;
      },
    );
  },
});

export const {} = trackSlice.actions;
export const selectTrack = (state: RootState): Track => state.track.track;
export const selectClasses = (state: RootState): Classes[] =>
  state.track.classes;
export const selectClassesId = (state: RootState): string[] =>
  state.track.classesId;
export const selectTrackMaster = (state: RootState): TrackMaster[] =>
  state.track.trackMaster;
export const selectKeySearch = (state: RootState): string =>
  state.track.keySearch;

export const { setKeySearch } = trackSlice.actions;
export default trackSlice.reducer;
