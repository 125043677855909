import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface GeneralState {
  isSidebarOpen: boolean;
  isSidebarCollapse: boolean;
}

const initialState: GeneralState = {
  isSidebarOpen: false,
  isSidebarCollapse: false,
};

export const generalSlide = createSlice({
  name: 'general',
  initialState,
  reducers: {
    showSidebar: (state) => {
      state.isSidebarOpen = true;
    },
    hideSidebar: (state) => {
      state.isSidebarOpen = false;
    },
    collapseSidebar: (state) => {
      state.isSidebarCollapse = true;
    },
    expandSidebar: (state) => {
      state.isSidebarCollapse = false;
    },
  },
});

export const { showSidebar, hideSidebar, collapseSidebar, expandSidebar } =
  generalSlide.actions;
export const selectIsSidebarOpen = (state: RootState): boolean =>
  state.general.isSidebarOpen;
export const selectIsSidebarCollapse = (state: RootState): boolean =>
  state.general.isSidebarCollapse;
export default generalSlide.reducer;
