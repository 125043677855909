import { Spin, Col, Input, message, Row, Select } from 'antd';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import Button from 'libraries/components/commons/Button';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import {
  courseActiveValues,
  courseStatusValues,
  ROLE_SALE,
  ROLE_TRAINER,
} from 'libraries/utils/globalVariable';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetTagsQuery,
  useUpdateCourseInfoMutation,
} from 'services/course.service';
import { selectCourse } from 'store/course.slice';

import { useGetUsersByRoleQuery } from 'services/user.service';
import Editor from 'libraries/editor';
import parser from 'libraries/editor/parser';
import Hotkeys from 'react-hot-keys';

const { TextArea } = Input;
const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const CourseDetail = (): ReactElement => {
  const course = useSelector(selectCourse);

  const [updateCourseInfo] = useUpdateCourseInfoMutation();
  const { data: tags, isLoading: isTagsLoading } = useGetTagsQuery();
  const { data: teachersList, isLoading: isTeachersListLoading } =
    useGetUsersByRoleQuery(ROLE_TRAINER);
  const { data: salers, isLoading: isSalersLoading } =
    useGetUsersByRoleQuery(ROLE_SALE);

  const [chosenThumbnail, setChosenThumbnail] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  const handleChangeDescription = (value: string) => {
    form.setFieldsValue({
      description: value,
    });
  };

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    updateCourseInfo({
      id: course.id,
      name: values.course_name,
      is_interactive: values.is_interactive === 'true' ? true : false,
      status: values.status,
      description: parser.render(values.description),
      short_description: values.short_description,
      thumbnail: chosenThumbnail,
      tag_ids: values.tag_ids,
      sale_id: values.sale_id,
      teachers: values.teachers,
    })
      .unwrap()
      .then(() => message.success('Cập nhật khoá học thành công'))
      .catch((error) => {
        message.error(error.data);
      });
  };
  const handlersHotkeys = (e: KeyboardEvent) => {
    e.preventDefault();
    onFinish(form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldsValue({
      course_name: course.name,
      is_interactive: course.is_interactive ? 'true' : 'false',
      status: course.status,
      description: course.description,
      short_description: course.short_description,
      tag_ids: course.tag_ids,
      sale_id: course.sale_id,
      teachers: course.teachers,
    });
    setChosenThumbnail(course.thumbnail);
  }, [course]);

  return (
    <>
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Spin
          spinning={isTagsLoading || isTeachersListLoading || isSalersLoading}
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={course}
          >
            <LinksContainer>
              <Button.Primary type="primary" htmlType="submit">
                Cập nhật
              </Button.Primary>
            </LinksContainer>
            <Row gutter={[20, 20]}>
              <Col xs={24} md={17}>
                <Form.Item
                  label={<FormLabel label="Tên khoá học" />}
                  name="course_name"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập tên khoá học!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Nội dung" />}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập nội dung khoá học!',
                    },
                  ]}
                >
                  <Editor
                    value="description"
                    onChange={handleChangeDescription}
                    height={250}
                  />
                </Form.Item>

                <Form.Item
                  name="short_description"
                  label={<FormLabel label="Mô tả ngắn" require={false} />}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item
                  label={<FormLabel label="Hình thức" require={false} />}
                  name="is_interactive"
                >
                  <Select placeholder="Cách học">
                    {courseActiveValues.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Trạng thái" />}
                  name="status"
                >
                  <Select placeholder="Chọn trạng thái">
                    {courseStatusValues.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {teachersList && (
                  <Form.Item
                    label={<FormLabel label="Giảng viên" require={false} />}
                    name="teachers"
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Danh sách giảng viên"
                    >
                      {teachersList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.info}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {salers && (
                  <Form.Item
                    label={
                      <FormLabel label="Nhân viên marketing" require={false} />
                    }
                    name="sale_id"
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{ width: '100%' }}
                      placeholder="Danh sách nhân viên"
                    >
                      {salers.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.info}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {tags && (
                  <Form.Item
                    label={<FormLabel label="Danh mục" require={false} />}
                    name="tag_ids"
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                    >
                      {tags.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item label="" name="thumbnail">
                  {chosenThumbnail !== '' && (
                    <img
                      style={{ width: '100%', marginBottom: '20px' }}
                      src={chosenThumbnail}
                    />
                  )}
                </Form.Item>
                <div>
                  <Button.Info onClick={() => setIsModalVisible(true)}>
                    Chọn ảnh bài viết
                  </Button.Info>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Hotkeys>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setChosenThumbnail}
        />
      )}
    </>
  );
};

export default CourseDetail;
