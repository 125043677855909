import { TbMath } from "react-icons/tb";
import { Button, Dropdown, MenuProps } from 'antd';
import { ReactElement, useMemo, useCallback } from 'react';

interface iMathBtn {
  handleMath: (type: number) => void;
}
const DropdownMath = ({ handleMath }: iMathBtn): ReactElement => {
  const onClick = useCallback(
    ({ key }: { key: string }) => {
      const type = +key;
      handleMath(type);
    },
    [handleMath],
  );

  const items: MenuProps['items'] = useMemo(
    () => [
      { key: '1', label: 'Inline math', style: { fontSize: 14 } },
      { key: '2', label: 'Block math', style: { fontSize: 14 } },

    ],
    [],
  );

  return (
    <Dropdown menu={{ items, onClick }}>
      <TbMath />
    </Dropdown>
  );
};

const MathBtn = ({ handleMath }: iMathBtn) => {
  return (
    <Button
      title="Math"
      type="text"
      icon={<DropdownMath handleMath={handleMath} />}
    />
  );
};

export default MathBtn;
