import { useContext } from 'react';
import { EditorContext as EC } from '../EditorContext';
import markers from '../markers';
import { Button, Dropdown, Form as FormAntd, InputNumber } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { styleForm } from './YoutubeLinkBtn';
import { TableOutlined } from '@ant-design/icons';


const Form = styled(FormAntd)`
  & .ant-form-item-explain.ant-form-item-explain-error,
  .ant-form-item-explain,
  .ant-form-item-explain-error {
    display: none;
  }
`;  
const TableButton = () => {
  const { state } = useContext(EC);
  const { editorView } = state;

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const handleBtnTableClick = () => {
    if (visible) { // Nếu dropdown đang mở thì đóng lại
      setVisible(false);
      return;
    }
    if (!editorView) return;
    if (markers.isMarkdownTableSelected(editorView)) {
      markers.formatSelectedMarkdownTable(editorView);
      setVisible(false);
    } else {
      setVisible(true); //selected text không phải là markdown table
    }
  };

  const onFinish = (values: any) => {
    const { column, row } = values;
    form.validateFields().then(() => {
      if (!editorView) return;
      markers.pasteTable(editorView, row, column);
    });
    setVisible(false);
  };
  //popupForm hiện thị khi DrowDown mở
  const popupForm = (
    <Form
      labelCol={{
        span: 9,
      }}
      onFinish={onFinish}
      form={form}
      name="table"
      initialValues={{
        column: 3,
        row: 3,
      }}
      style={styleForm}
    >
      <Form.Item
        rules={[
          () => ({
            validator(_: any, value: any) {
              if (value <= 12 && value > 0) return Promise.resolve();
              return Promise.reject();
            },
          }),
        ]}
        style={{
          padding: 0,
          margin: 0,
        }}
        name="column"
        label="column"
      >
        <InputNumber keyboard={true} autoFocus />
      </Form.Item>
      <Form.Item
        style={{
          padding: 0,
          margin: 0,
        }}
        name="row"
        label="row"
        rules={[
          () => ({
            validator(_: any, value: any) {
              if (value <= 12 && value > 0) return Promise.resolve();
              return Promise.reject();
            },
          }),
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        style={{
          padding: 0,
          margin: '8px 0',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: '100%',
          }}
        >
          Enter
        </Button>
      </Form.Item>
    </Form>
  );

return (
    <Dropdown
      destroyPopupOnHide={true}
      trigger={['click']}
      open={visible}
      overlay={popupForm}
    >
      <Button title="Bảng" icon={<TableOutlined />} type="text" onClick={handleBtnTableClick}/>
    </Dropdown>
  );
};
export default TableButton;
