import {
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Table,
  Button,
  Tooltip,
} from 'antd';
import type { SpinProps, TableColumnsType } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { Key, ReactElement } from 'react';
import { useFindStudentQuery } from 'services/classes.service';
import ServerError from 'components/commons/ServerError';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { formatNumber } from 'libraries/utils/changeFormatData';
import { usePutFixedSalaryMutation } from 'services/configure.service';
import { LabeledValue } from 'antd/lib/select';
import { useGetFixedSalaryHistoryQuery } from 'services/configure.service';
import { FixedSalaryHistoryRO } from 'models/config.model';
import { generatePath, Link } from 'react-router-dom';
import { formatDate } from 'utils/formatDate';
import Container from 'components/table/TableContainer';
import { EditableCell, EditableRow } from './CFEditableCells';
import { DeleteTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import { route } from '../../../routers/routeNames';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 14 },
};

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContainerStyle = styled.p`
  margin-top: -24px;
  font-weight: 600;
  position: absolute;
  top: 172%;
  left: 12.5%;
  font-size: 14px;
  @media (max-width: 576px) {
    top: 135%;
    left: 0;
  }
`;

const TableContainer = styled(Container)`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-height: 28px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
`;

const SmallLoading = ({ size = 'default' }: SpinProps) => (
  <LoadingContainer>
    <Spin size={size} />
  </LoadingContainer>
);

const ConfigFixedSalary = (): ReactElement => {
  const [form] = Form.useForm();
  const [keyword, setKeyword] = useState('');
  const [numberMoney, setNumberMoney] = useState('');
  const {
    data: users,
    isLoading,
    isError,
    error: errorUsers,
  } = useFindStudentQuery(keyword, {
    skip: keyword.length <= 2,
  });
  const {
    data: history = [] as Array<FixedSalaryHistoryRO & { key: Key }>,
    isLoading: isLoadingHistory,
    isError: isErrorHistory,
    error: errorHistory,
  } = useGetFixedSalaryHistoryQuery();

  const dataSource = useMemo(
    () =>
      history.map((item) => ({
        ...item,
        fix_salary: item.history[0].fix_salary,
        start_date: item.history[0].start_date,
      })),
    [history],
  );

  const [putFixedSalary, { isLoading: isPutLoading }] =
    usePutFixedSalaryMutation();
  const fetchRef = useRef(0);

  const handleSearch = (value: string) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    if (fetchId !== fetchRef.current) return;
    setKeyword(value);
  };

  const options = useMemo(
    () =>
      users?.map((user) => ({
        label: `${user.full_name} \xa0\xa0--SDT: \xa0${
          user.phone ?? 'Không có'
        } \xa0\xa0 --Email: \xa0${user.email ?? 'Không có'}`,
        value: user.id,
        title: user.full_name,
        key: v4(),
      })) ?? undefined,
    [users],
  );

  const onFinish = async () => {
    try {
      const { search, number = -1 }: { search: string[]; number: number } =
        await form.validateFields();
      if (!search || number < 0) return;

      putFixedSalary(
        search.map((value) => ({ user_id: value, fix_salary: number })),
      )
        .unwrap()
        .then((res) => {
          message.success(res);
          form.setFieldsValue({ number: null, search: [] });
        })
        .catch((err) => {
          message.error(`${JSON.stringify(err)}`);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onDeselect = (value: string | number | LabeledValue) => {
    if (typeof value !== 'string') return;
    putFixedSalary([{ user_id: value, fix_salary: 0 }]);
  };

  const handleDelete = (user_id: string) => {
    putFixedSalary([{ user_id, fix_salary: 0 }])
      .unwrap()
      .then((res) => {
        message.success('Xóa thành công');
        form.setFieldsValue({ number: null, search: [] });
      })
      .catch((err) => {
        message.error(`${JSON.stringify(err)}`);
      });
  };

  const defaultColumns: TableColumnsType<FixedSalaryHistoryRO> & {
    editable?: boolean;
  } = useMemo(
    () => [
      {
        dataIndex: 'full_name',
        key: 'full_name',
        title: 'Họ và tên',
        width: '35%',
        render: (full_name, { user_id }) => (
          <Link to={generatePath(route.user.info, { userId: user_id })}>
            {full_name}
          </Link>
        ),
      },
      {
        dataIndex: 'fix_salary',
        key: 'fix_salary',
        title: 'Lương cứng',
        width: '30%',
        editable: true,
        render: (fix_salary) => formatNumber(fix_salary),
      },
      {
        dataIndex: 'start_date',
        key: 'start_date',
        width: '35%',
        title: (
          <div>
            <Tooltip title="Nhấn vào ngày tháng bên dưới để hiện thị lịch sử lương">
              Ngày bắt đầu{' '}
              <QuestionCircleOutlined
                style={{ marginLeft: 8, color: '#8c8c8c' }}
              />
            </Tooltip>{' '}
          </div>
        ),
        render: (start_date, { user_id, full_name }) => (
          <Link
            to={{
              pathname: generatePath(route.configure.salary.fix_salary_history),
              search: `?user_id=${user_id}&user_name=${full_name}`,
            }}
          >
            {formatDate(start_date)}
          </Link>
        ),
      },
      {
        dataIndex: 'action',
        key: 'action',
        width: '15%',
        title: 'Thao tác',
        align: 'center',
        render: (_, { user_id }) => (
          <Button
            size="small"
            onClick={() => handleDelete(user_id)}
            shape="circle"
            type="dashed"
          >
            <DeleteTwoTone />
          </Button>
        ),
      },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      defaultColumns.map((col: any) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record: FixedSalaryHistoryRO) => {
            return {
              record,
              editable: col.editable,
              dataIndex: col.dataIndex,
              title: col.title,
              user_id: record.user_id,
              // handleSave,
            };
          },
        };
      }),
    [],
  );

  if (isError)
    return (
      <ServerError
        title={`Status ${
          !!('originalStatus' in errorUsers) && errorUsers.originalStatus
        }: ${!!('status' in errorUsers) && errorUsers.status},${
          !!('error' in errorUsers) && errorUsers.error
        } `}
      />
    );

  return (
    <>
      <Form
        form={form}
        {...layout}
        labelAlign="right"
        layout="horizontal"
        onFinish={onFinish}
        style={{ marginBottom: 36 }}
      >
        <Form.Item
          label="Tìm kiếm"
          name={'search'}
          rules={[{ required: true, message: 'Rỗng' }]}
        >
          <Select
            filterOption={false}
            style={{ width: '80%' }}
            showSearch
            mode="multiple"
            allowClear
            placeholder="Người dùng"
            notFoundContent={isLoading ? <SmallLoading size="small" /> : null}
            loading={isLoading}
            onSearch={handleSearch}
            onDeselect={onDeselect}
            autoFocus
            options={options}
          />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <Form.Item
            label="Cấu hình lương"
            name={'number'}
            rules={[{ required: true, message: 'Rỗng' }]}
          >
            <InputNumber
              placeholder="Lương cứng"
              formatter={formatNumber}
              min={0}
              addonAfter={'VND'}
              onChange={(value: any) => setNumberMoney(value)}
            />
          </Form.Item>
          <ContainerStyle>
            {convertCurrencyToWords(+numberMoney)}
          </ContainerStyle>
        </div>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 3 }}>
          <Button type="primary" loading={isPutLoading} htmlType="submit">
            Đặt mức lương
          </Button>
        </Form.Item>
      </Form>
      {!isErrorHistory ? (
        <TableContainer>
          <Table
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
            loading={isLoadingHistory}
            rowClassName={() => 'editable-row'}
          />
        </TableContainer>
      ) : (
        <ServerError
          title={`Status ${
            !!('originalStatus' in errorHistory) && errorHistory.originalStatus
          }: ${!!('status' in errorHistory) && errorHistory.status},${
            !!('error' in errorHistory) && errorHistory.error
          } `}
        />
      )}
    </>
  );
};

export default ConfigFixedSalary;
