import { useState } from 'react';
import moment, { Moment } from 'moment';

interface IDateRange {
  firstDay: Moment;
  lastDay: Moment;
  setFirstDay: (date: Moment) => void;
  setLastDay: (date: Moment) => void;
  startOfDay: (date: Moment) => Moment;
  endOfDay: (date: Moment) => Moment;
  startOfMonth: (date: Moment) => Moment;
  endOfMonth: (date: Moment) => Moment;
  subtractDay: (date: Moment, day: number) => Moment;
  subtractMonth: (date: Moment, month: number) => Moment;
  format: (date: Moment) => string;
}

export const useDateRange = (
  from: string | undefined = undefined,
  to: string | undefined = undefined,
): IDateRange => {
  const today = moment();

  const startOfDay = (date: Moment): Moment => moment.utc(date).startOf('D');
  const endOfDay = (date: Moment): Moment => moment.utc(date).endOf('D');
  const startOfMonth = (date: Moment): Moment => moment.utc(date).startOf('M');
  const endOfMonth = (date: Moment): Moment => moment.utc(date).endOf('M');
  const subtractDay = (date: Moment, day: number): Moment =>
    moment.utc(date).subtract(day, 'days');
  const subtractMonth = (date: Moment, month: number): Moment =>
    moment.utc(date).subtract(month, 'M');
  const format = (date: Moment) => date.format('DD/MM/YYYY');

  const [firstDay, setFirstDay] = useState(
    from ? startOfDay(moment(from)) : startOfMonth(today),
  );
  const [lastDay, setLastDay] = useState(
    to ? endOfDay(moment(to)) : endOfMonth(today),
  );

  return {
    firstDay,
    lastDay,
    startOfDay,
    endOfDay,
    startOfMonth,
    endOfMonth,
    setFirstDay,
    setLastDay,
    subtractDay,
    subtractMonth,
    format,
  };
};
