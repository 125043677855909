import { Col, Row, Typography } from 'antd';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { ListPayReceiptType } from 'models/report.model';
import OrderReceiptTable from 'pages/report/finance/order-receipt/OrderReceiptTable';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectSale } from 'store/report.slice';

interface PropsType {
  data: ListPayReceiptType;
}

const CollectedReceiptsReport = ({ data }: PropsType): ReactElement => {
  const sale = useSelector(selectSale);
  let paidAmount = 0;

  const receiptsFromOrder = data?.receipt_from_order.filter(
    ({ receiver_full_name, amount }) => {
      if (receiver_full_name !== sale.full_name) return false;

      paidAmount += amount || 0;
      return true;
    },
  );

  return (
    <>
      <div style={{ marginBottom: '0.8rem' }}>
        <strong>Thống kê trong tháng</strong>
      </div>

      <Row style={{ maxWidth: '16rem', marginBottom: '0.6rem' }}>
        <Col span={12}>- Số tiền đã đóng:</Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Typography.Text type="success" strong>
            {formatMoneyVnd(paidAmount)}
          </Typography.Text>
        </Col>
      </Row>
      <OrderReceiptTable reports={receiptsFromOrder ?? []} />
    </>
  );
};

export default CollectedReceiptsReport;
