import { LeftOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import AddUserToPayment from 'components/report/AddUserToPayment';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import disabledSubmissionDate from 'libraries/utils/disabledSubmissionDate';
import moment, { Moment } from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useCreatePaymentReceiptMutation } from 'services/report.service';
import { selectUser } from 'store/auth.slice';
import styled from 'styled-components';
import Editor from 'libraries/editor/Editor';
import useQuery from '../../../../libraries/hooks/query';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const today = new Date();
const { Option } = Select;
const { TextArea } = Input;

const CreatePaymentContent = (): ReactElement => {
  const user = useSelector(selectUser);
  const history = useHistory();
  const searchParams = useQuery();
  const [numberMoney, setNumberMoney] = useState('');
  useEffect(() => {
    return () => {
      searchParams.set('tab', '3');
      history.replace({ search: searchParams.toString() });
    };
  }, []);

  const [userId, setUserId] = useState(user.id ? user.id : '');
  const [userName, setUserName] = useState(
    user.full_name ? user.full_name : '',
  );
  const [receiverId, setReceiverId] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [purpose, setPurpose] = useState('pay_for_office');

  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isReceiverModalVisible, setIsReceiverModalVisible] = useState(false);
  const [createPayment] = useCreatePaymentReceiptMutation();

  const [form] = Form.useForm();

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    createPayment({
      datetime:
        values.datetime !== undefined
          ? values.datetime.add(7, 'hours').toISOString()
          : '',
      user_id: userId,
      full_name: userName,
      receiver_id: receiverId,
      amount: values.amount,
      content: values.content,
      note: values.note,
      is_paid: values.is_paid,
      purpose: values.purpose,
    })
      .unwrap()
      .then(() => {
        message.success('Tạo phiếu chi thành công');
        setTimeout(() => {
          history.push({
            pathname: generatePath(route.report.finance),
            search: '?tab=3',
          });
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.report.finance)}
        secondaryText="Báo cáo"
        text="Tạo phiếu chi"
      />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          datetime: moment(moment(today).format('DD/MM/YYYY'), 'DD/MM/YYYY'),
          purpose: 'pay_for_office',
          user: user.full_name,
          is_paid: false,
        }}
      >
        <GroupButton>
          <LinksContainer style={{ display: 'flex' }}>
            <Link.Default
              to={{
                pathname: generatePath(route.report.finance),
                search: '?tab=3',
              }}
            >
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
            >
              Tạo phiếu
            </Button.Primary>
          </LinksContainer>
        </GroupButton>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={16} lg={16}>
            <BorderTopBoxContent>
              <Form.Item
                valuePropName="checked"
                name="is_paid"
                label={
                  <FormLabel label="Xác nhận thanh toán" require={false} />
                }
              >
                <Checkbox>Công ty đã thanh toán</Checkbox>
              </Form.Item>
              <Form.Item
                name="datetime"
                label={<FormLabel label="Ngày giao dịch" />}
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập ngày giao dịch!',
                  },
                ]}
              >
                <DatePicker
                  // disabledDate={disabledSubmissionDate}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
              <Form.Item
                name="purpose"
                label={<FormLabel label="Mục đích chi" />}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Chọn mục đích"
                  onChange={(value: string) => setPurpose(value)}
                >
                  <Option value="pay_for_office">Chi phí văn phòng</Option>
                  <Option value="internet_fee">Phí dịch vụ Internet</Option>
                  <Option value="license_fee">Mua bản quyền phần mềm </Option>
                  <Option value="office_renting_fee">Phí thuê nhà</Option>
                  <Option value="electric_fee">Tiền điện </Option>
                  <Option value="buy_equipment">Mua thiết bị</Option>
                  <Option value="advance_payment">Ứng tiền</Option>
                  <Option value="other_service">Dịch vụ khác</Option>
                  <Option value="adhoc_task">Thù lao trả một lần</Option>
                </Select>
              </Form.Item>
              <div style={{ position: 'relative' }}>
                <Form.Item
                  label={<FormLabel label="Số tiền" />}
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập số tiền chi!',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    onChange={(value: any) => setNumberMoney(value)}
                  />
                </Form.Item>
                <p
                  style={{
                    marginTop: '-24px',
                    fontWeight: '600',
                    position: 'absolute',
                    top: '135%',
                    left: '0',
                    fontSize: '14px',
                  }}
                >
                  {convertCurrencyToWords(+numberMoney)}
                </p>
              </div>
              <Form.Item
                label={<FormLabel label="Người chi tiền" />}
                name="user"
              >
                <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 150px)' }}
                    value={userName}
                    disabled
                  />
                  <Button.Primary
                    style={{ height: 32, width: 150 }}
                    onClick={() => setIsUserModalVisible(true)}
                  >
                    Chọn người chi
                  </Button.Primary>
                </Input.Group>
              </Form.Item>
              {purpose !== 'pay_for_office' && (
                <Form.Item
                  label={<FormLabel label="Người nhận tiền" />}
                  name="receiver"
                >
                  <Input.Group compact>
                    <Input
                      style={{ width: 'calc(100% - 150px)' }}
                      value={receiverName}
                      disabled
                    />
                    <Button.Primary
                      style={{ height: 32, width: 150 }}
                      onClick={() => setIsReceiverModalVisible(true)}
                    >
                      Chọn người nhận
                    </Button.Primary>
                  </Input.Group>
                </Form.Item>
              )}
              <Form.Item
                label={<FormLabel label="Nội dung chi" />}
                name="content"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập nội dung chi!',
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Ghi chú" require={false} />}
                name="note"
              >
                <Editor height={350} />
                {/* <TextArea rows={3} /> */}
              </Form.Item>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
      {isUserModalVisible && (
        <AddUserToPayment
          isModalVisible={isUserModalVisible}
          closeModal={() => setIsUserModalVisible(false)}
          setUserId={setUserId}
          setUserName={setUserName}
        />
      )}
      {isReceiverModalVisible && (
        <AddUserToPayment
          isModalVisible={isReceiverModalVisible}
          closeModal={() => setIsReceiverModalVisible(false)}
          setUserId={setReceiverId}
          setUserName={setReceiverName}
        />
      )}
    </>
  );
};

export default CreatePaymentContent;
