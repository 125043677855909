import Modal from 'components/commons/Modal';
import { ReactElement, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStudent,
  Classes,
  selectListClass,
  selectOrder,
} from 'store/order.slice';
import {
  Table,
  Select,
  Input,
  message,
  Row,
  Col,
  Tooltip,
  Checkbox,
} from 'antd';
import Form from 'libraries/components/form/Form';
import Button from 'libraries/components/commons/Button';
import FormLabel from 'libraries/components/form/FormLabel';
import { useAddStudentToClassMutation } from 'services/order.service';
import { useParams } from 'react-router';
import { Class } from '../../../../models/class.model';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
const { Option } = Select;

interface EnrollModalProps {
  isEnrollModalVisibile: boolean;
  handleCancel: () => void;
}
interface ShowErrorType {
  className: boolean;
  content: string;
}

interface ClassAdd {
  id: string;
  name: string;
  type: 'live' | 'lab' | 'video' | '';
  checkOnlab: boolean | null;
  checkOnline: boolean | null;
}
const isCantChangeType = (arg: string) => ['live', 'video'].includes(arg);

const TableStyle = styled.div`
  thead th {
    padding-top: 0;
  }
`;

const EnrollModal = ({
  isEnrollModalVisibile,
  handleCancel,
}: EnrollModalProps): ReactElement => {
  const { orderId } = useParams<{ orderId: string }>();
  const classes = useSelector(selectListClass);
  const dataOrder = useSelector(selectOrder);
  const [classId, setClassId] = useState('');
  const [classesNotAddList, setClassesNotAddList] = useState<any[]>([]);
  const classesNotAddListRef = useRef<any[]>([]);
  // const [showSuccess, setShowSuccess] = useState<any[]>([]);
  // const [showError, setShowError] = useState<any[]>([]);
  const [addStudentToClass] = useAddStudentToClassMutation();
  // const [isSelect, setIsSelect] = useState(false);

  const [currentSelected, setCurrentSelected] = useState<Classes | undefined>();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { classes_added, classes_not_add } = classes;

  useEffect(() => {
    const classes_not_add_List = classes_not_add?.map((item: any) => {
      return { ...item, checkOnlab: false, checkOnline: false };
    });
    if (classes_not_add_List) {
      setClassesNotAddList(classes_not_add_List);
      classesNotAddListRef.current = classes_not_add_List;
    }

    // if (classes_not_add_List?.length !== 0 && classes_not_add_List) {
    //   setIsSelect(true);
    // }
  }, [classes_not_add]);

  const { students } = dataOrder;

  const handleChangeOnlab = (id: string) => {
    const classesNotAddNewList = classesNotAddList?.map((item: any) => {
      if (item.id === id) {
        return { ...item, checkOnlab: !item.checkOnlab, checkOnline: false };
      }
      return item;
    });
    setClassesNotAddList(classesNotAddNewList);
  };
  const handleChangeOnline = (id: string) => {
    const classesNotAddNewList = classesNotAddList?.map((item: any) => {
      if (item.id === id) {
        return { ...item, checkOnline: !item.checkOnline, checkOnlab: false };
      }
      return item;
    });
    setClassesNotAddList(classesNotAddNewList);
  };

  const handleFilterSearch = (value: string) => {
    if (value !== '') {
      let classesNotAddNewList = classesNotAddListRef.current?.filter(
        (item: any) => {
          if (item.name.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        },
      );
      if (!classesNotAddNewList) {
        classesNotAddNewList = [];
      }
      setClassesNotAddList(classesNotAddNewList);
    } else {
      setClassesNotAddList(classesNotAddListRef.current);
    }
  };

  const columns = [
    {
      title: 'Học viên trong đơn hàng',
      dataIndex: 'full_name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
  ];
  const columns1: ColumnsType<ClassAdd> = [
    {
      key: 'stt',
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: '5%',
      render: (_, __, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      key: 'Onlab',
      title: 'Onlab',
      dataIndex: 'checkOnlab',
      width: '10%',
      render: (checkOnlab, classItem) => {
        const { id, type } = classItem;
        return type === 'live' || type === 'video' ? (
          <Tooltip
            title="Các lớp thuộc dạng Online hoặc Video không thể chuyển sang học Onlab"
            placement="rightTop"
          >
            <Checkbox defaultChecked={false} disabled></Checkbox>
          </Tooltip>
        ) : (
          <Checkbox
            onChange={() => handleChangeOnlab(id)}
            checked={checkOnlab}
          ></Checkbox>
        );
      },
    },
    {
      key: 'Online',
      title: 'Trực tuyến tương tác',
      dataIndex: 'checkOnline',
      width: '10%',
      render: (checkOnline, classItem) => {
        const { id, type } = classItem;
        return (
          <Checkbox
            onChange={() => handleChangeOnline(id)}
            checked={checkOnline}
          ></Checkbox>
        );
      },
    },
    {
      key: 'class',
      title: (
        <div style={{ paddingTop: '18px' }}>
          <p> Tên lớp học</p>
          <Input onChange={(e) => handleFilterSearch(e.target.value)} />
        </div>
      ),
      dataIndex: 'name',
      width: '40%',
      render: (_, { name }) => {
        return <p>{name}</p>;
      },
    },
  ];

  const handleChange = (value: string) => {
    setClassId(value);
    // setIsSelect(true);
    setCurrentSelected(() => {
      const item = classes.classes_not_add.find((item) => item.id === value);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        class_type: isCantChangeType(item?.type ?? '') ? 'live' : 'lab',
      });
      return item;
    });
  };

  const handleSubmit = (values: any) => {
    let showSuccess = '';
    const showError: ShowErrorType[] = [];
    classesNotAddList?.forEach((classItem, index) => {
      let classType = 'lab';

      if (classItem.checkOnlab === false && classItem.checkOnline === false) {
        return;
      }
      if (classItem.checkOnline === true) {
        classType = 'live';
      }
      addStudentToClass({
        orderId,
        classId: classItem.id,
        class_type: classType === 'lab' ? 'lab' : 'live',
      })
        .unwrap()
        .then(() => {
          showSuccess = showSuccess + classItem.name;
          dispatch(
            addStudent(
              classes_not_add.filter((item) => item.id === classItem.id)[0],
            ),
          );
          handleCancel();
        })
        .catch((error) => {
          showError.push({ className: classItem.name, content: error.data });
          message.error(error.data);
        });
      if (index === classesNotAddList.length - 1) {
        message.success(`Thêm học viên vào lớp ${showSuccess} thành công`);
        if (showError.length > 0) {
          console.error('Có lỗi xảy ra khi thêm học viên:', showError);
        }
      }
    });
  };

  return (
    <Modal
      title="Nhập học"
      visible={isEnrollModalVisibile}
      onCancel={handleCancel}
      width={800}
      footer={[]}
      getContainer={false} // required to use form in modal
      forceRender={true}
    >
      {/* <Table
        dataSource={students}
        columns={columns}
        rowKey="id"
        bordered
        pagination={false}
      /> */}

      {classes_added && classes_added.length > 0 && (
        <>
          <p>Lớp học đã nhập cho học viên</p>
          <Row gutter={[8, 8]}>
            {classes_added.map((item) => (
              <Col key={item.id} xs={24} md={12}>
                <Input value={item.name} disabled />
              </Col>
            ))}
          </Row>
        </>
      )}

      <>
        <Form
          form={form}
          name="addStudentToClass"
          initialValues={{
            remember: true,
          }}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="date"
            label={<FormLabel label="Thêm học viên vào lớp" require={false} />}
          >
            <span style={{ margin: '0 5px 15px', display: 'inline-block' }}>
              Lưu ý :{' '}
            </span>
            <i>
              Có thể lựa chọn lại hình thức học Onlab hoặc Trực tuyến tương tác
            </i>
            <TableStyle>
              <Table
                columns={columns1}
                dataSource={classesNotAddList}
                // pagination={false}
                bordered
              />
            </TableStyle>
            {/* thêm từng lớp 1 */}
            {/* <Select
              style={{ width: '100%' }}
              optionFilterProp="children"
              onChange={handleChange}
              showSearch
            >
              {classes_not_add &&
                classes_not_add.length > 0 &&
                classes_not_add.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select> */}
          </Form.Item>
          {/* thêm từng lớp 1 */}
          {/* <Form.Item
            label={<FormLabel label="Hình thức học" require={false} />}
            name="class_type"
          >
            <Select
              defaultActiveFirstOption={false}
              style={{ width: '50%' }}
              optionFilterProp="children"
            >
              <Option
                disabled={isCantChangeType(currentSelected?.type ?? '')}
                value={'lab'}
              >
                <Tooltip
                  title={
                    isCantChangeType(currentSelected?.type ?? '')
                      ? 'Các lớp thuộc dạng Online hoặc Video không thể chuyển sang học Onlab'
                      : undefined
                  }
                  placement="rightTop"
                >
                  <div style={{ width: '100%' }}>Onlab</div>
                </Tooltip>
              </Option>
              <Option value={'live'}>Trực tuyến tương tác</Option>
            </Select>
          </Form.Item> */}

          <div style={{ textAlign: 'end' }}>
            <Button.Primary
              htmlType="submit"
              disabled={classesNotAddList.length === 0 ? true : false}
            >
              Nhập học
            </Button.Primary>
          </div>
        </Form>
      </>
    </Modal>
  );
};

export default EnrollModal;
