import styled from 'styled-components';

const Sidebar = styled.aside`
  position: fixed;
  left: 0;
  top: 60px;
  bottom: 0;
  transition: transform 0.25s;
  z-index: 2;
`;

export default Sidebar;
