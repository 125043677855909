import { DatePicker } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import ShowServerError from 'components/commons/ShowServerError';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { useDateRange } from 'libraries/hooks/date';
import moment, { Moment } from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ComponentContent } from '../../../../components/commons/ComponentContent';
import LoadingSpin from '../../../../libraries/components/LoadingSpin';
import useQuery from '../../../../libraries/hooks/query';
import { formatMoneyVnd } from '../../../../libraries/utils/changeFormatData';
import { SummarySalaryList } from '../../../../models/report.model';
import { useGetSalaryListQuery } from '../../../../services/report.service';
import SummaryList from './SummaryList';
import Button from 'libraries/components/commons/Button';
import ManualPayModal from './ManualPayModal';

const TotalSpan = styled.span`
  display: inline-block;
  background-color: #00a65a;
  color: #fff;
  font-size: 16px;
  padding: 4px 8px;

  @media screen and (max-width: 991px) {
    height: 32px;
    line-height: 32px;
  }
`;
const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SummaryListReport = (): ReactElement => {
  const [isManualPayModalVisible, setIsManualPayModalVisible] = useState(false);
  const { startOfMonth, endOfMonth } = useDateRange();
  const today = moment();
  const lastMonth = moment(today).subtract(1, 'month');
  // api
  const [firstDay, setFirstDay] = useState(startOfMonth(lastMonth));
  const [lastDay, setLastDay] = useState(endOfMonth(lastMonth));
  const { data, isLoading, isError, error, refetch } = useGetSalaryListQuery({
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });
  // router
  const searchParams = useQuery();
  const history = useHistory();
  const start_date = searchParams.get('start_date');

  useEffect(() => {
    if (start_date) {
      onChange(moment(+start_date));
    }
  }, []);
  //
  const total =
    data?.reduce((count, item) => {
      return (count += item.salary);
    }, 0) ?? 0;
  const onChange = (value: Moment | null, dateString = '') => {
    if (value) {
      setFirstDay(startOfMonth(value));
      setLastDay(endOfMonth(value));

      //set search param
      searchParams.set('start_date', `${value.valueOf()}`);
      history.replace({ search: searchParams.toString() });
    }
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }
  if (isLoading) return <LoadingSpin />;
  return (
    <>
      <Breadcrumb text="Danh sách lương" />
      <GroupButton>
        <LinksContainer style={{ margin: '10px 0' }}>
          <DatePicker
            onChange={onChange}
            defaultPickerValue={firstDay}
            defaultValue={moment(firstDay)}
            picker="month"
            format="MM-YYYY"
          />
          <Button.Primary onClick={() => setIsManualPayModalVisible(true)}>
            Trả lương thủ công
          </Button.Primary>

          <ManualPayModal
            isManualPayModalVisible={isManualPayModalVisible}
            handleCancel={() => setIsManualPayModalVisible(false)}
            firstDay={firstDay}
            lastDay={lastDay}
            refetch={refetch}
          />
        </LinksContainer>
        <LinksContainer>
          <TotalSpan>Tổng lương: {formatMoneyVnd(total)}</TotalSpan>
        </LinksContainer>
      </GroupButton>
      <ComponentContent>
        <SummaryList users={data ?? ([] as SummarySalaryList[])} />
      </ComponentContent>
    </>
  );
};

export default SummaryListReport;
