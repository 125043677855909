import { ReactElement, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { IRouteWithTitle } from 'models/route.model';
import { useSelector } from 'react-redux';
import { selectIsSidebarCollapse } from 'store/general.slice';
import { selectUser } from 'store/auth.slice';
import Header from 'layout/Header';
import CollapsableSidebar from 'layout/CollapsableSidebar';
import SlideableSidebar from 'layout/SlideableSidebar';
import routes from 'routers';
import LoginPage from 'libraries/pages/LoginPage';
import useMedia from 'utils/useMedia';
import styled from 'styled-components';
import UserInfo from '../StudentInfo';
import StudentInfo from '../StudentInfo';

const MainContainer = styled.div`
  background-color: #ecf0f5;
  padding: 75px 15px 15px 15px;
  min-height: 100vh;
`;

const Page = ({ title, Component }: IRouteWithTitle): ReactElement => {
  const { xs, sm } = useMedia();
  const isSidebarCollapse = useSelector(selectIsSidebarCollapse);
  const [isLoading, setLoading] = useState(false);

  // Add page title
  useEffect(() => {
    if (!title) return;

    document.title = title;
  }, []);

  // Run page transition
  useEffect(() => {
    setLoading(true);

    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <>
      {title !== 'Info User' ? <Header /> : null}
      {title !== 'Info User' ? (
        xs || sm ? (
          <SlideableSidebar />
        ) : (
          <CollapsableSidebar />
        )
      ) : null}

      <MainContainer
        style={{
          marginLeft:
            title === 'Info User'
              ? 0
              : xs || sm
                ? 0
                : isSidebarCollapse
                  ? 80
                  : 256,
        }}
      >
        <CSSTransition in={isLoading} classNames="fade" timeout={300}>
          <Component />
        </CSSTransition>
      </MainContainer>
    </>
  );
};

const Routes = (): ReactElement => {
  const user = useSelector(selectUser);

  return (
    <Switch>
      <Route key={'/infoUser/:userId'} path={'/infoUser/:userId'} exact={true}>
        <Page title={'Info User'} Component={StudentInfo} />
      </Route>
      {user?.full_name ? (
        routes.map(({ path, title, component }) => {
          return (
            <Route key={path} path={path} exact={true}>
              <Page title={title} Component={component} />
            </Route>
          );
        })
      ) : (
        <div style={{ height: '100vh' }}>
          <LoginPage />
        </div>
      )}
    </Switch>
  );
};

export default Routes;
