import { Row as RowAnt, Col } from 'antd';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { orderStatusValues } from 'libraries/utils/globalVariable';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectOrder } from 'store/order.slice';
import styled from 'styled-components';

const RowOrder = styled(RowAnt)`
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;

  p {
    margin: 0;
    font-weight: 600;
  }
`;

const TextOrder = styled.span`
  font-size: 18px;
  &.info {
    color: #00c0ef;
  }
  &.default {
    color: #d2d6de;
  }
  &.warning {
    color: #f39c12;
  }
  &.success {
    color: #00a65a;
  }
  &.primary {
    color: #3c8dbc;
  }
`;
interface StatusProps {
  title: string;
  border: boolean;
  money?: number;
  className?: string;
  status?: string;
}

const Status = ({
  title,
  border,
  money,
  className,
  status,
}: StatusProps): ReactElement => {
  const statusElement = orderStatusValues.find((item) => item.value === status);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '14px 0',
        borderRight: border ? '1px solid #c4c4c4 ' : '',
      }}
    >
      <p>{title}</p>
      {(money || money == 0) && (
        <TextOrder className={className}>{formatMoneyVnd(money)}</TextOrder>
      )}

      {statusElement && (
        <TextOrder className={statusElement.className}>
          {statusElement.label}
        </TextOrder>
      )}
    </div>
  );
};

const OrderStatus = (): ReactElement => {
  const data = useSelector(selectOrder);
  const { status, debt, should_pay, paid } = data;

  return (
    <RowOrder>
      <Col xs={12} sm={6}>
        <Status title={'Trạng thái'} status={status} border={true} />
      </Col>
      <Col xs={12} sm={6}>
        <Status
          title="Tổng đơn hàng"
          money={should_pay}
          border={true}
          className="success"
        />
      </Col>
      <Col xs={12} sm={6}>
        <Status
          title="Đã thanh toán"
          money={paid}
          border={true}
          className="primary"
        />
      </Col>
      <Col xs={12} sm={6}>
        <Status
          title="Còn nợ"
          money={debt}
          border={false}
          className="warning"
        />
      </Col>
    </RowOrder>
  );
};

export default OrderStatus;
