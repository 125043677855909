import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import FormLabel from 'libraries/components/form/FormLabel';
import {
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  message,
  Space,
  Button,
} from 'antd';
import { orderPaymentTypeValues } from 'libraries/utils/globalVariable';
import Form from 'libraries/components/form/Form';
// import Button from 'libraries/components/commons/Button';
import { useCreateOrderMutation } from 'services/order.service';
import { ReactElement, useState } from 'react';
import { UsersType } from 'models/user.model';
import Modal from 'components/commons/Modal';
import FindStudent from 'pages/classes/detail/student/FindStudent';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';

const { TextArea, Search } = Input;

const CreateOrderForm = (): ReactElement => {
  const [isCheck, setIsCheck] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [keyword, setKeyword] = useState('');
  const [tmpKeyword, setTmpKeyword] = useState('');
  const [hasResult, setHasResult] = useState(false);
  const [isSearchDone, setIsSearchDone] = useState(false);

  const history = useHistory();

  const onSearch = () => {
    setIsSearchDone(true);
    setKeyword(tmpKeyword);
    setHasResult(true);
  };

  const addExistUser = () => {
    setIsModalVisible(false);
    form.setFields([
      {
        name: ['full_name'],
        value: selectedUser.full_name,
      },
      {
        name: ['email'],
        value: selectedUser.email,
      },
      {
        name: ['phone'],
        value: selectedUser.phone,
      },
    ]);
  };

  const onSearchModal = () => {
    setIsModalVisible(true);
  };

  const [createOrder] = useCreateOrderMutation();
  const [selectedUser, setSelectedUser] = useState({} as UsersType);
  const [form] = Form.useForm();

  function onChange(e: CheckboxChangeEvent) {
    setIsCheck(e.target.checked);
  }

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    const company_info = {
      company: values.company || '',
      tax_code: values.tax_code || '',
      address: values.address || '',
      mobile: values.company_phone || '',
    };

    const data = {
      user_id: selectedUser.id,
      full_name: values.full_name,
      phone: values.phone,
      email: values.email,
      is_company: isCheck,
      note: values.note,
      payment_type: values.payment_type,
      company_info,
    };

    createOrder(data)
      .unwrap()
      .then((response) => {
        message.success('Tạo đơn hàng thành công');
        history.push(
          generatePath(route.order.detail, { orderId: response.id }),
        );
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <BorderTopBoxContent>
        <p>Thông tin đơn hàng</p>
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={selectedUser}
          form={form}
        >
          <Row gutter={[30, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={<FormLabel label="Họ tên người mua" require={true} />}
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập đầy đủ họ tên người mua',
                  },
                ]}
              >
                <Search
                  allowClear
                  enterButton="Chọn người mua"
                  onSearch={onSearchModal}
                />
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Email" require={true} />}
                name="email"
                rules={[{ required: true, message: 'Vui lòng nhập email' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Số điện thoại" require={false} />}
                name="phone"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="payment_type"
                label={<FormLabel label="Hình thức thanh toán" />}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn hình thức thanh toán',
                  },
                ]}
              >
                <Select>
                  {orderPaymentTypeValues.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={<FormLabel label="Ghi chú đơn hàng" require={false} />}
                name="note"
              >
                <TextArea rows={5} />
              </Form.Item>

              {isCheck && (
                <Row gutter={[30, 0]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<FormLabel label="Tên công ty" />}
                      name="company"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập tên công ty',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<FormLabel label="Mã số thuế" />}
                      name="tax_code"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập mã số thuế',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<FormLabel label="Địa chỉ" />}
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập địa chỉ',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={
                        <FormLabel label="Số điện thoại" require={false} />
                      }
                      name="company_mobile"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>

            <Col span={24}>
              <Form.Item>
                <Space size="middle">
                  <Button type="primary" htmlType="submit">
                    Tạo đơn hàng
                  </Button>

                  <Checkbox onChange={onChange}>Đơn hàng cho công ty</Checkbox>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </BorderTopBoxContent>

      {/* Modal search User */}
      <Modal
        title="Chọn người mua"
        visible={isModalVisible}
        width={800}
        footer={[]}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Nhập email hoặc số điện thoại học viên</label>
        <Search
          allowClear
          enterButton="Tìm học viên"
          value={tmpKeyword}
          onChange={(e) => {
            setHasResult(false);
            setTmpKeyword(e.target.value);
          }}
          onSearch={onSearch}
        />

        {isSearchDone && (
          <FindStudent
            keyword={keyword}
            hasResult={hasResult}
            setSelectedUser={(value: UsersType) => setSelectedUser(value)}
          />
        )}

        {selectedUser.id && (
          <div style={{ textAlign: 'end' }}>
            <Button onClick={addExistUser}>Lựa chọn</Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CreateOrderForm;
