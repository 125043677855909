import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { Tag } from 'models/tag.model';
import { tagsApi } from 'services/tag.service';

export interface TagsState {
  tags: Tag[];
}

const initialState: TagsState = {
  tags: [] as Tag[],
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    removeTagFromList: (state, { payload }) => {
      const arr = [...state.tags];
      const findIndex = state.tags.findIndex((item) => item.id === payload);
      if (findIndex > -1) arr.splice(findIndex, 1);
      state.tags = arr;
    },
    updateTagName: (state, { payload }) => {
      const { id, name } = payload;
      const findIndex = state.tags.findIndex((item) => item.id === id);
      if (findIndex > -1) state.tags[findIndex].name = name;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tagsApi.endpoints.getTags.matchFulfilled,
      (state, { payload }) => {
        state.tags = payload;
      },
    );
    builder.addMatcher(
      tagsApi.endpoints.createNewTag.matchFulfilled,
      (state, { payload }) => {
        const arr = [...state.tags];
        arr.unshift(payload);
        state.tags = arr;
      },
    );
  },
});

export const { removeTagFromList, updateTagName } = tagsSlice.actions;
export const selectTags = (state: RootState): Tag[] => state.tags.tags;

export default tagsSlice.reducer;
