// neededRoles: các Role yêu cầu user có để thực hiện 1 chức năng

import { useSelector } from 'react-redux';
// import { generatePath, useHistory } from 'react-router-dom';
// import { route } from 'routers/routeNames';
import { selectUser } from 'store/auth.slice';

const checkRoles = (neededRoles: number[], roles: number[]): boolean => {
  for (let i = 0; i < roles.length; i++) {
    for (let j = 0; j < neededRoles.length; j++) {
      if (roles[i] === neededRoles[j]) return true;
    }
  }
  return false;
};

export const checkUserRoleInMenu = (
  roles: number[],
  neededRoles: number[],
): boolean => {
  for (let i = 0; i < roles.length; i++) {
    for (let j = 0; j < neededRoles.length; j++) {
      if (roles[i] === neededRoles[j]) return true;
    }
  }
  return false;
};

// Thay đổi hàm checkUserRole thành một custom hook
const useCheckUserRole = (neededRoles: number[]): boolean => {
  const { roles } = useSelector(selectUser);
  return checkRoles(neededRoles, roles);
};

export default useCheckUserRole;

//  const checkRole = checkUserRole(highestRoles);

//   if (!checkRole) {
//     if (checkUserRole([ROLE_AUTHOR])) {
//       history.push(generatePath(route.blog.own));
//     }
//   }
