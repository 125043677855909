import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';
import ServerError from 'components/commons/ServerError';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { ReactElement, useState } from 'react';
import { useGetTagsQuery } from 'services/tag.service';
import CreateTagModal from '../tags/CreateTagModal';
import TagsTable from '../tags/TagsTable';
import CreateSubjectModal from './CreateSubjectModal';

interface KeySearchType {
  keyword: string;
  keyType: string;
  keyOrder: string;
  keyDirection: 'asc' | 'desc';
}

const GetSubjectsList = (): ReactElement => {
  const [keySearch, setKeySearch] = useState(
    'keyword=&type=&order=&direction=desc',
  );
  const [state, setState] = useState<KeySearchType>({
    keyword: '',
    keyType: '',
    keyOrder: '',
    keyDirection: 'desc',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const { isError, error } = useGetTagsQuery(keySearch);

  const resetKeySearch = () => {
    setKeySearch('keyword=&type=&order=&direction=desc');
    setState({
      keyword: '',
      keyType: '',
      keyOrder: '',
      keyDirection: 'desc',
    });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Danh sách chủ đề" />
      <LinksContainer>
        <Link.Primary to="#" onClick={() => setIsModalVisible(true)}>
          <PlusOutlined /> Tạo chủ đề
        </Link.Primary>
        <Link.Info to="#" onClick={resetKeySearch}>
          <ReloadOutlined /> Refresh
        </Link.Info>
      </LinksContainer>
      <TagsTable
        state={state}
        handleSetState={handleSetState}
        setKeySearch={setKeySearch}
        resetKeySearch={resetKeySearch}
      />
      {isModalVisible && (
        <CreateSubjectModal
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};
export default GetSubjectsList;
