import { FontSizeOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { ReactElement, useMemo, useCallback } from 'react';

interface iHeaderBtn {
  handleHeader: (type: number) => void;
}
const DropdownHeader = ({ handleHeader }: iHeaderBtn): ReactElement => {
  const onClick = useCallback(
    ({ key }: { key: string }) => {
      const type = +key;
      handleHeader(type);
    },
    [handleHeader],
  );

  const items: MenuProps['items'] = useMemo(
    () => [
      { key: '1', label: 'Header 1', style: { fontSize: 28 } },
      { key: '2', label: 'Header 2', style: { fontSize: 24 } },
      { key: '3', label: 'Header 3', style: { fontSize: 20 } },
      { key: '4', label: 'Header 4', style: { fontSize: 16 } },
    ],
    [],
  );

  return (
    <Dropdown menu={{ items, onClick }}>
      <FontSizeOutlined />
    </Dropdown>
  );
};

const HeaderBtn = ({ handleHeader }: iHeaderBtn) => {
  return (
    <Button
      title="Header"
      type="text"
      icon={<DropdownHeader handleHeader={handleHeader} />}
    />
  );
};

export default HeaderBtn;
