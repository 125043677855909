import Breadcrumb from 'components/commons/Breadcrumb';
import ShowServerError from 'components/commons/ShowServerError';
import useTitle from 'libraries/utils/useTitle';
import { PayReceiptDetail } from 'models/report.model';
import { ReactElement } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetReceiptPaymentDetailQuery } from 'services/report.service';
import ReceiptDetailContent from './ReceiptDetailContent';

const GetReceipt = (): ReactElement => {
  const { receiptId } = useParams<{ receiptId: string }>();
  const { data, isError, error } = useGetReceiptPaymentDetailQuery(receiptId);

  const receipt = data ?? ({} as PayReceiptDetail);

  useTitle(`Phiếu thu ${data?.id ?? ''}`);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.report.finance)}
        secondaryText="Báo cáo thu chi"
        text={`Phiếu thu ${receipt.id}`}
      />
      {data && <ReceiptDetailContent receipt={receipt} />}
    </>
  );
};

export default GetReceipt;
