import { createApi } from '@reduxjs/toolkit/query/react';
import { UserInforType, UsersType, UserWithRole } from 'models/user.model';
import { baseQuery } from './base.service';

interface UpdateRoleRequest {
  user_id: string;
  roles: number[];
}
interface getStudent {
  class_id: string;
  student_id: string;
}

interface CreateUser {
  full_name: string;
  phone: string;
  email: string;
  bank_name: string;
  bank_account: string;
  avatar: string;
  description: string;
}

interface CreateUserResponse {
  id: string;
  full_name: string;
  phone: string;
  email: string;
  roles: number[];
}

interface UpdateUser {
  id: string;
  full_name: string;
  phone: string;
  email: string;
  bank_name: string;
  bank_code: string | any;
  bank_account: string;
  avatar: string;
  description: string;
  roles: number[];
  dob?: string;
  link_cv: string;
}

export const userApi = createApi({
  reducerPath: 'userApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    updateRole: builder.mutation<string, UpdateRoleRequest>({
      query: (data) => {
        return {
          url: `update-role`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getUsers: builder.query<UsersType[], string>({
      query: (keyword) => {
        return {
          url: `users${keyword}`,
          method: 'GET',
        };
      },
      transformResponse: (response: UsersType[]) => {
        response = response === null ? ([] as UsersType[]) : response;
        if (response.length > 0) {
          response.map((item) => {
            item.roles = item.roles === null ? ([] as number[]) : item.roles;
            item.created_at = item.created_at.includes('0001-01-01T00')
              ? ''
              : item.created_at;
          });
        }
        return response;
      },
    }),

    getUsersByRole: builder.query<UserWithRole[], number>({
      query: (role) => {
        return {
          url: `list-users`,
          method: 'GET',
          params: { role },
        };
      },
      transformResponse: (response: UserWithRole[]) => {
        response = response === null ? ([] as UserWithRole[]) : response;
        if (response.length > 0) {
          response.map((item) => {
            item.info = `${item.full_name} - ${item.email} - ${
              item.phone ? item.phone : ''
            }`;
            item.bank_account = item.bank_account ? item.bank_account : '';
            item.bank_name = item.bank_name ? item.bank_name : '';
          });
        }
        return response;
      },
    }),
    getStudentInfo: builder.query<UserInforType, getStudent>({
      query: (arg) => {
        const { student_id, class_id } = arg;
        return {
          url: `user/${student_id}?class_id=${class_id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: UserInforType) => {
        response.bank_name = response.bank_name ? response.bank_name : '';
        response.bank_account = response.bank_account
          ? response.bank_account
          : '';
        response.description = response.description ? response.description : '';
        response.avatar = response.avatar ? response.avatar : '';
        response.roles = response.roles ? response.roles : ([] as number[]);
        response.rolesStr = response.roles.reduce((arr, item) => {
          arr.push(item.toString());
          return arr;
        }, [] as string[]);
        response.statusStr = response.status ? 'true' : 'false';
        response.learn_process = response.learn_process ?? [];
        response.transaction = response.transaction ?? [];
        return response;
      },
    }),

    getUserInfo: builder.query<
      UserInforType,
      { user_id: string; code: string }
    >({
      query: (arg) => {
        const { user_id, code } = arg;
        return {
          url: `user/${user_id}?code=${code}`,
          method: 'GET',
        };
      },
      transformResponse: (response: UserInforType) => {
        response.bank_name = response.bank_name ? response.bank_name : '';
        response.bank_account = response.bank_account
          ? response.bank_account
          : '';
        response.description = response.description ? response.description : '';
        response.avatar = response.avatar ? response.avatar : '';
        response.roles = response.roles ? response.roles : ([] as number[]);
        response.rolesStr = response.roles.reduce((arr, item) => {
          arr.push(item.toString());
          return arr;
        }, [] as string[]);
        response.statusStr = response.status ? 'true' : 'false';
        response.learn_process = response.learn_process ?? [];
        response.transaction = response.transaction ?? [];
        return response;
      },
    }),

    createUser: builder.mutation<CreateUserResponse, CreateUser>({
      query: (data: CreateUser) => {
        return {
          url: `create-users`,
          method: 'POST',
          body: data,
        };
      },
    }),

    resetPassword: builder.mutation<void, string>({
      query: (userId) => {
        return {
          url: `/user/${userId}/reset-password`,
          method: 'PUT',
        };
      },
    }),

    updateUserInfo: builder.mutation<string, UpdateUser>({
      query: (data: UpdateUser) => {
        return {
          url: `user/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    exportPasscode: builder.mutation<{ link: string }, string>({
      query: (userId) => {
        return {
          url: `user/${userId}/create-passcode`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useUpdateRoleMutation,
  useGetUsersByRoleQuery,
  useGetUsersQuery,
  useGetUserInfoQuery,
  useGetStudentInfoQuery,
  useCreateUserMutation,
  useUpdateUserInfoMutation,
  useResetPasswordMutation,
  useExportPasscodeMutation,
} = userApi;
