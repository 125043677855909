import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { currDate } from 'libraries/utils/datetime';
import { downloadExcelFile, exportSingleSheet } from 'libraries/utils/excel';
import { classTypeValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { ClassAttendance } from 'models/class.model';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetClassAttendancesQuery } from 'services/classes.service';
import { selectUser } from 'store/auth.slice';
import { selectClass } from 'store/classes.slice';

const ClassAttendances = (): ReactElement => {
  const { id, name, total_learns } = useSelector(selectClass);
  const { data } = useGetClassAttendancesQuery(id);
  const attendances = data ? data : ([] as ClassAttendance[]);
  const { full_name, email } = useSelector(selectUser);

  const columns: ColumnsType<ClassAttendance> = [
    {
      key: 'lesson_id',
      title: 'Buổi học',
      dataIndex: 'lesson_id',
      render: (_text, item) => {
        return (
          <RouterLink
            to={generatePath(route.class.attendance, {
              classId: id,
              lessonId: item.lesson_id,
            })}
          >
            {item.complete_date !== ''
              ? formatDate(item.complete_date)
              : item.lesson_id}
          </RouterLink>
        );
      },
    },
    {
      key: 'type',
      title: 'Hình thức',
      dataIndex: 'type',
      render: (type) => {
        if (type !== '') {
          for (let i = 0; i < classTypeValues.length; i++) {
            const item = classTypeValues[i];
            if (item.value === type) {
              return item.label;
            }
          }
        } else {
          return '';
        }
      },
    },
    {
      key: 'trainer',
      title: 'Người điểm danh',
      dataIndex: 'trainer',
    },
    {
      key: 'attendances',
      title: 'Sĩ số',
      dataIndex: 'attendances',
      render: (_text, item) => `${item.attendances}/${item.total}`,
    },
  ];

  const handleExportExcel = () => {
    const info = {
      wsName: `Danh sách điểm danh khóa ${name.replace(/[*?:\/\\\[\]]/g, '')}`,
      creator: full_name,
      email: email,
      date: currDate(),
    };
    const header = ['Buổi học', 'Hình thức', 'Người điểm danh', 'Sĩ số'];
    const data = attendances.map((item) => {
      return [
        item.complete_date !== '' ? formatDate(item.complete_date) : '',
        item.type,
        item.trainer,
        `${item.attendances}/${item.total}`,
      ];
    });
    const excelData = [header, ...data];

    const workbook = exportSingleSheet(excelData, info);

    downloadExcelFile(workbook, info.wsName);
  };

  return (
    <>
      {attendances.length > 0 && (
        <LinksContainer>
          <Link.Primary to="#" onClick={handleExportExcel}>
            Xuất Excel
          </Link.Primary>
        </LinksContainer>
      )}

      <div style={{ marginBottom: '10px' }}>
        Số buổi đã học &ensp;
        <span style={{ fontSize: '2.5rem', color: '#dd4b39' }}>
          {attendances.length}/{total_learns}
        </span>
      </div>
      <TableContainer>
        <Table<ClassAttendance>
          columns={columns}
          dataSource={attendances}
          pagination={false}
          rowKey="lesson_id"
          bordered
        />
      </TableContainer>
    </>
  );
};
export default ClassAttendances;
