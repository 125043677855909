import NotFound from 'components/commons/NotFound';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import {
  useGetAllStudentsClassQuery,
  useGetClassInfoQuery,
} from 'services/classes.service';
import ClassDetailContent from './ClassDetailContent';

const GetClassDetail = (): ReactElement => {
  const { classId } = useParams<{ classId: string }>();
  const { isError } = useGetClassInfoQuery(classId);
  const { isError: isStudentError } = useGetAllStudentsClassQuery(classId);

  if (isError || isStudentError) {
    return <NotFound subTitle="Không tìm thấy thông tin lớp học" />;
  }

  return <ClassDetailContent />;
};
export default GetClassDetail;
