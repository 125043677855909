import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ClassInfo, LessonAttendance } from 'models/class.model';
import { Course } from 'models/course.model';
import { ClassStudent, Student, UserLearnProcess } from 'models/user.model';
import { classesApi } from 'services/classes.service';
import { RootState } from 'store';

export interface ClassState {
  courses: Course[];
  classroom: ClassInfo;
  students: Student[];
  attendances: LessonAttendance[];
  student: ClassStudent;
}
interface EditNote {
  id: string;
  note: string;
  type: 'live' | 'lab';
}
interface Payload<T> {
  payload: T;
}

const initialState: ClassState = {
  courses: [] as Course[],
  classroom: {} as ClassInfo,
  students: [] as Student[],
  attendances: [] as LessonAttendance[],
  student: {} as ClassStudent,
};

export const classesSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    removeStudentInList: (state, { payload }) => {
      const newArr = [...state.students];
      const findIndex = newArr.findIndex((item) => item.id === payload);
      if (findIndex >= 0) {
        newArr.splice(findIndex, 1);
        state.students = newArr;
      }
    },

    addStudentToList: (state, { payload }) => {
      const { student, avatar, email, phone, type } = payload;
      state.students.push({
        id: student.id,
        full_name: student.full_name,
        status: student.status,
        join_date: student.join_date,
        end_date: '',
        total_money: 0,
        paid: 0,
        debt: 0,
        order_id: 0,
        note: student.note,
        index: 0,
        avatar: avatar,
        email: email,
        phone: phone,
        type,
        //* có thể bỏ type nếu không cần thiết
      });

      const newArr = [...state.students];
      newArr.map((item, index) => {
        item.index = index + 1;
      });
      state.students = newArr;
    },

    updateStudentStatus: (state, { payload }) => {
      const { id, status } = payload;
      const findIndex = state.students.findIndex((item) => item.id === id);
      state.students[findIndex].status = status;
    },

    checkAttendance: (state, { payload }) => {
      // Update status attendance when teacher toggle checkbox
      const studentId = payload;
      const student = state.attendances.find(
        (item) => item.student_id === studentId,
      );
      if (!student) return;
      if (!student.is_attend && student.attend_online) {
        student.attend_online = false;
        student.is_attend = true;
      } else {
        student.is_attend = !student.is_attend;
      }
    },
    checkAttendanceOnline: (state, { payload }) => {
      // Update status attendance when teacher toggle checkbox
      const studentId = payload;
      const student = state.attendances.find(
        (item) => item.student_id === studentId,
      );
      if (!student) return;
      if (!student.attend_online && student.is_attend) {
        student.attend_online = true;
        student.is_attend = false;
      } else {
        student.attend_online = !student.attend_online;
      }
    },
    changeAttendOnline: (state, { payload }) => {
      // Update status attendance when teacher toggle checkbox
      const studentId = payload.student_id;
      const student = state.attendances.find(
        (item) => item.student_id === studentId,
      );
      if (!student) return;
      if (payload.attend_online) {
        student.attend_online = payload.attend_online;
        student.is_attend = false;
      } else {
        student.attend_online = payload.attend_online;
        student.is_attend = true;
      }
    },

    checkAll: (state) => {
      state.attendances.forEach(
        (item) => ((item.attend_online = false), (item.is_attend = true)),
      );
    },
    uncheckAll: (state) => {
      state.attendances.forEach(
        (item) => ((item.attend_online = false), (item.is_attend = false)),
      );
    },
    checkOnlineAll: (state) => {
      state.attendances.forEach(
        (item) => ((item.attend_online = true), (item.is_attend = false)),
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      classesApi.endpoints.getAllStudentsClass.matchFulfilled,
      (state, { payload }) => {
        state.students = payload;
      },
    );

    builder.addMatcher(
      classesApi.endpoints.getListCourses.matchFulfilled,
      (state, { payload }) => {
        state.courses = payload;
      },
    );

    builder.addMatcher(
      classesApi.endpoints.getClassInfo.matchFulfilled,
      (state, { payload }) => {
        state.classroom = payload;
      },
    );

    builder.addMatcher(
      classesApi.endpoints.getLessonAttendances.matchFulfilled,
      (state, { payload }) => {
        state.attendances = payload;
      },
    );

    builder.addMatcher(
      classesApi.endpoints.getClassStudentInfo.matchFulfilled,
      (state, { payload }) => {
        state.student = payload;
      },
    );

    builder.addMatcher(
      classesApi.endpoints.addSalary.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
      },
    );

    builder.addMatcher(
      classesApi.endpoints.patchNoteEdit.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
      },
    );
  },
});

export const {
  removeStudentInList,
  addStudentToList,
  updateStudentStatus,
  checkAttendance,
  checkAttendanceOnline,
  changeAttendOnline,
  checkAll,
  uncheckAll,
  checkOnlineAll,
} = classesSlice.actions;
export const selectCourses = (state: RootState): Course[] =>
  state.class.courses;
export const selectClass = (state: RootState): ClassInfo =>
  state.class.classroom;
export const selectClassStudents = (state: RootState): Student[] =>
  state.class.students;
export const selectTest = createSelector(selectClassStudents, (state) => state);
export const selectLessonAttendances = (state: RootState): LessonAttendance[] =>
  state.class.attendances;
export const selectStudent = (state: RootState): ClassStudent =>
  state.class.student;

export default classesSlice.reducer;
