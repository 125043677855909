import { Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import TableContainer from 'components/table/TableContainer';
import { promotionStatusValues } from 'libraries/utils/globalVariable';
import { Promotion } from 'models/promotion.model';
import moment from 'moment';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  filterListByContent,
  filterListByName,
  filterListByStatus,
  selectPromotions,
} from 'store/promotion.slice';

const { Option } = Select;

const PromotionTable = (): ReactElement => {
  const dispatch = useDispatch();
  const promotions = useSelector(selectPromotions);
  const [keyName, setKeyName] = useState('');
  const [keyContent, setKeyContent] = useState('');
  const [keyStatus, setKeyStatus] = useState('');

  const columns: ColumnsType<Promotion> = [
    {
      sorter: (a, b) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      width: '20%',
      title: 'Tiêu đề',
      filterSearch: true,
      children: [
        {
          dataIndex: 'name',
          key: 'name',
          title: (
            <Input
              value={keyName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  dispatch(filterListByName(keyName));
                }
              }}
              onChange={(e) => setKeyName(e.target.value)}
            />
          ),
          render: (_text, item) => (
            <RouterLink
              to={generatePath(route.promotion.detail, {
                voucherName: item.name,
              })}
              style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            >
              {item.name}
            </RouterLink>
          ),
        },
      ],
    },
    {
      sorter: (a, b) => a.content.localeCompare(b.content),
      showSorterTooltip: false,
      width: '20%',
      title: 'Mã kích hoạt',
      filterSearch: true,
      children: [
        {
          dataIndex: 'content',
          key: 'content',
          title: (
            <Input
              value={keyContent}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  dispatch(filterListByContent(keyContent));
                }
              }}
              onChange={(e) => setKeyContent(e.target.value)}
            />
          ),
        },
      ],
    },
    {
      width: '20%',
      title: 'Trạng thái',
      children: [
        {
          dataIndex: 'status',
          key: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={150}
              onChange={(value) => {
                setKeyStatus(value);
                dispatch(filterListByStatus(value));
              }}
              value={keyStatus}
            >
              <Option value="">Tất cả</Option>
              {promotionStatusValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
        },
      ],
    },

    {
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      sorter: (a, b) => Date.parse(a.start_date) - Date.parse(b.start_date),
      width: '20%',
      title: 'Ngày bắt đầu',
      children: [
        {
          dataIndex: 'start_date',
          key: 'start_date',
          render: (start_date) =>
            start_date !== ''
              ? moment.utc(start_date).format('DD-MM-YYYY')
              : '',
        },
      ],
    },
    {
      sorter: (a, b) => Date.parse(a.end_date) - Date.parse(b.end_date),
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
      width: '20%',
      title: 'Ngày kết thúc',
      children: [
        {
          dataIndex: 'end_date',
          key: 'end_date',
          render: (end_date) =>
            end_date !== '' ? moment.utc(end_date).format('DD-MM-YYYY') : '',
        },
      ],
    },
  ];

  return (
    <BorderBoxContent>
      <TableContainer>
        <Table<Promotion>
          columns={columns}
          dataSource={promotions}
          pagination={{ showSizeChanger: false }}
          rowKey="index"
          bordered
        />
      </TableContainer>
    </BorderBoxContent>
  );
};
export default PromotionTable;
