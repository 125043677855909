import {
  ReactNode,
  createContext,
  ReactElement,
  useState,
  useRef,
  useContext,
  useEffect,
} from 'react';
import { Form, InputNumber, message } from 'antd';
import { Student } from 'models/user.model';
import type { FormInstance } from 'antd/lib/form';
import { usePutFixedSalaryMutation } from '../../../services/configure.service';

interface EditableRowProps {
  index: number;
}

interface IEditableCells {
  title: ReactNode;
  children: ReactNode;
  record: Student;
  editable: boolean;
  dataIndex: keyof Student;
  handleSave: (arg: Student) => void;
  user_id: string;
}

const EditableContext = createContext<FormInstance<any> | null>(null);

export const EditableRow = ({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  children,
  record,
  editable,
  dataIndex,
  user_id,
  handleSave,
  ...restProps
}: IEditableCells): ReactElement => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [putFixedSalary, { isLoading: isPutLoading }] =
    usePutFixedSalaryMutation();

  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    const { fix_salary } = await form.validateFields();
    if (fix_salary === record[dataIndex]) {
      toggleEdit();
      return;
    }
    putFixedSalary([
      {
        user_id,
        fix_salary,
      },
    ])
      .unwrap()
      .then((res) => message.success(res))
      .catch((err) => message.error(err));
    toggleEdit();
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
          padding: 0,
        }}
        name={dataIndex}
      >
        <InputNumber
          style={{ width: '100%' }}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          min={0}
          addonAfter={'VND'}
          step={100000}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
