import { EditorView as CMEditorView } from 'codemirror';
import { Text as CMText } from '@codemirror/state';
import { EditorSelection as CMEditorSelection } from '@codemirror/state';
import { countAllArrString } from 'libraries/editor/string';
import { isMarkdownTable, formatMarkdownTable, generateTable } from './markdown-table';

const viewKit = (view: CMEditorView, lineNumber?: number) => {
  const getSelections = () =>
    view.state.selection.ranges.map((r) => view.state.sliceDoc(r.from, r.to));
  const setCursor = (pos: number) =>
    view.dispatch({ selection: { anchor: pos } });
  const lineAt = (number: number | undefined = lineNumber) =>
    number && view.state.doc.lineAt(number);
  const textAtLine = (number: number | undefined = lineNumber) =>
    number && view.state.doc.lineAt(number).text;

  const cursor = view.state.selection.main.head;
  const currentLine = view.state.doc.lineAt(
    view.state.selection.main.head,
  ).number;
  const line = view.state.doc.lineAt(view.state.selection.main.head);
  const lineText = view.state.doc.lineAt(view.state.selection.main.head).text;

  const somethingSelected = view.state.selection.ranges.some((r) => !r.empty);
  const listSelection = view.state.selection.ranges;
  const trimmedSelection = getSelections()[0].trim();
  const isTrailing = !!(getSelections()[0].length - trimmedSelection.length);
  return {
    line,
    currentLine,
    lineText,
    somethingSelected,
    getSelections,
    setCursor,
    listSelection,
    cursor,
    lineAt,
    textAtLine,
    trimmedSelection,
    isTrailing,
  };
};

const header = (view: CMEditorView, type: number) => {
  const { getSelections } = viewKit(view);
  const headMark = '#'.repeat(Math.min(type, 4));
  const selectionText = getSelections()[0].trim();

  view.dispatch(
    view.state.changeByRange((range) => {
      let newText: string;
      let newCursor: number;

      if (selectionText) {
        //không rỗng
        newText = `${headMark} ${selectionText.replace(/^#+\s*/, '')}`;
        newCursor = range.from + newText.length;
      } else {
        newText = `${headMark} Heading ${type}`;
        newCursor = range.from + headMark.length + 1;
      }

      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of([newText]),
          },
        ],
        range: selectionText
          ? CMEditorSelection.cursor(newCursor)
          : CMEditorSelection.range(newCursor, range.from + newText.length),
      };
    }),
  );
};

const math = (view: CMEditorView, type: number) => {
  const insertText = (text: string) => {
    const transaction = view.state.update({
      changes: { from: view.state.selection.main.from, insert: text }
    });
    view.dispatch(transaction);
  };

  const { cursor, line,lineText } = viewKit(view);
  const cursorPos = cursor - line.from;
  if (type === 1) {
    if (cursorPos > 0 && cursorPos < lineText.length) {
      insertText(' $a^2 + b^2 = c^2$ ');
    } else {
      insertText('$a^2 + b^2 = c^2$');
    }
  } else if (type === 2) {
    const sampleMathBlock = `\`\`\`math
\\frac{\\partial^2 u}{\\partial t^2} = c^2 \\nabla^2 u
\`\`\``;
    insertText((cursorPos > 0 ?'\n' + sampleMathBlock : sampleMathBlock) + (cursorPos < lineText.length ? '\n' : ''));
  }
};

const wrapText = (view: CMEditorView, tag: string, sample: string) => {
  const { trimmedSelection, setCursor, cursor, somethingSelected } =
    viewKit(view);

  view.dispatch(
    view.state.replaceSelection(trimmedSelection),
    view.state.changeByRange((range) => {
      const changes = !somethingSelected
        ? [{ from: range.from, insert: CMText.of([`${tag}${sample}${tag}`]) }]
        : [
            { from: range.from, insert: CMText.of([tag]) },
            { from: range.to, insert: CMText.of([tag]) },
          ];

      const newRange = !somethingSelected
        ? CMEditorSelection.range(
            range.from + tag.length,
            range.from + tag.length + sample.length,
          )
        : CMEditorSelection.range(
            range.from + tag.length,
            range.to + tag.length,
          );

      return {
        changes,
        range: newRange,
      };
    }),
  );

  if (somethingSelected) {
    setCursor(cursor + 2 * tag.length + trimmedSelection.length);
  }
};

// Sử dụng hàm wrapText cho các hàm bold, italic, strikethrough
const bold = (view: CMEditorView) => wrapText(view, '**', 'Bold');
const italic = (view: CMEditorView) => wrapText(view, '*', 'Italic');
const strikethrough = (view: CMEditorView) => wrapText(view, '~~', 'Strikethrough');
const underline = (view: CMEditorView) => wrapText(view, '++', 'Underline');
const highlight = (view: CMEditorView) => wrapText(view, '==', 'Highlight');
const code = (view: CMEditorView) => wrapText(view, '`', 'Inline');

const blockCode = (view: CMEditorView) => {
  const { somethingSelected, getSelections } = viewKit(view);
  const selectionText = getSelections()[0];

  view.dispatch(
    view.state.changeByRange((range) => {
      if (!somethingSelected || selectionText.trim() === '') {
        const codeBlock = ['```py:demo.py', 'print("Hello, World!")', '```'];

        return {
          changes: [
            {
              from: range.from,
              to: range.to,
              insert: CMText.of(codeBlock),
            },
          ],
          range: CMEditorSelection.range(
            range.from + 3,
            range.from + codeBlock[0].length + codeBlock[1].length + 1,
          ),
        };
      } else {
        const wrapCodeBlock = ['```', ...selectionText.split('\n'), '```'];
        return {
          changes: [
            {
              from: range.from,
              to: range.to,
              insert: CMText.of(wrapCodeBlock),
            },
          ],
          range: CMEditorSelection.cursor(range.from + 4),
        };
      }
    }),
  );
};

const codeTabs = (view: CMEditorView) => {
  const codeBlocks = [
    '```js [g1:JavaScript]',
    'console.log("hello");',
    '```',
    '',
    '```py [g1:Python3]',
    'print("hello")',
    '```',
    '',
    '```java [g1:Java]',
    'System.out.println("hello");',
    '```',
    '',
    '```go [g1:Golang]',
    'fmt.Println("hello")',
    '```'
  ];

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of(['', ...codeBlocks]),
          },
        ],
        range: CMEditorSelection.cursor(range.from + codeBlocks.join('\n').length + 1),
      };
    }),
  );
};

const mermaid = (view: CMEditorView) => {
  const { lineText, somethingSelected, getSelections, setCursor, line } =
    viewKit(view);
  view.dispatch(
    view.state.changeByRange((range) => {
      return lineText.length
        ? somethingSelected
          ? {
              changes: [
                {
                  from: range.from,
                  to: range.to,
                  insert: CMText.of([
                    '~~~mermaid',
                    ...getSelections()[0].split('\n'),
                    '~~~',
                  ]),
                },
              ],
              range: CMEditorSelection.range(range.from + 4, range.to + 4),
            }
          : {
              changes: [
                {
                  from: range.from,
                  insert: CMText.of([
                    '',
                    '~~~mermaid',
                    'graph TD',
                    'A[Christmas] -->|Get money| B(Go shopping)',
                    'B --> C{Let me think}',
                    'C -->|One| D[Laptop]',
                    'C -->|Two| E[iPhone]',
                    'C -->|Three| F[Car]',
                  ]),
                },
                {
                  from: range.to,
                  insert: CMText.of(['', '~~~']),
                },
              ],
              range: CMEditorSelection.range(range.from + 4, range.to + 4),
            }
        : {
            changes: [
              {
                from: range.from,
                insert: CMText.of([
                  '~~~mermaid',
                  'graph TD',
                  'A[Christmas] -->|Get money| B(Go shopping)',
                  'B --> C{Let me think}',
                  'C -->|One| D[Laptop]',
                  'C -->|Two| E[iPhone]',
                  'C -->|Three| F[Car]',
                  '~~~',
                ]),
              },
            ],
            range: CMEditorSelection.range(range.from + 3, range.to + 3),
          };
    }),
  );
  if (lineText.length && somethingSelected) setCursor(line.from + 3);
};

const link = (view: CMEditorView) => {
  view.dispatch(
    view.state.changeByRange((range) => {
      const selectionText = view.state.sliceDoc(range.from, range.to);
      let linkText: string;
      let cursorStart: number;
      let cursorEnd: number;
      const urlPlaceholder = 'https://url_here';
      if (!selectionText) {
        // chuỗi rỗng
        linkText = `[text](${urlPlaceholder})`;
        cursorStart = range.from + 1;
        cursorEnd = range.from + 5;
      } else if (
        selectionText.startsWith('http') ||
        selectionText.includes('/')
      ) {
        //khả năng đây là link
        linkText = `[text](${selectionText})`;
        cursorStart = range.from + 1;
        cursorEnd = range.from + 5;
      } else {
        //chỉ là text bình thường
        linkText = `[${selectionText}](${urlPlaceholder})`;
        cursorStart = range.from + selectionText.length + 3;
        cursorEnd = cursorStart + urlPlaceholder.length;
      }

      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of([linkText]),
          },
        ],
        range: CMEditorSelection.range(cursorStart, cursorEnd),
      };
    }),
  );
};
const bulletList = (view: CMEditorView) => {
  const { getSelections, setCursor, cursor } = viewKit(view);
  const selectionText = getSelections()[0];

  if (selectionText) {
    const lines = selectionText.split('\n');
    const allLinesHaveBullet = lines.every((line) => line.startsWith('* '));
    const newLines = allLinesHaveBullet
      ? lines.map((line) => line.replace(/^\* /, ''))
      : lines.map((line) => `* ${line}`);

    view.dispatch(
      view.state.changeByRange((range) => {
        return {
          changes: [
            {
              from: range.from,
              to: range.to,
              insert: CMText.of(newLines),
            },
          ],
          range: CMEditorSelection.range(
            range.from,
            range.from + newLines.join('\n').length,
          ),
        };
      }),
    );

    setCursor(cursor + newLines.join('\n').length);
  } else {
    const textList = ['* itemA', '* itemB', '* itemC'];

    view.dispatch(
      view.state.changeByRange((range) => {
        const from = range.from;
        const to = from + textList.join('\n').length;

        return {
          changes: [
            {
              from: range.from,
              insert: CMText.of(textList),
            },
          ],
          range: CMEditorSelection.range(from + 2, to),
        };
      }),
    );

    // Đặt con trỏ sau `* ` ở dòng đầu tiên và chọn đến cuối dòng thứ ba
    const newFrom = cursor + 2;
    const newTo = cursor + textList.join('\n').length;
    view.dispatch({
      selection: CMEditorSelection.range(newFrom, newTo),
    });
  }
};

const image = (view: CMEditorView, alt?: string, url?: string) => {
  const img = `![${alt ?? 'text'}](${url ?? 'https://'})`;

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of([img]),
          },
        ],
        range: CMEditorSelection.range(
          range.from + (url ? img.length : img.length - 1),
          range.to + (url ? img.length : img.length - 1),
        ),
      };
    }),
  );
};

const numberList = (view: CMEditorView) => {
  const { getSelections, setCursor, cursor } = viewKit(view);
  const selectionText = getSelections()[0];

  if (selectionText) {
    const lines = selectionText.split('\n');
    const numberedLines = lines.map((line, index) => {
      const trimmedLine = line.trim();
      const match = trimmedLine.match(/^\d+\.\s*(.*)/);
      return match
        ? `${index + 1}. ${match[1]}`
        : `${index + 1}. ${trimmedLine}`;
    });

    view.dispatch(
      view.state.changeByRange((range) => {
        return {
          changes: [
            {
              from: range.from,
              to: range.to,
              insert: CMText.of(numberedLines),
            },
          ],
          range: CMEditorSelection.range(
            range.from,
            range.from + numberedLines.join('\n').length,
          ),
        };
      }),
    );

    setCursor(cursor + numberedLines.join('\n').length);
  } else {
    const textList = ['1. itemA', '2. itemB', '3. itemC'];

    view.dispatch(
      view.state.changeByRange((range) => {
        const from = range.from;
        const to = from + textList.join('\n').length;

        return {
          changes: [
            {
              from: range.from,
              insert: CMText.of(textList),
            },
          ],
          range: CMEditorSelection.range(from + 3, to),
        };
      }),
    );

    // Đặt con trỏ sau `1. ` ở dòng đầu tiên và chọn đến cuối dòng thứ ba
    const newFrom = cursor + 3;
    const newTo = cursor + textList.join('\n').length;
    view.dispatch({
      selection: CMEditorSelection.range(newFrom, newTo),
    });
  }
};

const align = (
  view: CMEditorView,
  direction: 'center' | 'right' | 'left' | 'justify',
) => {
  const openingTag = `::: ${direction}`;
  const closingTag = ':::';

  const { lineText, somethingSelected } = viewKit(view);
  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert:
              lineText && !somethingSelected
                ? // nếu trên dòng có ký tự thì xuống dòng, không tính trường hợp bôi đen
                  CMText.of(['', openingTag, ''])
                : CMText.of([openingTag, '']),
          },
          {
            from: range.to,
            insert: CMText.of(['', closingTag]),
          },
        ],
        range: CMEditorSelection.range(
          range.from +
            openingTag.length +
            (lineText && !somethingSelected ? 2 : 1),
          // nếu con trỏ chuột ở trên 1 dòng có chữ thì xuống dòng, nếu bôi đen r bấm nút thì con trỏ chuột nằm ở cuối văn bàn
          range.to +
            openingTag.length +
            (lineText && !somethingSelected ? 2 : 1),
        ),
      };
    }),
  );
};
const isMarkdownTableSelected = (view: CMEditorView): boolean => {
  const { getSelections } = viewKit(view);
  const selectionText = getSelections()[0];
  return isMarkdownTable(selectionText);
}

const formatSelectedMarkdownTable = (view: CMEditorView) => {
  const { getSelections } = viewKit(view);
  const selectionText = getSelections()[0];
  const formatedTable = formatMarkdownTable(selectionText);
  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of([
              ...formatedTable
            ]),
          },
        ],
        range: CMEditorSelection.range(range.from, range.from + formatedTable.join('\n').length),
      };
    }),
  );
};
/*
Chèn Markdown table
*/
const pasteTable = (view: CMEditorView, row = 3, column = 3) => {
  const table = generateTable(row, column);
  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of([
              ...table
            ]),
          },
        ],
        range: CMEditorSelection.range(range.from, range.from + table.join('\n').length),
      };
    }),
  );
};

const horizontalLine = (view: CMEditorView) => {
  const { lineText, cursor, line } = viewKit(view);
  const getSelectionBeforeCursor = view.state.sliceDoc(line.from, cursor);
  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert:
              !lineText || !getSelectionBeforeCursor
                ? CMText.of(['', '---', '', ''])
                : CMText.of(['', '', '---', '', '']),
          },
        ],
        range: CMEditorSelection.range(
          range.from + (!lineText || !getSelectionBeforeCursor ? 6 : 7),
          range.to + (!lineText || !getSelectionBeforeCursor ? 6 : 7),
        ),
      };
    }),
  );
};

const pasteYoutube = (view: CMEditorView, url: string) => {
  view.dispatch(
    view.state.changeByRange((range) => {
      const makeUrlObj = (string: string) => {
        let urlObj;
        try {
          urlObj = new URL(string);
        } catch {
          return undefined;
        }
        return urlObj;
      };
      const urlObj = makeUrlObj(url);
      const urlId =
        urlObj?.searchParams.get('v') ?? urlObj?.pathname.replace('/', '');
      const link = `<iframe style="max-width: 560px; width: 100%; aspect-ratio: 16 / 9;" src="https://www.youtube.com/embed/${urlId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
      return {
        changes: [
          {
            from: range.from,
            insert: CMText.of([link, '', '']),
          },
        ],
        range: CMEditorSelection.range(
          range.from + link.length + 2,
          range.to + link.length + 2,
        ),
      };
    }),
  );
};

const labels = (view: CMEditorView) => {
  const { lineText, setCursor, line } = viewKit(view);

  const defaultExam = [
    '#[information](green)',
    '#[dangeruos](red)',
    '#[remider](yellow)',
    '#[custom color](#624E88)',
  ];

  const numberStr = countAllArrString(defaultExam);

  if (lineText.length) {
    setCursor(line.from);
  }

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of(defaultExam),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );

  setCursor(numberStr + defaultExam.length - 1);
};

const collapsible = (view: CMEditorView) => {
  const { lineText, setCursor, line } = viewKit(view);

  const defaultExam = [
    '+++> Click me!',
    'Hidden text',
    '+++ Nested',
    'Inner hidden text',
    '+++',
    '+++>',
  ];

  const countChar = countAllArrString(defaultExam);

  if (lineText.length) {
    setCursor(line.from);
  }

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of(defaultExam),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );

  setCursor(countChar + defaultExam.length - 1);
};

const uml = (view: CMEditorView) => {
  const { lineText, setCursor, line } = viewKit(view);

  const defaultExam = [
    '@startuml',
    'User --> (registers an account)',
    '@enduml',
  ];

  const countChar = countAllArrString(defaultExam);

  if (lineText.length) {
    setCursor(line.from);
  }

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of(defaultExam),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );

  setCursor(countChar + defaultExam.length - 1);
};

const treelist = (view: CMEditorView) => {
  const { lineText, setCursor, line } = viewKit(view);

  const defaultExam = ['root', '+-- item1', ' +-- sub-item1', '+-- item2'];

  const countChar = countAllArrString(defaultExam);

  if (lineText.length) {
    setCursor(line.from);
  }

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            to: range.to,
            insert: CMText.of(defaultExam),
          },
        ],
        range: CMEditorSelection.range(range.from + 2, range.to + 2),
      };
    }),
  );

  setCursor(countChar + defaultExam.length - 1);
};

const alert = (view: CMEditorView, type: alerts) => {
  const openingTag = `> [!${type}]`;
  const closingTag = '> Your alert';

  const { lineText } = viewKit(view);

  view.dispatch(
    view.state.changeByRange((range) => {
      return {
        changes: [
          {
            from: range.from,
            insert: lineText
              ? CMText.of(['', openingTag, closingTag])
              : CMText.of([openingTag, closingTag]),
          },
        ],
        range: CMEditorSelection.range(
          range.from + openingTag.length,
          range.to + closingTag.length,
        ),
      };
    }),
  );
};

export type alerts = 'note' | 'tip' | 'important' | 'caution' | 'warning';

export interface Markers {
  header: (view: CMEditorView, type: number) => void;
  math: (view: CMEditorView, type: number) => void;
  bold: (view: CMEditorView) => void;
  underline: (view: CMEditorView) => void;
  uml: (view: CMEditorView) => void;
  alert: (view: CMEditorView, type: alerts) => void;
  italic: (view: CMEditorView) => void;
  collapsible: (view: CMEditorView) => void;
  strikethrough: (view: CMEditorView) => void;
  link: (view: CMEditorView, text?: string, url?: string) => void;
  image: (view: CMEditorView, alt?: string, url?: string) => void;
  code: (view: CMEditorView) => void;
  blockCode: (view: CMEditorView) => void;
  codeTabs: (view: CMEditorView) => void;
  mermaid: (view: CMEditorView) => void;
  labels: (view: CMEditorView) => void;
  bulletList: (view: CMEditorView) => void;
  numberList: (view: CMEditorView) => void;
  treelist: (view: CMEditorView) => void;
  align: (
    view: CMEditorView,
    direction: 'left' | 'center' | 'right' | 'justify',
  ) => void;
  isMarkdownTableSelected (view: CMEditorView): boolean;
  pasteTable: (view: CMEditorView, row: number, column: number) => void;
  formatSelectedMarkdownTable: (view: CMEditorView) => void;
  horizontalLine: (view: CMEditorView) => void;
  pasteYoutube: (view: CMEditorView, url?: string) => void;
  highlight: (view: CMEditorView) => void;
}

export default {
    header,
  math,
  bold,
  italic,
  underline,
  strikethrough,
  code,
  link,
  image,
  blockCode,
  codeTabs,
  bulletList,
  numberList,
  align,
  pasteTable,
  isMarkdownTableSelected,
  formatSelectedMarkdownTable,
  horizontalLine,
  pasteYoutube,
  highlight,
  mermaid,
  labels,
  collapsible,
  treelist,
  uml,
  alert,
} as Markers;
