import { Upload as AntUpload } from 'antd';
import styled from 'styled-components';

const Upload = styled(AntUpload)`
  width: auto;
  .ant-upload {
    width: auto;
    height: auto;
    margin-right: 0;
    margin-bottom: 0;
    border: none;
  }
`;

export default Upload;
