import NotFound from 'components/commons/NotFound';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import { useGetClassStudentInfoQuery } from 'services/classes.service';
import StudentInfo from './StudentInfo';

const GetStudent = (): ReactElement => {
  const { classId, studentId, orderId } = useParams<{
    classId: string;
    studentId: string;
    orderId: string;
  }>();

  const { isError } = useGetClassStudentInfoQuery({
    class_id: classId,
    student_id: studentId,
    order_id: orderId,
  });

  if (isError) {
    return <NotFound subTitle="Không tìm thấy thông tin học viên" />;
  }

  return <StudentInfo />;
};
export default GetStudent;
