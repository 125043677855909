import { createApi } from '@reduxjs/toolkit/query/react';
import { TrackMaster } from 'models/trackmaster.model';
import { UserWithRole } from 'models/user.model';
import { Key } from 'react';
import { baseQuery } from './base.service';

interface DataUpdateTrackMaster {
  sale: UserWithRole;
  name: string;
  descrition: string;
}
interface UpdateTrackMaster {
  trackMasterId: string;
  data: DataUpdateTrackMaster;
}
interface ActiveTrackInTrackMaster {
  trackMasterId: string;
  data: {
    active_id: Key;
  };
}

export const trackMasterApi = createApi({
  reducerPath: 'trackMasterApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getTrackMasterInfo: builder.query<TrackMaster, string>({
      query: (trackMasterId) => {
        return {
          url: `track-master/${trackMasterId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: TrackMaster) => {
        response = response === null ? ({} as TrackMaster) : response;
        if (response) {
          if (response.sales) {
            response.saleId = response.sales.id;
          }
        }
        return response;
      },
    }),

    updateTrackMaster: builder.mutation<string, UpdateTrackMaster>({
      query: (arg) => {
        const { data, trackMasterId } = arg;
        return {
          url: `track-master/${trackMasterId}`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    createTrackMaster: builder.mutation<{ id: string }, DataUpdateTrackMaster>({
      query: (data) => {
        return {
          url: `track-master`,
          method: 'POST',
          body: data,
        };
      },
    }),

    deleteTrackMaster: builder.mutation<string, string>({
      query: (trackMasterId) => {
        return {
          url: `track-master/${trackMasterId}`,
          method: 'DELETE',
        };
      },
    }),

    activeTrackinTrackMaster: builder.mutation<
      string,
      ActiveTrackInTrackMaster
    >({
      query: (arg) => {
        const { trackMasterId, data } = arg;
        return {
          url: `track-master/${trackMasterId}/active-track`,
          method: 'PUT',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetTrackMasterInfoQuery,
  useUpdateTrackMasterMutation,
  useCreateTrackMasterMutation,
  useDeleteTrackMasterMutation,
  useActiveTrackinTrackMasterMutation,
} = trackMasterApi;
