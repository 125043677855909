import {
  DeleteFilled,
  DownOutlined,
  ExclamationCircleFilled,
  LeftOutlined,
} from '@ant-design/icons';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Tabs from 'components/commons/Tabs';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { selectCourse } from 'store/course.slice';
import styled from 'styled-components';
import CourseDetail from './CourseDetail';
import confirm from 'antd/lib/modal/confirm';
import { useDeleteCourseMutation } from 'services/course.service';
import { message, Select } from 'antd';
import {
  CurriculumListType,
  useGetAllClassesQuery,
  useGetClassesCurriculumQuery,
  useGetClassesQuery,
  useSelectClassMutation,
} from 'services/classes.service';
import { Class } from 'models/class.model';
import Button from 'libraries/components/commons/Button';
const { Option } = Select;
const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CourseContentContainer = styled.div`
  border: solid #d2d3e2;
  border-width: 0 0 0 2px;
  box-sizing: border-box;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  padding-left: 20px;
  width: 70%;
  .d_none {
    display: none;
  }
  .d_block {
    display: block;
  }
  .course_content {
    display: flex;
    margin-bottom: 20px;
    .triangle {
      border-bottom: 10px solid transparent;
      border-right: 15px solid #f2f3f7;
      border-top: 10px solid transparent;
      box-sizing: border-box;
      height: 0;
      margin-top: 20px;
      width: 0;
    }
    .track_container {
      background-color: #f2f3f7;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      width: 100%;
      .track_content {
        width: 100%;
        cursor: pointer;
        padding: 20px 40px 0;
        .title {
          color: #000;
          display: flex;
          font-family:
            Open Sans,
            sans-serif;
          font-size: 20px;
          font-weight: 600;
          justify-content: space-between;
          margin-bottom: 20px;
          text-align: left;
          text-transform: none;
        }
      }
    }
  }
`;
const H2Title = styled.h2`
  text-align: center;
  margin-top: 15px;
`;
const PTitle = styled.p`
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
`;
const { TabPane } = Tabs;
function checkShowLecture(array: number[], display_order: number) {
  if (array.includes(display_order)) {
    return true;
  }
  return false;
}
const CoursePageContent = (): ReactElement => {
  const { id, name, class_id } = useSelector(selectCourse);
  const [deleteCourse] = useDeleteCourseMutation();
  const [selectClass] = useSelectClassMutation();
  const { data, isError, error } = useGetAllClassesQuery(id);
  const [value, setValue] = useState<string>(class_id);
  const { data: data1 } = useGetClassesCurriculumQuery(value);
  const classes = data ? data.classes : ([] as Class[]);
  const lecturesList = data1 ? data1 : ([] as CurriculumListType[]);
  const [showLecture, setShowLecture] = useState<number[] | []>([]);
  useEffect(() => {
    setValue(class_id);
  }, [class_id]);
  const children = [];

  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>,
    );
  }
  const history = useHistory();

  const showDeleteConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn xoá khoá học này?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Xác nhận',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deleteCourse(id)
          .unwrap()
          .then(() => {
            message.success('Xoá khoá học thành công!');
            setTimeout(() => {
              history.push(generatePath(route.course.list));
            }, 700);
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleChange = (value: string) => {
    setValue(value);
  };
  const handleShowLecture = (value: number) => {
    if (checkShowLecture(showLecture, value)) {
      setShowLecture(
        showLecture.filter((item) => {
          return item !== value;
        }),
      );
    } else {
      setShowLecture([...showLecture, value]);
    }
  };
  const selectClassDefault = () => {
    const data = {
      id: value,
    };

    selectClass({ course_id: id, data: data })
      .unwrap()
      .then(() => message.success('Chọn giáo trình  cho khóa học thành công!'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.course.list)}
        secondaryText="Tất cả khoá học"
        text={name}
      />
      <GroupButton>
        <LinksContainer>
          <Link.Default to={generatePath(route.course.list)}>
            <LeftOutlined /> Quay lại
          </Link.Default>
        </LinksContainer>
        <LinksContainer>
          <Link.Danger to="#" onClick={showDeleteConfirm}>
            <DeleteFilled /> Xoá khoá học
          </Link.Danger>
        </LinksContainer>
      </GroupButton>
      {name && (
        <BorderTopBoxContent>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin chung" key="1">
              <CourseDetail />
            </TabPane>
            <TabPane tab="Học phí" key="2"></TabPane>
            <TabPane tab="Review" key="3"></TabPane>
            <TabPane tab="Giáo trình" key="4">
              <Button.Primary
                style={{ marginTop: '16px' }}
                disabled={!data1}
                onClick={selectClassDefault}
              >
                Chọn giáo trình
              </Button.Primary>
              <p style={{ marginBottom: 10, marginTop: 20 }}>Lớp học</p>
              <Select
                onChange={handleChange}
                placeholder="Chọn lớp học"
                defaultValue={class_id}
                style={{
                  width: '30%',
                }}
              >
                {classes.map((item) => (
                  <Option key={item.id}>{item.name}</Option>
                ))}
              </Select>
              {lecturesList.length !== 0 && (
                <H2Title>Nội dung giáo trình</H2Title>
              )}
              {lecturesList.length === 0 && value !== '' && (
                <PTitle>Lớp chưa có giáo trình !</PTitle>
              )}
              <CourseContentContainer>
                {lecturesList.map((items) => {
                  return (
                    <div className="course_content" key={items.display_order}>
                      <div className="triangle"></div>
                      <div className="track_container">
                        <div className="track_content">
                          <div
                            className="title"
                            onClick={() =>
                              handleShowLecture(items.display_order)
                            }
                          >
                            <span>{items.name}</span>
                            <span className="toggle_btn">
                              {items?.lectures !== null && (
                                <DownOutlined style={{ fontSize: '15px' }} />
                              )}
                            </span>
                          </div>
                          <ul
                            className={
                              checkShowLecture(showLecture, items.display_order)
                                ? 'd_block'
                                : 'd_none'
                            }
                          >
                            <div className="list course_info">
                              {items?.lectures?.map((item) => {
                                return (
                                  <li key={item.display_order}>{item.title}</li>
                                );
                              })}
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </CourseContentContainer>
            </TabPane>
          </Tabs>
        </BorderTopBoxContent>
      )}
    </>
  );
};
export default CoursePageContent;
