import ShowServerError from 'components/commons/ShowServerError';
import { SaleClassReport } from 'models/report.model';
import { Moment } from 'moment';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSaleClassReportQuery } from 'services/report.service';
import ClassesCollapse from './ClassesCollapse';

interface PropsType {
  firstDay: Moment;
  lastDay: Moment;
}

const OpenedClassReport = ({ firstDay, lastDay }: PropsType): ReactElement => {
  const { saleId } = useParams<{ saleId: string }>();

  const { data, isError, error } = useGetSaleClassReportQuery({
    sale_id: saleId,
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });

  const classes = data ? data : ([] as SaleClassReport[]);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      {classes.length > 0 ? (
        <ClassesCollapse classes={classes} />
      ) : (
        <h3>Không có lớp</h3>
      )}
    </>
  );
};

export default OpenedClassReport;
