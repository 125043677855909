import { createSlice } from '@reduxjs/toolkit';
import { TrackMaster } from 'models/trackmaster.model';

import { trackMasterApi } from 'services/trackmaster.service';

import { RootState } from 'store';

export interface TrackMasterState {
  trackMaster: TrackMaster;
}

const initialState: TrackMasterState = {
  trackMaster: {} as TrackMaster,
};

export const trackMasterSlice = createSlice({
  name: 'trackMaster',
  initialState,
  reducers: {
    selectTrack: (state, { payload }) => {
      state.trackMaster.list_tracks.forEach((item) => {
        if (item.id !== payload) {
          item.is_master = false;
        } else {
          item.is_master = true;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      trackMasterApi.endpoints.getTrackMasterInfo.matchFulfilled,
      (state, { payload }) => {
        // console.log(payload);
        state.trackMaster = payload;
      },
    );

    builder.addMatcher(
      trackMasterApi.endpoints.updateTrackMaster.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        // state.trackMaster = payload;
      },
    );

    builder.addMatcher(
      trackMasterApi.endpoints.createTrackMaster.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        // state.trackMaster = payload;
      },
    );

    builder.addMatcher(
      trackMasterApi.endpoints.deleteTrackMaster.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        // state.trackMaster = payload;
      },
    );

    builder.addMatcher(
      trackMasterApi.endpoints.activeTrackinTrackMaster.matchFulfilled,
      (state, { payload }) => {
        console.log(payload);
        // state.trackMaster = payload;
      },
    );
  },
});

export const selectTrackMasterInfo = (state: RootState): TrackMaster =>
  state.trackMaster.trackMaster;

export const { selectTrack } = trackMasterSlice.actions;

export default trackMasterSlice.reducer;
