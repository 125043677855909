import { createApi } from '@reduxjs/toolkit/query/react';
import { Revenue, Traffic } from 'models/dashboard.model';
import {
  AdmissionClass,
  GeneralInfo,
  ItemReport,
} from 'models/dashboard.model';
import { baseQuery } from './base.service';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getTrafficData: builder.query<Traffic, void>({
      query: () => {
        return {
          url: `traffic`,
          method: 'GET',
        };
      },
    }),

    getRevenuesData: builder.query<Revenue, void>({
      query: () => {
        return {
          url: `revenue`,
          method: 'GET',
        };
      },
    }),

    getGeneralInfo: builder.query<GeneralInfo, void>({
      query: () => {
        return {
          url: `general_info`,
          method: 'GET',
        };
      },
      transformResponse: (response: GeneralInfo) => {
        response.items = response.items ?? ([] as ItemReport[]);
        response.admission_classes =
          response.admission_classes ?? ([] as AdmissionClass[]);
        response.admission_classes.length > 0 &&
          response.admission_classes.map((item) => {
            item.start_date = item.start_date.includes('0001-01-01T00')
              ? ''
              : item.start_date;
          });

        return response;
      },
    }),
  }),
});

export const {
  useGetTrafficDataQuery,
  useGetRevenuesDataQuery,
  useGetGeneralInfoQuery,
} = dashboardApi;
