import { Image } from '../../../models/media.model';
import { useGetUserImagesQuery } from '../../../services/media.service';
import { LIMIT_IMAGE_PER_PAGE } from '../../../utils/globalVariable';
import { ReactElement, useState } from 'react';

import UserImages from './UserImages';

interface PropsType {
  selectedImage: Image;
  setSelectedImage: (value: Image) => void;
  getUrl: (value: string) => void;
  closeModal: () => void;
}

const GetUserImages = ({
  selectedImage,
  setSelectedImage,
  getUrl,
  closeModal,
}: PropsType): ReactElement => {
  const [currentPage, setCurrentPage] = useState(1);

  useGetUserImagesQuery({
    pageNumber: currentPage,
    limitNumber: LIMIT_IMAGE_PER_PAGE,
  });

  return (
    <UserImages
      selectedImage={selectedImage}
      setSelectedImage={setSelectedImage}
      getUrl={(value) => getUrl(value)}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      closeModal={closeModal}
    />
  );
};
export default GetUserImages;
