import NotFound from 'components/commons/NotFound';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import { useGetCourseInfoQuery } from 'services/course.service';
import CoursePageContent from './CoursePageContent';

const GetCourse = (): ReactElement => {
  const { courseId } = useParams<{ courseId: string }>();
  const { isError } = useGetCourseInfoQuery(courseId);

  if (isError) {
    return <NotFound subTitle="Không tìm thấy thông tin khoá học" />;
  }

  return <CoursePageContent />;
};
export default GetCourse;
