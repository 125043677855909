import { UserWithRole } from 'models/user.model';
import { ReactElement } from 'react';
import { Card, Table } from 'antd';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import Avatar from 'antd/lib/avatar/avatar';

interface PropsType {
  salers: UserWithRole[];
}

const KpiSaleCartTitle = ({ data }: any): ReactElement => {
  return (
    <div className="cart-title" style={{ textAlign: 'center' }}>
      <Avatar
        size={150}
        icon={
          <img
            src={
              data.avatar != ''
                ? data.avatar
                : '/static/media/avatar.492b9b6f.png'
            }
          />
        }
      />
    </div>
  );
};
export default KpiSaleCartTitle;
