import styled from 'styled-components';

const Span = styled.span`
  display: inline-block;
  padding: 4px 8px;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 2px;
  &.info {
    background-color: #00c0ef;
    color: #fff;
  }
  &.default {
    background-color: #d2d6de;
    color: #444;
  }
  &.warning {
    background-color: #f39c12;
    color: #fff;
  }
  &.success {
    background-color: #00a65a;
    color: #fff;
  }
  &.primary {
    background-color: #3c8dbc;
    color: #fff;
  }
  &.employer {
    background-color: #faad14;
    color: #444;
  }
  &.editor {
    background-color: #13c2c2;
    color: #444;
  }
  &.maintainer {
    background-color: #722ed1;
    color: #fff;
  }
  &.danger {
    background-color: #dd4b39;
    color: #fff;
  }
`;

export default Span;
