import { createApi } from '@reduxjs/toolkit/query/react';
import { ActiveBanner, Banner } from 'models/banner.model';
import {
  ConfigHistory,
  FixedSalaryHistory,
  FixedSalaryHistoryRO,
  FixSalary,
} from 'models/config.model';
import { baseQuery } from './base.service';

interface BannersListType {
  total_pages: number;
  banners: Banner[];
}

interface BannerType {
  name: string;
  link: string;
  thumbnail: string;
  status: boolean;
}

export const configureApi = createApi({
  reducerPath: 'configure',
  baseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ['FixedSalary'],
  endpoints: (builder) => ({
    getBannersList: builder.query<BannersListType, string>({
      query: (keySearch: string) => {
        return {
          url: `list-banners?${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: BannersListType) => {
        response.banners = response.banners ?? ([] as Banner[]);
        response.banners.length > 0 &&
          response.banners.map((item) => {
            item.created_at = item.created_at.includes('0001-01-01T00')
              ? ''
              : item.created_at;
          });

        return response;
      },
    }),
    getActiveBannersList: builder.query<ActiveBanner[], void>({
      query: () => {
        return {
          url: `list-active-banners`,
          method: 'GET',
        };
      },
    }),
    getBannerDetail: builder.query<Banner, string>({
      query: (id) => {
        return {
          url: `banner/${id}`,
          method: 'GET',
        };
      },
    }),
    deleteBanner: builder.mutation<string, string>({
      query: (id) => {
        return {
          url: `banner/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateBanner: builder.mutation<string, { id: string; data: BannerType }>({
      query: ({ id, data }) => {
        return {
          url: `banner/${id}`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    createBanner: builder.mutation<{ banner_id: string }, BannerType>({
      query: (data) => {
        return {
          url: `banner`,
          method: 'POST',
          body: data,
        };
      },
    }),

    updateBannerPosition: builder.mutation<string, { banner_ids: number[] }>({
      query: (data) => {
        return {
          url: `sort-banner`,
          method: 'PUT',
          body: data,
        };
      },
    }),

    //salary
    putFixedSalary: builder.mutation<
      string,
      { fix_salary: number; user_id: string }[]
    >({
      query: (data) => ({
        url: 'fix-salary',
        method: 'PUT',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          configureApi.util.updateQueryData(
            'getFixedSalaryHistory',
            undefined,
            (draft) => {
              arg.forEach((item) => {
                const index = draft.findIndex(
                  (history) => history.user_id === item.user_id,
                );
                if (index < 0) {
                  return draft;
                }
                if (item.fix_salary === 0) {
                  draft.splice(index, 1);
                  return draft;
                }
                const date = new Date();
                const history = draft[index];
                history.history.unshift({
                  fix_salary: item.fix_salary,
                  start_date: date.toString(),
                });
              });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['FixedSalary'],
    }),

    updateBaseSalary: builder.mutation<
      ConfigHistory[],
      { percent_finance: number }
    >({
      query: (data) => ({
        url: 'base-salary',
        method: 'PUT',
        body: [data],
      }),
    }),

    getFixedSalaryHistory: builder.query<FixedSalaryHistoryRO[], void>({
      providesTags: (_res = [] as FixedSalaryHistoryRO[]) => ['FixedSalary'],
      query: () => 'fix-salary',
      transformResponse: (res: FixedSalaryHistory[]) => {
        return res
          .filter((item) => item.history[0].fix_salary !== 0)
          .map((item) => ({ ...item, key: item.user_id }));
      },
    }),
  }),
});

export const {
  useGetBannersListQuery,
  useGetActiveBannersListQuery,
  useGetBannerDetailQuery,
  useDeleteBannerMutation,
  useUpdateBannerMutation,
  useCreateBannerMutation,
  useUpdateBannerPositionMutation,
  useUpdateBaseSalaryMutation,
  usePutFixedSalaryMutation,
  useGetFixedSalaryHistoryQuery,
} = configureApi;
