import styled from 'styled-components';

export const ComponentContent = styled.div`
  position: relative;
  font-size: 16px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #d2d6de;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  .box-header {
    color: #444;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    border-bottom: 1px solid #f4f4f4;
  }
  .box-body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const BorderBoxContent = styled(ComponentContent)`
  border-color: #3c8dbc;
`;

export const BorderTopBoxContent = styled(ComponentContent)`
  border-top: 3px solid #3c8dbc;
`;

export const HeaderBackgroundContent = styled.div`
  position: relative;
  font-size: 16px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  .box-header {
    color: #444;
    display: block;
    text-transform: uppercase;
    padding: 10px;
    position: relative;
    color: #fff;
    background-color: #00a7d0;
    border-bottom: 1px solid #f4f4f4;
  }
  .box-body {
    padding: 10px;
  }
`;
