import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EditTwoTone,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Empty,
  Form as FormAntd,
  message,
  Modal,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import ServerError from 'components/commons/ServerError';
import LoadingSpinner from 'components/LoadingSpin';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import moment from 'moment';
import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  useSendAdminApprovalMutation,
  useSendAdminCreatedBonusMutation,
} from 'services/report.service';
import styled from 'styled-components';
import { v4 } from 'uuid';
import parser from 'libraries/editor/parser';
import { detailCell } from './BonusRequestTable';
import { useHistory, useLocation } from 'react-router-dom';
import { BonusDetails } from '../../../../../models/report.model';
import { ColumnsType } from 'antd/es/table/interface';
import BonusFormItems from './BonusFormItems';

interface PropsType {
  onChange?: () => void;
  hideTotal?: boolean;
  bonus: BonusDetails[];
  isError: boolean;
  isLoading: boolean;
  userId: string; // id của người dùng, có thể lấy ở trong userinfo
  allowance?: boolean;
}

const Form = styled(FormAntd)`
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
  label {
    font-weight: bold;
    margin: 16px;
  }
  .ant-col.ant-form-item-control {
    justify-content: center;
  }
`;
const ModalStyle = styled(Modal)`
  label {
    margin: 0 !important;
  }
  .ant-form-item {
    margin-bottom: 8px !important;
  }
`;

const deepClone = (item: detailCell[]) => {
  const json = JSON.stringify(item);
  const data = JSON.parse(json);
  return data;
};

const BonusSalaryTable = ({
  bonus,
  onChange,
  hideTotal,
  isError,
  isLoading,
  userId,
  allowance,
}: PropsType): ReactElement => {
  const [form] = FormAntd.useForm();
  const [details, setDetail] = useState([] as detailCell[]);
  const [editingKey, setEditingKey] = useState<React.Key>('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [hoverKey, setHoverKey] = useState<React.Key>('');
  const [isMobile, setIsMobile] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const [sendAdminApproval, { isLoading: isSendLoading }] =
    useSendAdminApprovalMutation();
  const [sendAdminCreatedBonus, { isLoading: isLoadingAdminCreatedBonus }] =
    useSendAdminCreatedBonusMutation();
  //modal state
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  //params
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const bonusId = queryParams.get('bonus');

  //life cycle
  useEffect(() => {
    if (bonusId && details.length) {
      const detail =
        details.find((item) => `${item.id}` === bonusId) ?? ({} as detailCell);
      const key = detail.key;
      seeDetail(key);
    }
  }, [bonusId, details]);

  useLayoutEffect(() => {
    const innerWidth = window.innerWidth;
    if (innerWidth <= 768) setIsMobile(true);
  }, []);

  // useEffect(() => {
  //   onChange && onChange();
  // }, [details]);

  useEffect(() => {
    if (!bonus) return;
    setDetail(() => {
      if (allowance) {
        return bonus.reduce((acc, curr) => {
          if (curr.status !== 'reject') {
            acc.push({ ...curr, key: v4() });
          }
          return acc;
        }, [] as detailCell[]);
      } else {
        return bonus.map((item) => ({ ...item, key: v4() }));
      }
    });
  }, [bonus]);

  //utils
  const find = (key: React.Key): [detailCell, number] => {
    const index = details.findIndex((item) => item.key === key);
    if (index === -1) {
      return [
        {
          bonus: 1000,
          date: moment().toISOString(),
          content: '',
          note: '',
          status: 'pending',
          key: v4(),
          id: 0,
          reason: '',
        },
        -1,
      ];
    }
    return [{ ...details[index] }, index];
  };
  const seeDetail = (key: React.Key) => {
    setEditingKey(key);
    setModalVisible(true);
    setIsReadOnly(true);
  };

  const editingItem = useMemo(
    () => details.find((el) => el.key === editingKey),
    [editingKey],
  );

  const columns: ColumnsType<detailCell> = [
    {
      title: 'Ngày thực hiện',
      dataIndex: 'date',
      // width: '15%',
      render: (_: void, { date }: detailCell) => formatDate(date.toString()),
      responsive: ['lg'],
    },
    {
      // width: '20%',
      title: 'Tên đầu việc',
      dataIndex: 'content',
      render: (_: void, { content, key }: detailCell) => (
        <Typography.Link onClick={() => seeDetail(key)} ellipsis={true}>
          {content}
        </Typography.Link>
      ),
    },
    {
      // width: '13%',
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (_: void, { status }: detailCell) => {
        switch (status) {
          case 'approve':
            return (
              <Tag color="success" icon={<CheckCircleOutlined />}>
                Chấp thuận
              </Tag>
            );
          case 'pending':
            return (
              <Tag color="processing" icon={<SyncOutlined />}>
                Chờ duyệt
              </Tag>
            );
          case 'reject':
            return (
              <Tag color="error" icon={<CloseCircleOutlined />}>
                Từ chối
              </Tag>
            );
        }
      },
      responsive: ['md'],
    },
    {
      title: 'Số tiền (đ)',
      dataIndex: 'bonus',
      // width: '17%',
      render: (_: void, { bonus }: detailCell) => formatMoneyVnd(bonus),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      // width: '20%',
      render: (_: void, { note }: detailCell) => (
        <Tooltip overlay={'Click !'}>
          <Typography.Text ellipsis={true} style={{ cursor: 'pointer' }}>
            {note}
          </Typography.Text>
        </Tooltip>
      ),
      onCell: (record: detailCell) => {
        return {
          onClick: () =>
            setExpandedRowKeys((prev) => {
              const copy = [...prev];
              if (copy[0] === record.key) {
                copy.pop();
              } else copy[0] = record.key;
              return copy;
            }),
        };
      },
      responsive: ['lg'],
    },
    {
      title: 'Thao tác',
      // width: '15%',
      dataIndex: 'action',
      align: 'center',
      render: (_: any, { key, status, bonus }: detailCell) => {
        return status === 'pending' && (hoverKey === key || !isMobile) ? (
          <Space size={'small'} align="center">
            <Button
              onClick={() => {
                handleSave(key, 'approve');
              }}
              onMouseEnter={() => {
                if (editingKey !== key && status === 'pending')
                  setEditingKey(key);
              }}
              onMouseLeave={() => setEditingKey('')}
              shape="circle"
              type="dashed"
              size="small"
            >
              <CheckOutlined />
            </Button>
            <Button
              onClick={() => {
                setEditingKey(key);
                setModalVisible(true);
                setIsReject(true);
                // setIsEdit(true);
              }}
              shape="circle"
              type="dashed"
              size="small"
            >
              <CloseOutlined />
              {/* <CloseCircleTwoTone /> */}
            </Button>
            <Divider type="vertical" />
            <Button
              shape="circle"
              type="dashed"
              onClick={() => {
                setEditingKey(key);
                setModalVisible(true);
                setIsEdit(true);
              }}
              size="small"
            >
              <EditTwoTone />
            </Button>
          </Space>
        ) : status === 'approve' &&
          allowance &&
          (hoverKey === key || !isMobile) ? (
          <Typography.Link
            // onClick={() => handleSave(key, 'approve')}
            type="danger"
          >
            Ngưng hiệu lực
          </Typography.Link>
        ) : status === 'approve' ? (
          <Button
            onClick={() => {
              handleSaveCannel(key, 'reject', bonus);
            }}
          >
            Hủy bỏ
          </Button>
        ) : (
          <></>
        );
      },
    },
  ];

  const onCancel = () => {
    setModalVisible(false);
    setEditingKey('');
    setIsReadOnly(false);
    setIsEdit(false);
    setIsReject(false);
    setIsAdding(false);
    if (queryParams.has('bonus')) {
      queryParams.delete('bonus');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const onOK = () => {
    isAdding
      ? onSaveAdminCreatedBonus()
      : handleSave(editingKey, isReject ? 'reject' : 'approve');
  };

  const handleSave = async (key: React.Key, type: 'approve' | 'reject') => {
    try {
      const copy = deepClone(details);
      const [item, index] = find(key);
      if (item.status !== 'pending') {
        message.error('Không gửi được dữ liệu đối với những đơn đã xử lý xong');
        return;
      }
      const { reason, bonus } = await form.validateFields();

      if (index > -1) {
        copy[index].status = type;
        copy[index].bonus = bonus;
        copy[index].reason = reason;
        sendAdminApproval({
          status: type,
          id: copy[index].id,
          reason: reason ? parser.render(reason) : '',
          bonus: bonus,
        })
          .unwrap()
          .then(() => {
            message.success('Cập nhật thành công');
            setDetail(copy);
            onChange && onChange();
            onCancel();
          })
          .catch((err: any) => {
            message.error(err);
          });
        setModalVisible(false);
      } else {
        message.info('Không tìm thấy thưởng');
      }
    } catch (error) {
      console.log('Xác thực thất bại:', error);
    }
  };
  const handleSaveCannel = async (
    key: React.Key,
    type: 'approve' | 'reject',
    bonus: any,
  ) => {
    try {
      const copy = deepClone(details);
      const [item, index] = find(key);

      const { reason } = await form.validateFields();

      if (index > -1) {
        copy[index].status = 'reject';
        copy[index].bonus = bonus;
        copy[index].reason = reason;

        sendAdminApproval({
          status: 'reject',
          id: copy[index].id,
          reason: reason ? parser.render(reason) : '',
          bonus: bonus,
        })
          .unwrap()
          .then(() => {
            message.success('Cập nhật thành công');
            setDetail(copy);
            onChange && onChange();
            onCancel();
          })
          .catch((err: any) => {
            message.error(err);
          });
        setModalVisible(false);
      } else {
        message.info('Không tìm thấy thưởng');
      }
    } catch (error) {
      console.log('Xác thực thất bại:', error);
    }
  };

  const handleAdd = () => {
    setIsAdding(true);
    setModalVisible(true);
  };

  const onSaveAdminCreatedBonus = async () => {
    try {
      const data = await form.validateFields();
      data.user_id = userId;
      sendAdminCreatedBonus(data)
        .unwrap()
        .then(({ id }) => {
          setDetail((prev) => {
            const copy = deepClone(prev);
            data.status = 'approve';
            data.note = '';
            data.id = id;
            delete data.user_id;
            copy.unshift(data);
            return copy;
          });
          onChange && onChange();
          setModalVisible(false);
          message.success('Tạo thưởng thành công');
        })
        .catch((err) => {
          message.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  if (isError) return <ServerError />;
  else
    return (
      <>
        <div
          style={{
            display: hideTotal ? 'none' : 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <Typography.Title type="success" level={5}>
            Tổng thưởng là:&nbsp;
            {formatMoneyVnd(
              details.reduce((prev, cur) => {
                if (cur.status === 'approve') {
                  prev += cur.bonus;
                }
                return prev;
              }, 0),
            )}
          </Typography.Title>
        </div>
        <Button
          icon={<PlusOutlined />}
          type="dashed"
          disabled={isLoading || isLoadingAdminCreatedBonus || isSendLoading}
          style={{ margin: '16px 0' }}
          onClick={handleAdd}
        >
          Thêm yêu cầu
        </Button>
        <Table
          loading={isLoading || isSendLoading || isLoadingAdminCreatedBonus}
          bordered
          dataSource={details}
          columns={columns}
          rowClassName="editable-row"
          scroll={{ y: 450 }}
          expandable={{
            rowExpandable: (record) =>
              record.note !== '' || record.reason !== '',
            onExpand: (_, record) => {
              setExpandedRowKeys((prev) => {
                const copy = [...prev];
                if (copy[0] === record.key) {
                  copy.pop();
                } else copy[0] = record.key;
                return copy;
              });
            },
            expandedRowKeys: expandedRowKeys,
            expandedRowRender: (record) => {
              return (
                <Form labelCol={{ span: 4 }} layout="horizontal">
                  <Form.Item
                    className={record.note ? 'showMarkdown' : ''}
                    label="Ghi chú"
                  >
                    {record.note ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <ShowMarkdown markdown={record.note} />
                      </div>
                    ) : (
                      <Empty
                        description="Không có ghi chú"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    )}
                  </Form.Item>
                  <Divider />
                  <Form.Item
                    label="Phản hồi"
                    className={record.reason ? 'showMarkdown' : ''}
                  >
                    {record.reason ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <ShowMarkdown markdown={record.reason} />
                      </div>
                    ) : (
                      <Empty
                        description="Hiện chưa có phản hồi từ admin"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    )}
                  </Form.Item>
                </Form>
              );
            },
          }}
          /**
           * todo khi mà sẵn sàng hãy mở lại pagination
           * * những việc cần lưu ý
           * * khi page không phải trang đầu tiên thì khi add item mới vẫn hoạt động nhưng sẽ hiện ở trang đầu tiên gây ra khó hiểu => khi bấm add thêm tự động roll về trang đầu tiên và nếu có thể scroll lên trên cùng
           */
          pagination={false}
          onRow={(record) => {
            return {
              onMouseEnter: () => {
                setHoverKey(record.key);
              },
              onMouseLeave: () => {
                setHoverKey('');
              },
            };
          }}
        />
        <ModalStyle
          width={'60%'}
          visible={isModalVisible}
          keyboard
          onCancel={onCancel}
          zIndex={100}
          title={
            isReadOnly
              ? 'Xem chi tiết'
              : isEdit
                ? 'Chỉnh sửa'
                : isAdding
                  ? 'Thêm thưởng'
                  : 'Từ chối'
          }
          onOk={onOK}
          getContainer={false}
          forceRender={true}
          destroyOnClose={true}
          footer={
            isReadOnly ? null : (
              <Button.Group>
                <Button key="back" type="default" onClick={onCancel}>
                  Hủy
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  danger={isReject}
                  loading={
                    isLoadingAdminCreatedBonus || isLoading || isSendLoading
                  }
                  onClick={onOK}
                >
                  {!isReject ? 'Chấp thuận' : 'Từ chối'}
                </Button>
              </Button.Group>
            )
          }
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              labelAlign="left"
              labelCol={{ span: 4 }}
              preserve={false}
              initialValues={{
                reason: editingItem?.reason ?? '',
                bonus: editingItem?.bonus ?? 0,
                note: editingItem?.note ?? '',
                date: moment(editingItem?.date) ?? moment(),
                content: editingItem?.content ?? '',
              }}
            >
              <BonusFormItems
                editingItem={find(editingKey)}
                isReadOnly={isReadOnly}
                isEdit={isEdit}
                isReject={isReject}
                isAdding={isAdding}
              />
            </Form>
          )}
        </ModalStyle>
      </>
    );
};

export default BonusSalaryTable;
