import { LeftOutlined } from '@ant-design/icons';
import { Col, Input, message, Row, Select } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import Editor from 'libraries/editor';
import parser from 'libraries/editor/parser';
import {
  blogStatusValues,
  postTypeValues,
} from 'libraries/utils/globalVariable';
import { ReactElement, useEffect, useState } from 'react';
import Hotkeys from 'react-hot-keys';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useCreateBlogPostMutation,
  useGetTagsQuery,
} from 'services/blog.service';
import { selectUserRole } from 'store/auth.slice';
import { deleteOneBlogLocalStore } from 'utils/deleteOneBlogLocalStore';
import { updateBlogLocalStore } from 'utils/updateBlogLocalStore';

const { TextArea } = Input;
const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const CreatePostContent = (): ReactElement => {
  const { data: tags } = useGetTagsQuery();
  const roleCheck = useSelector(selectUserRole);
  const [createNewPost] = useCreateBlogPostMutation();
  const history = useHistory();

  const [chosenThumbnail, setChosenThumbnail] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tmpType, setTmpType] = useState('normal');
  const [checkDescription, setCheckDescription] = useState('');
  const [checkTitle, setCheckTitle] = useState('');
  const [checkContent, setCheckContent] = useState('');
  const typeStatuss = ['draft', 'public'];
  const blogLocalStore = JSON.parse(
    window.localStorage.getItem('blog') || '[]',
  );

  const [initValueBlog, setInitValueBlog] = useState({
    title: '',
    content: '',
    description: '',
    // slug: '',
    // thumbnail: '',
    // status: 'draft',
    // post_type: 'normal',
    // tags: [ { id: 'X4kQK0HV', name: 'Quiz' }, { id: '-xT0ORO0', name: 'AWS' } ],
  });

  useEffect(() => {
    const newBlog = blogLocalStore.filter((item: any) => item.id === '');
    if (newBlog.length === 0) return;
    setInitValueBlog((prevState) => ({ ...prevState, ...newBlog[0] }));
    setCheckTitle(newBlog[0].title);
    setCheckContent(newBlog[0].content);
    setCheckDescription(newBlog[0].description);
  }, []);

  const [typeStatus, setTypeStatus] = useState(typeStatuss[0]);
  const handleTypeStatus = (value: string): void => {
    setTypeStatus(value);
  };

  // TODO: cần sửa lại type của values
  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    if (checkTitle === '') return;
    if (checkContent === '') return;

    createNewPost({
      ...values,
      content: parser.render(values.content),
      content_md: values.content,
      title: checkTitle,
      description: checkDescription,
      slug: values.slug !== undefined ? values.slug : '',
      tag_ids: values.tag_ids ? values.tag_ids : ([] as string[]),
      thumbnail: values.thumbnail ? values.thumbnail : '',
    })
      .unwrap()
      .then((res) => {
        message.success('Tạo bài viết mới thành công!');
        deleteOneBlogLocalStore(blogLocalStore, '');
        setTimeout(() => {
          history.push(
            generatePath(route.blog.detail, { postId: res.post_id }),
          );
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handlersHotkeys = (e: KeyboardEvent) => {
    e.preventDefault();
    handleSubmit(form.getFieldsValue());
  };
  const handleCheckSubmit = () => {
    if (checkTitle === '' && initValueBlog.title === '') {
      message.error('Xin vui lòng nhập tiêu đề bài viết!');
      return;
    }
    if (checkContent === '') {
      message.error('Xin vui lòng nhập nội dung bài viết!');
      return;
    }
    if (checkDescription === '' && typeStatus === 'public') {
      message.error('Xin vui lòng nhập mô tả bài viết!');
    } else if (checkDescription?.length < 50 && typeStatus === 'public') {
      message.error('Nội dung phần mô tả ngắn phải tối thiểu 50 ký tự!');
    } else if (chosenThumbnail === '' && typeStatus === 'public') {
      message.error('Bạn cần chọn ảnh thumbnail trước khi công khai bài viết');
    }
  };

  const [form] = Form.useForm();

  const handleChangeContent = (value: string) => {
    setCheckContent(value);
    form.setFieldsValue({
      content: value,
    });
    if (value.trim() === '') return;
    updateBlogLocalStore(blogLocalStore, '', {
      content: value,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      thumbnail: chosenThumbnail,
    });
  }, [chosenThumbnail, form]);

  return (
    <>
      <Breadcrumb
        secondaryLink={
          roleCheck.isFullRole
            ? generatePath(route.blog.list)
            : generatePath(route.blog.own)
        }
        secondaryText="Blog"
        text="Tạo bài viết"
      />
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            status: 'draft',
            post_type: 'normal',
          }}
        >
          <LinksContainer>
            <Link.Default to={generatePath(route.blog.list)}>
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
              onClick={handleCheckSubmit}
            >
              Lưu
            </Button.Primary>
          </LinksContainer>
          <BorderTopBoxContent>
            <Row gutter={[30, 30]}>
              <Col xs={24} md={16}>
                <Form.Item
                  label={
                    <FormLabel
                      label="Tiêu đề"
                      smallText="(Độ dài cho phép 1-200 kí tự)"
                    />
                  }
                  name="title"
                  rules={[
                    {
                      required: false,
                      message: 'Xin vui lòng nhập tiêu đề bài viết!',
                    },
                  ]}
                >
                  {initValueBlog.title ? (
                    <Input
                      maxLength={200}
                      defaultValue={initValueBlog.title}
                      onChange={(e) => {
                        setCheckTitle(e.target.value);
                        updateBlogLocalStore(blogLocalStore, '', {
                          title: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <div>
                      <Input
                        maxLength={200}
                        defaultValue={initValueBlog.title}
                        onChange={(e) => {
                          setCheckTitle(e.target.value);
                          updateBlogLocalStore(blogLocalStore, '', {
                            title: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    <FormLabel
                      label="Nội dung"
                      smallText="(Không sử dụng Heading cấp 1 - #)"
                    />
                  }
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập nội dung bài viết!',
                    },
                  ]}
                >
                  {/*  đoạn code:   {''}  là để sửa lỗi không nhận giá trị ban đầu Editor */}

                  {''}

                  {/*  */}
                  {initValueBlog.content ? (
                    <Editor
                      height={650}
                      onChange={handleChangeContent}
                      value={initValueBlog.content}
                    />
                  ) : (
                    <div>
                      <Editor
                        height={650}
                        onChange={handleChangeContent}
                        value={initValueBlog.content}
                      />
                    </div>
                  )}
                </Form.Item>

                {typeStatus !== 'draft' ? (
                  <Form.Item
                    name="description"
                    label={
                      <FormLabel
                        label="Mô tả ngắn"
                        smallText="(Độ dài cho phép: tối thiểu 50 kí tự)"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng nhập mô tả bài viết!',
                      },
                      {
                        min: 50,
                        message:
                          'Nội dung phần mô tả ngắn phải tối thiểu 50 ký tự!',
                      },
                    ]}
                  >
                    {initValueBlog.description ? (
                      <TextArea
                        rows={3}
                        showCount
                        defaultValue="1"
                        onChange={(e) => {
                          setCheckDescription(e.target.value);
                          if (e.target.value.trim() !== '') {
                            updateBlogLocalStore(blogLocalStore, '', {
                              description: e.target.value,
                            });
                          }
                        }}
                      />
                    ) : (
                      <div>
                        <TextArea
                          rows={3}
                          showCount
                          defaultValue="1"
                          onChange={(e) => {
                            setCheckDescription(e.target.value);
                            updateBlogLocalStore(blogLocalStore, '', {
                              description: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="description"
                    label={
                      <FormLabel
                        label="Mô tả ngắn"
                        smallText="(Độ dài cho phép: tối thiểu 50 kí tự)"
                      />
                    }
                  >
                    {initValueBlog.description ? (
                      <TextArea
                        rows={3}
                        showCount
                        defaultValue={initValueBlog.description}
                        onChange={(e) => {
                          setCheckDescription(e.target.value);
                          updateBlogLocalStore(blogLocalStore, '', {
                            description: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <div>
                        <TextArea
                          rows={3}
                          showCount
                          defaultValue={initValueBlog.description}
                          onChange={(e) => {
                            setCheckDescription(e.target.value);
                            updateBlogLocalStore(blogLocalStore, '', {
                              description: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<FormLabel label="Trạng thái" />}
                  name="status"
                >
                  <Select onChange={handleTypeStatus}>
                    {blogStatusValues.map((item, index) => (
                      <Option value={item.value} key={index}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Loại bài viết" />}
                  name="post_type"
                >
                  <Select
                    disabled={roleCheck.isFullRole ? false : true}
                    onChange={(value: string) => setTmpType(value)}
                  >
                    {postTypeValues.map((item, index) => (
                      <Option value={item.value} key={index}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {tmpType !== 'special' && (
                  <>
                    {tags && (
                      <Form.Item
                        label={<FormLabel label="Danh mục" require={false} />}
                        name="tag_ids"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                        >
                          {tags.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      label=""
                      name="thumbnail"
                      rules={
                        typeStatus !== 'draft'
                          ? [
                              {
                                required: true,
                                message: 'Xin vui lòng chọn ảnh bài viết!',
                              },
                            ]
                          : undefined
                      }
                    >
                      {chosenThumbnail !== '' && (
                        <img
                          style={{ width: '100%', marginBottom: '20px' }}
                          src={chosenThumbnail}
                          alt="Thumbnail"
                        />
                      )}
                      <div>
                        <Button.Info onClick={() => setIsModalVisible(true)}>
                          Chọn ảnh bài viết
                        </Button.Info>
                        <p>
                          <em>
                            Chiều rộng: Từ 600px đến 1000px. Tỉ lệ ảnh 1.4. Định
                            dạng jpg hoặc png
                          </em>
                        </p>
                      </div>
                    </Form.Item>
                  </>
                )}
              </Col>
            </Row>
          </BorderTopBoxContent>
        </Form>
      </Hotkeys>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setChosenThumbnail}
        />
      )}
    </>
  );
};
export default CreatePostContent;
