import { Moment } from 'moment';
import { ReactElement } from 'react';
import Button from '../../../../libraries/components/commons/Button';

interface PropsType {
  firstDay: Moment;
  lastDay: Moment;
}

const ExportExcel = ({ firstDay, lastDay }: PropsType): ReactElement => {
  return (
    <Button.Primary>
      <a
        href={`${
          process.env.REACT_APP_API_DOMAIN
        }teachers-salary/excel?from=${firstDay.toISOString()}&to=${lastDay.toISOString()}`}
        download
      >
        Xuất Excel
      </a>
    </Button.Primary>
  );
};

export default ExportExcel;
