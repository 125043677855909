import { createApi } from '@reduxjs/toolkit/query/react';
import { baseLogin } from './base.service';
import { Credentials, ProfileProps } from '../models/user.model';

export interface UpdateProfileProps {
  phone: string;
  bank_account: string;
  bank_name: string;
  description: string;
  full_name: string;
  avatar: string;
}

export interface ChangePasswordProps {
  password: string;
  new_password: string;
  confirm_password: string;
}

export const authApi = createApi({
  reducerPath: 'authApi',

  baseQuery: baseLogin,

  endpoints: (builder) => ({
    login: builder.mutation<ProfileProps, Credentials>({
      query: (data: Credentials) => ({
        url: `login`,
        method: 'POST',
        body: data,
      }),
    }),

    logout: builder.mutation<undefined, void>({
      query: () => ({
        url: `logout`,
        method: 'GET',
      }),
    }),

    getLoggedInUser: builder.query<ProfileProps, void>({
      query: () => `profile`,
    }),

    updateInformation: builder.mutation<ProfileProps, UpdateProfileProps>({
      query: (data) => {
        return {
          url: `update-profile`,
          method: 'POST',
          body: data,
        };
      },
    }),

    changePassword: builder.mutation<void, ChangePasswordProps>({
      query: (data) => {
        return {
          url: `change-password`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useGetLoggedInUserQuery,
  useLogoutMutation,
  useUpdateInformationMutation,
  useChangePasswordMutation,
} = authApi;
