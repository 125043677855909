import { ReactElement, useEffect, useState } from 'react';
import { Input, Pagination, Table, Select } from 'antd';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';

import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { Project } from 'models/project.model';
import { projectClassValues } from 'libraries/utils/globalVariable';

interface KeySearchType {
  title: string;
  student_name: string;
  className: string;
  status: '' | 'true' | 'false';
  order: 'student_name' | 'title' | 'class' | '';
  direction: 'desc' | 'asc';
}

interface PropsType {
  projects: Project[];
  total_page: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  setKeyword: (value: string) => void;
  state: KeySearchType;
  handleSetState: (field: string, value: string) => void;
  handleOrderList: (
    order: 'student_name' | 'title' | 'class' | '',
    direction: 'asc' | 'desc',
  ) => void;
}

const { Option } = Select;

const ProjectsTable = ({
  projects,
  total_page,
  currentPage,
  setCurrentPage,
  setKeyword,
  state,
  handleSetState,
  handleOrderList,
}: PropsType): ReactElement => {
  const [tmpTitle, setTmpTitle] = useState('');
  const [tmpName, setTmpName] = useState('');

  const { title, student_name, className, status, order, direction } = state;

  const handleOnchangePage = (page: number) => {
    setCurrentPage(page);
    setKeyword(
      `?page=${page}&title=${title}&student_name=${student_name}&class=${className}&status=${status}&order=${order}&direction=${direction}`,
    );
  };

  const columns: ColumnsType<Project> = [
    {
      width: '30%',
      sorter: true,
      showSorterTooltip: false,
      title: (
        <div
          onClick={() => {
            handleOrderList('title', direction === 'desc' ? 'asc' : 'desc');
          }}
        >
          Tên đồ án
        </div>
      ),
      children: [
        {
          key: 'title',
          dataIndex: 'title',
          title: (
            <Input
              value={tmpTitle}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('title', tmpTitle);
                }
              }}
              onChange={(e) => setTmpTitle(e.target.value)}
            />
          ),
          render: (_text, item) => (
            <RouterLink
              to={generatePath(route.project.detail, {
                projectId: item.id,
              })}
            >
              {item.title}
            </RouterLink>
          ),
        },
      ],
    },
    {
      width: '25%',
      sorter: true,
      showSorterTooltip: false,
      title: (
        <div
          onClick={() => {
            handleOrderList(
              'student_name',
              direction === 'desc' ? 'asc' : 'desc',
            );
          }}
        >
          Học viên
        </div>
      ),
      children: [
        {
          key: 'student_name',
          dataIndex: 'student_name',
          title: (
            <Input
              value={tmpName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('student_name', tmpName);
                }
              }}
              onChange={(e) => setTmpName(e.target.value)}
            />
          ),
        },
      ],
    },
    {
      width: '25%',
      title: 'Trạng thái',
      children: [
        {
          key: 'status',
          dataIndex: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={status}
              onChange={(value) => handleSetState('status', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              <Option value="true">Công khai</Option>
              <Option value="false">Ẩn</Option>
            </Select>
          ),
          render: (status) => (status ? 'Công khai' : 'Ẩn'),
        },
      ],
    },
    {
      width: '20%',
      sorter: true,
      showSorterTooltip: false,
      title: (
        <div
          onClick={() => {
            handleOrderList('class', direction === 'desc' ? 'asc' : 'desc');
          }}
        >
          Lớp
        </div>
      ),
      children: [
        {
          key: 'class',
          dataIndex: 'class',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={className}
              onChange={(value) => handleSetState('className', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              {projectClassValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (_text, item) => {
            for (let i = 0; i < projectClassValues.length; i++) {
              const e = projectClassValues[i];
              if (e.value === item.class) {
                return e.label;
              }
            }
            return '';
          },
        },
      ],
    },
  ];

  useEffect(() => {
    const { title, student_name, className, status, order, direction } = state;
    setCurrentPage(1);
    setKeyword(
      `?page=1&title=${title}&student_name=${student_name}&class=${className}&status=${status}&order=${order}&direction=${direction}`,
    );
    if (title === '') setTmpTitle('');
    if (student_name === '') setTmpName('');
  }, [state]);

  return (
    <>
      <TableContainer>
        <Table<Project>
          columns={columns}
          dataSource={projects}
          pagination={false}
          rowKey="id"
          bordered
        />
      </TableContainer>
      <Pagination
        current={currentPage}
        pageSize={10}
        showSizeChanger={false}
        defaultCurrent={currentPage}
        total={total_page * 10}
        style={{ marginTop: '20px' }}
        onChange={handleOnchangePage}
      />
    </>
  );
};
export default ProjectsTable;
