import { ReactElement, Key, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Menu as AntMenu, MenuProps } from 'antd';

import menu from 'routers/menu';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';
import { checkUserRoleInMenu } from 'utils/checkUserRole';
import styled from 'styled-components';

const MenuWrapper = styled(AntMenu)`
  &&& {
    height: calc(100vh - 60px);
    color: #b8c7ce;
    background-color: #222d32;

    .ant-menu-submenu-arrow,
    .ant-menu-submenu-expand-icon {
      color: #fff;
    }
    li {
      margin-top: 0;
      margin-bottom: 0;
    }
    & > .ant-menu-item,
    & > .ant-menu-submenu .ant-menu-submenu-title {
      border-left: 3px solid transparent;
      margin-top: 0;
      margin-bottom: 0;
    }
    & > .ant-menu-item.ant-menu-item-selected,
    & > .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title {
      border-left-color: #3c8dbc;
    }
    .ant-menu-submenu-title:hover {
      background-color: #1e282c;
      span {
        color: #fff;
      }
    }
  }
`;

const SubMenu = styled(AntMenu.SubMenu)`
  &&& {
    &.ant-menu-submenu-selected {
      color: #fff;
    }
    .ant-menu-sub.ant-menu-inline {
      color: #fff;
      background-color: #2c3b41;
    }
  }
`;

const Item = styled(AntMenu.Item)`
  &&& {
    a {
      color: #8aa4af;
    }
    &.ant-menu-item-selected {
      background-color: #3c8dbc;
      a,
      .ant-menu-item-icon {
        color: #fff;
      }
    }
  }
`;

const Menu = (props: MenuProps): ReactElement => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([
    location.pathname.split('/')[1],
  ]);

  const user = useSelector(selectUser);

  const onOpenChange = (keys: Key[]) => {
    const lastestOpenKeys = keys.find(
      (key) => openKeys.indexOf(key.toString()) === -1,
    );
    setOpenKeys(lastestOpenKeys ? [lastestOpenKeys.toString()] : []);
  };

  return (
    <MenuWrapper
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={[location.pathname]}
      mode="inline"
      inlineCollapsed={props.inlineCollapsed}
      onSelect={props.onSelect}
    >
      {menu.map(({ href, label, icon: Icon, submenu, neededRoles }) => {
        if (submenu) {
          if (neededRoles) {
            if (checkUserRoleInMenu(user.roles, neededRoles)) {
              return (
                <SubMenu
                  key={href}
                  title={label}
                  icon={Icon ? <Icon style={{ fontSize: 22 }} /> : null}
                >
                  {submenu.map((item) => {
                    if (item.neededRoles) {
                      if (checkUserRoleInMenu(user.roles, item.neededRoles)) {
                        return (
                          <Item key={item.href}>
                            <Link to={item.href}>{item.label}</Link>
                          </Item>
                        );
                      }
                    } else {
                      return (
                        <Item key={item.href}>
                          <Link to={item.href}>{item.label}</Link>
                        </Item>
                      );
                    }
                  })}
                </SubMenu>
              );
            }
          } else {
            return (
              <SubMenu
                key={href}
                title={label}
                icon={Icon ? <Icon style={{ fontSize: 22 }} /> : null}
              >
                {submenu.map((item) => {
                  if (item.neededRoles) {
                    if (checkUserRoleInMenu(user.roles, item.neededRoles)) {
                      return (
                        <Item key={item.href}>
                          <Link to={item.href}>{item.label}</Link>
                        </Item>
                      );
                    }
                  } else {
                    return (
                      <Item key={item.href}>
                        <Link to={item.href}>{item.label}</Link>
                      </Item>
                    );
                  }
                })}
              </SubMenu>
            );
          }
        } else {
          if (neededRoles) {
            if (checkUserRoleInMenu(user.roles, neededRoles)) {
              return (
                <Item
                  key={href}
                  icon={Icon ? <Icon style={{ fontSize: 22 }} /> : null}
                >
                  <Link to={href}>{label}</Link>
                </Item>
              );
            }
          } else {
            return (
              <Item
                key={href}
                icon={Icon ? <Icon style={{ fontSize: 22 }} /> : null}
              >
                <Link to={href}>{label}</Link>
              </Item>
            );
          }
        }
      })}
    </MenuWrapper>
  );
};

export default Menu;
