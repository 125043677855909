import { ReactElement } from 'react';

interface PropsType {
  label: string;
  smallText?: string;
  require: boolean;
}

const FormLabel = ({ label, smallText, require }: PropsType): ReactElement => {
  return (
    <>
      <span>{label}</span>
      {require && (
        <>
          <span style={{ color: 'red' }}>*</span>&nbsp;
        </>
      )}
      {smallText !== '' && (
        <span style={{ fontSize: '14px' }}>
          <em>{smallText}</em>
        </span>
      )}
    </>
  );
};

FormLabel.defaultProps = {
  require: true,
  smallText: '',
};

export default FormLabel;
