import { LeftOutlined } from '@ant-design/icons';
import { Col, Input, message, Row, Select } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import { projectClassValues } from 'libraries/utils/globalVariable';
import { ReactElement, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useCreateProjectMutation } from 'services/project.service';

const { Option } = Select;

const CreateProjectContent = (): ReactElement => {
  const [createProject] = useCreateProjectMutation();

  const history = useHistory();

  // eslint-disable-next-line
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [thumbnail, setThumbnail] = useState('');

  const [form] = Form.useForm();

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    createProject({
      ...values,
      thumbnail,
      status: values.status === 'true' ? true : false,
    })
      .unwrap()
      .then(() => {
        message.success('Đăng sản phẩm học viên thành công');
        setTimeout(() => {
          history.push(generatePath(route.project.list));
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      thumbnail: thumbnail,
    });
  }, [thumbnail]);

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.project.list)}
        secondaryText="Sản phẩm học viên"
        text="Đăng sản phẩm"
      />
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ class: 'web', status: 'true' }}
      >
        <LinksContainer>
          <Link.Default to={generatePath(route.project.list)}>
            <LeftOutlined /> Quay lại
          </Link.Default>
          <Button.Primary
            type="primary"
            htmlType="submit"
            style={{ height: '36px' }}
          >
            Lưu
          </Button.Primary>
        </LinksContainer>

        <Row>
          <Col xs={24} md={16} lg={10}>
            <BorderTopBoxContent>
              <Form.Item
                label={<FormLabel label="Sản phẩm lớp" require={false} />}
                name="class"
              >
                <Select>
                  {projectClassValues.map((item, index) => (
                    <Option value={item.value} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Tên sản phẩm" />}
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên sản phẩm!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Link sản phẩm" />}
                name="link"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên link sản phẩm!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Trạng thái" require={false} />}
                name="status"
              >
                <Select>
                  <Option value="true">Công khai</Option>
                  <Option value="false">Ẩn</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Tên học viên" />}
                name="student_name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên học viên!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={<FormLabel label="Ảnh thumbnail" />}
                name="thumbnail"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn ảnh thumbnail!',
                  },
                ]}
                extra="Kích thước ảnh: 400x300px. Định dạng jpg hoặc png."
              >
                {thumbnail !== '' && (
                  <img
                    style={{ width: '100%', marginBottom: '20px' }}
                    src={thumbnail}
                  />
                )}
              </Form.Item>
              <div>
                <Button.Info onClick={() => setIsModalVisible(true)}>
                  Chọn ảnh
                </Button.Info>
              </div>
            </BorderTopBoxContent>
          </Col>
        </Row>
      </Form>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setThumbnail}
        />
      )}
    </>
  );
};

export default CreateProjectContent;
