import { ReactElement } from 'react';

import { GeneralInfo } from 'models/dashboard.model';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import SummaryAdvisory from './SummaryAdvisory';
import ClassesTable from './ClassesTable';

interface PropsType {
  data: GeneralInfo;
}

const AdvisoryComponent = ({ data }: PropsType): ReactElement => {
  return (
    <BorderTopBoxContent>
      <div className="box-header">Tiến độ tuyển sinh</div>
      <div className="box-body">
        <SummaryAdvisory data={data} />
        <ClassesTable items={data.admission_classes} />
      </div>
    </BorderTopBoxContent>
  );
};

export default AdvisoryComponent;
