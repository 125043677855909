import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetScheduleInfoQuery,
  useGetTracksListQuery,
} from 'services/schedule.service';

import ScheduleDetailContent from './ScheduleDetailContent';

import NotFound from 'components/commons/NotFound';
import { Schedule } from 'models/schedule.model';
import { useGetListCoursesQuery } from 'services/classes.service';
import { Course } from 'models/course.model';
import { TrackList } from 'models/track.model';

const GetSchedule = (): ReactElement => {
  const { itemId, itemType, startDate } = useParams<{
    itemId: string;
    itemType: string;
    startDate: string;
  }>();

  const { data, isError } = useGetScheduleInfoQuery({
    item_id: itemId,
    type: itemType,
    start_date: startDate,
  });

  const { data: courses } = useGetListCoursesQuery();
  const { data: tracks } = useGetTracksListQuery();

  const schedule = data ?? ({} as Schedule);

  if (isError) {
    return <NotFound subTitle="Không tìm thấy thông tin lịch khai giảng này" />;
  }

  return (
    <>
      {schedule.item_id && (
        <ScheduleDetailContent
          schedule={schedule}
          courses={courses ? courses : ([] as Course[])}
          tracks={tracks ? tracks : ([] as TrackList[])}
        />
      )}
    </>
  );
};

export default GetSchedule;
