import Breadcrumb from 'components/commons/Breadcrumb';
import ShowServerError from 'components/commons/ShowServerError';
import { Banner } from 'models/banner.model';
import { ReactElement } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetBannerDetailQuery } from 'services/configure.service';
import BannerDetailContent from './BannerDetailContent';

const GetBanner = (): ReactElement => {
  const { bannerId } = useParams<{ bannerId: string }>();

  const { data, isError, error } = useGetBannerDetailQuery(bannerId);

  const banner = data ?? ({} as Banner);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.configure.banner.list)}
        secondaryText="Banner trang chủ"
        text="Chỉnh sửa banner"
      />
      {banner.id && <BannerDetailContent {...banner} />}
    </>
  );
};

export default GetBanner;
