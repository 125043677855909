import {
  DeleteFilled,
  ExclamationCircleFilled,
  LeftOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Breadcrumb from 'components/commons/Breadcrumb';
import { ComponentContent } from 'components/commons/ComponentContent';
import Tabs from 'components/commons/Tabs';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useDeleteClassMutation } from 'services/classes.service';
import { selectClass, selectClassStudents } from 'store/classes.slice';
import styled from 'styled-components';
import LoadingSpin from '../../../libraries/components/LoadingSpin';
import useQuery from '../../../libraries/hooks/query';
import ClassAttendances from './attendance/ClassAttendances';
import ClassInfoContent from './info/ClassInfoContent';
import Salary from './salary/Salary';
import StudentsDetailMobile from './student/StudentsDetailMobile';
import StudentsDetailTable from './student/StudentsDetailTable';

import StudentsTable from './student/StudentsTable';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { TabPane } = Tabs;

const ClassDetailContent = (): ReactElement => {
  const classroom = useSelector(selectClass);
  const students = useSelector(selectClassStudents);
  const [deleteClass] = useDeleteClassMutation();
  const history = useHistory();
  const searchParams = useQuery();
  const activeKey = useQuery().get('tab') ?? '2';
  const onChange = (activeKey: string) => {
    searchParams.set('tab', activeKey);
    history.replace({ search: searchParams.toString() });
  };

  if (!Object.keys(classroom).length) return <LoadingSpin />;

  const showDeleteConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn xoá lớp học này?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Xác nhận',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deleteClass(classroom.id)
          .unwrap()
          .then(() => {
            message.success('Xoá lớp học thành công!');
            setTimeout(() => {
              history.push(generatePath(route.class.list));
            }, 700);
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.class.list)}
        secondaryText="Lớp học"
        text={classroom.name}
      />
      <GroupButton>
        <LinksContainer>
          <Link.Default to={generatePath(route.class.list)}>
            <LeftOutlined /> Quay lại
          </Link.Default>
          {/* <Link.Primary to="#">Điểm danh</Link.Primary> */}
        </LinksContainer>
        {students.length === 0 && (
          <LinksContainer>
            <Link.Danger to="#" onClick={showDeleteConfirm}>
              <DeleteFilled /> Xoá lớp
            </Link.Danger>
          </LinksContainer>
        )}
      </GroupButton>

      <ComponentContent>
        <Tabs activeKey={activeKey} onChange={onChange}>
          <TabPane tab="Thông tin chung" key="1">
            <ClassInfoContent classroom={classroom} />
          </TabPane>

          <TabPane tab="Học viên" key="2">
            <StudentsTable />
          </TabPane>
          <TabPane tab="Thông tin học viên" key="3">
            {window.innerWidth > 768 ? (
              <StudentsDetailTable />
            ) : (
              <StudentsDetailMobile />
            )}
          </TabPane>

          <TabPane tab="Thông báo" key="4"></TabPane>

          <TabPane tab="Điểm danh" key="5">
            <ClassAttendances />
          </TabPane>

          <TabPane tab="Lương dạy" key="6">
            <Salary />
          </TabPane>
        </Tabs>
      </ComponentContent>
    </>
  );
};
export default ClassDetailContent;
