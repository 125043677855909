import { ExclamationCircleFilled } from '@ant-design/icons';
import { message, Popover, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import type { Key } from 'antd/lib/table/interface';
import { ColumnType } from 'antd/lib/table';
import Container from 'components/table/TableContainer';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Span from 'libraries/components/commons/Span';
import { formatMoney } from 'libraries/utils/changeFormatData';
import { studentStatusValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { Student } from 'models/user.model';
import { ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generatePath, Link as RouterLink, Redirect } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useDelayStudentFromClassMutation,
  useGetAllStudentsClassQuery,
  usePatchNoteEditMutation,
  useRemovedStudentFromClassMutation,
  useReturnStudentToClassMutation,
} from 'services/classes.service';
import { selectClass, updateStudentStatus } from 'store/classes.slice';
import { selectOrder } from 'store/order.slice';
import styled from 'styled-components';
import AddStudentToClass from './AddStudentToClass';
import CreateOrder from './CreateOrder';
import MoveStudentToAnotherClass from './MoveStudentToAnotherClass';
import StudentInfo from './StudentInfo';
import { EditableCell, EditableRow } from './Editable';
import {
  downloadExcelFile,
  exportSingleSheet,
} from '../../../../libraries/utils/excel';
import { currDate } from '../../../../libraries/utils/datetime';
import { selectUser } from 'store/auth.slice';

export const NoteWrapper = styled.div`
  word-wrap: 'break-word';
  word-break: 'break-word';
  .anticon-edit {
    position: absolute;
    font-size: 20px;
    right: 4px;
    top: 6px;
    padding: 2px 4px;
    cursor: pointer;
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    display: none;
  }
  &:hover .anticon-edit {
    display: block;
  }
`;

const TableContainer = styled(Container)`
  .ant-table-wrapper {
    overflow: auto;
  }
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  .editable-cell-value-wrap {
    padding: 4px 11px;
    border-radius: 2px;
    min-height: 28px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
`;

type CustomColumnType<T> = ColumnType<T> & {
  editable?: boolean;
  dataIndex?: keyof T;
};

const StudentsTable = (): ReactElement => {
  const dispatch = useDispatch();
  const { classId } = useParams<{ classId: string }>();
  const { name: className, status: classStatus } = useSelector(selectClass);
  const { id: orderId } = useSelector(selectOrder);
  const { full_name, email } = useSelector(selectUser);

  const linkCurriculum =
    process.env.REACT_APP_TEACHER_URL + 'classroom/' + classId + '/subgroups';

  const [removedStudentFromClass] = useRemovedStudentFromClassMutation();
  const [delayStudent] = useDelayStudentFromClassMutation();
  const [returnStudentToClass] = useReturnStudentToClassMutation();
  const { data: students = [] as Student[], isFetching: isStudentsFetching } =
    useGetAllStudentsClassQuery(classId);
  const [, { isLoading: isPatchLoading }] = usePatchNoteEditMutation();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<Student>(
    {} as Student,
  );
  const [isPendingModalVisible, setIsPendingModalVisible] = useState(false);
  const [isAddingModalVisible, setIsAddingModalVisible] = useState(false);
  const [isMoveStudentModalVisible, setIsMoveStudentModalVisible] =
    useState(false);

  const [isCreateOrderModalVisible, setIsCreateOrderModalVisible] =
    useState(false);

  const [studentCreateOrder, setStudentCreateOrder] = useState<Student>(
    {} as Student,
  );

  const totalPaid = students.reduce(
    (prev, current: Student) => prev + current.paid,
    0,
  );
  const totalDebt = students.reduce(
    (prev, current: Student) => prev + current.debt,
    0,
  );
  const TotalPaid = useCallback((money: string, type: 'paid' | 'debt') => {
    return <Total className={type === 'paid' ? 'paid' : 'debt'}>{money}</Total>;
  }, []);

  const exportToExcel = () => {
    const info = {
      wsName: `Danh sách học viên khóa ${className.replace(/[*?:\/\\\[\]]/g, '')}`,
      creator: full_name,
      email: email,
      date: currDate(),
    };

    let header = [
      'STT',
      'Họ tên',
      'Email',
      'SĐT',
      'Trạng thái',
      'Ngày nhập học',
      'Ngày kết thúc',
      'Ghi chú',
      'Đã nộp',
      'Còn nợ',
      'Tổng tiền ĐH',
      'Mã đơn hàng',
    ];

    const data = students.map((student, index) => [
      index + 1,
      student.full_name,
      student.email,
      student.phone,
      studentStatusValues.find((item) => item.value === student.status)
        ?.label || '',
      student.join_date ? formatDate(student.join_date) : '',
      student.end_date ? formatDate(student.end_date) : '',
      student.note,
      student.paid !== 0 ? formatMoney(student.paid) : '',
      student.debt !== 0 ? formatMoney(student.debt) : '',
      student.total_money !== 0 ? formatMoney(student.total_money) : '',
      student.order_id,
    ]);

    const workbook = exportSingleSheet([header, ...data], info);

    downloadExcelFile(workbook, info.wsName);
  };

  const defaultColumns: CustomColumnType<Student>[] = [
    {
      key: 'index',
      width: '3%',
      title: 'STT',
      render: (_, __, index) => index + 1,
    },

    {
      key: 'full_name',
      width: '13%',
      title: 'Họ tên',
      dataIndex: 'full_name',
      render: (_text, item) => {
        return (
          <Popover placement="topLeft" content={<StudentInfo student={item} />}>
            <RouterLink
              to={generatePath(route.class.student, {
                classId: classId,
                studentId: item.id,
                orderId: item.order_id,
              })}
            >
              {item.full_name}
            </RouterLink>
          </Popover>
        );
      },
    },

    {
      key: 'status',
      width: '6%',
      title: 'Trạng thái',

      dataIndex: 'status',
      render: (status) => {
        if (status !== '') {
          for (let i = 0; i < studentStatusValues.length; i++) {
            const item = studentStatusValues[i];
            if (item.value === status) {
              return <Span className={item.className}>{item.label}</Span>;
            }
          }
        } else {
          return '';
        }
      },
    },

    {
      key: 'join_date',
      width: '6%',
      title: 'Nhập học',
      dataIndex: 'join_date',
      render: (join_date) =>
        join_date !== '' ? formatDate(join_date, 'dd-MM-yyyy') : '',
    },

    {
      key: 'end_date',
      width: '6%',
      title: 'Kết thúc',
      dataIndex: 'end_date',
      render: (end_date) =>
        end_date !== '' ? formatDate(end_date, 'dd-MM-yyyy') : '',
    },

    {
      key: 'note',
      width: '6%',
      title: 'Ghi chú học viên',
      dataIndex: 'note',
      editable: true,
      render: (_text, item) => item.note,
    },

    {
      key: 'paid',
      width: '6%',
      title: <>Đã nộp = {TotalPaid(formatMoney(totalPaid), 'paid')}</>,
      dataIndex: 'paid',
      render: (paid) => (paid !== 0 ? formatMoney(paid) : ''),
    },

    {
      key: 'debt',
      width: '6%',
      title: <>Còn nợ= {TotalPaid(formatMoney(totalDebt), 'debt')}</>,
      dataIndex: 'debt',
      render: (debt) => (debt !== 0 ? formatMoney(debt) : ''),
    },
    {
      key: 'total_money',
      width: '7%',
      title: 'Tổng tiền ĐH',
      dataIndex: 'total_money',
      render: (total_money) =>
        total_money !== 0 ? formatMoney(total_money) : '',
    },

    {
      key: 'order_id',
      width: '7%',
      title: 'Mã đơn hàng',
      dataIndex: 'order_id',
      render: (_text, item) => {
        if (item.order_id === 0 || item.status === 'move') return '';
        return (
          <RouterLink
            to={generatePath(route.order.detail, {
              orderId: item.order_id,
            })}
          >
            {item.order_id}
          </RouterLink>
        );
      },
    },
    {
      key: 'type',
      title: 'Hình thức',
      width: '6%',
      dataIndex: 'type',
      render: (type) => (type === 'live' ? 'Trực tuyến' : 'Onlab'),
      editable: true,
    },

    {
      key: 'action',
      width: '6%',
      title: '',
      render: (_, item) => {
        if (item.status === 'pending') {
          return (
            <Link.Default
              to={'#'}
              onClick={() => {
                setIsCreateOrderModalVisible(true);
                setStudentCreateOrder(item);
              }}
            >
              Tạo đơn
            </Link.Default>
          );
        }
      },
    },
  ];

  const columns: ColumnType<Student>[] = defaultColumns.map((col) => {
    if (!col.editable) {
      return col as ColumnType<Student>;
    }

    return {
      ...col,
      onCell: (record: Student) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title as string,
        classId,
      }),
    } as ColumnType<Student>;
  });

  const handleReturnStudentsClick = () => {
    returnStudentToClass({ class_id: classId, student_id: selectedStudent.id })
      .unwrap()
      .then((res) => {
        dispatch(
          updateStudentStatus({ id: selectedStudent.id, status: 'active' }),
        );
        setSelectedStudent({} as Student);
        setSelectedRowKeys([] as Key[]);
        message.success(res);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleDeleteStudent = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn xóa học viên khỏi lớp?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Xác nhận',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        removedStudentFromClass({
          class_id: classId,
          student_id: selectedStudent.id,
          data: {
            note: selectedStudent.note,
          },
        })
          .unwrap()
          .then(() => {
            setSelectedStudent({} as Student);
            setSelectedRowKeys([] as Key[]);
            message.success('Xóa học viên khỏi lớp thành công');
          })
          .catch((err) => {
            message.error(err);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onSelectChange = (selected: Key[], selectedRows: Student[]): void => {
    if (selectedRowKeys.length === 0) {
      setSelectedRowKeys(selected);
      setSelectedStudent(selectedRows[0]);
    } else {
      if (selected.length === 0) {
        setSelectedRowKeys(selected);
        setSelectedStudent({} as Student);
      } else {
        const arr = selected.filter((item) => item !== selectedRowKeys[0]);
        setSelectedStudent(selectedRows[1]);
        setSelectedRowKeys(arr);
      }
    }
  };

  function showDelayConfirm() {
    confirm({
      title: 'Xác nhận bảo lưu',
      content: `Bạn có chắc chắn muốn bảo lưu học viên ${selectedStudent.full_name}?`,
      onOk() {
        delayStudent({
          class_id: classId,
          student_id: selectedStudent.id,
        })
          .unwrap()
          .then(() => {
            message.success('Bảo lưu học viên thành công');
            setSelectedStudent({} as Student);
            setSelectedRowKeys([] as Key[]);
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const showDeleteConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn muốn rút học viên khỏi lớp?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Xác nhận',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        removedStudentFromClass({
          class_id: classId,
          student_id: selectedStudent.id,
          data: { note: selectedStudent.note },
        })
          .unwrap()
          .then(() => {
            message.success('Rút học viên khỏi lớp thành công');
            setSelectedStudent({} as Student);
            setSelectedRowKeys([] as Key[]);
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  if (orderId) {
    return <Redirect to={generatePath(route.order.detail, { orderId })} />;
  }
  return (
    <>
      <LinksContainer
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {classStatus !== 'completed' ? (
            <>
              <Link.Warning
                to="#"
                onClick={() => setIsPendingModalVisible(true)}
              >
                Thêm KH tiềm năng
              </Link.Warning>
              <Link.Info to="#" onClick={() => setIsAddingModalVisible(true)}>
                Thêm học viên(không tự tạo đơn hàng)
              </Link.Info>

              <Link.Primary to="#" onClick={exportToExcel}>
                Xuất Excel
              </Link.Primary>

              <Link.Default
                to="#"
                onClick={() => {
                  window.open(
                    `${linkCurriculum}`,
                    '_blank',
                    'noopener, noreferrer',
                  );
                }}
              >
                Xem giáo trình
              </Link.Default>
              {selectedStudent.id &&
                selectedStudent.status !== 'completed' &&
                selectedStudent.status !== 'move' && (
                  <>
                    {selectedStudent.status !== 'pending' && (
                      <>
                        <Link.Warning to="#" onClick={showDelayConfirm}>
                          Bảo lưu
                        </Link.Warning>
                        {selectedStudent.status !== 'removed' && (
                          <Link.Danger to="#" onClick={showDeleteConfirm}>
                            Rút khỏi lớp
                          </Link.Danger>
                        )}
                      </>
                    )}
                    {['removed', 'delay'].includes(selectedStudent.status) && (
                      <Link.Info to="#" onClick={handleReturnStudentsClick}>
                        Quay lại lớp
                      </Link.Info>
                    )}
                    {selectedStudent.status === 'removed' && (
                      <Link.Danger to="#" onClick={handleDeleteStudent}>
                        Xóa khỏi lớp
                      </Link.Danger>
                    )}
                    <Link.Primary
                      to="#"
                      onClick={() => setIsMoveStudentModalVisible(true)}
                    >
                      Chuyển lớp
                    </Link.Primary>
                    {selectedStudent.status === 'pending' && (
                      <Link.Danger to="#" onClick={showDeleteConfirm}>
                        Xoá học viên dự bị
                      </Link.Danger>
                    )}
                  </>
                )}
              {selectedStudent.status === 'move' && (
                <Link.Info to="#" onClick={handleReturnStudentsClick}>
                  Quay lại lớp
                </Link.Info>
              )}
            </>
          ) : (
            <>
              <span
                style={{
                  fontSize: 16,
                  color: '#52c41a',
                  padding: '6px 12px',
                  textAlign: 'center',
                }}
              >
                Trạng thái: Đã kết thúc
              </span>
            </>
          )}
        </div>
        <Gain>Tổng doanh thu: {formatMoney(totalPaid)} VND</Gain>
      </LinksContainer>
      <TableContainer>
        <Table<Student>
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
            hideSelectAll: true,
          }}
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
          loading={isStudentsFetching || isPatchLoading}
          rowClassName={() => 'editable-row'}
          columns={columns}
          dataSource={students}
          pagination={false}
          rowKey="index"
          bordered
        />
      </TableContainer>

      {isPendingModalVisible && (
        <AddStudentToClass
          isModalVisible={isPendingModalVisible}
          closeModal={() => setIsPendingModalVisible(false)}
          status="pending"
        />
      )}

      {isAddingModalVisible && (
        <AddStudentToClass
          isModalVisible={isAddingModalVisible}
          closeModal={() => setIsAddingModalVisible(false)}
          status="active"
        />
      )}

      {isMoveStudentModalVisible && (
        <MoveStudentToAnotherClass
          isModalVisible={isMoveStudentModalVisible}
          closeModal={() => setIsMoveStudentModalVisible(false)}
          student_id={selectedStudent.id}
          resetSelected={() => {
            setSelectedStudent({} as Student);
            setSelectedRowKeys([] as Key[]);
          }}
        />
      )}

      {isCreateOrderModalVisible && (
        <CreateOrder
          isModalVisible={isCreateOrderModalVisible}
          closeModal={() => setIsCreateOrderModalVisible(false)}
          student={studentCreateOrder}
        />
      )}
    </>
  );
};
export default StudentsTable;

const Total = styled.span`
  &.paid {
    color: #00a65a;
  }
  &.debt {
    color: #ff0800;
  }
`;
const Gain = styled.span`
  padding: 6px 12px;
  color: #f39c12;
  font-size: 16px;
  text-align: center;
`;
