import {
  DeleteFilled,
  ExclamationCircleFilled,
  LeftOutlined,
} from '@ant-design/icons';
import { Col, Input, message, Row, Select } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import Editor from 'libraries/editor';
// import hljs from 'highlight.js';
import parser from 'libraries/editor/parser';
import {
  blogStatusValues,
  postTypeValues,
} from 'libraries/utils/globalVariable';
import { PostInfo } from 'models/blog.model';
import { Tag } from 'models/tag.model';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useDeleteBlogPostMutation,
  useUpdateBlogPostMutation,
} from 'services/blog.service';
import { selectUserRole } from 'store/auth.slice';
import styled from 'styled-components';
import Hotkeys from 'react-hot-keys';
import { updateBlogLocalStore } from 'utils/updateBlogLocalStore';
import { deleteOneBlogLocalStore } from 'utils/deleteOneBlogLocalStore';

interface PropsType {
  post: PostInfo;
  tags: Tag[];
  contents: any;
  postRevert: any;
  setInitValueBlog: any;
}

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PreviewButton = styled.a`
  font-size: 16px;
  height: 36px;
  line-height: 36px !important;
  padding: 0 15px !important;
`;

const { TextArea } = Input;
const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const PostContentPage = ({
  post,
  tags,
  contents,
  setInitValueBlog,
  postRevert,
}: PropsType): ReactElement => {
  const roleCheck = useSelector(selectUserRole);
  const blogLocalStore = JSON.parse(
    window.localStorage.getItem('blog') || '[]',
  );
  const history = useHistory();
  const [deletePost] = useDeleteBlogPostMutation();
  const [updatePostInfo] = useUpdateBlogPostMutation();

  const {
    id,
    title,
    content,
    description,
    thumbnail,
    slug,
    status,
    post_type,
    tag_ids,
  } = post;
  const [chosenThumbnail, setChosenThumbnail] = useState(thumbnail);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tmpType, setTmpType] = useState('');
  const [checkDescription, setCheckDescription] = useState(description);
  const [checkContent, setCheckContent] = useState(content);
  const typeStatuss = ['draft', 'public'];
  const [typeStatus, setTypeStatus] = useState(typeStatuss[0]);
  const handleTypeStatus = (value: string): void => {
    setTypeStatus(value);
  };

  const [form] = Form.useForm();

  const handleChangeContent = (value: string, html: string) => {
    setCheckContent(value);
    if (!contents) return;
    if (value.trim() === '' || value === contents) return;
    updateBlogLocalStore(blogLocalStore, id, {
      content: value,
    });
    form.setFieldsValue({
      content: value,
    });
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Xác nhận xoá',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn có chắc chắn muốn xoá bài viết này không ?',
      okText: 'Xoá',
      okType: 'danger',
      cancelText: 'Huỷ',
      onOk() {
        deletePost(id)
          .unwrap()
          .then(() => {
            message.success('Xoá bài viết thành công!');
            deleteOneBlogLocalStore(blogLocalStore, id);
            setTimeout(() => {
              history.push(generatePath(route.blog.list));
            }, 700);
          })
          .catch((error) => {
            message.error(error.data);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    if (checkContent === '') return;
    updatePostInfo({
      id: post.id,
      data: {
        ...values,
        content: parser.render(values.content),
        content_md: values.content,
        editor_type: post.editor_type,
        tag_ids: values.tag_ids ? values.tag_ids : tag_ids,
        thumbnail: values.thumbnail ? values.thumbnail : thumbnail,
      },
    })
      .unwrap()
      .then(() => {
        message.success('Cập nhật bài viết thành công');
        deleteOneBlogLocalStore(blogLocalStore, id);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      title,
      content,
      description,
      slug,
      post_type,
      status,
      thumbnail,
      tag_ids,
    });
    setTmpType(post_type);
  }, [
    title,
    content,
    description,
    slug,
    thumbnail,
    status,
    post_type,
    tag_ids,
    form,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      thumbnail: chosenThumbnail,
    });
  }, [chosenThumbnail, form]);

  const handlersHotkeys = (event: KeyboardEvent) => {
    event.preventDefault();
    handleSubmit(form.getFieldsValue());
  };
  const handleCheckSubmit = () => {
    if (checkContent === '') {
      message.error('Xin vui lòng nhập nội dung bài viết!');
      return;
    }
    if (checkDescription === '' && typeStatus === 'public') {
      message.error('Xin vui lòng nhập mô tả bài viết!');
    } else if (checkDescription?.length < 50 && typeStatus === 'public') {
      message.error('Nội dung phần mô tả ngắn phải tối thiểu 50 ký tự!');
    } else if (
      chosenThumbnail === '' &&
      thumbnail === '' &&
      typeStatus === 'public'
    ) {
      message.error('Bạn cần chọn ảnh thumbnail trước khi công khai bài viết');
    }
  };
  return (
    <>
      <Breadcrumb
        secondaryLink={
          roleCheck.isFullRole
            ? generatePath(route.blog.list)
            : generatePath(route.blog.own)
        }
        secondaryText="Blog"
        text={title}
      />
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={post}
        >
          <GroupButton>
            <LinksContainer style={{ display: 'flex' }}>
              <Link.Default to={generatePath(route.blog.list)}>
                <LeftOutlined /> Quay lại
              </Link.Default>
              <Button.Primary
                type="primary"
                htmlType="submit"
                style={{ height: '36px' }}
                onClick={handleCheckSubmit}
              >
                Lưu
              </Button.Primary>
              <PreviewButton
                href={`${process.env.REACT_APP_MAIN_URL}/preview/posts/${post.id}/${post.slug}`}
                target="_blank"
                rel="noreferrer"
                className="ant-btn ant-btn-primary"
              >
                Preview
              </PreviewButton>
            </LinksContainer>

            <LinksContainer>
              <Link.Danger to="#" onClick={showDeleteConfirm}>
                <DeleteFilled /> Xoá
              </Link.Danger>
            </LinksContainer>
          </GroupButton>
          <BorderTopBoxContent>
            <Row gutter={[30, 30]}>
              <Col xs={24} md={16}>
                <Form.Item
                  label={
                    <FormLabel
                      label="Tiêu đề"
                      smallText="(Độ dài cho phép 1-200 kí tự)"
                    />
                  }
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập tên tiêu đề bài viết!',
                    },
                  ]}
                >
                  <Input
                    maxLength={200}
                    onChange={(e) => {
                      updateBlogLocalStore(blogLocalStore, id, {
                        title: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <FormLabel
                      label="Nội dung"
                      smallText="(Không sử dụng Heading cấp 1 - #)"
                    />
                  }
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập nội dung bài viết!',
                    },
                  ]}
                >
                  {content === '' || content === undefined ? (
                    <Editor
                      value={content}
                      onChange={handleChangeContent}
                      onSave={() => form.submit()}
                    />
                  ) : (
                    <div>
                      <Editor
                        value={content}
                        onChange={handleChangeContent}
                        onSave={() => form.submit()}
                      />
                    </div>
                  )}
                </Form.Item>

                {typeStatus !== 'draft' ? (
                  <Form.Item
                    name="description"
                    label={
                      <FormLabel
                        label="Mô tả ngắn"
                        smallText="(Độ dài cho phép: tối thiểu 50 kí tự)"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Xin vui lòng nhập mô tả bài viết!',
                      },
                      {
                        min: 50,
                        message:
                          'Nội dung phần mô tả ngắn phải tối thiểu 50 ký tự!',
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      showCount
                      onChange={(e) => {
                        setCheckDescription(e.target.value);
                        if (e.target.value.trim() !== '') {
                          updateBlogLocalStore(blogLocalStore, id, {
                            description: e.target.value,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="description"
                    label={
                      <FormLabel
                        label="Mô tả ngắn"
                        smallText="(Độ dài cho phép: tối thiểu 50 kí tự)"
                      />
                    }
                  >
                    <TextArea
                      rows={3}
                      showCount
                      onChange={(e) => {
                        setCheckDescription(e.target.value);
                        if (e.target.value.trim() !== '') {
                          updateBlogLocalStore(blogLocalStore, id, {
                            description: e.target.value,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<FormLabel label="Trạng thái" />}
                  name="status"
                >
                  <Select onChange={handleTypeStatus}>
                    {blogStatusValues.map((item, index) => (
                      <Option value={item.value} key={index}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Loại bài viết" />}
                  name="post_type"
                >
                  <Select
                    disabled={roleCheck.isFullRole ? false : true}
                    onChange={(value: string) => setTmpType(value)}
                  >
                    {postTypeValues.map((item, index) => (
                      <Option value={item.value} key={index}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {tmpType !== 'special' && (
                  <>
                    {tags && (
                      <Form.Item
                        label={<FormLabel label="Danh mục" require={false} />}
                        name="tag_ids"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                        >
                          {tags.map((item, index) => {
                            return (
                              <Option key={index} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      label=""
                      name="thumbnail"
                      rules={
                        typeStatus !== 'draft'
                          ? [
                              {
                                required: true,
                                message: 'Xin vui lòng chọn ảnh bài viết!',
                              },
                            ]
                          : undefined
                      }
                    >
                      {chosenThumbnail !== '' ? (
                        <img
                          style={{ width: '100%', marginBottom: '20px' }}
                          src={chosenThumbnail}
                          alt="Chosen thumbnail"
                        />
                      ) : thumbnail !== '' ? (
                        <img
                          style={{ width: '100%', marginBottom: '20px' }}
                          src={thumbnail}
                          alt="Default thumbnail"
                        />
                      ) : (
                        ''
                      )}
                      <div>
                        <Button.Info onClick={() => setIsModalVisible(true)}>
                          Chọn ảnh bài viết
                        </Button.Info>
                        <p>
                          <em>
                            Chiều rộng: Từ 600px đến 1000px. Tỉ lệ ảnh 1.4. Định
                            dạng jpg hoặc png
                          </em>
                        </p>
                      </div>
                    </Form.Item>
                  </>
                )}
              </Col>
            </Row>
          </BorderTopBoxContent>
        </Form>
      </Hotkeys>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setChosenThumbnail}
        />
      )}
    </>
  );
};
export default PostContentPage;
