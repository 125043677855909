import { Col, Row } from 'antd';
import { classTypeValues } from 'libraries/utils/globalVariable';
import { ReactElement } from 'react';

interface PropsType {
  name: string;
  type: string;
  location: string;
}

const CollapseHeader = ({ name, type, location }: PropsType): ReactElement => {
  let classType = '';
  for (let i = 0; i < classTypeValues.length; i++) {
    if (classTypeValues[i].value === type) {
      classType = classTypeValues[i].label;
      break;
    }
  }
  return (
    <Row
      justify="space-between"
      align="middle"
      gutter={[2, 0]}
      style={{ width: '100%' }}
    >
      <Col xs={24} sm={24} md={24} lg={8}>
        {name}
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        Hình thức học: {classType}
      </Col>
      <Col xs={24} sm={24} md={12} lg={8}>
        Địa điểm: {location}
      </Col>
    </Row>
  );
};

export default CollapseHeader;
