import Modal from 'components/commons/Modal';
import Span from 'libraries/components/commons/Span';
import { Form, Input, InputNumber, message } from 'antd';

import { formatMoneyVnd } from 'libraries/utils/changeFormatData';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrder } from 'store/order.slice';
import Button from 'libraries/components/commons/Button';
import { useAdjustOrderMutation } from 'services/order.service';
import { useParams } from 'react-router';
import convertCurrencyToWords from 'utils/convertCurrencyToWords';

interface ModalAdjustProps {
  isAdjustModalVisible: boolean;
  closeModal: () => void;
  handleOk: () => void;
  handleCancel: () => void;
}

interface AdjustStatusProps {
  title: string;
  money: number;
  className: string;
}

const { TextArea } = Input;

export const AdjustStatus = ({
  title,
  money,
  className,
}: AdjustStatusProps): ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p style={{ margin: '0' }}>{title}</p>
      <Span className={className}>{formatMoneyVnd(money)}</Span>
    </div>
  );
};

const ModalAdjust = ({
  isAdjustModalVisible,
  handleOk,
  handleCancel,
  closeModal,
}: ModalAdjustProps): ReactElement => {
  const dataOrder = useSelector(selectOrder);
  const { paid, should_pay, debt } = dataOrder;
  const [adjustOrder] = useAdjustOrderMutation();
  const { orderId } = useParams<{ orderId: string }>();
  const [isAdjust, setIsAdjust] = useState(true);
  const [numberMoney, setNumberMoney] = useState('');
  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    adjustOrder({ order_id: orderId, data: values })
      .unwrap()
      .then(() => {
        closeModal();
        message.success('Chỉnh sửa đơn hàng thành công');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleChange = () => {
    setIsAdjust(false);
  };

  return (
    <Modal
      title="Cập nhật tổng đơn hàng mới"
      visible={isAdjustModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <AdjustStatus
        title="Tổng đơn hàng"
        money={should_pay}
        className="primary"
      />
      <AdjustStatus title="Đã thanh toán" money={paid} className="success" />
      <AdjustStatus title="Nợ" money={debt} className="warning" />

      <hr />
      <Form
        name="basic"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={handleChange}
      >
        <div style={{ position: 'relative' }}>
          <Form.Item
            label={<span style={{ fontSize: '14px' }}>Tổng đơn hàng mới</span>}
            name="should_pay"
            rules={[{ required: true, message: 'Vui lòng nhập số tiền' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={(value: any) => setNumberMoney(value)}
            />
          </Form.Item>
          <p
            style={{
              marginTop: '-24px',
              fontWeight: '600',
              position: 'absolute',
              top: '135%',
              left: 'left:0px;',
            }}
          >
            {convertCurrencyToWords(+numberMoney)}
          </p>
        </div>

        <Form.Item
          label={<span style={{ fontSize: '14px' }}>Ghi chú</span>}
          name="note"
          rules={[{ required: true, message: 'Vui lòng nhập ghi chú' }]}
        >
          <TextArea />
        </Form.Item>
        <div style={{ textAlign: 'end' }}>
          <Button.Default htmlType="submit" disabled={isAdjust}>
            Cập nhật
          </Button.Default>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAdjust;
