import { ReactElement } from 'react';
import { Select } from 'antd';

import { ClassFind } from 'models/class.model';

interface PropsType {
  classes: ClassFind[];
  destClass: string;
  setDestClass: (value: string) => void;
  setClassInfo: (value: ClassFind) => void;
}

const { Option } = Select;

const SelectClass = ({
  classes,
  destClass,
  setDestClass,
  setClassInfo,
}: PropsType): ReactElement => {
  return (
    <>
      <label>Chọn lớp chuyển vào</label>
      <Select
        showSearch
        optionFilterProp="children"
        defaultValue={destClass}
        value={destClass}
        onChange={(value) => {
          setDestClass(value);
          setClassInfo(classes.filter((item) => item.id === value)[0]);
        }}
        style={{ width: '100%' }}
        placeholder="Danh sách lớp học"
      >
        {classes.map((item, index) => {
          return (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          );
        })}
      </Select>
    </>
  );
};
export default SelectClass;
