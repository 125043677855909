import { Col, InputNumber, message, Row } from 'antd';
import Button from 'libraries/components/commons/Button';
import Form from 'libraries/components/form/Form';
import { ReactElement, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAddSalaryMutation } from 'services/classes.service';
import { selectClass } from 'store/classes.slice';

interface LabelProps {
  handleChange: (arg: any) => void;
  isSelect: boolean;
  text: string;
  id: string;
}

const Label = (funcs: LabelProps): ReactElement => {
  const { handleChange, isSelect, text, id } = funcs;
  return (
    <label>
      <input
        id={id}
        type="radio"
        name="salary"
        onChange={handleChange}
        checked={isSelect}
      />
      {text}
    </label>
  );
};

const Salary = (): ReactElement => {
  const classroom = useSelector(selectClass);
  const {
    id,
    salary_per_student,
    salary_fixed,
    min_salary,
    salary_total,
    max_salary,
  } = classroom;

  const [selectId, setSelectId] = useState(() => {
    return salary_per_student > 0
      ? 'salary_per_student'
      : salary_fixed > 0
        ? 'salary_fixed'
        : salary_total > 0
          ? 'salary_total'
          : max_salary > 0
            ? 'max_salary'
            : 'salary_per_student';
  });
  const [form] = Form.useForm();

  const [addSalary] = useAddSalaryMutation();

  const handleChange = (values: React.FormEvent<HTMLInputElement>) => {
    const id = values.currentTarget.id;
    setSelectId(id);
    switch (id) {
      case 'salary_fixed':
        form.setFieldsValue({
          salary_per_student: 0,
          min_salary: 0,
          salary_total: 0,
          max_salary: 0,
        });
        break;
      case 'salary_per_student':
        form.setFieldsValue({
          salary_total: 0,
          salary_fixed: 0,
        });
        break;
      default:
        form.setFieldsValue({
          salary_per_student: 0,
          salary_fixed: 0,
        });
    }
  };

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    addSalary({ class_id: id, data: values })
      .unwrap()
      .then(() => message.success('Lưu thông tin thành công'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      initialValues={{
        salary_per_student,
        salary_fixed,
        min_salary,
        max_salary,
        salary_total,
      }}
    >
      <Row>
        <Col xs={24} lg={8}>
          <Form.Item
            name="salary_per_student"
            label={
              <Label
                id="salary_per_student"
                handleChange={handleChange}
                text="Tính theo điểm danh"
                isSelect={'salary_per_student' === selectId}
              />
            }
          >
            <InputNumber
              style={{ width: '100%' }}
              step={1000}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              disabled={'salary_per_student' !== selectId}
              addonAfter="VNĐ/ học viên"
            />
          </Form.Item>
          <Form.Item
            name="salary_fixed"
            label={
              <Label
                id="salary_fixed"
                handleChange={handleChange}
                text="Thù lao thoả thuận theo buổi"
                isSelect={'salary_fixed' === selectId}
              />
            }
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              step={1000}
              disabled={'salary_fixed' !== selectId}
              addonAfter="VNĐ/ buổi"
            />
          </Form.Item>
          <Form.Item
            name="salary_total"
            label={
              <Label
                id="salary_total"
                handleChange={handleChange}
                text="Tính theo số học viên"
                isSelect={'salary_total' === selectId}
              />
            }
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              step={1000}
              disabled={'salary_total' !== selectId}
              addonAfter="VNĐ/ học viên"
            />
          </Form.Item>
          <Form.Item name="min_salary" label="Lương tối thiểu">
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              step={1000}
              disabled={selectId === 'salary_fixed'}
              addonAfter="VNĐ"
            />
          </Form.Item>
          <Form.Item name="max_salary" label="Lương tối đa">
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              step={1000}
              disabled={selectId === 'salary_fixed'}
              addonAfter="VNĐ"
            />
          </Form.Item>
          <Button.Primary type="primary" htmlType="submit">
            Lưu thông tin
          </Button.Primary>
        </Col>
      </Row>
    </Form>
  );
};

export default Salary;
