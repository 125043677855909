import { ReactElement } from 'react';
import { Menu } from 'antd';
import { useLogoutMutation } from 'libraries/services/auth.service';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth.slice';
import { ROLE_STUDENT, ROLE_TRAINER } from 'libraries/utils/globalVariable';

const Dropdown = styled(Menu)`
  &&& {
    width: 200px;
    text-align: center;
    margin-top: 10px;
    padding: 5px 0;
    background: #ffffff;
    border: 0;
    box-shadow: 0 2px 3px #d5d7da;
  }
`;

const DropdownItem = styled(Menu.Item)`
  &&& {
    padding-left: 25px;
    padding-right: 25px;
  }

  &&&.ant-menu-item-active {
    background: #e6f7ff;
  }
`;

const UserDropdown = (): ReactElement => {
  const { roles } = useSelector(selectUser);

  const teacherLink = process.env.REACT_APP_TEACHER_URL
    ? process.env.REACT_APP_TEACHER_URL
    : '';
  const learnLink = process.env.REACT_APP_LEARN_URL
    ? process.env.REACT_APP_LEARN_URL
    : '';
  const profileLink = process.env.REACT_APP_PROFILE_URL
    ? process.env.REACT_APP_PROFILE_URL
    : '';

  const [logout] = useLogoutMutation();

  const handleLogout = () => {
    logout();
  };

  return (
    <Dropdown>
      <DropdownItem key={1}>
        <a href="https://techmaster.vn/">Trang chủ</a>
      </DropdownItem>
      {roles.includes(ROLE_TRAINER) && (
        <DropdownItem key={2}>
          <a href={teacherLink}>Trang giảng viên</a>
        </DropdownItem>
      )}
      {roles.includes(ROLE_STUDENT) && (
        <DropdownItem key={4}>
          <a href={learnLink}>Lớp học của tôi</a>
        </DropdownItem>
      )}
      <DropdownItem key={5}>
        <a href={profileLink}>Hồ sơ cá nhân</a>
      </DropdownItem>

      <DropdownItem key={6}>
        <a onClick={handleLogout}>Đăng xuất</a>
      </DropdownItem>
    </Dropdown>
  );
};

export default UserDropdown;
