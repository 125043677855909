import { CalendarOutlined } from '@ant-design/icons';
import { Dropdown, Empty, Input } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { Collapse, Panel } from 'components/commons/Collapse';
import ServerError from 'components/commons/ServerError';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { TeacherAttendanceSalary } from 'models/report.model';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useGetTeacherSalaryReportQuery,
  useGetUserBonusSalaryReportQuery,
} from 'services/report.service';
import { selectTeacher } from 'store/report.slice';
import styled from 'styled-components';
import DropdownMenu from 'components/report/DropdownMenu';
import AttendancesSalaryTable from './AttendancesSalaryTable';
import CollapseHeader from './collapse/CollapseHeader';
import ExportExcel from './ExportExcel';
import { useDateRange } from 'libraries/hooks/date';
import BonusRequestTable from 'pages/report/sale/detail/tables/BonusRequestTable';
import { selectUser } from 'store/auth.slice';
import BonusSalaryTable from 'pages/report/sale/detail/tables/BonusSalaryTable';
import { BonusDetails } from 'models/report.model';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface StateType {
  from?: string;
  to?: string;
}

const TeacherDetailContent = (): ReactElement => {
  const { userId } = useParams<{ userId: string }>();
  const teacher = useSelector(selectTeacher);
  const [visible, setVisible] = useState(false);
  const { state } = useLocation<StateType>();
  const { id, roles } = useSelector(selectUser);

  const { firstDay, lastDay, setFirstDay, setLastDay, format } = useDateRange(
    state?.from,
    state?.to,
  );

  const { data, isError } = useGetTeacherSalaryReportQuery({
    id: userId,
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });

  const {
    data: bonus,
    isLoading: isBonusLoading,
    isError: isBonusError,
  } = useGetUserBonusSalaryReportQuery({
    id: userId,
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });

  const bonusProps = {
    bonus: bonus ?? ([] as BonusDetails[]),
    firstDay,
    lastDay,
    isLoading: isBonusLoading,
    isError: isBonusError,
  };

  const attendances = data
    ? data.salary_attendance
    : ([] as TeacherAttendanceSalary[]);

  const attendances_total = attendances.reduce((count, item) => {
    count += item.salary;
    return count;
  }, 0);

  if (isError) {
    return <ServerError />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.report.teacher.list)}
        secondaryText="Báo cáo thu nhập chung"
        text={`Thu nhập của giảng viên ${teacher.full_name}`}
      />
      <GroupButton>
        <LinksContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ marginTop: '5px' }}>
            <Dropdown
              overlay={
                <DropdownMenu
                  setVisible={setVisible}
                  firstDay={firstDay}
                  lastDay={lastDay}
                  setFirstDay={setFirstDay}
                  setLastDay={setLastDay}
                />
              }
              placement="bottomLeft"
              arrow
              trigger={['click']}
              visible={visible}
              onVisibleChange={(flag: boolean) => setVisible(flag)}
            >
              <Input
                style={{ width: 250 }}
                addonBefore={<CalendarOutlined />}
                value={`${format(firstDay)} - ${format(lastDay)}`}
              />
            </Dropdown>
          </div>
          <ExportExcel
            name={teacher.full_name}
            attendances={attendances}
            firstDay={format(firstDay)}
            lastDay={format(lastDay)}
            bonuses={bonus ?? ([] as BonusDetails[])}
          />
        </LinksContainer>
      </GroupButton>
      <Collapse accordion>
        <Panel
          key={1}
          header={<CollapseHeader title="Daỵ học" total={attendances_total} />}
        >
          <AttendancesSalaryTable attendances={attendances} />
        </Panel>
        {!roles.includes(1) ? (
          <Panel key={2} header={<CollapseHeader title="Lương thưởng" />}>
            {userId === id ? (
              <BonusRequestTable {...bonusProps} />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Không thể xem lương thưởng của giảng viên khác"
              />
            )}
          </Panel>
        ) : (
          <Panel header={<CollapseHeader title="Duyệt thưởng" />} key={2}>
            <BonusSalaryTable
              bonus={bonus ?? ([] as BonusDetails[])}
              isLoading={isBonusLoading}
              isError={isBonusError}
              userId={userId}
            />
          </Panel>
        )}
      </Collapse>
    </>
  );
};

export default TeacherDetailContent;
