import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { UserTransaction } from 'models/user.model';
import { formatDate } from 'utils/formatDate';
import { orderStatusValues } from 'libraries/utils/globalVariable';
import Span from 'libraries/components/commons/Span';
import { generatePath, Link } from 'react-router-dom';
import { route } from 'routers/routeNames';

const TransactionContainer = styled.div`
  font-size: 16px;

  .trans-success {
    font-weight: 550;
    color: green;
  }
  .trans-fail {
    font-weight: 550;
    color: red;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

interface PropsType {
  transactionsList: UserTransaction[];
}

const columns: ColumnsType<UserTransaction> = [
  {
    title: 'ID đơn hàng',
    dataIndex: 'order_id',
    render: (order_id) => (
      <Link
        to={generatePath(route.order.detail, {
          orderId: order_id,
        })}
      >
        {order_id}
      </Link>
    ),
  },
  {
    title: 'Tên khoá học',
    dataIndex: 'items',
    render: (items) => (
      <Ul>
        {items.map((item: string, index: number) => (
          <li key={index}>{item}</li>
        ))}
      </Ul>
    ),
  },
  {
    title: 'Học phí',
    dataIndex: 'total',
    sorter: {
      compare: (a, b) => a.total - b.total,
    },
    render: (total) =>
      new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(total),
  },
  {
    title: 'Ngày đăng kí',
    dataIndex: 'order_at',
    render: (date) => <span>{formatDate(date)}</span>,
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (status) => {
      if (status !== '') {
        for (let i = 0; i < orderStatusValues.length; i++) {
          const item = orderStatusValues[i];
          if (item.value === status) {
            return (
              <Span
                style={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
                className={item.className}
              >
                {item.label}
              </Span>
            );
          }
        }
      }
      return '';
    },
  },
];

const TransactionItem = ({ transactionsList }: PropsType): ReactElement => {
  return (
    <TransactionContainer>
      <Table
        columns={columns}
        dataSource={transactionsList}
        bordered
        pagination={false}
        rowKey="order_id"
      />
    </TransactionContainer>
  );
};

export default TransactionItem;
