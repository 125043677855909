import { createSlice, createSelector } from '@reduxjs/toolkit';
import { orderApi } from 'services/order.service';
import { RootState } from 'store';
import {
  Advisory,
  AdvisoryDetail,
  OrdersListType,
  OrderType,
} from 'models/order.model';

interface PriceType {
  type: string;
  price: number;
}
export interface ListItemType {
  class_type: string;
  discount: number;
  discount_percent: number;
  item_id: string;
  name: string;
  price: PriceType[];
  price_id: number;
  type: string;
}

export interface ItemOrderDetail {
  choose_type: string;
  discount: number;
  discount_percent: number;
  item_id: string;
  price_id: number;
  name: string;
  price: PriceType[];
  type: string;
  class_type: 'lab' | 'live';
}
export interface OrderState {
  orders: OrdersListType[];
  order: OrderType;
  items: ListItemType[];
  classes: ClassesType;
  total_page: number;
  currentPage: number;
  keySearch: string;
  advisory: AdvisoryDetail;
  advisories: Advisory[];
}

export interface Classes {
  id: string;
  name: string;
  type: 'live' | 'lab' | '';
}

export interface ClassesType {
  classes_added: Classes[];
  classes_not_add: Classes[];
}

const initialState: OrderState = {
  orders: [] as OrdersListType[],
  order: {} as OrderType,
  items: [] as ListItemType[],
  classes: {} as ClassesType,
  total_page: 0,
  currentPage: 1,
  keySearch: `page=1&id=&fullname=&email=&phone=&item=&status=&type=&order=`,
  advisory: {} as AdvisoryDetail,
  advisories: [] as Advisory[],
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setTotalPage: (state, { payload }) => {
      state.total_page = payload;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setKeySearch: (state, { payload }) => {
      state.keySearch = payload;
    },
    setItemToOrder: (state, { payload }) => {
      state.order.items.push(payload);
    },
    deleteItemInOrder: (state, { payload }) => {
      state.order.items.splice(payload, 1);
    },
    addStudentToOrder: (state, { payload }) => {
      state.order.students.push(payload);
    },
    // updateHistoryOrder: (state, { payload }) => {
    //   state.order.history= state.order.history.map(item=>{
    //     if(item.id==payload.event_id){
    //       return {...item,payload.status,payload.reply}
    //     }else{return item}
    //   })
    // },
    addStudent: (state, { payload }) => {
      state.classes.classes_not_add.splice(
        state.classes.classes_not_add.findIndex(
          (item) => item.id === payload.id,
        ),
        1,
      );
      state.classes.classes_added.push(payload);
    },
    deleteStudentInOrder: (state, { payload }) => {
      state.order.students.splice(payload, 1);
    },
    updateAdvisoryHistory: (state, { payload }) => {
      state.advisory.history.push(payload);
    },
    deleteAdvisoryFromList: (state, { payload }) => {
      const newArr = state.advisories.filter((item) => item.id !== payload);
      state.advisories = newArr;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      orderApi.endpoints.getListOrders.matchFulfilled,
      (state, { payload }) => {
        const { total_page, order_list } = payload;
        state.orders = order_list;
        state.total_page = total_page;
      },
    );

    builder.addMatcher(
      orderApi.endpoints.getOrderInfo.matchFulfilled,
      (state, { payload }) => {
        state.order = payload;
      },
    );

    builder.addMatcher(
      orderApi.endpoints.adjustOrder.matchFulfilled,
      (state, { payload }) => {
        const newListHistory = [...state.order.history];
        newListHistory.push(payload.history);
        state.order = {
          ...state.order,
          debt: payload.debt,
          note: payload.note,
          paid: payload.paid,
          should_pay: payload.should_pay,
          history: newListHistory,
        };
      },
    );

    builder.addMatcher(
      orderApi.endpoints.getListAdvisory.matchFulfilled,
      (state, { payload }) => {
        state.advisories = payload.list_advisories;
      },
    );

    builder.addMatcher(
      orderApi.endpoints.getListItem.matchFulfilled,
      (state, { payload }) => {
        state.items = payload;
      },
    );

    builder.addMatcher(
      orderApi.endpoints.getListClass.matchFulfilled,
      (state, { payload }) => {
        state.classes = payload;
      },
    );

    // builder.addMatcher(
    //   orderApi.endpoints.addStudentToClass.matchFulfilled,
    //   (state, { payload }) => {
    //     console.log(payload);
    //     state.classes = payload;
    //   },
    // );

    builder.addMatcher(
      orderApi.endpoints.saveItemToOrder.matchFulfilled,
      (state, { payload }) => {
        state.order.history.push(payload.history);
      },
    );

    builder.addMatcher(
      orderApi.endpoints.updateOrderInfo.matchFulfilled,
      (state, { payload }) => {
        state.order = {
          ...state.order,
          history: state.order.history.concat(payload.history),
          sponsor: payload.company_info,
          note: payload.note,
          payment_type: payload.payment_type,
          is_company: payload.is_company,
        };
      },
    );

    builder.addMatcher(
      orderApi.endpoints.payOrder.matchFulfilled,
      (state, { payload }) => {
        const { debt, paid, status } = payload;
        state.order = {
          ...state.order,
          debt,
          paid,
          status,
        };
      },
    );

    builder.addMatcher(
      orderApi.endpoints.getAdvisoryDetail.matchFulfilled,
      (state, { payload }) => {
        state.advisory = payload;
      },
    );

    builder.addMatcher(
      orderApi.endpoints.deleteOrder.matchFulfilled,
      (state) => {
        state.order = {
          ...state.order,
          status: 'cancel',
        };
      },
    );
  },
});

export const {
  setTotalPage,
  setCurrentPage,
  setKeySearch,
  setItemToOrder,
  deleteItemInOrder,
  addStudentToOrder,
  deleteStudentInOrder,
  updateAdvisoryHistory,
  addStudent,
  deleteAdvisoryFromList,
} = orderSlice.actions;

export const selectOrdersList = (state: RootState): OrdersListType[] =>
  state.order.orders;

export const selectTotalPage = (state: RootState): number =>
  state.order.total_page;

export const selectOrder = (state: RootState): OrderType => state.order.order;

export const selectCurrentPage = (state: RootState): number =>
  state.order.currentPage;

export const selectKeySearch = (state: RootState): string =>
  state.order.keySearch;

export const selectListClass = (state: RootState): ClassesType => {
  return state.order.classes;
};
// get list item available and filter items haven't in order
export const selectItemsHasFilter = (state: RootState): ListItemType[] =>
  state.order.items.filter(
    (item) =>
      !state.order.order.items
        .map((item) => item.item_id)
        .includes(item.item_id),
  );

export const selectListItemInOrder = (state: RootState): ItemOrderDetail[] =>
  state.order.order.items;

export const selectTotalPrice = createSelector(
  selectListItemInOrder,
  (items: ItemOrderDetail[]) => {
    let totalDiscount = 0;
    let totalPercent = 0;
    let totalZero = 0;
    let totalTemp = 0;

    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        const result = items[i].price.map((item) => item.price);
        if (items[i].discount === 0 && items[i].discount_percent === 0) {
          totalZero += result[0];
        }
        if (items[i].discount > 0) {
          totalDiscount += result[0] - items[i].discount;
        }
        if (items[i].discount_percent > 0) {
          const percent = result[0] * (100 - items[i].discount_percent);
          totalPercent += percent / 100;
        }
      }
      totalTemp = totalDiscount + totalPercent + totalZero;
    }

    return totalTemp;
  },
);

export const selectItems = (state: RootState): ListItemType[] =>
  state.order.items;

export const selectAdvisory = (state: RootState): AdvisoryDetail =>
  state.order.advisory;

export const selectListAdvisories = (state: RootState): Advisory[] => {
  return state.order.advisories;
};

export default orderSlice.reducer;
