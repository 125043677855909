import { createApi } from '@reduxjs/toolkit/query/react';
import { Tag } from 'models/tag.model';
import { baseQuery } from './base.service';

export const tagsApi = createApi({
  reducerPath: 'tagsApi',

  baseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getTags: builder.query<Tag[], string>({
      query: (keySearch) => {
        return {
          url: `tags?${keySearch}`,
          method: 'GET',
        };
      },
      transformResponse: (response: Tag[]) => {
        response = response === null ? ([] as Tag[]) : response;
        if (response.length > 0) {
          response.map((item) => {
            item.types = item.types === null ? ([] as string[]) : item.types;
          });
        }
        return response;
      },
    }),
    createNewTag: builder.mutation<Tag, { name: string }>({
      query: (data) => {
        return {
          url: `tags`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (response: Tag) => {
        response.types = [] as string[];
        return response;
      },
    }),
    editTag: builder.mutation<Tag, Tag>({
      query: (data) => {
        return {
          url: `tags/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      transformResponse: (response: Tag) => {
        response.types =
          response.types === null ? ([] as string[]) : response.types;
        return response;
      },
    }),
    deleteTag: builder.mutation<string, string>({
      query: (id) => {
        return {
          url: `tags/${id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetTagsQuery,
  useCreateNewTagMutation,
  useEditTagMutation,
  useDeleteTagMutation,
} = tagsApi;
