import { InputNumber, DatePicker, Form, Typography, Input } from 'antd';
import React, {
  isValidElement,
  ReactElement,
  useCallback,
  useRef,
} from 'react';
import Editor from 'libraries/editor/Editor';
import ShowMarkdown from 'libraries/components/markdown/ShowMarkdown';
import { formatDate } from 'utils/formatDate';
import { detailCell } from './BonusRequestTable';
import { v4 } from 'uuid';
import styled from 'styled-components';

interface FormItemsProps {
  isEdit: boolean;
  isReadOnly: boolean;
  editingItem: [detailCell, number];
  isReject: boolean;
  isAdding: boolean;
}
const ShowMarkdownStyle = styled.div`
  .show-markdown div {
    min-height: 250px !important;
    display: flex;
    align-items: start;
    flex-direction: column;
  }
`;

const { Text } = Typography;

const BonusFormItems = ({
  isEdit,
  isReadOnly,
  editingItem,
  isReject,
  isAdding,
}: FormItemsProps): ReactElement => {
  const [item] = editingItem;
  const keys = Object.keys(item).filter((key) =>
    ['bonus', 'date', 'note', 'content', 'reason'].includes(key),
  );
  const formItemsPattern = useCallback(
    (interactive: JSX.Element, readonly: JSX.Element, label: string) => {
      return {
        node: isReadOnly ? readonly : interactive,
        label,
      };
    },
    [isReadOnly],
  );

  return (
    <>
      {keys.map((el: string) => {
        const input = () => {
          switch (el) {
            case 'date':
              return formItemsPattern(
                isAdding ? (
                  <DatePicker format={'DD-MM-YYYY'} />
                ) : (
                  <div>{formatDate(item.date)}</div>
                ),
                <div>{formatDate(item.date)}</div>,
                'Ngày thực hiện',
              );
            case 'bonus':
              return formItemsPattern(
                !isReject ? (
                  <InputNumber
                    min={0}
                    addonAfter={'VND'}
                    step={10000}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                ) : (
                  <div>{item.bonus.toLocaleString()} VND</div>
                ),
                <div>{item.bonus.toLocaleString()} VND</div>,
                'Lương thưởng',
              );
            case 'note':
              return formItemsPattern(
                isEdit ? (
                  <ShowMarkdownStyle>
                    <ShowMarkdown
                      height={250}
                      markdown={item.note}
                      short_content
                    />
                  </ShowMarkdownStyle>
                ) : (
                  <></>
                ),
                <ShowMarkdownStyle>
                  <ShowMarkdown
                    height={250}
                    markdown={item.note}
                    short_content
                  />
                </ShowMarkdownStyle>,
                'Ghi chú',
              );
            case 'reason':
              return formItemsPattern(
                <Editor height={250} />,
                <div dangerouslySetInnerHTML={{ __html: item.reason }} />,
                'Phản hồi',
              );
            default:
              return formItemsPattern(
                isEdit ? <span>{item.content}</span> : <Input />,
                <div>{item.content}</div>,
                'Mô tả',
              );
          }
        };
        const { node, label } = input();
        if (node.type === React.Fragment)
          return <React.Fragment key={v4()}></React.Fragment>;
        return (
          <Form.Item
            name={el}
            label={<Text strong>{label}</Text>}
            key={v4()}
            rules={[
              {
                required: isReject || isEdit || isAdding ? true : false,
                message: `${label} không được để trống`,
              },
            ]}
          >
            {node}
          </Form.Item>
        );
      })}
    </>
  );
};

export default BonusFormItems;
